define("mytaskpanel/templates/components/utils/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vuQG7ag4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"p\"],[11,\"class\",\"centered\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner size-spinner fa-spin\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/utils/loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});