define("mytaskpanel/services/price", ["exports", "moment", "mytaskpanel/helpers/get-date-with-format"], function (_exports, _moment, _getDateWithFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    client: null,
    packagePreSelected: null,
    toast: Ember.inject.service('toast'),

    /**
     * @method setInitialData
     * @description stay the initial data of the component
     */
    async setInitialData() {
      const clientId = this.get('session.data.authenticated.user').client;
      const response = await this.get('ajax').request('/get-or-update-client?client_id=' + clientId, {
        method: 'GET'
      });
      const client = response.data;
      const packagePreSelected = client.package;
      this.set('client', client);
      this.set('packagePreSelected', packagePreSelected);
      Ember.$('#renew_date').html((0, _getDateWithFormat.getDateWithFormat)(client.renew_date));
      Ember.$('#pre-package-name').html(packagePreSelected.name);
      const dueDate = client.due_date;

      if (dueDate !== undefined && dueDate !== null && dueDate !== '') {
        Ember.$('#due_date').html((0, _getDateWithFormat.getDateWithFormat)(dueDate));
      }

      Ember.$('#add_date').html((0, _getDateWithFormat.getDateWithFormat)(client.add_date));
      return client;
    },

    /**
     * @method createCheckout
     * @description save payment
     */
    async createCheckout(packageSelected, billingCycle) {
      const data = {
        gateway: 'paypal',
        client_id: this.get('session.data.authenticated.user').client,
        package_id: packageSelected.id,
        yearPayment: billingCycle == 'annual' ? true : false,
        name: Ember.$('#name2').val(),
        address: Ember.$('#address').val(),
        company_name: Ember.$('#company').val(),
        country: Ember.$('#pais').val(),
        city: Ember.$('#city').val(),
        amount: parseFloat(Ember.$("#billingCycle-mount").text()) || 0,
        package_name: packageSelected.name,
        province: Ember.$('#provincia').val(),
        type: Ember.$('input[name="inlineRadioOptions"]:checked').val(),
        vat_number: Ember.$('#vat-number').val()
      };
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });
      return await this.ajax.request('/generate-checkout/', {
        method: 'POST',
        processData: false,
        contentType: false,
        data: formData
      });
    },

    /**
     * @method onSelectedPlan
     * @description handle selected plan
     */
    onSelectedPlan(packageSelected) {
      Ember.$('.selected-div').html('');
      Ember.$(`#selected-div-${packageSelected.id}`).append(`<div class="ribbon"><span>Seleccionado</span></div>`);
      Ember.$('#month_value-1').html(packageSelected.month_value + ' €');
      Ember.$('#year_value-1').html(packageSelected.year_value + ' €');
      Ember.$('#year_value-1-50').html(packageSelected.year_value + 50 + ' €');
      Ember.$('.package-name-label').html(packageSelected.name);
      Ember.$('#company').val(this.get('session').get('session.content.authenticated.user.client_name'));
    },

    /**
     * @method onChangeBillingCycle
     * @description handle change billing cycle
     */
    async onChangeBillingCycle(billingCycle, packageSelected) {
      Ember.$('#billingCycle-3').html(`${this.get('intl').t('suscription.plan')}: ${this.get('intl').t(`calendar.${billingCycle}`)}`);
      Ember.$('#billingCycle-2').html(`${this.get('intl').t('suscription.plan')}: ${this.get('intl').t(`calendar.${billingCycle}`)}`);
      const pricePlan = await this.getPricePlanSelected(billingCycle, packageSelected);
      const expirationDate = (0, _moment.default)(pricePlan.due_date, 'YYYY-MM-DD');

      if (pricePlan.error_code) {
        this.get('toast').error(this.get('intl').t(`upgrade.${pricePlan.error_code}`));
        return false;
      } else {
        if (billingCycle === 'monthly') {
          Ember.$('#tr-anual').css('background', '');
          Ember.$('#tr-mensual').css('background', '#08d98d');
        } else {
          Ember.$('#tr-anual').css('background', '#08d98d');
          Ember.$('#tr-mensual').css('background', '');
        }

        Ember.$('#new_expiration_date').html(expirationDate.format('DD/MM/YYYY'));
        Ember.$('#result-due-date').html(expirationDate.format('DD/MM/YYYY'));
        Ember.$('#billingCycle-mount').html(pricePlan.price + ' €');
        Ember.$('#billingCycle-mount-3').html(pricePlan.price + ' €');
        Ember.$('#total-cost-label').html('€' + pricePlan.price);
        return true;
      }
    },

    /**
     * @method getPricePlanSelected
     * @description Get the amount of plan selected
     * @param billingCycle {string} If is by month or year
     * @param packageSelected {object} Package selected
     */
    async getPricePlanSelected(billingCycle, packageSelected) {
      const data = {
        client_id: this.get('session.data.authenticated.user').client,
        package_id: packageSelected.id,
        year_payment: billingCycle == 'annual' ? true : false
      };
      const response = await this.ajax.request('/calculate-price/', {
        method: 'POST',
        data: data
      });
      return response.data;
    },

    /**
     * @method updateClientStorage
     * @description Update local storage after payment
     * @param client
     */
    updateClientStorage(client) {
      this.get('session').set('session.content.authenticated.user.date_expired_client', client.date_expired_client);
      this.get('session').set('session.content.authenticated.user.licence_type', client.licence_type);
      const newSession = this.get('session.data');
      const key = this.get('session.store._store.key'); // Save in localStorage

      localStorage.setItem(key, JSON.stringify(newSession));
    }

  });

  _exports.default = _default;
});