define("mytaskpanel/controllers/tasks/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service('ajax'),
    guideTour: Ember.inject.service('guide-tour'),
    intl: Ember.inject.service('intl'),
    store: Ember.inject.service('store'),
    sprintService: Ember.inject.service('sprint'),
    taskService: Ember.inject.service('task'),
    swal: Ember.inject.service('swal'),
    toast: Ember.inject.service('toast'),
    application: Ember.inject.controller('application'),
    searchFilterValue: Ember.computed.alias('application.searchFilterValue'),
    queryParams: ['task_id'],
    task_id: null,
    sprintName: '',
    currentSprintId: '',
    previousSprint: false,
    viewTasksSelected: 'state',
    showLoading: false,
    collapseTasks: true,
    hiddenCompleteTask: false,
    projectId: '',
    projectName: '',
    sprints: null,
    netSprints: null,
    dataProject: null,
    loadModel: Ember.observer('model', async function () {
      // Check if in the localStorage has value previously selected for collapse
      const projectId = this.model.params.project_id;
      const collapseValue = window.localStorage.getItem(`collapse_${projectId}`);

      if (collapseValue !== null && collapseValue !== undefined) {
        this.set('collapseTasks', collapseValue === "true" ? true : false);
      } else {
        // Default value
        this.set('collapseTasks', true);
      } // Check if in the localStorage has value previously selected for hide or show completed tasks


      const valueHideOrShowCompletedTask = window.localStorage.getItem(`hide_or_show_completed_task_${projectId}`);

      if (valueHideOrShowCompletedTask !== null && valueHideOrShowCompletedTask !== undefined) {
        Ember.run.later(() => {
          this.send('hideCompletedAllTasks', false, valueHideOrShowCompletedTask);
        }, 1000);
      } else {
        // Default value
        this.set('hiddenCompleteTask', false);
      }

      if (this.model.project.projectType === 'P') {
        this.set('showLoading', true); // Set the project name

        const projectSlug = this.model.params.project_slug;
        this.set('projectId', projectId);
        this.set('projectName', this.model.project.name); // Get the last iteration of sprint

        const {
          sprintName,
          currentSprintId
        } = await this.get('sprintService').getLastIteration(projectId, projectSlug);
        this.set('sprintName', sprintName);
        this.set('currentSprintId', currentSprintId);
        this.send('showTasksBySprint', currentSprintId); // Get the sprints of the project

        const {
          sprintsArray,
          netSprints
        } = await this.get('sprintService').getSprintsOfProject(projectId, projectSlug, this.get('currentSprintId'));
        this.set('sprints', sprintsArray);
        this.set('netSprints', netSprints); // Display the tree

        this.send('showTreeSprint', !Ember.$(".wrap_all_tasks").hasClass('open-sidebar'));
        this.set('showLoading', false);
      } else {
        // Normal project
        this.set('currentSprintId', null);
      } // Guide tour for task


      this.get('guideTour').showGuideTourTask(); // Load the data related to the project

      this.send('loadDataProject');
    }),

    init() {
      this._super();

      this.set('sprints', []);
      this.set('netSprints', []);
      this.set('dataProject', {});
    },

    actions: {
      /**
       * @method updateTasks
       * @description Update tasks route model
       */
      updateTasks() {
        // Ember does not update the tooltip it is necessary to hide everything
        Ember.$('[data-toggle="tooltip"]').tooltip('hide');
        this.send('updateTasksRoute');
      },

      /**
       * @method refresh
       * @description refresh route
       */
      refresh() {
        // Ember does not update the tooltip it is necessary to hide everything
        Ember.$('[data-toggle="tooltip"]').tooltip('hide');
        this.send('refreshRoute');
      },

      /**
       * @method collapse
       * @description Collapse tasks
       */
      collapse() {
        this.set('collapseTasks', this.get('collapseTasks') ? false : true); // Save in the localStorage select option collapse

        const projectId = this.model.params.project_id;
        window.localStorage.setItem(`collapse_${projectId}`, this.get('collapseTasks'));
      },

      /**
       * @method showTreeSprint
       * @description show tree sprint
       */
      showTreeSprint(displayDivTree = true) {
        const customSprint = this.sprints.map(item => {
          item.text = `${item.text} <button class="tree-more-options">•••</button>`;
          return item;
        });
        Ember.$('#dragTree').jstree({
          'core': {
            'data': customSprint,
            'check_callback': true,
            'themes': {
              'responsive': false
            }
          },
          'types': {
            'default': {
              'icon': 'fa fa-folder'
            },
            'file': {
              'icon': 'fa fa-file'
            }
          },
          'plugins': ['contextmenu', 'types', 'dnd'],
          'contextmenu': {
            'select_node': false,
            'items': node => {
              return {
                createItem: {
                  'label': this.get('intl').t('add'),
                  'action': () => {
                    // Check if chosen sprint isn't the root sprint
                    const sprintChosen = isNaN(parseInt(node.id)) ? false : parseInt(node.id); // Get sprints

                    const sprints = this.get('netSprints');
                    let sprintsOptions;

                    if (sprintChosen) {
                      sprintsOptions = sprints.map(sprint => `<option value=${sprint.id} ${parseInt(sprint.id) === sprintChosen ? 'selected' : ''}>${sprint.nameWithParent}</option>`);
                    } else {
                      sprintsOptions = sprints.map(sprint => `<option value=${sprint.id}>${sprint.nameWithParent}</option>`);
                    } // Open Pop-Up form


                    this.get('swal').open({
                      title: this.get('intl').t('sprint.new.sprint'),
                      showCancelButton: true,
                      onOpen: () => {
                        Ember.$('#newSprintName').focus();
                        Ember.$("#editSprintEndDate").attr("min", Ember.$("#editSprintStartDate").val());
                        Ember.$('#editSprintStartDate').on('change', function () {
                          Ember.$("#editSprintEndDate").attr("min", this.value);
                        });
                      },
                      html: `
                      <form class="row">
                        <div class="col-12 form-group">
                          <label for="newSprintName">${this.get('intl').t('name')}</label>
                          <input type="text" class="form-control" id="newSprintName" placeholder="${this.get('intl').t('name')}">
                        </div>
                        <div class="col-12 form-group">
                          <label for="newSprintParentSprint">${this.get('intl').t('sprint.parent.sprint')}</label>
                          <select class="form-control" id="newSprintParentSprint">
                            <option value="0" ${!sprintChosen ? 'selected' : ''}>${this.get('intl').t('sprint.choose.sprint')}</option>
                            ${sprintsOptions}
                          </select>
                        </div>
                        <div class="col-6 form-group">
                          <label for="newSprintStartDate">${this.get('intl').t('start.date')}</label>
                          <input type="date" class="form-control" id="newSprintStartDate">
                        </div>
                        <div class="col-6 form-group">
                          <label for="newSprintEndDate">${this.get('intl').t('end.date')}</label>
                          <input type="date" class="form-control" id="newSprintEndDate">
                        </div>
                      </form>
                    `,
                      cancelButtonText: this.get('intl').t('cancel'),
                      confirmButtonText: this.get('intl').t('create')
                    }).then(async result => {
                      if (result.value) {
                        // Get data from form
                        const name = Ember.$('#newSprintName').val();
                        const startDate = Ember.$('#newSprintStartDate').val();
                        const endDate = Ember.$('#newSprintEndDate').val(); // Check if sprint name is empty

                        if (name.trim() === '') {
                          this.get('swal').open({
                            type: 'error',
                            title: this.get('intl').t('sprint.invalid.name'),
                            text: this.get('intl').t('sprint.sprint.must.have.name')
                          });
                        } else {
                          // Get project
                          const p = await this.get('store').find('project', this.get('projectId')); // Check if user chosen a parent sprint

                          if (parseInt(Ember.$('#newSprintParentSprint').val()) !== 0) {
                            const pSprint = await this.get('store').find('sprint', parseInt(Ember.$('#newSprintParentSprint').val()));
                            const newSprint = await this.get('store').createRecord('sprint', {
                              name,
                              startDate: startDate !== '' ? startDate : null,
                              endDate: endDate !== '' ? endDate : null,
                              project: p,
                              parentSprint: pSprint,
                              parentSprintId: pSprint.id
                            });
                            await newSprint.save(); // Set the new sprint in the storage

                            this.get('sprintService').saveSprintStorage(this.get('projectId'), newSprint); // Reload the tree

                            await this.loadModel();
                            this.send('reloadTree'); // Click in the new node

                            this.send('setNodeTree', newSprint.id);
                          } else {
                            const newSprint = await this.get('store').createRecord('sprint', {
                              name,
                              startDate: startDate !== '' ? startDate : null,
                              endDate: endDate !== '' ? endDate : null,
                              project: p
                            });
                            await newSprint.save(); // Set the new sprint in the storage

                            this.get('sprintService').saveSprintStorage(this.get('projectId'), newSprint); // Reload the tree

                            await this.loadModel();
                            this.send('reloadTree'); // Click in the new node

                            this.send('setNodeTree', newSprint.id);
                          }
                        }
                      }
                    });
                  },
                  '_class': 'class'
                },
                renameItem: {
                  'label': this.get('intl').t('modify'),
                  '_disabled': isNaN(node.id) ? true : false,
                  'action': () => {
                    // Get sprint
                    this.get('store').find('sprint', parseInt(node.id)).then(sprint => {
                      // Check if sprint selected has parent sprint
                      const parentSprintId = node.parent; // Get sprints

                      const sprints = this.get('netSprints');
                      const sprintsOptions = sprints.map(pSprint => {
                        if (pSprint.id !== sprint.id && !node.children_d.includes(pSprint.id)) {
                          return `<option value=${pSprint.id}${parentSprintId === pSprint.id ? ' selected' : ''}>${pSprint.nameWithParent}</option>`;
                        }
                      }); // Open the Pop-Up form

                      this.get('swal').open({
                        title: this.get('intl').t('sprint.edit.sprint'),
                        showCancelButton: true,
                        onOpen: () => {
                          Ember.$('#editSprintName').focus();
                          Ember.$("#editSprintEndDate").attr("min", Ember.$("#editSprintStartDate").val());
                          Ember.$('#editSprintStartDate').on('change', function () {
                            Ember.$("#editSprintEndDate").attr("min", this.value);
                          });
                        },
                        html: `
                        <form class="row">
                          <div class="col-12 form-group">
                            <label for="editSprintName">${this.get('intl').t('name')}</label>
                            <input type="text" class="form-control" id="editSprintName" placeholder="${this.get('intl').t('name')}" value="${sprint.name}">
                          </div>
                          <div class="col-12 form-group">
                            <label for="editSprintParentSprint">${this.get('intl').t('sprint.parent.sprint')}</label>
                            <select class="form-control" id="editSprintParentSprint">
                              <option value="0"${parentSprintId === undefined ? ' selected' : ''}>${this.get('intl').t('sprint.choose.sprint')}</option>
                              ${sprintsOptions}
                            </select>
                          </div>
                          <div class="col-6 form-group">
                            <label for="editSprintStartDate">${this.get('intl').t('start.date')}</label>
                            <input type="date" class="form-control" id="editSprintStartDate" value="${sprint.startDate}">
                          </div>
                          <div class="col-6 form-group">
                            <label for="editSprintEndDate">${this.get('intl').t('end.date')}</label>
                            <input type="date" class="form-control" id="editSprintEndDate" value="${sprint.endDate}">
                          </div>
                        </form>
                      `,
                        cancelButtonText: this.get('intl').t('cancel'),
                        confirmButtonText: this.get('intl').t('save')
                      }).then(async result => {
                        if (result.value) {
                          // Get data from form
                          const name = Ember.$('#editSprintName').val();
                          const startDate = Ember.$('#editSprintStartDate').val();
                          const endDate = Ember.$('#editSprintEndDate').val(); // Check if the sprint name is empty

                          if (name.trim() === '') {
                            this.get('swal').open({
                              type: 'error',
                              title: this.get('intl').t('sprint.invalid.name'),
                              text: this.get('intl').t('sprint.sprint.must.have.name')
                            });
                          } else {
                            sprint.set('name', name);
                            sprint.set('startDate', startDate !== '' ? startDate : null);
                            sprint.set('endDate', endDate !== '' ? endDate : null); // Check if the sprint has a parent

                            const parent = parseInt(Ember.$('#editSprintParentSprint').val());

                            if (parent !== 0) {
                              const parentSprint = await this.get('store').find('sprint', parent);
                              sprint.set('parentSprint', parentSprint);
                              sprint.set('parentSprintId', parentSprint.id);
                              await sprint.save(); // Check if update the storage data

                              const projectId = this.model.params.project_id;
                              const dataSprintSelectedStorage = window.sessionStorage.getItem(`sprint_${projectId}`);

                              if (dataSprintSelectedStorage !== undefined && dataSprintSelectedStorage !== null) {
                                const dataSprintSelected = JSON.parse(dataSprintSelectedStorage); // Check if update the item selected

                                if (parseInt(dataSprintSelected.id) === parseInt(node.id)) {
                                  dataSprintSelected.name = name;
                                  const newDataSprint = dataSprintSelected;
                                  window.sessionStorage.setItem(`sprint_${projectId}`, JSON.stringify(newDataSprint));
                                }
                              } // Reload the tree


                              await this.loadModel();
                              this.send('reloadTree');
                            } else {
                              await sprint.save(); // Reload the tree

                              await this.loadModel();
                              this.send('reloadTree');
                            }
                          }
                        }
                      });
                    });
                  }
                },
                deleteItem: {
                  'label': this.get('intl').t('delete'),
                  '_disabled': isNaN(node.id) ? true : false,
                  'action': async () => {
                    // Open the Pop-Up to ask if the user is sure about this action
                    this.get('swal').open({
                      title: this.get('intl').t('sprint.delete.question'),
                      text: this.get('intl').t('sprint.delete.warning'),
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#d33',
                      cancelButtonColor: '#3085d6',
                      cancelButtonText: this.get('intl').t('cancel'),
                      confirmButtonText: this.get('intl').t('sprint.delete.confirmation')
                    }).then(async result => {
                      if (result.value) {
                        // Get tasks
                        const tasks = await self.get('store').query('task', {
                          'type_filter': 'alltasks_byuser',
                          'project_id': parseInt(self.projectId)
                        });
                        const counter = tasks.filter(task => task.sprintId === parseInt(node.id)); // Check if the sprint has tasks

                        if (counter.length === 0) {
                          this.get('store').findRecord('sprint', parseInt(node.id), {
                            reload: true
                          }).then(async sprint => {
                            await sprint.destroyRecord(); // The current sprint id value is saved. When the function 'loadModel' is executed, this value is changed.

                            const currentSprintId = this.get('currentSprintId');
                            const deleteSelectedSprint = parseInt(currentSprintId) === parseInt(node.id) ? true : false; // Check if the selected sprint was removed

                            if (deleteSelectedSprint) {
                              const firstSprint = this.get('sprints')[0]; // Set the new sprint in the storage

                              this.get('sprintService').saveSprintStorage(this.get('projectId'), {
                                id: firstSprint.id,
                                name: self.get('intl').t('sprint.all.sprints')
                              }); // Reload the tree

                              await this.loadModel();
                              this.send('reloadTree'); // Click in the new node

                              Ember.run.later(() => {
                                this.send('setNodeTree', firstSprint.text);
                              }, 500);
                            }
                          });
                        } else {
                          this.get('swal').open({
                            title: this.get('intl').t('sprint.cannot.be.deleted.title'),
                            text: this.get('intl').t('sprint.cannot.be.deleted.text'),
                            type: 'error'
                          });
                        }
                      }
                    });
                  }
                },
                moveTasks: {
                  'label': this.get('intl').t('sprint.move.pending.tasks'),
                  '_disabled': isNaN(node.id) ? true : false,
                  'action': async () => {
                    // Get sprints
                    const sprints = this.get('sprints');
                    const sprintsOptions = sprints.map(mSprint => {
                      if (parseInt(mSprint.id) !== parseInt(node.id)) {
                        return `<option value=${mSprint.id}>${mSprint.nameWithParent === undefined ? mSprint.text : mSprint.nameWithParent}</option>`;
                      }
                    }); // Open Pop-Up to ask the user about choose the sprint

                    this.get('swal').open({
                      title: this.get('intl').t('sprint.move.sprint'),
                      showCancelButton: true,
                      html: `
                      <form class="row">
                        <div class="col-12 form-group">
                          <label for="moveTasksToAnotherSprintSelect">${this.get('intl').t('sprint.what.sprint.move.tasks.to')}</label>
                          <select class="form-control" id="moveTasksToAnotherSprintSelect">
                            <option value="0" selected>${this.get('intl').t('sprint.choose.sprint')}</option>
                            ${sprintsOptions}
                          </select>
                        </div>
                      </form>
                    `,
                      cancelButtonText: this.get('intl').t('cancel'),
                      confirmButtonText: this.get('intl').t('move')
                    }).then(async result => {
                      if (result.value) {
                        const toast = this.get('toast');
                        const selectedSprintId = parseInt(Ember.$('#moveTasksToAnotherSprintSelect').val()); // Check if user chose a sprint

                        if (selectedSprintId === 0) {
                          this.get('swal').open({
                            title: this.get('intl').t('sprint.no.sprint.has.been.chosen'),
                            text: this.get('intl').t('sprint.choose.a.valid.sprint'),
                            type: 'error'
                          });
                        } else {
                          // Function to move tasks
                          const moveTasks = async sprint => {
                            this.set('showLoading', true); // Get tasks

                            let ownTasks = await this.get('store').query('task', {
                              'type_filter': 'alltasks_byuser',
                              'project_id': parseInt(self.projectId),
                              'sprint_id': parseInt(node.id)
                            });
                            ownTasks = ownTasks.filter(task => parseInt(task.taskState) !== 2); // Change sprint

                            await ownTasks.forEach(task => {
                              if (sprint) {
                                task.set('sprint', sprint);
                                task.set('sprintId', selectedSprintId);
                              } else {
                                task.set('sprint', null);
                                task.set('sprintId', null);
                              }

                              task.save();
                            });
                            toast.success(this.get('intl').t('sprint.tasks.moved')); // Show task in the sprint in which they moved

                            this.set('sprintName', sprint.nameWithParent);
                            this.set('currentSprintId', sprint.id);
                            Ember.run.later(() => {
                              this.send('showTasksBySprint', sprint.id);
                              this.send('setNodeTree', sprint.id);
                              this.set('showLoading', false);
                            }, 1000);
                          }; // Check if user chose a sprint or chose the project as the parent


                          this.get('store').find('sprint', selectedSprintId).then(async sprint => {
                            moveTasks(sprint);
                          });
                        }
                      }
                    });
                  }
                }
              };
            }
          }
        });
        const self = this; // Action to execute when user clicks on a sprint

        Ember.$('#dragTree').on('activate_node.jstree', async function (e, data) {
          // Check if the sprint is valid
          if (data == undefined || data.node == undefined || data.node.id == undefined) {
            return;
          } // Show tasks of the selected sprint


          self.send('showTasksBySprint', parseInt(data.node.id)); // Check if the selected sprint exists or if user chose the project

          let nameSprint = '';

          if (parseInt(data.node.id) >= 0) {
            nameSprint = data.node.original.nameWithParent;
            self.set('sprintName', nameSprint);
          } else {
            nameSprint = self.get('intl').t('sprint.all.sprints');
            self.set('sprintName', nameSprint);
          }

          self.set('currentSprintId', data.node.id); // Refresh data of the project

          self.send('loadDataProject');
          const projectId = self.model.params.project_id;
          window.sessionStorage.setItem(`sprint_${projectId}`, JSON.stringify({
            'id': self.get('currentSprintId'),
            'name': nameSprint
          }));
        }); // Set folder icons to sprints when selected node

        Ember.$('#dragTree').bind('select_node.jstree', function (node, selected) {
          // Set closed folder to the previous sprint
          let previousSprint = self.get('previousSprint');

          if (previousSprint) {
            Ember.$('#dragTree').jstree(true).set_icon(previousSprint, 'fa fa-folder');
          } // Set open folder to the current sprint


          Ember.$('#dragTree').jstree(true).set_icon(selected.node, 'fa fa-folder-open');
          self.set('previousSprint', selected.node);
        }); // Drop function

        Ember.$('#dragTree').bind('move_node.jstree', function (e, data) {
          // Get sprint
          self.get('store').find('sprint', parseInt(data.node.id)).then(async sprint => {
            // Check if has a new parent sprint
            if (!isNaN(parseInt(data.node.parent))) {
              // Get the parent sprint
              const parentSprint = await self.get('store').find('sprint', parseInt(data.node.parent));
              sprint.set('parentSprint', parentSprint);
              sprint.set('parentSprintId', parentSprint.id); // If hasn't parent sprint
            } else {
              sprint.set('parentSprint', null);
              sprint.set('parentSprintId', null);
            }

            await sprint.save(); // Reload the tree

            await this.loadModel();
            self.send('reloadTree');
          });
        }); // Show treeview

        if (displayDivTree) {
          Ember.$(".wrap_all_tasks").toggleClass("open-sidebar");
        }

        Ember.run.later(() => {
          self.send('setNodeTree', self.get('currentSprintId'));
          Ember.$('.tree-more-options').on('click', function (e) {
            e.stopPropagation();
            const nodeId = e.target.parentNode.parentNode.id;
            Ember.$('#dragTree').jstree('show_contextmenu', '#' + nodeId);
          });
        }, 500);
      },

      /**
       * @method hideCompletedAllTasks
       * @description Hide complete all tasks
       */
      hideCompletedAllTasks(setStorage = true, valueSelectedStorage = null) {
        const tasks = this.get('model.tasks');
        const classEye = Ember.$('#eye_button_all').find('i');

        if (setStorage) {
          // Save in the localStorage select option
          const projectId = this.model.params.project_id; // Flag set the inverse

          this.set('hiddenCompleteTask', this.get('hiddenCompleteTask') || this.get('hiddenCompleteTask') === 'false' ? false : true);
          window.localStorage.setItem(`hide_or_show_completed_task_${projectId}`, this.get('hiddenCompleteTask'));
        } else {
          const value = valueSelectedStorage === 'true' || valueSelectedStorage === true ? true : false;
          this.set('hiddenCompleteTask', value);
        } // Show or hide tasks


        this.get('taskService').changeEye(!this.get('hiddenCompleteTask'), classEye);
        this.get('taskService').setCompletedTasks(tasks, this.get('hiddenCompleteTask'));
      },

      /**
       * @method showModalFilters
       * @description Show modal filters
       */
      showModalFilters() {
        if (!Ember.$('.modal.in').length) {
          Ember.$('.modal-dialog').css({
            top: 70,
            right: 0
          });
        }

        Ember.$('#myModal2').modal({
          backdrop: false,
          show: true
        });
      },

      /**
       *
       * @method showTasksBySprint
       * @description Show tasks of the sprint selected
       * @param sprintId {int} Sprint id
       */
      async showTasksBySprint(sprintId) {
        // Get the tasks of the sprint
        const tasks = await this.get('sprintService').showTasksBySprint(this.projectId, sprintId, this.get('viewTasksSelected'));
        this.set('model.tasks', tasks);
      },

      /**
       * @method reloadTree
       * @description Reload the the tree
       */
      async reloadTree() {
        Ember.$('#dragTree').jstree(true).settings.core.data = this.get('sprints');
        Ember.$('#dragTree').jstree(true).load_all(function () {
          Ember.$('#dragTree').jstree(true).refresh();
        });
      },

      /**
       * @method setNodeTree
       * @description Set node click
       * @param {*} id
       */
      setNodeTree(id) {
        Ember.$('#dragTree').jstree('deselect_all');
        Ember.run.later(() => {
          Ember.$('#dragTree').jstree().select_node(id);
        }, 500);
      },

      /**
       * @method showChangeViews
       * @description Change view for tasks list
       * @param typeView {string} type view to order tasks
       */
      async showChangeViews(typeView) {
        let tasks;
        const projectId = this.model.params.project_id;
        this.set('showLoading', true);

        switch (typeView) {
          case 'user':
            {
              tasks = await this.get('store').query('task', {
                'type_filter': 'alltasks_byuser',
                'project_id': projectId
              });
              this.set('viewTasksSelected', typeView);
              break;
            }

          case 'state':
            {
              tasks = await this.get('store').query('task', {
                'type_filter': 'alltasks_bystate',
                'project_id': projectId
              });
              this.set('viewTasksSelected', typeView);
              break;
            }

          case 'group':
            {
              tasks = await this.get('store').query('task', {
                'type_filter': 'alltasks_bygroup',
                'project_id': projectId
              });
              this.set('viewTasksSelected', typeView);
              break;
            }
        } // Update tasks data


        this.set('model.tasks', tasks); // If the projects is sprint, set the current sprint

        if (this.model.project.projectType === 'P') {
          this.send('showTasksBySprint', this.get('currentSprintId'));
        }

        this.set('showLoading', false);
      },

      /**
       * @method loadDataProject
       * @description Load data related to the project
       */
      async loadDataProject() {
        const data = await this.get('sprintService').loadDataProject(this.model.params.project_id, this.get('currentSprintId'));
        this.set('dataProject', data);
      }

    }
  });

  _exports.default = _default;
});