define("mytaskpanel/libs/utils", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getValidTypesImage = getValidTypesImage;
  _exports.formatDateIsoString = formatDateIsoString;
  _exports.validateEmail = validateEmail;
  _exports.getDateWithTimeMidnight = getDateWithTimeMidnight;
  _exports.getLocaleDateString = getLocaleDateString;
  _exports.getFormatDate = getFormatDate;
  _exports.validExtensionFile = validExtensionFile;
  _exports.bytesToSize = bytesToSize;
  _exports.getTomorrowDateTime = getTomorrowDateTime;
  _exports.daysInMonth = daysInMonth;
  _exports.daysInWeek = daysInWeek;

  /**
  * @method getValidTypesImage
  * @description Get types files valid
  */
  function getValidTypesImage() {
    return ['image/gif', 'image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
  }
  /**
  * @method formatDateIso
  * @description Format date to yyyy-mm-dd
  * @param {*} date
  */


  function formatDateIsoString(date) {
    if (date === undefined) {
      return window.moment().format("YYYY-MM-DD");
    } else {
      return window.moment(date).format("YYYY-MM-DD");
    }
  }
  /**
  * @method validateEmail
  * @description Check if is a email valid
  * @param {*} email
  */


  function validateEmail(email) {
    var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  /**
  * @method getDateWithTimeMidnight
  * @description Get date in midnight time
  * @param {*} date
  */


  function getDateWithTimeMidnight(date) {
    let dt;

    if (date === undefined) {
      dt = new Date();
      dt.setHours(0, 0, 0, 0);
    } else {
      dt = new Date(date + 'T00:00');
    }

    return dt;
  }
  /**
  * @method getLocaleDateString
  * @description Get locale date format
  * @param {date} date
  * @param {boolena} hasHour
  */


  function getLocaleDateString(date, hasHour = false) {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    if (hasHour) {
      options['hour'] = '2-digit';
      options['minute'] = '2-digit';
    }

    let newDate, d;

    if (typeof date === 'string') {
      d = getDateWithTimeMidnight(date).toLocaleDateString(navigator.language, options);
    } else {
      d = new Date(date).toLocaleDateString(navigator.language, options);
    }

    if (d instanceof Date && isNaN(d)) {
      newDate = date;
    } else {
      newDate = d;
    }

    return newDate;
  }
  /**
  * @method getFormatDate
  * @description Get current format date
  */


  function getFormatDate() {
    const formats = {
      'es': 'dd/mm/yyyy',
      'en': 'mm/dd/yyyy'
    };

    if (navigator.language.substr(0, 2) === 'es') {
      return formats['es'];
    } else {
      return formats['en'];
    }
  }
  /**
  * @method validExtensionFile
  * @description Validate the extension
  * @param {*} extension
  */


  function validExtensionFile(extension) {
    const extensions = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'tiff', 'txt', 'csv', 'ics', 'rtx', 'css', 'js', 'html', 'pdf', 'doc', 'docx', 'pps', 'ppt', 'xls', 'xlsx', 'pptx', 'mp3', 'm4a', 'wav', 'ogg', 'midi', 'wmv', 'avi', 'divx', 'flv', 'mov', 'mpeg', 'mp4', 'ogv', 'mkv', '3gp', 'tar', 'zip', 'gzip', 'rar', '7z'];
    return extensions.includes(extension.toLowerCase());
  }
  /**
  * @method bytesToSize
  * @description Bytes to size
  * @param {*} bytes
  */


  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }
  /**
   * @method getDateWithDateTime
   * @description Get tomorrow datetime
   */


  function getTomorrowDateTime() {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  }
  /**
   * @method daysInMonth
   * @description Days (in moment object) of month
   * @param {*} month
   * @param {*} year
   */


  function daysInMonth(month, year) {
    const date = (0, _moment.default)().month(month).year(year);
    const count = date.daysInMonth();
    const days = [];

    for (var i = 1; i < count + 1; i++) {
      days.push(date.date(i).format('YYYY-MM-DD'));
    }

    return days;
  }
  /**
   * @method daysInWeek
   * @description Days (in momento object) of week
   * @param {*} dateOriginal
   */


  function daysInWeek(dateOriginal) {
    const days = [];
    const date = dateOriginal.startOf('week');
    let weeklength = 7;

    while (weeklength--) {
      days.push((0, _moment.default)(date.format('YYYY-MM-DD')).format('YYYY-MM-DD'));
      date.add(1, 'day');
    }

    return days;
  }
});