define("mytaskpanel/controllers/inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller('application'),
    searchFilterValue: Ember.computed.alias('application.searchFilterValue'),
    actions: {
      /**
      * @method updateTasks
      * @description Update tasks route model
      */
      updateTasks() {
        this.send('updateTasksRoute');
      },

      /**
      * @method refresh
      * @description refresh route
      */
      refresh() {
        this.send('refreshRoute');
      }

    }
  });

  _exports.default = _default;
});