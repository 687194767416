define("mytaskpanel/components/tasks/modal-form-task", ["exports", "mytaskpanel/libs/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    modalFormTask: Ember.inject.service('modal-form-task'),
    taskService: Ember.inject.service('task'),
    fileService: Ember.inject.service('files'),
    task: null,
    showLoading: false,
    parentTasks: null,
    summernoteDescription: '#TaskSummernoteDescription',
    //endDateFormTask:null,
    taskLoad: Ember.observer('task', async function () {
      const task = this.get('task');

      if (task !== null) {
        this.send('showModalTask');
        this.loadComments();
        const listFiles = await this.get('fileService').getFiles('task', task);
        this.set('taskFiles', listFiles);
      }
    }),

    didInsertElement() {
      this._super();

      this.set('parentTasks', []);
      this.set('updateTasks', this.get('parent.updateTasks'));
      this.set('loadTasks', this.get('parent.loadTasks'));
      this.set('refreshRoute', this.get('parent.refreshRoute')); // Load task from querystring id

      this.loadTaskFromQueryParam();
      const self = this; // Event for detect if another component can open the modal form

      this.get('modalFormTask').on('open-modal-form-task', function () {
        self.send('showModalTask');
      });
    },

    /**
    * @method loadTaskFromQueryParam
    * @description Load task modal form with task from query param
    */
    async loadTaskFromQueryParam() {
      if (this.parent.task_id_query_param) {
        const task_id = this.parent.task_id_query_param;
        const task = await this.get('store').find('task', task_id);
        this.set('task', task);
        this.send('showModalTask');
      }
    },

    /**
    * @method setTaskStateFormat
    * @description Set badge a badgeClass for taskState
    */
    setTaskStateFormat() {
      // Remove attr style for set color
      Ember.$("#state-task").removeAttr("style"); // Set badge

      const taskState = this.get('task.taskState') || '';

      switch (taskState.trim()) {
        // ToDo
        case '0':
          this.set('badge', 'ToDo');
          this.set('badgeClass', 'warning');
          break;
        // Doing

        case '1':
          this.set('badge', 'Doing');
          this.set('badgeClass', 'success');
          break;
        // Done

        case '2':
          this.set('badge', 'Done');
          this.set('badgeClass', 'primary');
          break;
      }
    },

    /**
    * @method setTaskLabels
    * @description Set labels for form
    * @param {*} task
    */
    async setTaskLabels(task) {
      if (task.id) {
        // Set labels selected
        const taskLabelsAux = await this.get('store').query('task-label', {
          'task': task.id
        });
        const taskLabels = [];
        taskLabelsAux.map(ts => {
          taskLabels.push({
            id: ts.get('label.id'),
            name: ts.get('nameLabel'),
            delete: false,
            pk: ts.get('id')
          });
        });
        this.set('taskLabels', taskLabels);
      }
    },

    /**
    * @method setTaskFollowers
    * @description Set followers for form
    * @param {*} task
    */
    async setTaskFollowers(task) {
      if (task.id) {
        // Set followers selected
        const followersAux = await this.get('store').query('follower', {
          'task': task.id
        });
        const followers = [];
        followersAux.map(fw => {
          followers.push({
            id: fw.get('user.id'),
            name: fw.get('name'),
            delete: false,
            pk: fw.get('id')
          });
        });
        this.set('followers', followers);
      }
    },

    /**
    * @method loadComments
    * @description Load comments task
    */
    async loadComments() {
      const comments = await this.get('store').query('task-comment', {
        'task': this.get('task.id')
      });
      const history = await this.get('store').query('history', {
        'task': this.get('task.id')
      });
      const commentsList = [];
      comments.map(comment => {
        commentsList.pushObject(comment);
      });
      history.map(comment => {
        commentsList.pushObject(comment);
      });
      commentsList.sort((a, b) => {
        return a.date < b.date ? 1 : -1;
      });
      this.set('taskComments', commentsList);
    },

    actions: {
      /**
      * @method showModalTask
      * @description Show modal for edit task
      */
      async showModalTask() {
        const self = this;
        const task = this.get('task');

        if (task) {
          // Set first tab default
          Ember.$('#tabs-form-task a:first').tab('show'); // Show loading, this is hide in task-files observer

          this.set('showLoading', true); // Reset parent combo if hasn't parentId

          if (task.get('parentTaskId') === undefined || task.get('parentTaskId') === null) {
            Ember.run.later(() => {
              Ember.$('#parent-task-form-task').val('');
            }, 500);
          } // Set task state


          this.setTaskStateFormat(); // Set date to header

          const dueDateHeader = this.get('task.dueDate');

          if (Ember.isPresent(dueDateHeader) && window.moment(dueDateHeader, window.moment.ISO_8601, true).isValid()) {
            this.set('date', (0, _utils.getLocaleDateString)(dueDateHeader));
          } else {
            this.set('date', '');
          }

          const user_type = this.get('userType');

          if (user_type === 'A' || user_type === 'O' || user_type === 'I') {
            // Editable title in header
            Ember.$('#task-name').editable({
              validate: function (value) {
                if (Ember.$.trim(value) == '') return 'This field is required';
                self.set('task.name', value); // If not is new, update

                if (self.task.id) {
                  self.task.save();
                }
              },
              mode: 'inline',
              showbuttons: false,
              inputclass: 'form-control-sm'
            }); // Reset value

            Ember.$('#task-name').editable('setValue', task.name);
          } // Load description


          Ember.$(this.summernoteDescription).summernote("code", this.get('task.description'));
          const estimateEffort = this.get('task.estimateEffort');
          const estimateEffortValue = estimateEffort === undefined || estimateEffort === null ? null : estimateEffort;
          this.set('task.estimateEffort', estimateEffortValue);

          if (this.get('task.taskState') === '2' && this.get('task.endDate') !== '') {
            this.set('checkedTask', true);
          } else {
            this.set('checkedTask', false);
          } // Load subtasks and parent tasks


          if (task.id) {
            const subTasks = await this.get('store').query('sub-task', {
              'task': task.id
            });
            this.set('subTasks', subTasks);
            let parentTasks;

            if (this.get('parentComponent') === 'tasks-list') {
              // Task-lists
              parentTasks = this.get('taskService').getParentTasksCombo(this.get('tasks'), task.id);
              this.set('parentTasks', parentTasks);
            } else {
              // For inbox
              const tasksProject = await this.get('store').query('task', {
                'project_id': task.projectId
              });
              parentTasks = this.get('taskService').getParentTasksCombo(tasksProject, task.id);
              this.set('parentTasks', parentTasks);
            }
          } else {
            this.set('parentTasks', this.get('tasks'));
          } // Clear labels


          if (Ember.$('.tokenize-labels').tokenize2()) {
            Ember.$('.tokenize-labels').tokenize2().trigger('tokenize:clear');
          }

          this.setTaskLabels(task); // Load labels

          this.set('labels', this.get('parent.labels')); // Clear followers

          if (Ember.$('.tokenize-followers').tokenize2()) {
            Ember.$('.tokenize-followers').tokenize2().trigger('tokenize:clear');
          }

          this.setTaskFollowers(task);
          const startDate = this.get('task.startDate');
          const endDate = this.get('task.endDate');
          const dueDate = this.get('task.dueDate');
          Ember.run.later(() => {
            if (Ember.isPresent(startDate)) {
              Ember.$('#start_date_form_task').val(startDate);
            } else {
              Ember.$('#start_date_form_task').val(null);
            }

            if (Ember.isPresent(endDate)) {
              Ember.$('#end_date_form_task').val(endDate);
            } else {
              Ember.$('#end_date_form_task').val(null);
            }

            if (Ember.isPresent(dueDate)) {
              Ember.$('#due_date_form_task').val(dueDate);
            } else {
              Ember.$('#due_date_form_task').val(null);
            }
          }, 100); // Hide loading

          this.set('showLoading', false);

          if (!Ember.$('#wrapper').hasClass('right-bar-enabled')) {
            Ember.$('#wrapper').toggleClass('right-bar-enabled');
          }
        }
      },

      /**
       * @method copyUrlTask
       * @description copy the url task to clipboard
       */
      copyUrlTask() {
        this.get('taskService').copyUrlTask(this.get('task'));
      },

      /**
      * @method doneTask
      * @description Done task
      */
      async doneTask() {
        await this.get('taskService').done(this.get('task'), this.get('parent.parent.hiddenCompleteTask'));
        this.set('checkedTask', true);
        const date = new Date();
        this.set('task.endDate', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
        this.set('parent.endDateFormTask', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
        Ember.$("#checkbox_" + this.get('task.id')).prop('checked', true);

        if (this.get('parentComponent') === 'inbox-index') {
          const taskId = this.get('task.id');
          let color; // Check in which array to eliminate

          const existsExpired = this.get('parent.expiredTasks').filter(t => {
            return t.id === taskId;
          }).length > 0 ? true : false;

          if (existsExpired) {
            color = this.get('parent.red');
            this.get('parent.expiredTasks').removeObject(this.get('task'));
          }

          const existsToday = this.get('parent.expiredTodayTasks').filter(t => {
            return t.id === taskId;
          }).length > 0 ? true : false;

          if (existsToday) {
            color = this.get('parent.yellow');
            this.get('parent.expiredTodayTasks').removeObject(this.get('task'));
          }

          const existsNext = this.get('parent.nextToExpiredTasks').filter(t => {
            return t.id === taskId;
          }).length > 0 ? true : false;

          if (existsNext) {
            color = this.get('parent.grey');
            this.get('parent.nextToExpiredTasks').removeObject(this.get('task'));
          }

          const existsWithoutPlannedTasks = this.get('parent.withoutPlannedTasks').filter(t => {
            return t.id === taskId;
          }).length > 0 ? true : false;

          if (existsWithoutPlannedTasks) {
            color = this.get('parent.black');
            this.get('parent.withoutPlannedTasks').removeObject(this.get('task'));
          } // Refreshing the graphic


          Ember.run.later(() => {
            this.get('parent').loadDataGraphic();
          }, 1000); // Update char

          this.get('parent').send('updateTasksLength', color);
        }

        Ember.$('#wrapper').toggleClass('right-bar-enabled');
      },

      /**
      * @method unDoneTask
      * @description Done task
      */
      async unDoneTask() {
        await this.get('taskService').unDone(this.get('task'));
        this.set('checkedTask', false);
        this.set('task.taskState', '0');
        Ember.$("#checkbox_" + this.get('task.id')).prop('checked', false);
      }

    }
  });

  _exports.default = _default;
});