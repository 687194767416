define("mytaskpanel/components/timesheet/form-timesheet", ["exports", "moment", "mytaskpanel/components/timesheet/utils"], function (_exports, _moment, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    swal: Ember.inject.service('swal'),
    toast: Ember.inject.service('toast'),
    timesheetService: Ember.inject.service('timesheet'),
    showLoading: false,
    totalHours: 0,
    userSelected: null,
    typeDateSelected: 'week',
    dateNow: (0, _moment.default)().format('YYYY-MM-DD'),
    date: null,
    timesheetReadonly: false,
    sortIconProject: 'fa fa-sort',
    sortIconTask: 'fa fa-sort',
    filterProjectActvityValue: null,
    sortedBy: null,
    isAdminOrOwner: false,
    filterUser: false,
    disabledButtons: Ember.computed('date', 'userSelected', {
      get() {
        if (Ember.isPresent(this.userSelected) && Ember.isPresent(this.date)) {
          return false;
        } else {
          return true;
        }
      }

    }),
    dateObserver: Ember.observer('date', function () {
      this.send('filter');
    }),
    userSelectedObserver: Ember.observer('userSelected', function () {
      if (Ember.isPresent(this.userSelected)) {
        const user = this.get('users').find(user => user.id == this.userSelected);

        if (user) {
          this.set('timesheetReadonly', user.timesheet_readonly);
        }
      }

      this.send('filter');
    }),
    typeDateSelectedObserver: Ember.observer('typeDateSelected', async function () {
      if (Ember.isPresent(this.get('date'))) {
        this.initTimesheet();

        if (this.get('isAdminOrOwner')) {
          const filterUser = this.get('filterUser');
          const users = await this.timesheetService.getUsersWithHours(filterUser, this.typeDateSelected, this.date);
          this.set('users', users);
        }
      }
    }),
    filterProjectActvityValueChanged: Ember.observer('filterProjectActvityValue', function () {
      this.send('filter');
    }),

    didInsertElement() {
      this._super();

      const userType = this.get('session.data.authenticated.user').user_type;
      this.set('isAdminOrOwner', userType === 'O' || userType === 'A' ? true : false);
      this.loadActivitiesProject();
      this.initTimesheet();
    },

    /**
     * @method loadActivitiesProject
     * @description Load the data for dropdown project/activity
     */
    loadActivitiesProject() {
      const data = this.get('timesheetService').loadActivitiesProject(this.get('genericTasks'), this.get('projects'));
      this.set('projectsActivities', data);
    },

    /**
     * @method initTimesheet
     * @description Init data timesheet
     */
    initTimesheet() {
      this.set('data', []);
      const days = this.get('timesheetService').initTimesheet(this.get('typeDateSelected'));
      this.set('days', days);
    },

    /**
     * @method changeIconSort
     * @description Change sorting icon
     */
    changeIconSort(property) {
      if (this.get(property).includes('asc')) {
        this.set(property, 'fa fa-sort-desc');
        return 'desc';
      } else {
        this.set(property, 'fa fa-sort-asc');
        return 'asc';
      }
    },

    actions: {
      /**
       * @method filter
       * @description Load the grid
       */
      async filter() {
        if (!Ember.isPresent(this.get('typeDateSelected')) || !Ember.isPresent(this.get('date'))) {
          return false;
        }

        const user = this.get('userSelected');
        const {
          dateFrom,
          dateTo
        } = (0, _utils.getDateParams)(this.get('typeDateSelected'), this.get('date'));

        if (Ember.isPresent(user) && Ember.isPresent(dateFrom)) {
          this.set('showLoading', true);
          this.initTimesheet(); // Get data

          const {
            records,
            totalHours,
            days
          } = await this.get('timesheetService').getDataTimesheet(this.get('days'), user, dateFrom, dateTo, this.sortedBy, this.filterProjectActvityValue);
          this.set('data', records);
          this.set('totalHours', totalHours);
          this.set('days', days);
          this.set('showLoading', false);
        } else {
          this.set('data', []);
          this.set('totalHours', 0);
        }
      },

      /**
       * @method selectProject
       * @description Select project
       * @param {*} projectId
       */
      async selectProject(optionSelected) {
        // Option selected
        const option = optionSelected.options[optionSelected.selectedIndex]; // Is necessary for get the record (is possible that exists two record with the same id
        // because the combo is a mix between project and activity projects )

        const valueId = option.value;
        const type = option.dataset.type; // Get id record

        const recordId = option.dataset.record === undefined ? null : option.dataset.record; // Get the project selected

        const project = this.get('projectsActivities').filter(r => r.id === valueId && r.type === type)[0];
        const projectId = project.id; // Get the record timesheet for update the value

        const record = this.get('data').filter(r => r.id === recordId)[0];

        if (type === 'project') {
          const pr = this.get('store').peekRecord('project', projectId);
          record.set('project', pr);
          record.set('genericTask', null); // Get the tasks

          const tasks = await this.get('store').query('task', {
            'project_id': projectId
          });
          const dataTasks = [];
          tasks.map(t => dataTasks.push({
            id: t.id,
            name: t.name
          }));
          record.set('tasks', dataTasks);
        } else {
          const gt = this.get('store').peekRecord('generic-task', projectId);
          record.set('genericTask', gt);
          record.set('project', null);
        } // If not is new and update the task, save in the db


        if (recordId !== null) {
          let typeField = 'project';

          if (type === 'genericTask') {
            typeField = 'generic_task';
          }

          this.send('updateRecord', record, typeField, valueId);
        }
      },

      /**
       * @method selectTask
       * @description Select the task in the timesheet
       * @param {*} optionSelected
       */
      async selectTask(optionSelected) {
        // Option selected
        const option = optionSelected.options[optionSelected.selectedIndex];
        const taskId = option.value; // Get id record

        const recordId = option.dataset.record === undefined ? null : option.dataset.record;
        const record = this.get('data').filter(r => r.id === recordId)[0];

        if (taskId !== null && taskId !== undefined && taskId !== '') {
          const task = await this.get('store').find('task', taskId);
          record.set('task', task);
          record.set('taskDescription', task.name);
          record.set('taskFree', task.name); // If not is new and update the task, save in the db

          if (recordId !== null) {
            this.send('updateRecord', record, "task", taskId);
          }
        } else {
          record.set('task', null);
          record.set('taskDescription', null);
        }
      },

      /**
       * @method saveHours
       * @description Save the hours (Create or edit)
       * @param {object} record
       * @param {boolean} isNew
       * @param {object} day
       * @param {object} input
       */
      async saveHours(record, isNew, day, input) {
        await this.get('timesheetService').saveHours(record, isNew, day, input);

        try {
          // Get total column to update
          const column = this.get('days').filter(d => d.day === day.day)[0]; // Get data for update

          const {
            totalColumn,
            totalHoursGeneral
          } = this.get('timesheetService').getDataForUpdateTotals(this.get('data'), day); // Update the column

          column.set('totalHoursByDay', Number(totalColumn).toFixed(2)); // Update general total hours

          this.set('totalHours', Number(totalHoursGeneral).toFixed(2));
        } catch (e) {// Nothing
        }
      },

      /**
       * @method addRecord
       * @description Add record to the grid
       */
      addRecord() {
        const recordsNews = this.get('data').filter(r => r.id === null);

        if (recordsNews.length === 0) {
          const userSelected = this.get('userSelected');
          const user = this.get('store').findRecord('user', userSelected);
          this.get('timesheetService').addRecord(this.get('data'), this.get('days'), user);
        }
      },

      /**
       * @method updateRecord
       * @description Update record
       */
      async updateRecord(record, typeField, newValue) {
        this.set('showLoading', true);
        await this.get('timesheetService').bulkUpdateTimesheet(record, typeField, newValue);
        this.set('showLoading', false);
      },

      /**
       * @method removeRecord
       * @description Remove record
       * @param {*} record
       */
      async removeRecord(record) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('timesheet.delete.question');
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          this.set('showLoading', true);

          try {
            await this.get('timesheetService').bulkDeleteTimesheet(record, this.get('data'));
            const {
              totalHoursGeneral
            } = this.get('timesheetService').getDataForUpdateTotals(this.get('data'), null, true);
            this.set('totalHours', totalHoursGeneral);
          } catch (e) {
            const toast = this.get('toast');
            toast.warning(this.get('intl').t('timesheet.delete.error'));
          }

          this.set('showLoading', false);
        }
      },

      /**
       * @method sortTable
       * @description Sort the timesheet' table by columns project/activity, task
       * @param {*} parameter
       */
      sortTable(parameter) {
        if (parameter == "project") {
          if (this.sortIconTask != 'fa fa-sort') {
            this.set('sortIconTask', 'fa fa-sort');
          }

          const sortedIn = this.changeIconSort("sortIconProject");
          const sortedBy = sortedIn == 'asc' ? 'project,generic_task' : '-project,-generic_task';
          this.set('sortedBy', sortedBy);
          this.send('filter');
        } else {
          if (this.sortIconProject != 'fa fa-sort') {
            this.set('sortIconProject', 'fa fa-sort');
          }

          const sortedIn = this.changeIconSort("sortIconTask");
          const sortedBy = sortedIn == 'asc' ? 'task_free' : '-task_free';
          this.set('sortedBy', sortedBy);
          this.send('filter');
        }
      },

      /**
       * @method filterByProjectActivity
       * @description Filter timesheet by project/activity
       * @param {*} target
       */
      filterByProjectActivity(target) {
        const option = target.options[target.selectedIndex];
        const type = option.dataset.type;
        let value = option.value;

        if (type === 'project') {
          value = value.split("-")[1].trim();
        }

        this.set('filterProjectActvityValue', value);
      }

    }
  });

  _exports.default = _default;
});