define("mytaskpanel/components/timesheet/form-filters", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),
    session: Ember.inject.service('session'),
    timesheetService: Ember.inject.service('timesheet'),
    filterHoursByWeek: null,
    tagName: '',
    nowDate: null,
    nowMonth: (0, _moment.default)().format('YYYY-MM'),
    nowWeek: (0, _moment.default)().startOf('isoWeek').year() + '-W' + ('0' + (0, _moment.default)().isoWeek()).slice(-2),
    executeActionFilter: Ember.observer('filterUser', function () {
      this.send('filterUsersWithHours');
    }),
    filterByMonth: Ember.observer('filterHoursByWeek', function () {
      this.send('setTypeDateSelected');
    }),

    didReceiveAttrs() {
      this._super();

      if (this.get('typeDateSelected') === 'month') {
        this.set('nowDate', this.nowMonth);
        this.set('filterHoursByWeek', false);
      } else {
        this.set('nowDate', this.nowWeek);
        this.set('filterHoursByWeek', true);
      }
    },

    didInsertElement() {
      this._super();

      this.initFilters();
    },

    /**
     * @method initFilters
     * @description Init filters properties
     */
    initFilters() {
      Ember.$('#switch-type-users-hours').bootstrapToggle({
        on: this.get('intl').t('timesheet.filter.user.with_hours'),
        off: this.get('intl').t('users.all_2')
      });
      this.send('orderUsers');

      try {
        let userId = this.get('session.data.authenticated.user').id;
        const userStorage = window.sessionStorage.getItem('userSelectedTimesheet');

        if (userStorage !== undefined && userStorage !== null && userStorage !== '') {
          userId = userStorage;
        }

        this.set('userSelected', userId);
      } catch (e) {// Nothing
      }
    },

    /**
     * @method validateDate
     * @description Check if the date is correct otherwise set the current date
     */
    validateDate() {
      if (this.get('date') === null || this.get('date') === undefined || this.get('date') === '') {
        if (this.get('typeDateSelected') === 'month') {
          this.set('date', this.nowMonth);
          this.set('nowDate', this.nowMonth);
        } else {
          this.set('date', this.nowWeek);
          this.set('nowDate', this.nowWeek);
        }
      }
    },

    actions: {
      /**
       * @method orderUsers
       * @description Order to the combobox user
       */
      orderUsers() {
        const usersData = this.get('users');
        const users = this.timesheetService.orderUsers(usersData);
        this.set('users', users);
      },

      /**
       * @method selectDate
       * @description Select the date to filter
       * @param {*} v
       */
      selectDate(v) {
        this.set('date', v);
      },

      /**
       * @method setTypeDateSelected
       * @description Set the value of setTypeDateSelected (month or week)
       */
      setTypeDateSelected() {
        // Need the later because ember complains that it modifies the date variable twice
        Ember.run.later(() => {
          if (this.filterHoursByWeek) {
            this.set('typeDateSelected', 'week');
            this.set('date', this.nowWeek);
          } else {
            this.set('typeDateSelected', 'month');
            this.set('date', this.nowMonth);
          }
        }, 100);
      },

      /**
       * @method selectUser
       * @description Select the user and show the data
       * @param {*} user
       */
      async selectUser(userId) {
        this.set('userSelected', userId);

        if (userId) {
          const user = this.get('users').find(user => user.id == userId);
          this.set('timesheetReadonly', user.timesheet_readonly);
        }

        window.sessionStorage.setItem('userSelectedTimesheet', userId);
      },

      /**
       * @method fakeSave
       * @description Fake save. It's necessary for the users
       */
      fakeSave() {
        const toast = this.get('toast');
        toast.success(this.get('intl').t('timesheet.save'));
      },

      /**
       * @method filterUsersWithHours
       * @description Filter the users filter with hours
       */
      async filterUsersWithHours() {
        this.validateDate();
        const filterUser = this.get('filterUser');
        const users = await this.timesheetService.getUsersWithHours(filterUser, this.get('typeDateSelected'), this.get('date'));
        this.set('users', users);
        this.send('orderUsers');
      }

    }
  });

  _exports.default = _default;
});