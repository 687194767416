define("mytaskpanel/routes/accounts", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "mytaskpanel/libs/auth"], function (_exports, _authenticatedRouteMixin, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    async beforeModel(transition) {
      try {
        // NOTE: This code is to know if the account has only one project or not.
        // and check if it's from authentication (login or client) to redirect directly
        // to the project or list the projects of the account.
        if ((0, _auth.routeIsFromAuthentication)(transition)) {
          const userId = this.get('session.data.authenticated.user').id;
          const accounts = await this.get('store').query('account', {
            'user': userId
          }); // And only there is an account, redirect to project

          if (accounts.length === 1) {
            const account = accounts.firstObject;
            this.transitionTo('projects', account.get('id'), account.get('slug'));
          }
        }
      } catch (e) {
        transition.abort();
        this.transitionTo('login');
      }
    },

    model() {
      const prevTransition = this.get('session.prevTransition');

      if (prevTransition) {
        this.set("session.prevTransition", null);
        prevTransition.retry();
      }

      const userId = this.get('session.data.authenticated.user').id;
      const clientId = this.get('session.data.authenticated.user').client;
      return Ember.RSVP.hash({
        user: this.get('store').find('user', userId),
        users: this.get('store').findAll('user', {
          reload: true
        }),
        accounts: this.get('store').query('account', {
          'user': userId
        }),
        publicPendingMembers: this.get('ajax').request('/pending-members/?client_id=' + clientId, {
          method: 'GET'
        }).then(response => {
          return response.data;
        })
      });
    },

    actions: {
      willTransition() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});