define("mytaskpanel/services/photo-profile", ["exports", "mytaskpanel/libs/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),

    /**
    * @method setPhoto
    * @description Set photo profile dynamic
    */
    setPhoto(file, self) {
      const size = file.size; // 2mg max

      if (!this.validSizeImage(size)) {
        return false;
      }

      self.set('photo_file', file);
      const validImageTypes = (0, _utils.getValidTypesImage)();

      if (Ember.$.inArray(file['type'], validImageTypes) > 0) {
        const reader = new FileReader();
        self.set('photo', file);
        let imageData; //Reading file is async

        reader.onload = () => {
          imageData = reader.result;
          self.set('photo', imageData);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        this.set('errorMessage', this.get('intl').t('photo.error_type'));
      }
    },

    /**
    * @method setPhoto
    * @description Set photo profile dynamic in base64
    */
    async setPhotoBase64(file, self) {
      const size = file.size; // 2mg max

      if (!this.validSizeImage(size)) {
        return false;
      }

      const validImageTypes = (0, _utils.getValidTypesImage)();

      if (Ember.$.inArray(file['type'], validImageTypes) > 0) {
        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => resolve(reader.result);

          reader.onerror = error => reject(error);
        });

        const fileBase64 = await toBase64(file);
        self.set('photo', fileBase64);
        self.set('photo_file', fileBase64);
      } else {
        this.set('errorMessage', this.get('intl').t('photo.error_type'));
      }
    },

    /**
     * @method validSizeImage
     * @description Valid the size of image
     * @param {*} size
     */
    validSizeImage(size) {
      // 2mg max
      if (size > 2000000) {
        const toast = this.get('toast');
        toast.warning(this.get('intl').t('photo.file.size'));
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});