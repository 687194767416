define("mytaskpanel/helpers/get-total-tasks-completed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTotalTasksCompleted = getTotalTasksCompleted;
  _exports.default = void 0;

  /**
  * @method getTotalTasksCompleted
  * @description Get total tasks end
  * @param {*} params
  */
  function getTotalTasksCompleted(params) {
    const tasks = params[0];
    return tasks.filter(t => t.endDate !== null).length;
  }

  var _default = Ember.Helper.helper(getTotalTasksCompleted);

  _exports.default = _default;
});