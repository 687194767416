define("mytaskpanel/authenticators/jwt", ["exports", "ember-simple-auth/authenticators/base", "mytaskpanel/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global FastBoot */
  const decode = str => {
    if (typeof atob === 'function') {
      return atob(str);
    } else if (typeof FastBoot === 'object') {
      try {
        const buffer = FastBoot.require('buffer');

        return buffer.Buffer.from(str, 'base64').toString('utf-8');
      } catch (err) {
        throw new Error('buffer must be available for decoding base64 strings in FastBoot. Make sure to add buffer to your fastbootDependencies.');
      }
    } else {
      throw new Error('Neither atob nor the FastBoot global are avaialble. Unable to decode base64 strings.');
    }
  };

  var _default = _base.default.extend({
    session: Ember.inject.service('session'),

    /**
    *  @method init
    *  @private
    */
    init() {
      this._super(...arguments);

      this.tokenPropertyName = 'token';
      this.tokenDataPropertyName = 'tokenData';
      this.refreshTokenPropertyName = 'token';
      this.tokenExpireName = 'exp';
      this.refreshAccessTokens = true;
      this.tokenExpirationInvalidateSession = true;
      this.serverTokenEndpoint = _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/token-auth/';
      this.serverTokenRefreshEndpoint = _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/auth-jwt-refresh/'; // 15 min refresh token

      this.refreshLeeway = 900;
    },

    /**
    *  Restores the session from a set of session properties.
    *  It will return a resolving promise if one of two conditions is met:
    *  1) Both `data.token` and `data.expiresAt` are non-empty and `expiresAt`
    *     is greater than the calculated `now`.
    *  2) If `data.token` is non-empty and the decoded token has a key for
    *     `tokenExpireName`.
    *  If `refreshAccessTokens` is true, `scheduleAccessTokenRefresh` will
    *  be called and an automatic token refresh will be initiated.
    *  @method restore
    *  @param {Object} data The data to restore the session from
    *  @return {Promise} A promise that when it resolves results
    *                    in the session being authenticated
    */
    restore(data) {
      const dataObject = Ember.Object.create(data);
      return new Ember.RSVP.Promise((resolve, reject) => {
        const now = this.getCurrentTime();
        const token = dataObject.get(this.tokenPropertyName);
        const refreshToken = dataObject.get(this.refreshTokenPropertyName);
        let expiresAt = dataObject.get(this.tokenExpireName);

        if (Ember.isEmpty(token)) {
          return reject(new Error('empty token'));
        }

        if (Ember.isEmpty(expiresAt)) {
          // Fetch the expire time from the token data since `expiresAt`
          // wasn't included in the data object that was passed in.
          const tokenData = this.getTokenData(token);
          expiresAt = tokenData[this.tokenExpireName];

          if (Ember.isEmpty(expiresAt)) {
            return resolve(data);
          }
        }

        if (expiresAt > now) {
          const wait = (expiresAt - now - this.refreshLeeway) * 1000;

          if (this.tokenExpirationInvalidateSession) {
            this.scheduleAccessTokenExpiration(expiresAt);
          }

          if (wait > 0) {
            if (this.refreshAccessTokens) {
              this.scheduleAccessTokenRefresh(dataObject.get(this.tokenExpireName), refreshToken);
            }

            return resolve(data);
          } else if (this.refreshAccessTokens) {
            return resolve(this.refreshAccessToken(refreshToken));
          } else {
            return reject(new Error('unable to refresh token'));
          }
        } else {
          // the refresh token might not be expired,
          // we can't test this on the client so attempt to refresh the token.
          // If the server rejects the token the user session will be invalidated
          if (this.refreshAccessTokens) {
            return resolve(this.refreshAccessToken(refreshToken));
          } else {
            return reject(new Error('token is expired'));
          }
        }
      });
    },

    /**
    * Authenticates the session with the specified `credentials`.
    * It will return a resolving promise if it successfully posts a request
    * to the `JWT.serverTokenEndpoint` with the valid credentials.
    * An automatic token refresh will be scheduled with the new expiration date
    * from the returned refresh token. That expiration will be merged with the
    * response and the promise resolved.
    * @method authenticate
    * @return {Promise} A promise that resolves when an auth token is
    * successfully acquired from the server and rejects otherwise
    */
    authenticate(identification, password) {
      const credentials = {
        username: identification,
        password: password
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.makeRequest(this.serverTokenEndpoint, credentials, {}).then(response => resolve(this.handleAuthResponse(response))).catch(e => reject(e));
      });
    },

    /**
    * Schedules a token refresh request to be sent to the backend after a calculated
    * `wait` time has passed.
    * If both `token` and `expiresAt` are non-empty, and `expiresAt` minus the optional
    * refres leeway is greater than the calculated `now`, the token refresh will be scheduled
    * through later.
    * @method scheduleAccessTokenRefresh
    * @private
    */
    scheduleAccessTokenRefresh(expiresAt, refreshToken) {
      if (this.refreshAccessTokens) {
        const now = this.getCurrentTime();
        const wait = (expiresAt - now - this.refreshLeeway) * 1000;

        if (!Ember.isEmpty(refreshToken) && !Ember.isEmpty(expiresAt)) {
          if (wait > 0) {
            Ember.run.cancel(this._refreshTokenTimeout);
            delete this._refreshTokenTimeout;
            this._refreshTokenTimeout = Ember.run.later(this, this.refreshAccessToken, refreshToken, wait);
          } else if (expiresAt > now) {
            throw new Error('refreshLeeway is too large which is preventing token refresh.');
          }
        }
      }
    },

    /**
    * Makes a refresh token request to grab a new authenticated JWT token from the server.
    * It will return a resolving promise if a successful POST is made to the
    * `JWT.serverTokenRefreshEndpoint`.
    * After the new token is obtained it will schedule the next automatic token refresh
    * based on the new `expiresAt` time.
    * The session will be updated via the trigger `sessionDataUpdated`.
    * @method refreshAccessToken
    * @private
    */
    refreshAccessToken(token) {
      let client = null;
      const headers = {};

      try {
        headers['Authorization'] = 'jwt ' + token;

        if (this.get('session.data.authenticated.user').client != null) {
          client = this.get('session.data.authenticated.user').client;
          headers['client-id'] = client;
        }
      } catch (e) {// null
      }

      return this.makeRequest(this.serverTokenRefreshEndpoint, {
        token: token
      }, headers).then(response => {
        const sessionData = this.handleAuthResponse(response);
        sessionData['user']['client'] = client;
        this.trigger('sessionDataUpdated', sessionData);
        return sessionData;
      }).catch(error => {
        this.handleTokenRefreshFail(error.status);
        return Ember.RSVP.Promise.reject(error);
      });
    },

    /**
    * Returns the decoded token with accessible returned values.
    * @method getTokenData
    * @return {object} An object with properties for the session.
    */
    getTokenData(token) {
      const payload = token.split('.')[1];
      const decodedPayload = decode(payload.replace(/-/g, '+').replace(/_/g, '/'));
      const tokenData = decodeURIComponent(window.escape(decodedPayload));

      try {
        return JSON.parse(tokenData);
      } catch (error) {
        return tokenData;
      }
    },

    /**
    * Cancels any outstanding automatic token refreshes and returns a resolving
    * promise.
    * @method invalidate
    * @param {Object} data The data of the session to be invalidated
    * @return {Promise} A resolving promise
    */
    invalidate() {
      Ember.run.cancel(this._refreshTokenTimeout);
      delete this._refreshTokenTimeout;
      Ember.run.cancel(this._tokenExpirationTimeout);
      delete this._tokenExpirationTimeout;
      return Ember.RSVP.resolve();
    },

    /**
    * Returns the current time as a timestamp in seconds
    * @method getCurrentTime
    * @return {Integer} timestamp
    */
    getCurrentTime() {
      return Math.floor(new Date().getTime() / 1000);
    },

    /**
    * Handles authentication response from server, and returns session data
    * @method handleAuthResponse
    * @private
    */
    handleAuthResponse(response) {
      const token = response.token;

      if (Ember.isEmpty(token)) {
        throw new Error('Token is empty. Please check your backend response.');
      }

      const tokenData = this.getTokenData(token);
      const expiresAt = Ember.get(tokenData, this.tokenExpireName);
      const tokenExpireData = {};
      tokenExpireData[this.tokenExpireName] = expiresAt;

      if (this.tokenExpirationInvalidateSession) {
        this.scheduleAccessTokenExpiration(expiresAt);
      }

      if (this.refreshAccessTokens) {
        const refreshToken = Ember.get(response, this.refreshTokenPropertyName);

        if (Ember.isEmpty(refreshToken)) {
          throw new Error('Refresh token is empty. Please check your backend response.');
        }

        this.scheduleAccessTokenRefresh(expiresAt, refreshToken);
      }

      return Ember.assign(response, tokenExpireData, {
        tokenData: tokenData
      });
    },

    /**
    * Handles token refresh fail status. If the server response to a token refresh has a
    * status of 401 or 403 then the token in the session will be invalidated and
    * the sessionInvalidated provided by ember-simple-auth will be triggered.
    * @method handleTokenRefreshFail
    */
    handleTokenRefreshFail(refreshStatus) {
      if (refreshStatus === 401 || refreshStatus === 403) {
        return this.invalidate().then(() => {
          this.trigger('sessionDataInvalidated');
        });
      }
    },

    /**
    * Schedules session invalidation at the time token expires.
    * @method scheduleAccessTokenExpiration
    * @private
    */
    scheduleAccessTokenExpiration(expiresAt) {
      const now = this.getCurrentTime();
      const wait = Math.max((expiresAt - now) * 1000, 0);

      if (!Ember.isEmpty(expiresAt)) {
        Ember.run.cancel(this._tokenExpirationTimeout);
        delete this._tokenExpirationTimeout;
        this._tokenExpirationTimeout = Ember.run.later(this, this.handleAccessTokenExpiration, wait);
      }
    },

    /**
    * Handles access token expiration
    * @method handleAccessTokenExpiration
    * @private
    */
    handleAccessTokenExpiration() {
      return this.invalidate().then(() => {
        this.trigger('sessionDataInvalidated');
      });
    },

    /**
    * @method makeRequest
    * @param {Object} url Server endpoint
    * @param {Object} data Object that will be sent to server
    * @param {Object} headers Additional headers that will be sent to server
    * @private
    */
    makeRequest(url, data, headers) {
      const requestOptions = {
        url: url,
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: data,
        headers: headers
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        return Ember.$.ajax(requestOptions).then(response => {
          const text = response;
          Ember.run(() => {
            resolve({
              token: text.data.token,
              user: text.data.user
            });
          });
        }).catch(reject);
      });
    }

  });

  _exports.default = _default;
});