define("mytaskpanel/mixins/except-invited-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),

    beforeModel() {
      try {
        // All except invited users
        const userType = this.get('session.data.authenticated.user').user_type;

        if (userType.trim().toUpperCase() === 'I') {
          this.transitionTo('index');
        }
      } catch (e) {
        this.transitionTo('index');
      }
    }

  });

  _exports.default = _default;
});