define("mytaskpanel/templates/inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cLxtdr2R",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"inbox/inbox-index\",null,[[\"tasks\",\"accounts\",\"users\",\"labels\",\"updateTasks\",\"refreshRoute\",\"reloadData\",\"searchFilterValue\"],[[23,[\"model\",\"tasks\"]],[23,[\"model\",\"accounts\"]],[23,[\"model\",\"users\"]],[23,[\"model\",\"labels\"]],[27,\"action\",[[22,0,[]],\"updateTasks\"],null],[27,\"action\",[[22,0,[]],\"refresh\"],null],[23,[\"model\",\"reload\"]],[23,[\"searchFilterValue\"]]]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/inbox.hbs"
    }
  });

  _exports.default = _default;
});