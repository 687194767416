define("mytaskpanel/helpers/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sum = sum;
  _exports.default = void 0;

  /**
   * @method sum
   * @description Sum data
   * @param {*} params
   */
  function sum(params) {
    return params.reduce((a, b) => {
      return a + b;
    });
  }

  var _default = Ember.Helper.helper(sum);

  _exports.default = _default;
});