define("mytaskpanel/components/auth/list-clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    router: Ember.inject.service('-routing'),
    session: Ember.inject.service('session'),
    actions: {
      /**
      * @method goToClient
      * @description Redirect to client to work
      * @param {*} id - Client id
      * @param {*} clientName - Client name
      * @param {*} user - Data user
      */
      goToClient(client, user) {
        // Update client id
        this.get('session').set('session.content.authenticated.user.client', client.id);
        this.get('session').set('session.content.authenticated.user.client_language', client.language);
        this.get('session').set('session.content.authenticated.user.package', client.package);
        this.get('session').set('session.content.authenticated.user.user_type', user.user_type);
        this.get('session').set('session.content.authenticated.user.position', user.position);
        this.get('session').set('session.content.authenticated.user.department', user.department);
        this.get('session').set('session.content.authenticated.user.user_photo', user.photo);
        this.get('session').set('session.content.authenticated.user.rol', user.rol);
        this.get('session').set('session.content.authenticated.user.id', user.id);
        this.get('session').set('session.content.authenticated.user.client_name', client.name);
        this.get('session').set('session.content.authenticated.user.date_expired_client', client.date_expired_client);
        this.get('session').set('session.content.authenticated.user.licence_type', client.licence_type);
        const newSession = this.get('session.data');
        const key = this.get('session.store._store.key'); // Save in localStorage

        localStorage.setItem(key, JSON.stringify(newSession)); // Set lang

        this.get('intl').setLocale([client.language]); // Redirect

        const router = this.get('router');
        router.router.transitionTo('accounts');

        if (this.get('reload')) {
          window.location.href = '/';
        }
      }

    }
  });

  _exports.default = _default;
});