define("mytaskpanel/helpers/format-datetime", ["exports", "mytaskpanel/libs/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDatetime = formatDatetime;
  _exports.default = void 0;

  function formatDatetime(params) {
    let d = params[0];
    const hasHour = params[1];

    if (typeof d === 'string' && window.moment(d, window.moment.ISO_8601, true).isValid()) {
      if (hasHour) {
        d = new Date(d);
      } else {
        d = new Date(d + 'T00:00:00');
      }
    }

    let date = d;

    if (d instanceof Date) {
      const hasHour = params[1];
      date = (0, _utils.getLocaleDateString)(d, hasHour);
    }

    return date;
  }

  var _default = Ember.Helper.helper(formatDatetime);

  _exports.default = _default;
});