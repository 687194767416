define("mytaskpanel/models/timesheet", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    taskFree: _emberData.default.attr('string'),
    totalHours: _emberData.default.attr('number'),
    totalHoursGroup: _emberData.default.attr('number'),
    date: _emberData.default.attr(),
    days: _emberData.default.attr(),
    tasks: _emberData.default.attr(),
    taskDescription: _emberData.default.attr('string'),
    taskId: _emberData.default.attr('number'),
    genericTaskId: _emberData.default.attr('number'),
    projectId: _emberData.default.attr('number'),
    task: _emberData.default.belongsTo('task'),
    user: _emberData.default.belongsTo('user'),
    project: _emberData.default.belongsTo('project'),
    genericTask: _emberData.default.belongsTo('generic-task')
  });

  _exports.default = _default;
});