define("mytaskpanel/templates/price/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hI4gkefR",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"price/form-price\",null,[[\"params\",\"packages\"],[[23,[\"model\",\"params\"]],[23,[\"model\",\"packages\"]]]]],false],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/price/index.hbs"
    }
  });

  _exports.default = _default;
});