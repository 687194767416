define("mytaskpanel/templates/components/users/pending-users-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+fBz4REk",
    "block": "{\"symbols\":[\"user\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"publicPendingMembers\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"row mt-4 mx-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-lg-12\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"card-box h-30\"],[9],[0,\"\\n         \"],[7,\"h4\"],[11,\"class\",\"header-title mt-0\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"users.pending\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"table\"],[11,\"class\",\"table mb-0 table-responsive\"],[9],[0,\"\\n            \"],[7,\"thead\"],[9],[0,\"\\n                \"],[7,\"tr\"],[9],[0,\"\\n                    \"],[7,\"th\"],[9],[1,[27,\"t\",[\"email\"],null],false],[10],[0,\"\\n                    \"],[7,\"th\"],[9],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"publicPendingMembers\"]]],null,{\"statements\":[[0,\"                \"],[7,\"tr\"],[9],[0,\"\\n                    \"],[7,\"td\"],[9],[1,[22,1,[\"email\"]],false],[10],[0,\"\\n                    \"],[7,\"td\"],[9],[7,\"span\"],[11,\"style\",\"cursor: pointer; margin-top: -1em\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[11,\"aria-hidden\",\"true\"],[9],[10],[3,\"action\",[[22,0,[]],\"removePendingUser\",[22,1,[]],[23,[\"account\",\"id\"]]]],[10],[10],[0,\"\\n                \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/users/pending-users-table.hbs"
    }
  });

  _exports.default = _default;
});