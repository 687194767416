define("mytaskpanel/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    clients: Ember.inject.service('clients'),

    async model() {
      const response = await this.get('clients').checkTotalClients();

      if (response.total > 1) {
        this.transitionTo('clients');
      }
    },

    afterModel() {
      this.transitionTo('accounts');
    }

  });

  _exports.default = _default;
});