define("mytaskpanel/routes/tasks/timeline", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    model() {
      const params = this.paramsFor('tasks');
      return Ember.RSVP.hash({
        params: params,
        project: this.get('store').find('project', params.project_id),
        sprints: this.get('ajax').request(`/sprints/?project_id=${params.project_id}&order=1`, {
          method: 'GET'
        }).then(response => {
          return response.data;
        }).catch(error => {
          throw new Error(error);
        }),
        tasks: this.get('ajax').request('/task-timeline/', {
          method: 'GET',
          data: {
            project_id: params.project_id
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          throw new Error(error);
        })
      });
    }

  });

  _exports.default = _default;
});