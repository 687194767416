define("mytaskpanel/components/settings/form-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    photoProfile: Ember.inject.service('photo-profile'),
    session: Ember.inject.service('session'),
    toast: Ember.inject.service('toast'),
    error_password: '',
    client: null,
    photo_file: null,
    originalUsername: '',
    // Check if password match
    checkPassword: Ember.observer('password', 'confirm_password', function () {
      if (Ember.isPresent(this.password) && Ember.isPresent(this.confirm_password)) {
        if (this.password != this.confirm_password) {
          this.set('error_password', this.get('intl').t('auth.error_password'));
        } else {
          this.set('error_password', '');
        }
      }
    }),
    // Check if enable button save
    disabledSave: Ember.computed('user.{firstName,lastName,username}', 'password', 'confirm_password', {
      get() {
        if (this.password !== '') {
          if (this.password !== this.confirm_password || this.user.firstName === '' || this.user.lastName === '' || this.user.username === '') {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.user.firstName === '' || this.user.lastName === '' || this.user.username === '') {
            return true;
          } else {
            return false;
          }
        }
      }

    }),
    // Check if enable button save owner
    disabledSaveOwner: Ember.computed('client.{company_name,country,language,company_size_id}', {
      get() {
        try {
          if (this.client.company_name === '' || this.client.country === '' || this.client.language === '' || this.client.company_size_id === '') {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return true;
        }
      }

    }),

    async didInsertElement() {
      this._super();

      this.set('photo', this.get('user.userPhoto')); // Check if is owner or admin

      const userType = await this.get('user.userType');

      if (userType === 'O' || userType === 'A') {
        const clientId = this.get('session.data.authenticated.user').client;
        const response = await this.get('ajax').request('/get-or-update-client?client_id=' + clientId, {
          method: 'GET'
        });
        this.set('client', response.data);
      }

      this.set('originalUsername', this.get('user.username'));
    },

    actions: {
      /**
      * @method setPhoto
      * @description Set photo profile dynamic
      */
      async setPhoto() {
        const file = Ember.$('#photo_id')[0].files[0];
        await this.get('photoProfile').setPhotoBase64(file, this);
      },

      async changeUsername() {
        const formData = new FormData();
        formData.append('username', this.user.username);
        const response = await this.get('ajax').request('/check-if-exists-username/', {
          method: 'POST',
          processData: false,
          contentType: false,
          data: formData
        });

        if (response.data.exists) {
          this.set('user.username', this.get('originalUsername'));
          const toast = this.get('toast');
          toast.warning(this.get('intl').t('username.exists'));
        } else {
          this.set('originalUsername', this.get('user.username'));
        }
      },

      /**
      * @method setCountryOwner
      * @description set Value to country in form owner
      */
      setCountryOwner() {
        const country = Ember.$("#countries").val();
        this.set('client.country', country);
      },

      /**
      * @method setLanguageOwner
      * @description set Value to language in form owner
      */
      setLanguageOwner() {
        const languages = Ember.$("#languages").val();
        this.set('client.language', languages);
      },

      /**
      * @method setCompanySizeOwner
      * @description set Value to company size in form owner
      */
      setCompanySizeOwner() {
        const company_size = Ember.$("#company_size").val();
        this.set('client.company_size_id', company_size);
      },

      /**
      * @method setTimeZone
      * @description set Value to timezone in form user
      */
      setTimeZone() {
        const timezone = Ember.$("#timezone").val();
        this.set('user.timeZone', timezone);
      },

      /**
      * @method saveForm
      * @description Save form profile
      */
      async saveForm() {
        let changePhoto = false;

        if (this.get('photo_file') !== null) {
          this.set('user.photo', this.get('photo_file'));
          changePhoto = true;
        } else {
          this.set('user.photo', null);
        } // Update user


        const data = {
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          username: this.user.username
        };

        if (this.get('password') !== '' && this.get('password') !== undefined) {
          data['password'] = this.get('password');
        }

        const formData = new FormData();
        Object.keys(data).forEach(key => {
          formData.append(key, data[key]);
        });
        const response = await this.get('ajax').request('/update-user-admin/', {
          method: 'POST',
          processData: false,
          contentType: false,
          data: formData
        }); // Update token if recieve the token

        if (response.data.token) {
          this.get('session').set('session.content.authenticated.token', response.data.token);
        } // Update timezone in the localStorage


        try {
          const tz = Ember.$("#timezone option:selected").data('tz');
          this.get('session').set('session.content.authenticated.user.timezone', tz);
        } catch (e) {// nothing
        }

        if (changePhoto) {
          // Update photo in the localStorage
          this.get('session').set('session.content.authenticated.user.user_photo', this.user.photo);
        }

        this.get('session').set('session.content.authenticated.user.username', this.user.username); // For update localStorage

        const newSession = this.get('session.data');
        const key = this.get('session.store._store.key'); // Save in localStorage

        localStorage.setItem(key, JSON.stringify(newSession)); // Update user-schema

        await this.user.save();
        this.set('password', '');
        this.set('confirm_password', '');
        const toast = this.get('toast');
        toast.success(this.get('intl').t('profile.updated'));
      },

      /**
      * @method saveFormNotifications
      * @description Save form notifications
      */
      async saveFormNotifications() {
        await this.user.save();
        const toast = this.get('toast');
        toast.success(this.get('intl').t('profile.updated'));
      },

      /**
      * @method saveFormAccount
      * @description Save form account for owner
      */
      async saveFormAccount() {
        // Update client
        const data = {
          company_name: this.client.company_name,
          phone: this.client.phone || '',
          country: this.client.country,
          language: this.client.language,
          address: this.client.address || '',
          company_size_id: this.client.company_size_id
        };
        const formData = new FormData();
        Object.keys(data).forEach(key => {
          formData.append(key, data[key]);
        });
        await this.get('ajax').request('/get-or-update-client/', {
          method: 'POST',
          processData: false,
          contentType: false,
          data: formData
        }); // Update photo in the localStorage

        this.get('session').set('session.content.authenticated.user.client_language', this.client.language);
        this.get('session').set('session.content.authenticated.user.client_name', this.client.company_name);
        const newSession = this.get('session.data');
        const key = this.get('session.store._store.key'); // Set lang

        this.get('intl').setLocale([this.client.language]); // Save in localStorage

        localStorage.setItem(key, JSON.stringify(newSession));
        const toast = this.get('toast');
        toast.success(this.get('intl').t('profile.updated'));
      }

    }
  });

  _exports.default = _default;
});