define("mytaskpanel/templates/components/tasks/tasks-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G5d4WFHa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dashboard calendar\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"botonera\"],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"calendar-indicators-buttons\"],[11,\"style\",\"padding: 0.5em 1.5em\"],[9],[1,[27,\"t\",[\"today\"],null],false],[3,\"action\",[[22,0,[]],\"today\"]],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"calendar-indicators-buttons\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-chevron-left\"],[9],[10],[3,\"action\",[[22,0,[]],\"prev\"]],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"calendar-indicators-buttons\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-chevron-right\"],[9],[10],[3,\"action\",[[22,0,[]],\"next\"]],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"id\",\"calendar-current-month-text\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"btn-group btn-group-toggle\"],[11,\"data-toggle\",\"buttons\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"btn btn-outline-secondary mtp\"],[9],[0,\"\\n        \"],[7,\"input\"],[11,\"name\",\"options\"],[11,\"type\",\"radio\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"calendar.weekly\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"weekly\"]],[10],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"btn btn-outline-secondary mtp active\"],[9],[0,\"\\n        \"],[7,\"input\"],[11,\"name\",\"options\"],[11,\"checked\",\"\"],[11,\"type\",\"radio\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"calendar.monthly\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"monthly\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"id\",\"calendar\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/tasks/tasks-calendar.hbs"
    }
  });

  _exports.default = _default;
});