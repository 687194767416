define("mytaskpanel/routes/inbox", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    reload: 0,

    model(params) {
      const reload = this.get('reload') + 1;
      this.set('reload', reload);
      const userId = this.get('session.data.authenticated.user').id;
      return Ember.RSVP.hash({
        params: params,
        users: this.get('store').findAll('user'),
        labels: this.get('store').findAll('label'),
        tasks: this.get('store').query('task', {
          type_filter: 'inbox'
        }),
        accounts: this.get('store').query('account', {
          user: userId
        }),
        reload: reload
      });
    },

    actions: {
      /**
      * @method updateTasks
      * @description Update tasks route model
      */
      updateTasksRoute() {
        const controller = this.controllerFor('inbox'); // Update model

        this.model(this.context.params).then(m => {
          controller.set('model', m);
        });
      },

      /**
      * @method refreshRoute
      * @description Reload route
      */
      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});