define("mytaskpanel/components/auth/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),
    enableReset: Ember.computed('email', {
      get() {
        return Ember.isPresent(this.email) ? false : true;
      }

    }),
    actions: {
      /**
      * @method reset
      * @description Set email to reset password
      */
      async reset() {
        this.set('errorMessage', '');
        let valid = true;
        const {
          email
        } = this.getProperties('email');
        const response = await this.get('ajax').request('/reset-password/', {
          method: 'POST',
          data: {
            email: email
          }
        }).catch(() => {
          valid = false;
          this.set('errorMessage', this.get('intl').t('auth.reset.invalid_user'));
        });

        if (valid) {
          const toast = this.get('toast');

          if (response.data.success) {
            this.set('email', '');
            toast.success(this.get('intl').t('email.check'));
          } else {
            this.set('errorMessage', this.get('intl').t('auth.reset.error'));
          }
        }
      }

    }
  });

  _exports.default = _default;
});