define("mytaskpanel/components/projects/project-members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    randomId: 'list-members',
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),
    projectService: Ember.inject.service('projects'),

    init() {
      this._super(...arguments);

      this.set('selectedUserIds', []);
      this.set('removedUserIds', []);
    },

    actions: {
      addRemoveMemberProject(user) {
        const isChecked = Ember.$(`#checkbox_${user.id}`).is(":checked");

        if (user.isMember) {
          if (isChecked) {
            this.get('removedUserIds').removeObject(user);
          } else {
            this.get('removedUserIds').pushObject(user);
          }
        } else {
          if (isChecked) {
            this.get('selectedUserIds').pushObject(user);
          } else {
            this.get('selectedUserIds').removeObject(user);
          }
        }
      },

      saveMembers() {
        const _this = this;

        this.set('parent.parent.showLoading', true);

        if (this.selectedUserIds) {
          this.selectedUserIds.map(function (member) {
            _this.store.find('user', member.id).then(user => {
              const projectUser = _this.store.createRecord('project-user', {
                project: _this.project,
                user: user,
                userType: member.userType
              });

              projectUser.save().then(() => {
                Ember.set(member, "isMember", true);
              });
            });
          });
          this.set('selectedUserIds', []);
        }

        if (this.removedUserIds) {
          _this.removedUserIds.forEach(user => {
            _this.store.query('project-user', {
              project: _this.project.id,
              user: user.id
            }).then(records => {
              const projectUser = records.get('firstObject');
              projectUser.deleteRecord();
              projectUser.save().then(() => {
                _this.projectService.getMembersProject(_this.project).then(response => {
                  _this.set('projectMembers', response.data.members);
                });
              });
            });
          });

          this.set('removedUserIds', []);
        }

        const toast = this.get('toast');
        toast.success(this.get('intl').t('project.update'));
        this.set('parent.parent.showLoading', false);
        Ember.$('#wrapper').toggleClass('right-bar-enabled');
      },

      /**
      * @method showEditForm
      * @description Redirect to to edit user_type - ProjectUser
      */
      async showEditForm(user) {
        // Set accountUser
        const project = this.get('project');
        const records = await this.store.query('project-user', {
          project: project.id,
          user: user.id
        });
        const projectUser = records.get('firstObject');
        this.set('projectUser', projectUser);
        this.set('memberInList', user); // Set user type

        const userType = projectUser.userType;
        Ember.$('[name=user_type_' + this.get('randomId') + ']').filter('[value="' + userType + '"]').prop('checked', true); // Show modal

        Ember.$("#modal_edit_project_user").modal('show');
      }

    }
  });

  _exports.default = _default;
});