define("mytaskpanel/mixins/only-owner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    beforeModel() {
      const authenticationRoute = this.get('authenticationRoute');
      let email;

      try {
        // Only owner can access
        email = this.get('session.data.authenticated.user').email;
      } catch (e) {
        this.transitionTo(authenticationRoute);
        this.transitionTo('index');
      }

      try {
        this.get('ajax').request('/is-owner/', {
          method: 'POST',
          data: {
            email: email
          }
        }).then(response => {
          if (!response.data.is_owner) {
            this.transitionTo(authenticationRoute);
            this.transitionTo('index');
          }
        });
      } catch (e) {
        this.transitionTo('index');
      }
    }

  });

  _exports.default = _default;
});