define("mytaskpanel/components/utils/right-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      /**
      * @method closeModal
      * @description Close modal
      */
      closeModal() {
        Ember.$('#wrapper').toggleClass('right-bar-enabled'); // Close all links/popover

        Ember.$('.summernote').summernote('code', '');

        if (this.get('isFormTask')) {
          // close modal task, clean object task
          this.set('parent.task', null); // Only work for task-list

          try {
            this.get('parent.parent').loadPopovers();
          } catch (e) {// Nothing
          }
        }
      }

    }
  });

  _exports.default = _default;
});