define("mytaskpanel/helpers/get-date-with-format", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDateWithFormat = getDateWithFormat;
  _exports.default = void 0;

  function getDateWithFormat(date) {
    if (date === '') {
      return '';
    }

    let format;

    switch (navigator.language) {
      case 'en':
        format = 'MM/DD/YYYY';
        break;

      case 'es':
        format = 'DD/MM/YYYY';
        break;

      default:
        format = 'DD/MM/YYYY';
        break;
    }

    return (0, _moment.default)(date, 'YYYY-MM-DD').format(format);
  }

  var _default = Ember.Helper.helper(getDateWithFormat);

  _exports.default = _default;
});