define("mytaskpanel/models/account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    productOwner: _emberData.default.belongsTo('user'),
    privacy: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    color: _emberData.default.attr('string'),
    projects: _emberData.default.hasMany('project'),
    totalProjects: _emberData.default.attr('number'),
    totalUsersAccount: _emberData.default.attr('number')
  });

  _exports.default = _default;
});