define("mytaskpanel/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EK6zNGA3",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[1,[21,\"auth/form-login\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/login.hbs"
    }
  });

  _exports.default = _default;
});