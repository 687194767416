define("mytaskpanel/templates/favorite-projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6jCESUBD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-sm-12\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-title-box\"],[9],[0,\"\\n      \"],[7,\"nav\"],[11,\"class\",\"navbar navbar-dark navbar-expand-md bg-faded \"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"navcontent\"],[9],[0,\"\\n          \"],[7,\"ol\"],[11,\"class\",\"breadcrumb nav navbar-nav ml-auto w-100 justify-content-end\"],[9],[0,\"\\n            \"],[7,\"li\"],[11,\"class\",\"breadcrumb-item active\"],[9],[1,[27,\"t\",[\"favorites\"],null],false],[0,\" (\"],[1,[23,[\"model\",\"projects\",\"length\"]],false],[0,\")\"],[10],[0,\"\\n          \"],[10],[0,\"\\n          \\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[27,\"projects/projects-list\",null,[[\"projects\",\"params\",\"user\",\"users\",\"accountUser\",\"show_new_project\",\"searchFilterValue\",\"account\",\"favorites\"],[[23,[\"model\",\"projects\"]],[23,[\"model\",\"params\"]],[23,[\"model\",\"user\"]],[23,[\"model\",\"users\"]],[23,[\"model\",\"accountUser\"]],false,[23,[\"searchFilterValue\"]],[23,[\"model\",\"account\"]],true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/favorite-projects.hbs"
    }
  });

  _exports.default = _default;
});