define("mytaskpanel/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "account.activate": "Account has been activated",
    "account.activate.question": "Do you want to activate the account?",
    "account.add.upgrade_plan": "Your current plan does not allow adding more accounts. Please update your plan.",
    "account.all": "All",
    "account.archive.question": "Do you want to archive the account?",
    "account.archived": "Archived accounts",
    "account.create": "Create account",
    "account.created": "The account was successfully created",
    "account.delete.question": "Do you want to delete the account?",
    "account.description": "The account is the workspace in which the projects will be grouped.",
    "account.error": "Error to create the account",
    "account.invitations.error": "There was an error sending the invitations",
    "account.invitations.ok": "The invitations were sent correctly",
    "account.invite": "Invite contacts",
    "account.invite.empty": "The invitation field is empty. Please enter the email and press enter or tab.",
    "account.invite_email": "Invite by email",
    "account.invite_email_invalid": "Email invalid",
    "account.invite_message": "You can invite multiple users",
    "account.name": "Account",
    "account.new": "New account",
    "account.pending": "Pending",
    "account.plural": "Accounts",
    "account.tour.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">An account or workspace is an entity which function is to group all the projects under that title and invite users to work on that team. In this step you can decide if the access to the projects of the account will be <strong>public</strong> (all members of your organization can access) or <strong>private</strong> (only the members that you invite will have access)</span>",
    "account.tour.title": "<span style=\"font-size:17px!important; font-family:roboto; color:#259dab; font-weight:800\">ACCOUNT</span>",
    "account.update": "Updated account",
    "account.upgrade_plan": "Your current plan does not allow to add more users. Please update your plan.",
    "account.user": "My accounts",
    "actions": "Actions",
    "activate": "Activate",
    "add": "Add",
    "add_group": "+ Add Group",
    "all": "All",
    "all.f": "All",
    "apply": "Apply",
    "archive": "Archive",
    "auth.error_password": "Passwords do not match",
    "auth.login": "Log in",
    "auth.login.account": "Don´t have an account?",
    "auth.login.create_account": "Sign up",
    "auth.login.error": "Username or password incorrect.",
    "auth.login.forgot": "Did you forget your password?",
    "auth.onboarding.create_account": "Create user",
    "auth.onboarding.error": "Error to create the user",
    "auth.reset": "Reset Password",
    "auth.reset.description": "To reset the password, please indicate your email.",
    "auth.reset.error": "Error to send email to reset the password",
    "auth.reset.invalid_user": "Not a valid user",
    "auth.reset_password.description": "Please confirm the new password.",
    "auth.reset_password.error": "Error to reset password",
    "auth.signup.accept": "By registering, I confirm that I have read and accepted the ",
    "auth.signup.already": "Do you already have user?",
    "auth.signup.duplicated": "The user is already registered",
    "auth.signup.error": "An error occurred while sending the confirmation email",
    "auth.signup.free": "Sign up free",
    "auth.signup.privacy": "and Privacy policy",
    "auth.signup.register": "Register",
    "auth.signup.terms": "Terms and Conditions",
    "back": "Back",
    "billing.information": "Billing information",
    "buy.now": "Buy now",
    "by": "by",
    "by_month": "By month",
    "by_week": "By week",
    "calendar": "Calendar",
    "calendar.annual": "Annual",
    "calendar.fri": "Fri",
    "calendar.mon": "Mon",
    "calendar.month": "Month",
    "calendar.monthly": "Monthly",
    "calendar.sat": "Sat",
    "calendar.sun": "Sun",
    "calendar.thu": "Thu",
    "calendar.tue": "Tue",
    "calendar.wed": "Wed",
    "calendar.week": "Week",
    "calendar.weekly": "Weekly",
    "cancel": "Cancel",
    "choose": "Choose",
    "city": "City",
    "client.change": "Change workspace",
    "client.expire": "for your version to expire",
    "client.expire.trial": "for your trial version to expire",
    "client.feature.payment": "This feature is paid",
    "client.new": "Create new workspace",
    "client.question.create": "Do you want to create a new workspace? You must log out first.",
    "client.selected": "Please select the client to work",
    "clients": "Clients",
    "close": "Close",
    "collapse.expand": "Collapse Expand",
    "color": "Color",
    "color.select": "Select color",
    "coming.soon": "Coming soon",
    "comment.delete.question": "Do you want to delete the comment?",
    "comment.without_comments": "No comments loaded",
    "comments": "Comments",
    "company": "Company",
    "company.company_name": "Company name",
    "company.company_size": "Company size",
    "completed": "Completed",
    "contact": "Contact",
    "contact.us": "Contact us",
    "continue": "Continue",
    "create": "Create",
    "dashboard": "Dashboard",
    "dashboard.accounts_number": "Accounts number",
    "dashboard.choose_users": "Choose the users",
    "dashboard.error": "Error to obtain the information of the graphics.",
    "dashboard.projects_number": "Projects number",
    "date.date_due": "Due date",
    "date.date_due_short": "Due",
    "date.date_end": "End date",
    "date.date_end_short": "End",
    "date.date_start": "Start date",
    "date.new_due_date": "New due date",
    "date.no_delivery_date": "No delivery date",
    "date.no_delivery_date_min": "No date",
    "date.select": "Select date",
    "date_due": "Due date",
    "date_renewal": "Renewal date",
    "date_subscription": "Subscription date",
    "dates": "Dates",
    "dates.invalid": "Invalid dates",
    "day": "Day",
    "days": "Days",
    "days.delay": "of delay",
    "delete": "Delete",
    "delete.question": "Do you want to delete the selected record?",
    "description": "Description",
    "deviation": "Deviation",
    "discount": "Discount",
    "discount.affiliate": "Affiliate discount",
    "edit": "Edit",
    "email": "Email",
    "email.check": "Please, check your email. If the mail does not arrive, please check your spam box.",
    "end.date": "End date",
    "enter_action": "Enter to confirm",
    "error.500.msg": "Something went wrong. Our technicians are already notified to solve the problem. Try again later.",
    "estimate": "Estimate",
    "faq": "Frequently Asked Questions",
    "favorites": "Favorites",
    "files": "Files",
    "filters": "Filters",
    "free": "Free",
    "generic_task": "Generic task",
    "generic_task.created": "Created generic task",
    "generic_task.updated": "Updated generic task",
    "generic_tasks": "Generic tasks",
    "go.home": "Go to home page",
    "google.connect": "Connect with Google",
    "grouper": "Grouper",
    "guided": "Guided tour",
    "help": "Help",
    "help.advanced": "Advanced tasks editing",
    "help.advanced.files": "Attached files",
    "help.advanced.files.text1": "Attachments must be uploaded from the “Comments” tab in the task’s edition panel. They are therefore associated with the person who made the comment.<br> Then they can be viewed, downloaded or deleted, from the “Files” tab:",
    "help.advanced.files.text2": "Each file is stored on the server in a secure way, using the Amazon S3.<br> There are limitations regarding the type of file to upload, the size of the file and the total storage space contracted per account. If you require more space, you can request a plan upgrade.",
    "help.advanced.notifications": "Notifications",
    "help.advanced.notifications.text": "Whether a user is responsible for a task, its follower or the user assigned to perform a subtask, will be notified each time an important event occurs within that task.<br> The events that are communicated will depend on the role you occupy and the type of assignment you have. The most frequent cases of notifications are: <ul> <li>When you have been assigned a new task or deleted a previous assignment</li> <li>When a task that you have been assigned or a follower of that task, change group (for example, if it passes to testing) or status</li> <li>When someone enters a new comment on a task that is yours or on one that you follow</li> <li>When you are a follower of a task that changes its due, start or end date</li> </ul> If you do not want to receive any of these notifications, you can customize them from your user profile.",
    "help.advanced.subtask.text1": "This term is a bit confusing because it is necessarily used for different purposes.<br> First, the <strong>list of activities</strong> (or checklist) to be carried out to conclude a task is considered a sub-task:<br> This list is created and updated from the edit panel of a task and does not contain more information than the one displayed on the screen (a detail of the activity and a user assigned to it). The other case in which the term is used is when a <strong>child task</strong> associated or dependent on a main task is created.",
    "help.advanced.subtask.text2": "The task #33 was created by clicking on the<strong> “+” sign </strong>that had the task #30, therefore it was created dependent on it. In Scrum, this would represent the relationship between a product backlog and a sprint backlog. In Structured methodology, it would be the functionality to be developed and the activity to be performed to fulfill it.<br> By editing an associated task (or a child one), you can see what task it depends on and edit this information, either to eliminate any dependency or to associate it with a different main task.<br> By dragging a main task from one group to the other, the child tasks will also be moved, although on the other hand, it is possible to move a child task independently of its maintask. However, the associated task is an independent entity and has the same characteristics as a main task or an independent task (one that does not have children).",
    "help.advanced.subtasks": "Subtaks",
    "help.advanced.tags": "Tags",
    "help.advanced.tags.text": "Tags are very useful for <strong>classifying a task</strong>. Each task could have none, one or more tags. The criterion for this is entirely in the hands of the project manager. Each tag is assigned a color code to make its identification simpler. For example, the red color is often used for a tag that represents a high priority event, such as a bug to solve. The tags can then be used in the filters (from the inbox, from the task panel or from the dashboard) to, for example, see all those tasks classified as “to improve”.",
    "help.advanced.user": "Assigned user and followers",
    "help.advanced.user.text": "Each task can have only one <strong>user assigned</strong>, who is considered responsible for the task, although not necessarily its executor. That is, a card could have a series of subtasks assigned to different users. However, the card (or main task) would be assigned to a supervising user, who would be responsible for coordinating compliance with the subtasks. Anyone can sign up as a follower of a task. This serves to be notified of certain events about the activity in it. Whether a user is responsible for a task or its <strong>follower</strong>, this one will receive certain <strong>notifications</strong> when important events occur in the task. You can unsubscribe to these notifications from your profile.",
    "help.agile": "Projects with iterations",
    "help.agile.control": "Control the status of a project",
    "help.agile.control.text1": "To keep track of the general state of all the tasks of a project, there is a control panel that provides valuable information on the highlights of the project status. This board also has a section of filters to consult even more precise information.",
    "help.agile.control.text2": "It also has a <strong> Calendar </strong> where you can see all the project tasks scheduled according to their due date. The color of the tasks corresponds to their current status (due or not).",
    "help.agile.control.text3": "Additionally, in agile projects, you will be able to see a <strong> Timeline </strong> view showing the project's iteration tree with all the tasks distributed chronologically in a time or Gantt chart.",
    "help.agile.pb": "Create a Product & Sprint backlogs",
    "help.agile.pb.text": "The truth is that there are many ways to do it and it depends on the life cycle that they are using which would be the most indicated. Basically, a product backlog (PB) is nothing more than a <strong>functionality to implement</strong>, with more or less level of detail. While a sprint backlog (SB) is a <strong>task to be carried out</strong> with a compliance officer. To register the PBs they could use a folder in the sprint tree that contains several cards with the functional detail, or simply a parent task with the functional description. To register the SBs, they could use simple task cards within the aforementioned folder or daughter tasks (associated with the PB card previously created).",
    "help.agile.sprint": "Create a new iteration",
    "help.agile.sprint.text1": "When a new <strong>project with iterations</strong> is created, by default a tree with a <strong>first iteration is created</strong>, which should then be renamed. You can use the first button on the bar to display the mentioned tree. You can use the mouse to drag iterations and <strong>build a tree</strong> of as many nested levels as you want. ",
    "help.agile.sprint.text2": "You can use the mouse to drag iterations and build a tree of as many nested levels as you want. As you hover over each iteration, three dots will appear. If you click on them, you will be able to display a context menu to <strong>add a new sprint</strong>, modify the dates or name of the selected one, or delete it. You can also use the <strong>“Move pending tasks”</strong> option so that, once an iteration is finished, incomplete tasks can be moved to another sprint.<br> <br>It is essential to understand that in this type of projects there is always an active sprint and all the tasks displayed or added will be linked to that sprint. However, by selecting root (the project) you can see all the tasks of all sprints.",
    "help.kanban": "Kanban board",
    "help.kanban.add": "Add a task",
    "help.kanban.add.text1": "The simplest way is to click in the “+” sign  in the upper right corner of the navigation panel. You can fill the detail in the main box and press Enter to confirm it. That’s it. Then you will have the opportunity to edit the task and add all the information you consider useful. Another option is to use the icons at the bottom of the box to indicate, before confirming the new task, one or more of the following data:<br><br> <ol> <li>The <strong>resource</strong> to which the task is assigned</li> <li>The <strong>labels</strong> you consider appropiate to classify the activity</li> <li>The <strong>expiration date</strong></li> <li>The<strong> priority</strong> with which it should be considered</li> <li>The <strong>estimated remaining time</strong> to finish the task</li> </ol>",
    "help.kanban.add.text2": " Depending on the information you fill in this step, you can obtain cards with different levels of detail. The cards try to optimize the space to show only the most relevant information for the user.",
    "help.kanban.complete": "Complete a task",
    "help.kanban.complete.text1": "There are several ways to record progress and end a task. One of them is to use the <strong> cards</strong> that appear in the navigation panel to update the remaining time to complete the activity and, finally, to mark the task using the checkbox that appears to the left of its name.<br> The task will be marked as completed on the date this action is taken.<br> Note that the vertical bar on the left of the card changes its color according to the task that is <strong>pending planning</strong> (no expiration date assigned), <strong>planned</strong> (if it has a date), <strong>expired</strong> (if its due date has already expired, differentiating those that expire on the day from those that are already overdue).<br> Another way of updating the status of a task is through its <strong>editing pannel</strong>, which is accessed by clicking on the card. There you can edit more information, even leave comments regarding the activity performed. Finally you can use the check that appears in the upper part of the panel to mark the task as completed.",
    "help.kanban.complete.text2": "The third alternative consists in using the <strong>inbox</strong>, in which you can see all your assigned tasks according to their status: tasks that have already expired, those that expire on the day and the next ones to expire. It is necessary to clarify that if a task does not have an assigned due date, it will appear as “not planned”. In this panel you can use the checkbox to mark a task as finished or click the card to edit it.",
    "help.kanban.edit": "Edit a task",
    "help.kanban.edit.text1": "By clicking on a task card, you will have the possibility to edit all its details within a collapsible work window.<br> To confirm the changes press Save, to cancel, simply close the window or select another task to edit. The available information is organized in different sections to facilitate its access.<br> In summary, besides the name of the task (only mandatory data) you can indicate the following information for a task: <ul  > <li> Detailed <strong>description </strong>of the task to be performed </li> <li> <b>User </b>responsible for the execution of the task (who will receive the notifications associated with it) </li> <li> One or more <strong>labels </strong>that can be used to classify the task </li> <li> <strong>Dates </strong>of expiration, beginning and end of the task (this determines its state: To do, Doing, Done) </li> <li> Estimated <strong>effort </strong>to carry out the activity </li> <li><strong>Priority </strong>with which it should be considered (this determines the order in which it is displayed in the navigation panel)</li> <li> <strong>Estimated </strong>remaing <strong>time </strong>to complete the task and <strong>real </strong>time used in the completion of the same (this information is used to calculate the deviations in the planning) </li> <li> Reference data such as which is the <strong>main task</strong> (if it were a subtask) or in which <strong>group </strong>the task is located (groups are used to classify the stages through which a task passes, very used in <strong>Kanban</strong>) </li> <li> List of task <strong>followers</strong> (who will be notified when certain events occur in it)</div> </li> <li> <strong>Subtasks </strong>that should be done, which is some sort of checklist with activities that can be assigned to different users each one of them. </li> </ul>",
    "help.kanban.edit.text2": "In addition to all the above, you could also use this panel to fill <strong>comments</strong> related to the task or attach files to it. <strong>Comments</strong> can be filled in rich text. </p> <p style=\"clear:both\"> The files to attach to the task are uploaded through the tab \"Files\". Keep in mind that there are limitations to the type, size and number of files to upload according to the plan you have purchased. Once uploaded, the attached files can be viewed, downloaded or deleted by means of this same tab:",
    "help.misc": "Misc",
    "help.misc.hours": "Time recording",
    "help.misc.hours.text1": "In MyTaskPanel you can enter the times of each task carried out directly in the task, using the <strong> \"real time\" </strong> for it. If when you finish a task, you have not indicated the time that you have taken, by default the estimated time will be loaded as real time.",
    "help.misc.hours.text2": "However, there is another way to report daily hours worked. That is through the <strong> \"Time record\" </strong>:",
    "help.misc.hours.text3": "If you are a <strong> user member </strong> of a team, you can record your hours in a spreadsheet that you can view per month or per week. If you are an <strong> administrator </strong>, you will be able to see and edit the hours of all the users you control. If you are a <strong> guest </strong>, you will be able to see the hours that enable you to view, but without modifying anything. There are two types of tasks to which you can assign hours: <ol><li> <strong> Tasks of a particular project / account </strong> (only if you are part of the team of that project) </li> <li> <strong> Generic tasks </strong> (such as administrative or management tasks). The list of these tasks is controlled by a CRUD that you will find in the main menu as system administrator. </li> </ol> In the first option, you will be able to choose a task from the selected project to record the actual hours (in HH: MM format) worked day by day on that task.<br> In the second option, you can enter an optional description to complement the information about the activity carried out. ",
    "help.misc.hours.text4": "You can see a summary of the hours recorded in the week or month in the <strong> \"Summary\" </strong> tab, where you can also export the time record to an Excel spreadsheet with several tabs (that contains the detail of recorded hours in time and decimal format), for further treatment and control.",
    "help.misc.workspace": "Workspaces",
    "help.misc.workspace.text": "Every time an administrator invites you, to become a member of a project, indirectly you would be joining their workspace. A workspace is a private, separate area from where accounts and projects can be created. The one who creates the workspace becomes the “owner” of it and is the one who can then invite other users to their space.<br> If you have more than one workspace, when you log in, it will ask you to choose which one you want to work with. You can change workspaces using the context menu by pressing on your user at any time you want.<br> You could use MyTaskPanel in the workspace that other users have invited you, but you have the option to create your own to manage your own projects.<br> To create it, you must select this option from the context menu of the logged in user and follow the instructions to complete the registration in your own workspace.<br> Each email account can have one and only its own workspace.",
    "help.steps": "First steps",
    "help.steps.invite": "Invite people to the team",
    "help.steps.invite.text1": "The next step, after creating an account, is to <strong>invite the people</strong> that will participate in one or more projects. There is more than one way to do this.",
    "help.steps.invite.text2": "The first one consists in editing the account (if it has a private access). Do the following to achieve this: <ol> <li> <strong>Edit the Account</strong> which you want to give access to. </li> <li> In the <strong>Members tab</strong>, you can see the invited users who have access, the guests who have not yet accepted your invitation and, </li> <li> by pressign the <strong>+ sign</strong> you can enter, separated by commas, the emails of all those users you wish to invite and the type of access you will grant them.</div> </li> </ol> If you want to invite your company users to join the MyTaskPanel team, but not to a particular account, you can do the following: <ol> <li> Go to the <strong>Users module</strong>. </li> <li> Press the button to <strong>Invite Users</strong>. </li> <li> A window will appear to enter all the emails, separated by commas, from the people you wish to invite, and the type of access you will grant them. </li> </ol>",
    "help.steps.next": "What to do next?",
    "help.steps.next.text": "<p>This will depend on the type of project you have chosen: <ul> <li>Kanban board</li> <li>Agile project</li> </ul> <strong><u>Kanban board</u></strong><br> It consists of a simple but complete list of each and every one of the tasks to be carried out in a specific project, which you can manage using a <strong>Kanban board</strong>. It is very useful when you are in a stage of maintenance, deployment or something punctual and limited in time. For further information, go to the <strong>“Kanban board” </strong>section in this tutorial.</p> <p> <strong><u>Agile project</u></strong><br> In the case of having the need of managing a project in stages (especially in agile methodologies such as SCRUM, although it could be adapted to other life cycles), this choice is appropriate. You can divide the project into iterations (also called cycles, phases or stages), where each iteration will contain a series of tasks (and subtasks) to perform. There is greater control of the project, although it requires more administration. For further information, go to the <strong>“Projects with iterations”</strong> section in this tutorial. </p>",
    "help.steps.project": "Create a project",
    "help.steps.project.text": "<p>Once you edit the account and invite the users who will have access to it, you’ll have to create a  <strong>Project</strong>. In order to do this, perform the following steps: <ol> <li> Click on the card to create a New Project. </li> <li> You must give the project a name, a description (optional). Most importantly: decide between creating a simple <strong>Kanban board</strong> to manage task cards based on the capacity of your team (very  useful when it comes to maintenance projects or very limited in scope and complexity ones) or a <strong>project with agile methodologies</strong> such as SCRUM (with iterations, functionalities and tasks). </li> <li> You will see the board with the classic columns of the <strong>view by status </strong>(to do, doing, done), although you’ll be able to choose the view By group or By user. </li> </ol> </p>",
    "help.steps.workspace": "Access the account",
    "help.steps.workspace.text": "An <strong>account</strong>, in MyTaskPanel, is an entity which function is to group all the projects  under that title or denomination, and invite users to work on that team.  A default account will be generated, which will be empty for you to start creating your first project. From the Accounts button on the left margin, you can access and edit that account. Change the name, put a description (optional) and decide if the access to the projects will be either public (all members of the organization can access) or private (only members you invite will be able to access).",
    "help.video.agile.subtext": "Use the agile methodology for project management in development.",
    "help.video.dashboard.subtext": "Use the Dashboard to review the status and evolution of your projects projects.",
    "help.video.first": "How to create your first project from scratch",
    "help.video.first.subtext": "Basic concepts to understand the functionality of MyTaskPanel",
    "help.video.inbox.subtext": "Use the inbox to get a quick overview of the status of your projects status of your projects",
    "help.video.tasks.subtext": "Discover how to edit the tasks of each project, on your board Kanban, to control its evolution.",
    "help.video.time.subtext": "How to keep track of the time spent on each project.",
    "help.video.users": "Invitation and user management",
    "help.video.users.subtext": "Learn how to assign users to your accounts. Know the types of users and the permissions of each one for the management of your projects.",
    "icon.add": "Add task",
    "icon.addsub": "Add subtask",
    "icon.eye_button_all": "Show/Hide completed tasks",
    "icon.movetask": "Move pending tasks",
    "icon.trash": "Delete task group",
    "inbox": "Inbox",
    "inbox.apply": "Apply",
    "inbox.chart.title": "Summary",
    "inbox.filters": "Filters",
    "inbox.notasks": "No tasks",
    "invitation.public": "Form to invite public users",
    "issue.question": "Have you had any issue?",
    "iteration": "Iteration",
    "label": "Label",
    "label.created": "Created label",
    "label.updated": "Updated label",
    "labels": "Labels",
    "language": "Language",
    "limit.accounts": "You have reached the maximum accounts limit for the selected plan",
    "limit.files": "You have reached the maximum storage capacity for the selected plan",
    "limit.projects": "You have reached the maximum projects limit for the selected plan",
    "limit.users": "You have reached the maximum users limit for the selected plan",
    "link.copy": "Copy link",
    "logout": "Log out",
    "main": "Main",
    "maintenance": "Maintenance",
    "members": "Members",
    "modify": "Modify",
    "more": "More",
    "move": "Move",
    "name": "Name",
    "next_check_short": "Next check",
    "observations": "Observations",
    "or": "Or",
    "order": "Order",
    "owner": "Product owner",
    "package.choose": "Package selected",
    "package.current": "Current package",
    "package.current.information": "Information of the current package",
    "package.expired.message": "Sorry your current plan has expired. Please contact your administrator to get a new plan.",
    "package.new.information": "Information of the new package",
    "payment.confirmation": "Payment confirmation",
    "payment.current.method": "Current payment method",
    "payment.method.choose": "Payment method selected",
    "payment.paypal.agreement": "Make your payment through the payment processor Paypal. All applicable taxes, levies, or duties will be displayed on the payment processor's checkout page. By going to the Paypal website, you agree that Paypal will save and store your account and payment information for future purchases, including processing any subsequent automatic subscription renewals that may occur after this order.",
    "payment_details.vat_number": "NIF / VAT Number",
    "person": "Person",
    "person.full_name": "Full name",
    "photo.error_type": "Wrong image type",
    "photo.file": "Select photo",
    "photo.file.size": "The maximum allowed size is 2mb",
    "planned": "Planned",
    "premium": "Premium",
    "price": "Price",
    "privacy": "Privacy",
    "privacy.private": "Private",
    "privacy.private_description": "Only authorized members can access",
    "privacy.public": "Public",
    "privacy.public_description": "All members of your organization can access",
    "productivity": "Productivity",
    "profile.edit": "Edit profile",
    "profile.updated": "Updated profile",
    "project.activate": "Activated project",
    "project.activate.question": "Do you want to activate the project?",
    "project.active.plural": "Active projects",
    "project.activity.all": "All projects/activities",
    "project.add.upgrade_plan": "Your current plan does not allow adding more projects. Please update your plan.",
    "project.agile": "With iterations or stages",
    "project.all": "All",
    "project.archive.question": "Do you want to archive the project?",
    "project.archived": "Archived project",
    "project.create": "Create project",
    "project.created": "The project was successfully created",
    "project.delete.question": "Do you want to delete the project?",
    "project.description": "The project will contain the tasks.",
    "project.error": "Error to create project",
    "project.name": "Project",
    "project.name.cannot.be.numeric": "The project name cannot be numeric.",
    "project.new": "New project",
    "project.plural": "Projects",
    "project.project.description": "Create an agile project using Scrum or other lifecycles for managing projects divided into iterations",
    "project.projects_archived": "Archived projects",
    "project.projects_completed": "Completed projects",
    "project.projects_ongoing": "Ongoing projects",
    "project.projects_overdue": "Overdue projects",
    "project.status_active": "Status of active projects",
    "project.summary": "Project summary",
    "project.tasklist.description": "Create a Kanban board to manage separate task cards based on your team's capacity",
    "project.tour.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\"> The Project will be the main way to organize and manage your work. The most important thing at this point will be to decide between creating a <strong> board Kanban </strong> to be done (very useful when it comes to maintenance projects or very limited in scope and complexity) or a <strong> project </ strong> with agile methodologies (that contains iterations, functionalities and tasks).</span>",
    "project.tour.title": "<span style=\"font-size:17px!important; font-family:roboto; color:#259dab; font-weight:800\">PROJECT</span>",
    "project.update": "Updated project",
    "project.user": "My projects",
    "properties": "Properties",
    "province": "Province",
    "remaining": "Remaining",
    "required.fields": "Required fields",
    "reset": "Reset",
    "reset.filters": "Reset filters",
    "rol": "Rol",
    "rol.created": "Created rol",
    "rol.updated": "Updated rol",
    "roles": "Roles",
    "save": "Save",
    "save.and_new": "Save and create another",
    "search": "Search...",
    "select.multiple": "Ctrl to select multiple items",
    "send": "Send",
    "settings": "Settings",
    "settings.notification_1": "When I am assigned a task",
    "settings.notification_2": "When someone commented on my homework",
    "settings.notification_3": "When certain relevant changes are made to a task that affect the user or followers",
    "settings.notification_4": "Daily email with tasks due and close to expiration",
    "settings.notifications": "Notifications",
    "settings.personal_data": "Personal data",
    "settings.receive_notifications": "Receive notifications",
    "signup.button.start": "START FOR FREE NOW",
    "signup.text.box_1": "It will give you <strong>full control</strong> of the project, as it will provide an overview and help you <strong>plan</strong> and <strong>monitor</strong> from the beginning to the end of the project.",
    "signup.text.box_2": "You will be able to <strong>analyze</strong> possible <strong>deviations</strong>in terms of budget, expenses, times, progress according to forecasts, etc.",
    "signup.text.box_3": "It will improve <strong>communication between team members</strong>, make it <strong>more fluid</strong>, simple and direct, because you will not have to use emails to report on the progress of the project.",
    "signup.text.box_4": "<strong>It will save you management time</strong>, having centralized information (tasks, comments and other data) available to any member of the project.",
    "signup.text.box_5": "You will control the <strong>profitability of the projects</strong>, by recording the time spent, having information about the workloads per professional and per project.",
    "signup.text.box_6": "You will improve the <strong>transparency of the project</strong>, sharing the status with the client: delivery dates, milestones, general information, files, etc. In this way, you will increase trust between the parties.",
    "signup.text.control": "With MyTaskPanel, stay in control of your projects, no matter where your team works",
    "signup.text.intro": "If you work daily managing more or less complex projects, a management tool will be almost essential, because:",
    "signup.text.need": "No, it won´t be necessary. If you have no experience using management tools, you can begin using MyTaskPanel without any hassle. For both a small work team and more complex organizations, MyTaskPanel allows you to create a project in three easy steps. Find out in the next video.",
    "signup.text.quote": "«What I highlight is how something so simple allows you to handle very complex projects, always in a very structured way for the organization of the company.»",
    "signup.text.tool": "A project management tool helps you to concentrate and organize the information of your projects, so that the whole team knows what the current status of each one is, how they are working and what are the responsibilities that each member has.",
    "signup.text.what": "MyTaskPanel is a simple, complete and practical project management tool, but without unnecessary functionalities. It helps you manage and control your projects with agile methodologies, whether they are developments with Scrum or similar cycles or maintenances with Kanban. By having the possibility of having more than one workspace and locating the projects in different accounts, you will have a well-organized management, displaying due dates, priorities, efforts, labels and those responsible for each task.",
    "signup.text.what_cando_1": "<li>To organize your projects in a fast, simple and intuitive way.</li> <li>To keep track of the time spent on each task of each project.</li>",
    "signup.text.what_cando_2": "<li>To know the responsibilities of your team.</li> <li>To determine the progress, the possible gaps and the current status of each project.</li>",
    "signup.text.what_cando_3": "With MyTaskPanel, you can manage different types of projects, namely: maintenance, technology development, marketing, design and more. <br>See how to create and carry out the project of the assembly of a web site.",
    "signup.title.manage": "Manage your projects in the <span class=\"accent\">easiest</span> way",
    "signup.title.need": "Do I need prior knowledge to start using it?",
    "signup.title.tool": "What is a project management tool?",
    "signup.title.what": "What is MyTaskPanel?",
    "signup.title.what_cando": "WHAT CAN I DO WITH MYTASKPANEL?",
    "signup.title.why": "Why should you use a project management tool?",
    "sprint.all.sprints": "All sprints",
    "sprint.cannot.be.deleted.text": "Please move the tasks before removing this sprint.",
    "sprint.cannot.be.deleted.title": "Couldn't remove this sprint",
    "sprint.choose.a.valid.sprint": "To move tasks to another sprint, please choose a valid sprint.",
    "sprint.choose.sprint": "Choose sprint...",
    "sprint.dates.iteration": "Iteration dates: ",
    "sprint.delete.confirmation": "Yes, delete it",
    "sprint.delete.question": "Are you sure you want to remove this sprint?",
    "sprint.delete.warning": "You won't be able to revert this!",
    "sprint.edit.sprint": "Edit sprint",
    "sprint.invalid.name": "Invalid name",
    "sprint.iteration.closed": "Closed",
    "sprint.move.pending.tasks": "Move pending tasks",
    "sprint.move.sprint": "Move sprint",
    "sprint.new.sprint": "New sprint",
    "sprint.no.sprint.has.been.chosen": "No sprint has been chosen.",
    "sprint.parent.sprint": "Parent sprint",
    "sprint.plural": "Sprints",
    "sprint.singular": "Sprint",
    "sprint.sprint.must.have.name": "Sprint must have a name.",
    "sprint.tasks.moved": "Moved tasks",
    "sprint.what.sprint.move.tasks.to": "What sprint do you want to move tasks to?",
    "start": "Start",
    "start.date": "Start date",
    "subtasks": "Subtasks",
    "summary": "Summary",
    "summary.results.project": "Summary of results by project",
    "suscription.plan": "Subscription plan",
    "table.updated": "Table updated",
    "table.updated.error": "Error updating table",
    "task.all": "All",
    "task.all.plural": "All tasks",
    "task.assigned": "Assigned tasks",
    "task.assigned.to_me": "Assigned to me",
    "task.assigned_to": "Assigned to",
    "task.assignment": "Task assignment",
    "task.by_group": "Tasks by group",
    "task.by_tag": "Tasks by tag",
    "task.close": "Finish task",
    "task.completed": "Completed tasks",
    "task.copy.clipboard": "Task url copied to clipboard",
    "task.create_task": "Create new task",
    "task.deadline": "Deadline",
    "task.delayed": "Delayed",
    "task.delete.question": "Do you want to delete the task?",
    "task.duplicate": "Duplicate task",
    "task.elapsed_time": "Elapsed time",
    "task.error": "Error to save the task",
    "task.estimate_effort": "Estimate effort",
    "task.estimated_time": "Estimated time",
    "task.expire_today": "Expire today",
    "task.expire_tomorrow": "Expire tomorrow",
    "task.expired": "Expired",
    "task.file.delete.question": "Do you want to delete this file?",
    "task.file.disk_space": "Disk space exceeded the allowed limit",
    "task.file.download_error": "Error to download file",
    "task.file.invalid_extension": "Invalid extension",
    "task.file.max_size_1": "The file { file_name } exceeded the { size } permitted.",
    "task.file.name": "File name",
    "task.file.show_error": "Error to view the file",
    "task.file.upload_by": "Upload by",
    "task.file.upload_file": "Upload files",
    "task.filter.according_to_user": "According to the user",
    "task.filter.assigned_to": "Assigned to",
    "task.filter.assigned_to_me": "Assigned to me",
    "task.filter.by_due_date": "By due date",
    "task.filter.by_labels": "By labels",
    "task.filter.by_type_task": "By type of task",
    "task.filter.followed_by_me": "Followed by me",
    "task.filter.labels_that_dont_have": "That they do not have",
    "task.filter.labels_that_have": "That they have",
    "task.filter.subtask": "Subtask",
    "task.filter.task": "Task",
    "task.filter.unassigned": "Unassigned",
    "task.finish": "Finished task",
    "task.followers": "Followers",
    "task.labels": "Labels",
    "task.labels.add": "Add",
    "task.localization": "Localization",
    "task.must.belong.sprint": "You are creating a task without assigning it to any sprint. By default, it will be created on the first one.",
    "task.name_error": "You must complete the name of the task",
    "task.next_to_expire": "Upcoming to expire (7 days)",
    "task.next_to_expire_min": "Upcoming to expire",
    "task.no_authorized": "You are not authorized to create tasks.",
    "task.number_open_tasks_per_user": "Number of open tasks per user",
    "task.open": "Open task",
    "task.parent_task": "Parent task",
    "task.planned": "Planned",
    "task.plural": "Tasks",
    "task.priority": "Priority",
    "task.real_time": "Real time",
    "task.remain_time": "Remain time",
    "task.reopen": "Reopen task",
    "task.responsible": "Responsible",
    "task.select.priority": "Select the priority",
    "task.select.responsible": "Select a responsible",
    "task.select.sprint.first": "Select a sprint first",
    "task.select.tags": "Select labels",
    "task.singular": "Task",
    "task.status": "Status",
    "task.summary_by_state": "Task summary by state",
    "task.tags": "Tags",
    "task.task_list": "Task list",
    "task.time_exceeded": "Time exceeded",
    "task.timeline.errorDates": "The project must contain the start date and the due date to be able to graph it",
    "task.tour.step1.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\"> The <strong> tasks </ strong> are those activities you must do <br> </span>",
    "task.tour.step1.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">TASK</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (1/8)</span></div>",
    "task.tour.step10.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">It allows you to set <strong> filters </strong> to only show the tasks, with the search criteria that you have indicated.</span>",
    "task.tour.step10.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Filters</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (04/12)</span></div>",
    "task.tour.step11.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">It allows you to see the tasks grouped by <strong> Group </strong>, <strong> Status </strong> or by <strong> User <strong> </span>",
    "task.tour.step11.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Views</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (09/12)</span></div>",
    "task.tour.step12.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">A summary with the main indicators of how the project is doing </span>",
    "task.tour.step12.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Information</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (05/12)</span></div>",
    "task.tour.step13.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">Refresh the page, to show the latest changes made </span>",
    "task.tour.step13.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Refresh</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (06/12)</span></div>",
    "task.tour.step14.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">It allows you to hide or show all the tasks that are already completed, to have a clearer view of which the pending tasks are </span>",
    "task.tour.step14.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Show/ hide completed tasks</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (07/12)</span></div>",
    "task.tour.step15.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">You can <strong> collapse </strong> part of the task information, so it takes up less space on your work board, and whenever you want you can expand them again</span>",
    "task.tour.step15.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Collapse/Expand</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (08/12)</span></div>",
    "task.tour.step16.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">You can easily see the due date of each task</span>",
    "task.tour.step16.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Calendar</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (10/12)</span></div>",
    "task.tour.step17.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">You can see the dedicated time planned for each activity, with its estimated due date</span>",
    "task.tour.step17.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Timeline</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (11/12)</span></div>",
    "task.tour.step18.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">Control panel with valuable information on the status of the project</span>",
    "task.tour.step18.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Dashboard</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (12/12)</span></div>",
    "task.tour.step19.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">Click on the \"+\" symbol to add a new task to your project</span>",
    "task.tour.step19.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Add task</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (01/12)</span></div>",
    "task.tour.step2.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">You can enter the detail in the main box and press Ctrl + Enter to confirm it. That is all. Then you will have the opportunity to edit the task and add all the information you consider useful.</span>",
    "task.tour.step2.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Name of the task</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (2/8)</span></div>",
    "task.tour.step3.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">Another option is to use the icons at the bottom of the box to indicate, before confirming the new task, one or more of the following data</span>",
    "task.tour.step3.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Task icons</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (3/8)</span></div>",
    "task.tour.step4.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">It will be the <strong> resource </ strong> to which the task is assigned.</span>",
    "task.tour.step4.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Assigned to</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (4/8)</span></div>",
    "task.tour.step5.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">You can assign the <strong> tags </ strong> that you consider appropriate to classify the activity.</span>",
    "task.tour.step5.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Tags</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (5/8)</span></div>",
    "task.tour.step6.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">Indicates in which <strong> date </ strong> that task has to be completed.</span>",
    "task.tour.step6.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Expiration date</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (6/8)</span></div>",
    "task.tour.step7.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">The <strong> priority </ strong> with which the task should be considered.</span>",
    "task.tour.step7.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Priority</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (7/8)</span></div>",
    "task.tour.step8.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">It will be the <strong> estimated time remaining </ strong> to end the task</span>",
    "task.tour.step8.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Remaining time</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (8/8)</span></div>",
    "task.tour.step9.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">Show or hide the iteration tree. It will allow you to create the iterations or stages that the project needs. <br> Remember that <strong> there is always an active sprint </strong> and all the tasks displayed or added will be linked to it.</span>",
    "task.tour.step9.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Sprints</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (02/12)</span></div>",
    "task.tour.step9b.description": "<span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; line-height:1.3!important\">Click the button to create, modify or delete a iteration.</span>",
    "task.tour.step9b.title": "<div style=\"display:flex; justify-content: space-between;\"><span style=\"font-size:17px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:700\">Create Sprints</span>  <span style=\"font-size:13px!important; font-family:segoe ui, Helvetica, sans-serif; color:#259dab; font-weight:400; font-style: italic;\">     (03/12)</span></div>",
    "task.unassigned": "Unassigned tasks",
    "task.update": "Updated task",
    "task.user": "My tasks",
    "task.view.by_group": "By group",
    "task.view.by_state": "By state",
    "task.view.by_user": "By user",
    "task.without_planning": "Without planning",
    "task_close": "Closed Tasks: ",
    "task_estimate_effort": "Total estimated time: ",
    "task_group": "Task group",
    "task_group.created": "Created task group",
    "task_group.delete.not_empty": "You must delete all tasks from the task group.",
    "task_group.delete.question": "Do you want to delete the task group?",
    "task_group.updated": "Updated task group",
    "task_groups": "Task groups",
    "task_open": "Open Tasks: ",
    "task_remain_time": "Time remaining: ",
    "task_sprint_days_to_close": "Days to close: ",
    "task_total": "Quantity of tasks: ",
    "tasklist": "Kanban board",
    "tasks_status": "Tasks status",
    "thanks": "Thanks",
    "timesheet": "Time sheet",
    "timesheet.delete.error": "An error occurred while deleting the time sheet",
    "timesheet.delete.question": "Do you want to delete the record?",
    "timesheet.error.project": "You must select a project",
    "timesheet.export.async.message": "The export was a success, in a moment you will receive an email",
    "timesheet.export.error": "Error to export data time sheet",
    "timesheet.export.project.all": "Export all projects",
    "timesheet.export.project.all.description": "It will send an email, attaching a zip file, which will contain the excel files with the registration of hours per project",
    "timesheet.export.user": "Exportr user",
    "timesheet.export.user.all": "Export all users",
    "timesheet.export.user.all.description": "It will send an email, attaching a zip file, which will contain the excel files with the registration of hours per user",
    "timesheet.filter.user.with_hours": "Users with hours",
    "timesheet.save": "The hours were saved correctly",
    "timesheet.table.column.project": "Account / Project - Activity",
    "timesheet.table.column.task": "Task detail",
    "timesheet.value.incorrect": "Value incorrect",
    "timezone": "Timezone",
    "today": "Today",
    "tomorrow": "Tomorrow",
    "total": "Total",
    "total.hours": "Total hours",
    "total_hours_by_project": "Hs. Total project: ",
    "tour.closeBtnText": "Close",
    "tour.doneBtnText": "Done",
    "tour.nextBtnText": "Next",
    "tour.prevBtnText": "Prev",
    "update": "Update",
    "upgrade": "Upgrade",
    "upgrade.accounts": "Accounts (workspaces)",
    "upgrade.advice": "<p class=\"mt-3\">Don't put limits on your projects.</p><ul><li>More workspaces</li> <li>Greater number of projects</li><li>Greater amount of users on your team</li> </ul><p>Choose the plan that best suits your needs</p>",
    "upgrade.classic_dashboard": "Classic dashboard",
    "upgrade.current": "Actual",
    "upgrade.err000": "The upgrade is not possible. Please, contact us to work it out.",
    "upgrade.err001": "The size of the package to acquire is the same as the current one, but the internal code differs",
    "upgrade.err002": "It's too soon to renew your subscription.",
    "upgrade.err003": "In case of package category upgrade, you should use the same payment way.",
    "upgrade.full_dashboard": "Full dashboard",
    "upgrade.includes_everything_in": "Includes everything in",
    "upgrade.of_internal_storage": "of internal storage",
    "upgrade.selected": "Selected",
    "upgrade.sla.description": "Available SLA that guarantees a 7x24 service and priority support in any eventuality.",
    "upgrade.step_1.title": "Choose a plan",
    "upgrade.step_2.title": "Billing cycle",
    "upgrade.step_3.title": "Information",
    "upgrade.step_4.title": "Confirmation",
    "upgrade.title": "Choose your upgrade plan",
    "upgrade.unlimited.accounts": "Unlimited accounts (workspaces)",
    "upgrade.unlimited.backlogs_tasks": "Unlimited backlogs and tasks",
    "upgrade.unlimited.cloud_storage": "Unlimited cloud storage",
    "upgrade.unlimited.projects": "Unlimited projects",
    "user": "User",
    "user.address": "Address",
    "user.confirm_password": "Confirm password",
    "user.country": "Country",
    "user.department": "Department",
    "user.favorite_project.add": "The project has been added to your favorites",
    "user.favorite_project.add.error": "An unexpected error occurred when you tried to add a project to your favorites",
    "user.favorite_project.remove": "The project has been removed from your favorites",
    "user.favorite_project.remove.error": "An unexpected error occurred when you tried to remove a project from your favorites",
    "user.first_name": "First name",
    "user.last_name": "Last name",
    "user.name": "Name",
    "user.password": "Password",
    "user.phone": "Phone",
    "user.photo": "Photo",
    "user.position": "Position",
    "user.type": "Type of user",
    "user.type.admin": "Admin",
    "user.type.admin_desc": "Full access to manage the account, create new tasks, assign them and edit those of all users.",
    "user.type.invited": "Invited",
    "user.type.invited_desc": "Restricted access to the system. You can view, edit, and create tasks, but you can't assign them.",
    "user.type.member": "Member",
    "user.type.member_desc": "Limited access to the system. You can create and edit tasks with certain limitations.",
    "user.type.owner": "Owner",
    "user.type.owner_desc": "Maximum level of access. You can make business decisions about the account.",
    "user.updated": "Updated user",
    "user.username": "Username",
    "username.exists": "The username already exists",
    "users": "Users",
    "users.all": "All",
    "users.all_2": "All users",
    "users.invite": "Invite users",
    "users.invite.existing.error": "The following users are already registered",
    "users.me": "Me",
    "users.pending": "Pending public users",
    "users.registered": "Registered users",
    "videos": "Tutorial Videos",
    "view.change": "Change view",
    "view.info": "Information",
    "welcome": "Welcome",
    "workspace": "Work space"
  };
  _exports.default = _default;
});