define("mytaskpanel/services/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    /**
     * @method checkTotalClients
     * @description Get total clients of user logged
     */
    async checkTotalClients() {
      let response;

      try {
        const email = this.get('session.data.authenticated.user').email;
        const res = await this.get('ajax').request('/check-clients/?email=' + email, {
          method: 'GET'
        });
        response = res.data;
      } catch (e) {
        response = {
          total: 0
        };
      }

      return response;
    },

    /**
     * @method getClients
     * @description Get the clients of user logged
     */
    async getClients() {
      let response;

      try {
        const email = this.get('session.data.authenticated.user').email;
        response = await this.get('ajax').request('/get-clients/?email=' + email, {
          method: 'GET'
        });
      } catch (e) {
        response = {
          data: {
            clients: []
          }
        };
      }

      return response;
    },

    /**
     * @method isPayingClient
     * @description Check if the client is paying client
     */
    async isPayingClient() {
      const response = await this.get('ajax').request('/is-paying-client/', {
        method: 'POST'
      });
      return response.data.is_paying_client;
    }

  });

  _exports.default = _default;
});