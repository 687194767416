define("mytaskpanel/routes/timesheet/summary", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    timesheetService: Ember.inject.service('timesheet'),

    async model() {
      let users = [];
      await this.timesheetService.getUsers().then(response => {
        users = response.data;
      });
      return Ember.RSVP.hash({
        users: users
      });
    }

  });

  _exports.default = _default;
});