define("mytaskpanel/components/modal-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),
    swal: Ember.inject.service('swal'),
    files: Ember.inject.service('files'),
    showLoading: false,

    didInsertElement() {
      this._super(); // Slimscroll for scroll


      Ember.$(".comments_box").slimScroll({
        height: 'calc((100vh) - (470px))',
        alwaysVisible: false,
        allowPageScroll: false,
        color: '#259dab',
        distance: '5px',
        disableFadeOut: true
      });
      const self = this;

      const FileButton = () => {
        const ui = Ember.$.summernote.ui; // create button

        const button = ui.button({
          contents: '<i class="fa fa-paperclip"/>',
          tooltip: 'file',
          click: function () {
            // append input element
            const inputEle = document.createElement('input');
            inputEle.setAttribute('type', 'file');
            inputEle.setAttribute('multiple', true);
            inputEle.click(); //Upload file

            inputEle.onchange = async evt => {
              const imgs = evt.currentTarget.files;

              if (imgs.length > 0) {
                self.set('showLoading', true);
                let formData = new window.FormData();
                let loadFileURL;

                if (self.get('project')) {
                  formData.append('project', self.get('project.id'));
                  loadFileURL = '/project-uploads/';
                }

                if (self.get('task')) {
                  formData.append('task', self.get('task.id'));
                  loadFileURL = '/task-uploads/';
                }

                await self.get('files').uploadFile(imgs, formData, true, loadFileURL);
                self.set('showLoading', false);
              }
            };
          }
        });
        return button.render(); // return button as jquery object
      }; // Set summernote to textarea


      Ember.$('#summernote').summernote({
        minHeight: 120,
        focus: true,
        disableDragAndDrop: true,
        toolbar: [['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['insert', ['link']], ['files', ['file']]],
        buttons: {
          file: FileButton
        },
        callbacks: {
          onEnter(evt) {
            // Ctrl + enter create comment
            if (evt.ctrlKey && evt.keyCode === 13) {
              self.send('createComment');
            }
          }

        }
      }); // NOTE: Not use arrow function

      Ember.$(document).on('click', '.popover-content span a', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        self.openLink(Ember.$(this).attr('href'));
      });
      Ember.$(document).on('click', '.inbox-item-text p a', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        self.openLink(Ember.$(this).attr('href'));
      });
    },

    openLink(link) {
      const urlAttr = new URL(link).hostname;
      const isForProjectComment = Ember.$(".typeComment").data('typeProject'); // Only file from s3

      if (urlAttr.includes('s3.amazonaws.com')) {
        // Display file in new window
        let getFileURL;

        if (isForProjectComment) {
          getFileURL = `/project-uploads/?file=${link}&show_file=true&search=true`;
        } else {
          getFileURL = `/task-uploads/?file=${link}&show_file=true&search=true`;
        }

        this.get('ajax').request(getFileURL, {
          method: 'GET'
        }).then(response => {
          window.open(response.data.url, '_blank');
        }).catch(() => {
          const toast = this.get('toast');
          toast.error(this.get('intl').t('task.file.show_error'));
        });
      } else {
        window.open(link, '_blank');
      }
    },

    actions: {
      /**
      * @method createComment
      * @description Create a new comment
      */
      async createComment() {
        const text = Ember.$('#summernote').summernote('code');
        const textWithoutHtml = text.replace(/<{1}[^<>]{1,}>{1}/g, " ").trim();
        const textRemoveSpace = textWithoutHtml.replace(/&nbsp;/g, '').trim();

        if (Ember.isPresent(textWithoutHtml.trim()) && textRemoveSpace.trim() !== '') {
          const user = await this.get('store').find('user', this.get('session.data.authenticated.user').id);
          let comment;

          if (this.get('task')) {
            comment = this.get('store').createRecord('task-comment', {
              comment: text,
              date: new Date(),
              type: 'comment',
              objectId: this.get('task.id'),
              contentType: 'sprints.task',
              user: user
            });
          }

          if (this.get('project')) {
            comment = this.get('store').createRecord('project-comment', {
              comment: text,
              date: new Date(),
              type: 'comment',
              objectId: this.get('project.id'),
              contentType: 'security.project',
              user: user
            });
          }

          try {
            await comment.save();
            this.get('comments').unshiftObject(comment); // Clear text

            Ember.$('#summernote').summernote('code', '');
            Ember.$('#summernote').summernote('unlink');
          } catch (error) {
            const errorMsg = this.get('intl').t('error.500.msg');
            this.get('swal').open({
              title: '¡Ups!',
              text: errorMsg,
              type: 'error'
            });
          }
        }
      },

      /**
      * @method deleteComment
      * @description Delete comment from task
      * @param {comment} object
      */
      async deleteComment(comment) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('comment.delete.question');
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          try {
            comment.deleteRecord();
            await comment.save();
            this.get('comments').removeObject(comment);
          } catch (err) {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          }
        }
      }

    }
  });

  _exports.default = _default;
});