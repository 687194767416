define("mytaskpanel/helpers/count-viewa-tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countViewaTasks = countViewaTasks;
  _exports.default = void 0;

  function countViewaTasks(params) {
    const idclass = Ember.$('#eye_button_' + params[1]).find('i').attr('class');
    let total;

    if (idclass === 'fa fa-eye') {
      total = params[0].filter(element => {
        return !element.hideflag;
      });
    } else {
      total = params[0].filter(element => {
        return element.hideflag === true;
      });
    }

    return total.length;
  }

  var _default = Ember.Helper.helper(countViewaTasks);

  _exports.default = _default;
});