define("mytaskpanel/routes/tasks/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    model() {
      const params = this.paramsFor('tasks');
      const userId = this.get('session.data.authenticated.user').id;
      return Ember.RSVP.hash({
        params: params,
        project: this.get('store').find('project', params.project_id),
        users: this.get('store').findAll('user'),
        accountUser: this.get('store').query('account-user', {
          account: params.account_id,
          user: userId
        }).then(records => {
          return records.get('firstObject');
        }),
        projectUser: this.get('store').query('project-user', {
          project: params.project_id,
          user: userId
        }).then(records => {
          return records.get('firstObject');
        }),
        labels: this.get('store').findAll('label'),
        groups: this.get('store').query('task-group', {
          'project_id': params.project_id,
          'order': 'name'
        }),
        sprints: this.get('store').query('sprint', {
          'project_id': params.project_id
        }),
        tasks: this.get('store').query('task', {
          'type_filter': 'alltasks_bygroup',
          'project_id': params.project_id
        }),
        dataGraphic: this.get('ajax').request('/task-dashboard/', {
          method: 'POST',
          data: {
            project_id: params.project_id
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          throw new Error(error);
        })
      });
    }

  });

  _exports.default = _default;
});