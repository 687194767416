define("mytaskpanel/components/inbox/inbox-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    routing: Ember.inject.service('-routing'),
    intl: Ember.inject.service('intl'),
    red: 'cdeleted',
    black: 'cwithoutplanned',
    grey: 'csuspended',
    yellow: 'cplanned',
    textRed: 'text-danger',
    textGrey: 'text-suspended',
    textYellow: 'text-warning',
    textBlack: 'text-without-planed',
    projectSelected: 'all',
    tasksSelected: 0,
    completedTasksLength: 0,
    expiredTasksLength: 0,
    plannedTasksLength: 0,
    withoutPlannedTasksLength: 0,
    totalTasks: 0,
    loadChart: 0,
    showLoading: false,
    taskGroups: null,
    projects: null,
    observerSearchFilterValue: Ember.observer('searchFilterValue', function () {
      const value = this.searchFilterValue.toLowerCase();

      if (value.trim() === '') {
        this.set('expiredTasks', this.expiredTasksOriginal);
        this.set('expiredTodayTasks', this.expiredTodayTasksOriginal);
        this.set('nextToExpiredTasks', this.nextToExpiredTasksOriginal);
        this.set('withoutPlannedTasks', this.withoutPlannedTasksOriginal);
      } else {
        // Tasks
        const tasks = this.tasks.filter(t => {
          const task = t.name.toLowerCase();
          return task.includes(value);
        });
        this.set('tasks', tasks);
      }
    }),

    didReceiveAttrs() {
      this._super();

      this.loadTasks();
    },

    async didInsertElement() {
      this._super();

      const userType = this.get('session.data.authenticated.user').user_type;
      this.set('userType', userType); // Load data graphic

      await this.loadDataGraphic();
    },

    /**
    * @method loadTasks
    * @description Load tasks in inbox
    */
    loadTasks() {
      this.set('expiredTasks', []);
      this.set('expiredTodayTasks', []);
      this.set('nextToExpiredTasks', []);
      this.set('withoutPlannedTasks', []);
      const tasks = this.get('tasks'); // Get expired tasks

      const expired = tasks.filter(t => {
        return t.state === 'E';
      });
      this.set('expiredTasks', expired);
      this.set('expiredTasksOriginal', expired); // Get expired today

      const today = tasks.filter(t => {
        return t.state === 'T';
      });
      this.set('expiredTodayTasks', today);
      this.set('expiredTodayTasksOriginal', today); // Get next to expired tasks

      const next = tasks.filter(t => {
        return t.state === 'N';
      });
      this.set('nextToExpiredTasks', next);
      this.set('nextToExpiredTasksOriginal', next); // Get without planned

      const rest = tasks.filter(t => {
        return t.state === 'R';
      });
      this.set('withoutPlannedTasks', rest);
      this.set('withoutPlannedTasksOriginal', rest);
    },

    /**
     * @method getDataGraphic
     * @description Get data for graphics
     */
    async getDataGraphic(params) {
      let queryString = '';

      if (params !== null) {
        queryString += '?' + params;
      }

      const response = await this.get('ajax').request('/task-inbox-graphic/' + queryString, {
        method: 'GET'
      });
      this.set('dataGraphic', response.data);
    },

    /**
     * @method loadDataGraphic
     * @description Load chart
     * @param {*} params
     * @param {*} reload
     */
    async loadDataGraphic(params = null, reload = true) {
      if (reload) {
        await this.getDataGraphic(params);
      }

      this.set('totalTasks', 0);
      const self = this; // Reset values

      this.set('expiredTasksLength', 0);
      this.set('withoutPlannedTasksLength', 0);
      this.set('plannedTasksLength', 0); // Load values

      this.get('dataGraphic').forEach(state => {
        self.set('totalTasks', this.get('totalTasks') + state.total_state);

        switch (state.state) {
          // Expired
          case 'E':
            self.set('expiredTasksLength', state.total_state);
            break;
          // Without planned

          case 'R':
            self.set('withoutPlannedTasksLength', state.total_state);
            break;
          // Planned

          case 'P':
            self.set('plannedTasksLength', state.total_state);
            break;
        }
      }); // For load chart in inbox-chart component

      this.set('loadChart', this.get('loadChart') + 1);
    },

    actions: {
      /**
      * @method applyFilter
      * @description Set the filter to the tasks
      */
      async applyFilter() {
        const selectProjectOption = Ember.$('#selectProject')[0].value;
        const selectTagOption = Ember.$('#selectTag').val(); // Filter labels

        if (selectTagOption !== 'all') {
          // Filter by labels expiredTasks
          const expiredTasks = this.get('expiredTasksOriginal').filter(t => {
            return t.labels.includes(selectTagOption);
          });
          this.set('expiredTasks', expiredTasks); // Filter by labels expiredTodayTasks

          const expiredTodayTasks = this.get('expiredTodayTasksOriginal').filter(t => {
            return t.labels.includes(selectTagOption);
          });
          this.set('expiredTodayTasks', expiredTodayTasks); // Filter by labels nextToExpiredTasks

          const nextToExpiredTasks = this.get('nextToExpiredTasksOriginal').filter(t => {
            return t.labels.includes(selectTagOption);
          });
          this.set('nextToExpiredTasks', nextToExpiredTasks); // Filter by labels withoutPlannedTasks

          const withoutPlannedTasks = this.get('withoutPlannedTasksOriginal').filter(t => {
            return t.labels.includes(selectTagOption);
          });
          this.set('withoutPlannedTasks', withoutPlannedTasks);
        } else {
          // Show all
          this.set('expiredTasks', this.get('expiredTasksOriginal'));
          this.set('expiredTodayTasks', this.get('expiredTodayTasksOriginal'));
          this.set('nextToExpiredTasks', this.get('nextToExpiredTasksOriginal'));
          this.set('withoutPlannedTasks', this.get('withoutPlannedTasksOriginal'));
        }

        this.set('projectSelected', selectProjectOption);
      },

      /**
      * @method updateTasksLength
      * @description Update the tasks length to show in char
      */
      updateTasksLength(color) {
        if (color === this.get('red')) {
          const atr = this.get('expiredTasksLength');
          this.set('expiredTasksLength', atr - 1);
        } else if (color === this.get('yellow')) {
          const atr = this.get('plannedTasksLength');
          this.set('plannedTasksLength', atr - 1);
        } else if (color === this.get('black')) {
          const atr = this.get('withoutPlannedTasksLength');
          this.set('withoutPlannedTasksLength', atr - 1);
        }

        const completed = this.get('completedTasksLength');
        this.set('completedTasksLength', completed + 1); // For load chart in inbox-chart component

        this.set('loadChart', this.get('loadChart') + 1);
      }

    }
  });

  _exports.default = _default;
});