define("mytaskpanel/components/tasks/tasks-list", ["exports", "mytaskpanel/libs/utils", "mytaskpanel/data/colors", "moment"], function (_exports, _utils, _colors, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    swal: Ember.inject.service('swal'),
    toast: Ember.inject.service('toast'),
    taskService: Ember.inject.service('task'),
    sprintService: Ember.inject.service('sprint'),
    labelService: Ember.inject.service('labels'),
    taskGroupService: Ember.inject.service('task-group'),
    userType: null,
    taskGroupsTasks: null,
    taskGroupsTasksOriginal: null,
    labels_selected_new: null,
    labelsSelectedName: '',
    task: null,
    colors: null,
    subTasks: null,
    taskLabels: null,
    userSelected: null,
    userSelectedName: '',
    priority: 0,
    remainTime: 0,
    valueDatePickerCreate: null,
    valueDatePickerCreateLocaleString: null,
    example: null,
    listEyesClass: null,
    observerSearchFilterValue: Ember.observer('searchFilterValue', function () {
      const value = this.searchFilterValue.toLowerCase();

      if (value.trim() === '') {
        this.set('taskGroupsTasks', this.taskGroupsTasksOriginal);
      } else {
        const tasks = this.tasks.filter(t => {
          const task = t.name.toLowerCase();
          return task.includes(value);
        });
        this.set('tasks', tasks);
      }
    }),
    observerViewTasksSelected: Ember.observer('viewTasksSelected', function () {
      this.loadTasks();
    }),

    didReceiveAttrs() {
      this._super(); // Load tasks


      this.loadTasks();
      this.setClassList();
      this.set('colors', _colors.default);
    },

    didInsertElement() {
      this._super(); // Data account for session menu


      this.get('session').set('session.content.authenticated.account_id', this.params.account_id);
      this.get('session').set('session.content.authenticated.account_slug', this.params.account_slug);
      this.get('session').set('session.content.authenticated.project_id', this.params.project_id);
      this.get('session').set('session.content.authenticated.project_slug', this.params.project_slug); // Check id add new label then add to list

      this.get('labelService').addLabelToObject('labels', this); // Set user type

      let userType = this.get('user.userType');
      const accountUser = this.get('accountUser');
      const projectUser = this.get('projectUser');

      if (projectUser) {
        userType = projectUser.userType;
      } else if (accountUser) {
        userType = accountUser.userType;
      }

      this.set('userType', userType); // Classes allowed for drag and drop

      this.selector = '.widget-task, .card-box.pointer, .taskitem'; // For drop event

      this.selectorDrop = '';
      this.selectorDrop += this.selector;
      this.selectorDrop += ',.task_name, .clearfix, .importance, .badgets.m-b-5, .checkbox.not-touch-drag-and-drop, .dateuser';
      this.selectorDrop += ',.badge.badge-warning.badgetag, .cards_area, .pointer, .badgetag, .remain-time-group, .texto'; // For task dropping to another sprint

      this.selectorSprintDrop = '.jstree-anchor, .jstree-node, .jstree-icon';
      this.selectorSprintDrop += ', jstree-themeicon, .jstree-themeicon-custom';
      this.selectorSprintDrop += ', jstree-ocl';
    },

    didRender() {
      Ember.$('.text-editable-input').focus(); // Load popovers

      this.loadPopovers();
    },

    setClassList() {
      this.set('listEyesClass', []);
      const listClass = [];
      const elements = Ember.$('.task_header > .task_plus > a:first-child > i');
      Array.prototype.forEach.call(elements, function (element) {
        listClass.push({
          class: element.className
        });
      });
      this.set('listEyesClass', listClass);
    },

    /**
     * @method loadPopovers
     * @description Load popovers
     */
    loadPopovers() {
      // Load popovers
      Ember.$('.po-markup > .po-link').popover({
        html: true,
        // must have if HTML is contained in popover
        // get the title and conent
        title: function () {
          return Ember.$(this).parent().find('.po-title').html();
        },
        content: function () {
          const clone = Ember.$(this).parent().find('.po-body').clone(true).removeClass('hide');
          return clone;
        }
      });
    },

    /**
     * @method loadTasks
     * @description Load tasks in groups
     */
    loadTasks() {
      const self = this; // Set array

      this.set('taskGroupsTasks', []);
      this.set('labels_selected_new', []); // Data api

      const tasks = this.get('tasks');
      const GroupObject = Ember.Object.extend({
        taskGroupId: null,
        groupName: null,
        tasks: Ember.A([]),
        color: null,
        totalClickedCompletedTask: 0
      });

      switch (this.get('viewTasksSelected')) {
        case 'group':
          {
            const taskGroups = this.get('taskGroups'); // Get groups

            taskGroups.map(g => {
              self.taskGroupsTasks.pushObject(GroupObject.create({
                taskGroupId: parseInt(g.id),
                groupName: g.name,
                color: g.color,
                tasks: Ember.A([]),
                totalClickedCompletedTask: 0
              }));
            });
            break;
          }

        case 'user':
          {
            const users = this.get('users'); // Get groups

            users.map(g => {
              self.taskGroupsTasks.pushObject(GroupObject.create({
                taskGroupId: parseInt(g.id),
                groupName: g.name,
                color: 'Red',
                tasks: Ember.A([]),
                totalClickedCompletedTask: 0
              }));
            }); // Add unassgined group

            this.taskGroupsTasks.pushObject(GroupObject.create({
              taskGroupId: 0,
              groupName: this.get('intl').t('task.filter.unassigned'),
              color: 'Red',
              tasks: Ember.A([]),
              totalClickedCompletedTask: 0
            }));
            break;
          }

        case 'state':
          {
            const states = this.get('taskStates').data;
            const colors = [{
              'color': 'Red'
            }, {
              'color': 'Yellow'
            }, {
              'color': 'Green'
            }]; // Get groups

            states.map((g, index) => {
              self.taskGroupsTasks.pushObject(GroupObject.create({
                taskGroupId: parseInt(g.id),
                groupName: g.name,
                color: colors[index]['color'],
                tasks: Ember.A([]),
                totalClickedCompletedTask: 0
              }));
            });
            break;
          }
      } // Loop tasks for get tasks by group


      tasks.map(task => {
        if (task.hideFlag) delete task['hideFlag'];
        let group;

        switch (self.get('viewTasksSelected')) {
          case 'group':
            {
              const taskGroupId = task.get('taskGroupId');
              group = self.taskGroupsTasks.filter(x => x.taskGroupId === taskGroupId);
              break;
            }

          case 'user':
            {
              const taskUserId = task.get('userId');

              if (taskUserId !== null && taskUserId !== undefined && taskUserId !== '') {
                group = self.taskGroupsTasks.filter(x => x.taskGroupId === taskUserId);
              } else {
                // Unassigned
                group = self.taskGroupsTasks.filter(x => x.taskGroupId === 0);
              }

              break;
            }

          case 'state':
            {
              const taskState = parseInt(task.get('taskState'));
              group = self.taskGroupsTasks.filter(x => x.taskGroupId === taskState);
              break;
            }
        }

        if (group.objectAt(0) !== undefined) {
          group.objectAt(0).tasks.pushObject(task);
        }
      });
      this.set('taskGroupsTasksOriginal', this.taskGroupsTasks);
    },

    /**
     * @method hideCompletedTasksInit
     * @description set task with hideflag
     */
    hideCompletedTasksInit(index) {
      const tasks = this.get('taskGroupsTasks')[index].tasks;
      this.set('example', this.taskGroupsTasks);
      const classEye = Ember.$(`#eye_button_${index}`).find('i');
      this.get('taskService').hideCompletedTasks(tasks, classEye);
    },

    dragEnter(event) {
      event.preventDefault();

      if (Ember.$(event.target).hasClass('jstree-leaf')) {
        Ember.$(event.target).addClass('jstree-leaf-hovered');
      }

      if (Ember.$(event.target).hasClass('cards_area')) {
        Ember.$(event.target).addClass('list-tasks-hovered');
      }
    },

    dragLeave(event) {
      event.preventDefault();

      if (Ember.$(event.target).hasClass('jstree-leaf')) {
        Ember.$(event.target).removeClass('jstree-leaf-hovered');
      }

      if (Ember.$(event.target).hasClass('cards_area')) {
        Ember.$(event.target).removeClass('list-tasks-hovered');
      }
    },

    // Event dragStart
    dragStart(event) {
      // event.preventDefault();
      Ember.$(".jstree-leaf").addClass('jstree-leaf-none-pointer');

      if (event.target.firstChild.matches(this.selector)) {
        // Event dragStart
        event.dataTransfer.setData('text', event.target.id);
        event.dataTransfer.dropEffect = 'copy';
      }
    },

    // Event dragOver
    dragOver(event) {
      event.preventDefault();

      if (event.target.matches(this.selector)) {
        // Allowed drop
        event.dataTransfer.dropEffect = 'move';
      }
    },

    // Event drop
    drop(event) {
      event.preventDefault();
      Ember.$(event.target).removeClass('list-tasks-hovered');
      Ember.$(event.target).removeClass('jstree-leaf-hovered');
      Ember.$(".jstree-leaf").removeClass('jstree-leaf-none-pointer');

      if (event.target.matches(this.selectorDrop)) {
        // Drop event
        const data = event.dataTransfer.getData('text');
        const taskId = data.split('_')[1].trim();
        const el = Ember.$(`#${data}`);
        const oldIndexGroup = el.first().parents('.task_list').data('index');
        let taskGroupId = null;
        let indexGroup = null; // Old tasks

        const taskGroupsTasks = this.get('taskGroupsTasks'); // Get the task that is moving

        let task = this.get('store').peekRecord('task', taskId);

        if (task === null) {
          task = taskGroupsTasks.objectAt(oldIndexGroup).tasks.filter(t => {
            return t.id === taskId;
          })[0];
        } // Get id of the after element


        let afterElementId;

        if (Ember.$(event.target).first().children('.taskitem').length > 0) {
          afterElementId = Ember.$(event.target).first().children('.taskitem').attr('id').split('_')[1];
        } else if (Ember.$(event.target).first().parents('.taskitem').length > 0) {
          afterElementId = Ember.$(event.target).first().parents('.taskitem').attr('id').split('_')[1];
        } else {
          // Not exists another element
          afterElementId = 0;
        } // Get group id to which it moved


        taskGroupId = Ember.$(event.target).first().parents('.task_list').data('id');
        indexGroup = Ember.$(event.target).first().parents('.task_list').data('index'); // Add task to the new list

        task.set('taskGroupId', taskGroupId); // Get index array of the after element

        let insertIndex = null;
        taskGroupsTasks.objectAt(indexGroup).tasks.forEach((t, index) => {
          if (t.id === afterElementId) {
            insertIndex = index;
          }
        }); // Remove object en old list

        let oldGroup = taskGroupsTasks.objectAt(oldIndexGroup);
        oldGroup.set('totalClickedCompletedTask', oldGroup.get('totalClickedCompletedTask') + 1);
        oldGroup.tasks.removeObject(task); // Check if sum or not (if is the same group not sum because maybe can disapear the record)

        if (oldIndexGroup === indexGroup) {
          insertIndex = insertIndex === null ? 0 : insertIndex;
        } else {
          insertIndex = insertIndex === null ? 0 : insertIndex + 1;
        } // If is other element that not is task_list go to before


        taskGroupsTasks.objectAt(indexGroup).tasks.insertAt(insertIndex, task); // Update and save new taskgroup

        this.get('store').find('task', taskId).then(task => {
          if (this.get('viewTasksSelected') === 'group') {
            this.get('store').find('task-group', taskGroupId).then(taskGroup => {
              task.set('taskGroup', taskGroup);
              task.save();
            });
          } else if (this.get('viewTasksSelected') === 'state') {
            task.set('taskState', indexGroup); // If is todo

            if (indexGroup === 0) {
              task.set('startDate', null);
            } else if (indexGroup === 1) {
              // Set current date if state is 'Doing'
              task.set('startDate', (0, _moment.default)().format('YYYY-MM-DD'));
            } else if (indexGroup === 2) {
              // If is done close the task
              this.get('taskService').done(task);
            } // If not is done


            if (indexGroup !== 2) {
              task.set('endDate', null);
              task.save();
            }
          } else if (this.get('viewTasksSelected') === 'user') {
            const user = this.get('store').peekRecord('user', taskGroupId);

            if (!user) {
              task.set('user', null);
            } else {
              task.set('user', user);
              task.set('userId', user.id);
              task.set('username', user.username);

              if (user.userPhoto) {
                task.set('userPhoto', user.userPhoto);
              }
            }

            task.save();
          }
        });
      } else if (event.target.matches(this.selectorSprintDrop)) {
        // Dropping task to another sprint
        // Getting target sprint id
        const sprintId = parseInt(event.target.closest('.jstree-node').id); // Checking if sprint exists

        if (!isNaN(sprintId)) {
          // Getting task id
          const data = event.dataTransfer.getData('text');
          const taskId = data.split('_')[1].trim(); // Getting current group index

          const el = Ember.$(`#${data}`);
          const oldIndexGroup = el.first().parents('.task_list').data('index'); // Getting column tasks

          const taskGroupsTasks = this.get('taskGroupsTasks'); // Getting task

          this.get('store').find('task', taskId).then(task => {
            // Checking if the target sprint is not the current sprint
            if (task.sprintId !== sprintId) {
              // Getting sprint
              this.get('store').find('sprint', sprintId).then(sprint => {
                // Setting new sprint
                task.set('sprintId', sprintId);
                task.set('sprint', sprint);
                task.save(); // Removing task from old tasks column

                let oldGroup = taskGroupsTasks.objectAt(oldIndexGroup);
                oldGroup.tasks.removeObject(task);
                oldGroup.set('totalClickedCompletedTask', oldGroup.get('totalClickedCompletedTask') + 1);
              });
            }
          });
        }
      }
    },

    /**
     * @method resetCreateTask
     * @description Reset form
     */
    resetCreateTask() {
      this.set('priority', 0);
      this.set('remainTime', 0);
      this.set('contentTask', '');
      this.set('dueDate', null);
      this.set('valueDatePickerCreateLocaleString', null);
      this.set('valueDatePickerCreate', null);
      this.set('labels_selected_new', []);
      this.set('labelsSelectedName', '');
      this.set('userSelected', null);
      this.set('userSelectedName', '');
    },

    actions: {
      /**
       * @method cancelPopover
       * @description Cacel popover
       */
      cancelPopover() {
        Ember.$('.po-markup > .po-link').popover('hide');
      },

      /**
       * @method setPriority
       * @description Set value to priority
       * @param {string} typeForm If show or hide priority popover
       */
      setPriority(typeForm) {
        if (typeForm === 'show') {
          Ember.$(".popover-body").find(".priority_input_new").val(this.get('priority'));
        } else {
          const priority = Ember.$(".popover-body").find(".priority_input_new").val();
          this.set('priority', priority);
          this.actions.cancelPopover();
        }
      },

      /**
       * @method setUser
       * @description Set value to user selected in new task
       * @param {string} typeForm If show or hide user popover
       */
      setUser(typeForm) {
        if (typeForm === 'show') {
          Ember.$(".popover-body").find(".user_responsable_new").val(this.get('userSelected'));
        } else {
          const user = Ember.$(".popover-body").find(".user_responsable_new").val();
          const userName = Ember.$(".popover-body").find(".user_responsable_new").find(":selected").text();
          this.set('userSelected', user);
          this.set('userSelectedName', userName);
          this.actions.cancelPopover();
        }
      },

      /**
       * @method assignedToMe
       * @description Assigned to me
       */
      assignedToMe() {
        const userId = this.get('session.data.authenticated.user').id;
        Ember.$(".user_responsable_new").val(userId);
        const userName = Ember.$("#user_responsable_new").find(":selected").text();
        this.set('userSelected', userId);
        this.set('userSelectedName', userName);
        this.actions.cancelPopover();
      },

      /**
       * @method cancelCreateTask
       * @description Reset form
       */
      cancelCreateTask() {
        this.resetCreateTask();
      },

      /**askaddTask
       * @description Add new tasks to the project
       */
      async addTask(taskGroupId) {
        const originalCurrentSprintId = this.get('parent.currentSprintId'); // If the project is agile, check if select the sprint

        let currentSprintId = this.get('sprintService').checkIfSelectSprint(this);
        const user_type = this.userType;

        if (user_type === 'A' || user_type === 'O' || user_type === 'I') {
          const contentTask = this.get('contentTask');

          if (Ember.isPresent(contentTask)) {
            const viewId = taskGroupId;
            const task = await this.get('taskService').createTask(viewId, this.get('project.id'), this.get('parent.viewTasksSelected'), this.get('userSelected'), this.get('labels_selected_new'), currentSprintId, contentTask, this.get('priority'), this.get('remainTime'), this.get('valueDatePickerCreate'), this.get('taskGroups')); // Add new task to group

            const group = this.get('taskGroupsTasks').filter(x => x.taskGroupId === viewId);
            group.objectAt(0).tasks.insertAt(0, task); // Reset form

            this.resetCreateTask(); // Add task to array main of tasks

            this.get('tasks').unshiftObject(task); // Update info data

            this.get('parent').send('loadDataProject'); // If was parent sprint, back to it

            if (isNaN(originalCurrentSprintId)) {
              this.set('parent.currentSprintId', originalCurrentSprintId);
            }

            Ember.run.later(() => {
              this.get('updateTasks')();
            }, 100);
          }
        } else {
          this.resetCreateTask();
          const message = this.get('intl').t('task.no_authorized');
          this.get('swal').open({
            title: 'No tiene permisos',
            text: message,
            type: 'warning'
          });
        }
      },

      /**
       * @method hideCompletedTasks
       * @description Hide completed tasks
       * @param index index column
       */
      hideCompletedTasks(index) {
        const tasks = this.get('taskGroupsTasks')[index].tasks;
        this.set('example', this.taskGroupsTasks);
        const classEye = Ember.$(`#eye_button_${index}`).find('i');
        this.get('taskService').hideCompletedTasks(tasks, classEye);
        this.setClassList();
      },

      /**
       * @method newTaskInGroup
       * @description Add new task in column by task group id
       * @param {*} taskGroupId Group id
       */
      newTaskInGroup(taskGroupId) {
        Ember.$('.task-new-item').hide();
        this.resetCreateTask();
        Ember.$(`#create-task-form-${taskGroupId}`).show();
      },

      /**
       * @method removeNewTask
       * @description Remove new task added
       * @param {*} taskGroupId Group id
       */
      removeNewTask(taskGroupId) {
        this.resetCreateTask();
        Ember.$(`#create-task-form-${taskGroupId}`).hide();
      },

      /**
       * @method createTaskGroup
       * @description create task group
       * @param {*} name Group name
       */
      async createTaskGroup(name) {
        if (name) {
          const lastGroupOrder = this.get('taskGroups.lastObject').groupOrder + 1; // Create the tasks

          await this.get('taskGroupService').createTaskGroup(name, this.params.project_id, this.get('colors'), lastGroupOrder);
          this.get('store').query('task-group', {
            'project_id': this.params.project_id,
            'order': 'group_order'
          }).then(groups => {
            this.set('taskGroups', groups);
          });
        }
      },

      /**
       * @method updateNameTaskGroup
       * @description update name task group
       * @param {int} groupId Group id
       * @param {string} name Group name
       */
      async updateNameTaskGroup(groupId, name) {
        if (name) {
          await this.get('taskGroupService').updateNameTaskGroup(groupId, name, this);
        }
      },

      /**
       * @method deleteTaskGroup
       * @description Delete task group selected
       * @param {*} taskGroup taksGroup to delete
       */
      async deleteTaskGroup(taskGroup) {
        await this.get('taskGroupService').deleteTaskGroup(taskGroup, this.get('taskGroupsTasks'), this.get('tasks'));
      },

      /**
       * @method rotateList
       * @description Rotate the list task group
       * @param {*} taskGroupId
       */
      rotateList(taskGroupId) {
        const element = Ember.$(`#rotate_${taskGroupId}`);
        element.closest('.task_header').toggleClass('active');
        element.closest('.all_tasks').toggleClass('stretch');
      },

      /**
       * @method setNewTaskDateDue
       * @description set date_due of new task
       */
      setNewTaskDateDue(value) {
        this.set('valueDatePickerCreate', value);

        if (value === null) {
          this.set('valueDatePickerCreateLocaleString', '');
        } else {
          this.set('valueDatePickerCreateLocaleString', (0, _utils.getLocaleDateString)(value));
        }
      }

    }
  });

  _exports.default = _default;
});