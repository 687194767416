define("mytaskpanel/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    userType: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean'),
    isSuperUser: _emberData.default.attr('boolean'),
    position: _emberData.default.attr('string'),
    department: _emberData.default.attr('string'),
    photo: _emberData.default.attr(),
    userPhoto: _emberData.default.attr('string'),
    timeZone: _emberData.default.attr('number'),
    emailAssignTask: _emberData.default.attr('boolean'),
    emailComments: _emberData.default.attr('boolean'),
    emailEditTask: _emberData.default.attr('boolean'),
    emailDaily: _emberData.default.attr('boolean'),
    rol: _emberData.default.belongsTo('rol'),
    accounts: _emberData.default.hasMany('account'),
    projects: _emberData.default.hasMany('project'),
    tasks: _emberData.default.hasMany('task'),
    taskComments: _emberData.default.hasMany('task-comment'),
    subTasks: _emberData.default.hasMany('sub-task'),
    followers: _emberData.default.hasMany('follower'),
    histories: _emberData.default.hasMany('history'),
    taskUploads: _emberData.default.hasMany('task-upload')
  });

  _exports.default = _default;
});