define("mytaskpanel/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service('auth'),
    clientService: Ember.inject.service('clients'),
    guideTour: Ember.inject.service('guide-tour'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    swal: Ember.inject.service('swal'),
    year: new Date().getFullYear(),
    timesheetService: Ember.inject.service('timesheet'),
    // TODO: Check if is production for add facebook js
    isProduction: window.location.origin === 'https://platform.mytaskpanel.com',
    changesRoutes: Ember.observer('currentPath', function () {
      if (this.get('session.isAuthenticated')) {
        // If has open model, then close
        if (Ember.$('#wrapper').hasClass('right-bar-enabled')) {
          Ember.$('#wrapper').toggleClass('right-bar-enabled');
        } // right side-bar toggle for tutorials


        Ember.$('#tutorial-list').on('click', function () {
          Ember.$('#help-wrapper').toggleClass('right-bar-enabled');
        });
      }
    }),

    init() {
      this._super();

      this.set('clientsWorkspace', []); // === following js will activate the menu in left side bar based on url ====

      Ember.$(document).ready(function () {
        !function ($) {
          var Sidemenu = function () {
            this.$body = $('body'), this.$menuItem = $('#sidebar-menu a');
          };

          Sidemenu.prototype.openLeftBar = function () {
            $('#wrapper').toggleClass('enlarged');
            $('#wrapper').addClass('forced');

            if ($('#wrapper').hasClass('enlarged') && $('body').hasClass('fixed-left')) {
              $('body').removeClass('fixed-left').addClass('fixed-left-void');
            } else if (!$('#wrapper').hasClass('enlarged') && $('body').hasClass('fixed-left-void')) {
              $('body').removeClass('fixed-left-void').addClass('fixed-left');
            }

            if ($('#wrapper').hasClass('enlarged')) {
              $('.left ul').removeAttr('style');
            } else {
              $('.subdrop').siblings('ul:first').show();
            }

            $('body').trigger('resize');
          }, // menu item click
          Sidemenu.prototype.menuItemClick = function () {
            if (!$('#wrapper').hasClass('enlarged')) {
              if (!$(this).hasClass('subdrop')) {
                // hide any open menus and remove all other classes
                $('ul', $(this).parents('ul:first')).slideUp(350);
                $('a', $(this).parents('ul:first')).removeClass('subdrop');
                $('#sidebar-menu .pull-right i').removeClass('md-remove').addClass('md-add'); // open our new menu and add the open class

                $(this).next('ul').slideDown(350);
                $(this).addClass('subdrop');
                $('.pull-right i', $(this).parents('.has_sub:last')).removeClass('md-add').addClass('md-remove');
                $('.pull-right i', $(this).siblings('ul')).removeClass('md-remove').addClass('md-add');
              } else if ($(this).hasClass('subdrop')) {
                $(this).removeClass('subdrop');
                $(this).next('ul').slideUp(350);
                $('.pull-right i', $(this).parent()).removeClass('md-remove').addClass('md-add');
              }
            }
          }, //init sidemenu
          Sidemenu.prototype.init = function () {
            var $this = this;
            var ua = navigator.userAgent,
                event = ua.match(/iP/i) ? 'touchstart' : 'click'; // bind on click

            $('.open-left').on(event, function (e) {
              e.stopPropagation();
              $this.openLeftBar();
            }); // LEFT SIDE MAIN NAVIGATION

            $this.$menuItem.on(event, $this.menuItemClick); // NAVIGATION HIGHLIGHT & OPEN PARENT

            $('#sidebar-menu ul li.has_sub a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
          }; // init Sidemenu

          $.Sidemenu = new Sidemenu(), $.Sidemenu.Constructor = Sidemenu; // Shared sidemenu for access in other observer currentPath

          window.$.Sidemenu = $.Sidemenu;
        }(Ember.$);
      });
    },

    actions: {
      /**
      * @method invalidateSession
      * @description Logout
      */
      invalidateSession() {
        this.get('auth').logout();
      },

      /**
       * @method questionForCreateNewClient
       * @description Make a question if the user would like create a new client
       */
      async questionForCreateNewClient() {
        const text = this.get('intl').t('create');
        const question = this.get('intl').t('client.question.create');
        const result = await this.get('swal').open({
          title: text,
          text: question,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          this.get('session').set('session.blockInvalidateUrl', 'isCreateNewClient');
          this.get('session').invalidate();
        }
      },

      /**
       * @method displayModalForChangeClient
       * @description Display modal for change the client
       */
      async displayModalForChangeClient() {
        this.set('showLoading', true);
        const response = await this.get('clientService').getClients();
        this.set('clientsWorkspace', response.data.clients);
        this.set('showLoading', false);
        Ember.$('#modal_workspace_all').modal('show');
      },

      /**
      * @method toogleSideMenu
      * @description Show o hide sidemenu
      */
      toogleSideMenu() {
        window.$.Sidemenu.init();
        window.$.Sidemenu.openLeftBar();
      },

      /**
      * @method clearSearch
      * @description Clear the Search
      */
      clearSearch() {
        this.set('searchFilterValue', '');
      },

      /**
      * @method help
      * @description Help site mytaskpanel or show tour
      */
      help() {
        const currentPath = this.get('currentPath');

        switch (currentPath) {
          case 'accounts.index':
            this.get('guideTour').showGuideTourAccount(true);
            break;

          case 'projects.index':
            this.get('guideTour').showGuideTourProject(true);
            break;

          case 'tasks.index':
            this.get('guideTour').showGuideTourTask(true);
            break;

          default:
            Ember.$('#help-wrapper').toggleClass('right-bar-enabled');
            break;
        }
      }

    }
  });

  _exports.default = _default;
});