define("mytaskpanel/components/inbox/inbox-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    modalFormTask: Ember.inject.service('modal-form-task'),
    sprintService: Ember.inject.service('sprint'),
    intl: Ember.inject.service('intl'),
    taskService: Ember.inject.service('task'),
    checkedTask: false,
    actions: {
      /**
      * @method doneTask
      * @description Done task
      */
      async doneTask() {
        this.set('checkedTask', true);
        this.$().fadeOut('normal', () => {
          this.get('taskService').done(this.get('task')).then(() => {
            if (this.get('optionItem') === 1) {
              this.get('parent.expiredTasks').removeObject(this.get('task'));
            }

            if (this.get('optionItem') === 2) {
              this.get('parent.expiredTodayTasks').removeObject(this.get('task'));
            }

            if (this.get('optionItem') === 3) {
              this.get('parent.nextToExpiredTasks').removeObject(this.get('task'));
            }

            if (this.get('optionItem') === 4) {
              this.get('parent.withoutPlannedTasks').removeObject(this.get('task'));
            } // Update the graphic


            Ember.run.later(() => {
              this.parent.loadDataGraphic();
            }, 1000);
            Ember.run.later(() => {
              this.get('parent').send('updateTasksLength', this.color);
            }, 100);
          });
        });
      },

      /**
      * @method showModalTask
      * @description Show modal for edit task
      */
      async showModalTask() {
        // Obtengo los taskgroups
        const projectId = await this.get('task.project.id');
        const projectSlug = await this.get('task.project.slug');
        const taskGroups = await this.get('store').query('task-group', {
          'project_id': projectId
        });
        const accountId = await this.get('task.project.account.id');
        const projects = await this.get('store').query('project', {
          'account': accountId
        });
        this.set('parent.project', this.get('task.project'));
        this.set('parent.task', this.get('task'));
        this.set('parent.taskGroups', taskGroups);
        this.set('parent.projects', projects);
        const {
          netSprints
        } = await this.get('sprintService').getSprintsOfProject(projectId, projectSlug, null);
        this.set('parent.sprints', netSprints); // Open the modal form

        this.get('modalFormTask').open();
      }

    }
  });

  _exports.default = _default;
});