define("mytaskpanel/models/task-upload", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    date: _emberData.default.attr(),
    username: _emberData.default.attr('string'),
    fileType: _emberData.default.attr('string'),
    fileSize: _emberData.default.attr('string'),
    attachment: _emberData.default.attr('string'),
    fileOwner: _emberData.default.belongsTo('user'),
    objectId: _emberData.default.attr('number'),
    contentType: _emberData.default.attr('string')
  });

  _exports.default = _default;
});