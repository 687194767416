define("mytaskpanel/routes/accounts/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "mytaskpanel/mixins/only-owner"], function (_exports, _authenticatedRouteMixin, _onlyOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _onlyOwner.default, {
    model() {
      const params = this.modelFor('accounts');
      return params.params;
    }

  });

  _exports.default = _default;
});