define("mytaskpanel/components/auth/form-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    router: Ember.inject.service('-routing'),
    photoProfile: Ember.inject.service('photo-profile'),
    languageIntl: Ember.inject.service('language-intl'),
    language: '',
    country: '',
    company_name: '',
    company_size: '',
    error_password: '',
    photo: '/assets/images/profile.png',
    photo_file: null,
    showLoading: false,
    existsUser: false,
    // Check if password match
    checkPassword: Ember.observer('password', 'confirm_password', function () {
      if (Ember.isPresent(this.password) && Ember.isPresent(this.confirm_password)) {
        if (this.password != this.confirm_password) {
          this.set('error_password', this.get('intl').t('auth.error_password'));
        } else {
          this.set('error_password', '');
        }
      }
    }),
    // Check if enable button create
    disabledCreate: Ember.computed('password', 'confirm_password', {
      get() {
        // Validations for clients
        if (Ember.isPresent(this.password) && Ember.isPresent(this.confirm_password)) {
          if (this.password != this.confirm_password) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }

    }),

    didInsertElement() {
      this._super(); // If exists this value is that user exists


      this.set('username', this.get('model.username'));
      this.set('first_name', this.get('model.first_name'));
      this.set('last_name', this.get('model.last_name')); // Get language from browser

      const lang = this.get('languageIntl').getLanguageBrowser();
      this.set('language', lang);

      if (this.get('model.username') !== null && this.get('model.username') !== '') {
        this.set('existsUser', true); // For enable button create, in the backend is ignored

        this.set('password', 'exists');
        this.set('confirm_password', 'exists');
      } // Disabled copy and paste input password


      Ember.$('input[type="password"]').bind('copy paste', e => {
        Ember.run.bind(this, e.preventDefault());
      }); // Close session if opened

      this.get('session').set('session.blockInvalidateUrl', 'onboarding');
      this.get('session').invalidate();
    },

    actions: {
      /**
      * @method setLanguage
      * @description Set lenguage selected
      * @param {*} lang
      */
      setLenguage(lang) {
        this.set('language', lang);
      },

      /**
      * @method setCountry
      * @description Set country selected
      * @param {*} country
      */
      setCountry(country) {
        this.set('country', country);
      },

      /**
      * @method setCompanySize
      * @description Set company size selected
      * @param {*} company_size
      */
      setCompanySize(company_size) {
        this.set('company_size', company_size);
      },

      /**
      * @method setPhoto
      * @description Set photo profile dynamic
      */
      setPhoto() {
        const file = Ember.$('#photo_id')[0].files[0];
        this.get('photoProfile').setPhoto(file, this);
      },

      /**
      * @method createClientOrMember
      * @description Method complete the process for create account client or member
      */
      createClientOrMember() {
        this.set('errorMessage', '');
        this.set('disabledCreate', true);
        this.set('showLoading', true);
        const email = this.get('model.email');
        const typeInvitation = this.get('model.type_invitation');
        const password = this.get('password'); // userType use in typeInvitation invitation

        const userType = this.get('model.user_type');
        let data;

        if (typeInvitation === 'client') {
          // Form inputs for clients
          data = {
            company_name: this.get('company_name'),
            company_size: parseInt(this.get('company_size')),
            position: '',
            department: '',
            first_name: this.get('first_name'),
            last_name: this.get('last_name'),
            country: this.get('country'),
            language: this.get('language'),
            email: email,
            password: password,
            username: this.get('username'),
            phone: '',
            address: '',
            photo: this.get('photo_file'),
            type_invitation: typeInvitation,
            package: this.get('model.package')
          };
        } else {
          // For inputs for invitations
          data = {
            position: '',
            department: '',
            first_name: this.get('first_name'),
            last_name: this.get('last_name'),
            email: email,
            password: password,
            username: this.get('username'),
            photo: this.get('photo_file'),
            type_invitation: typeInvitation,
            client: this.get('model.client_id'),
            account: this.get('model.account_id'),
            user_type: userType
          };
        } // Create form data


        var formData = new FormData();
        Object.keys(data).forEach(key => {
          formData.append(key, data[key]);
        });
        this.get('ajax').request('/onboarding/', {
          method: 'POST',
          processData: false,
          contentType: false,
          data: formData
        }).then(response => {
          if (response.data.success) {
            this.set('showLoading', false);

            if (this.get('existsUser')) {
              // Go to login if exists the user
              const router = this.get('router');
              router.router.transitionTo('login');
            } else {
              this.get('session').authenticate('authenticator:jwt', email, password).catch(reason => {
                this.set('errorMessage', reason.responseJSON.non_field_errors);
              });
            }
          } else {
            this.set('errorMessage', this.get('intl').t('auth.onboarding.error'));
            this.set('showLoading', false);
            this.set('disabledCreate', false);
          }
        }).catch(() => {
          this.set('errorMessage', this.get('intl').t('auth.onboarding.error'));
          this.set('showLoading', false);
          this.set('disabledCreate', false);
        });
      }

    }
  });

  _exports.default = _default;
});