define("mytaskpanel/components/timesheet/utils", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDateParams = getDateParams;

  /**
  * @method getDateParams
  * @description Get date params for ajax call
  */
  function getDateParams(typeDateSelected, date) {
    let dateFrom = '';
    let dateTo = '';

    if (typeDateSelected === 'month') {
      dateFrom = (0, _moment.default)(date)._i;
    } else {
      dateFrom = (0, _moment.default)(date).startOf('week').format('YYYY-MM-DD');
      dateTo = (0, _moment.default)(date).endOf('week').format('YYYY-MM-DD');
    }

    return {
      dateFrom,
      dateTo
    };
  }
});