define("mytaskpanel/components/accounts/accounts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    guideTour: Ember.inject.service('guide-tour'),
    // For show the account
    userType: null,
    account: null,
    title: null,
    // For search accounts
    accountList: null,
    accountListOriginal: null,
    // For create account
    accountCreated: null,
    isCreatingAccount: true,
    typeAccount: '',
    // Utils
    showLoading: false,
    observerSearchFilterValue: Ember.observer('searchFilterValue', function () {
      const value = this.searchFilterValue.toLowerCase();

      if (value.trim() === '') {
        this.set('accountList', this.accountListOriginal);
      } else {
        const accounts = this.accountList.filter(ac => {
          const account = ac.name.toLowerCase();
          return account.includes(value);
        });
        this.set('accounts', accounts);
      }
    }),

    didReceiveAttrs() {
      this._super();

      const self = this; // Set array

      this.set('accountList', []);
      const accounts = this.get('accounts');
      accounts.map(account => {
        self.accountList.pushObject(account);
      }); // For filter

      this.set('accountListOriginal', this.accountList);
    },

    didInsertElement() {
      this._super();

      const userType = this.get('user.userType');
      this.set('userType', userType); // Guide tour for account

      this.get('guideTour').showGuideTourAccount();
    },

    actions: {
      /**
      * @method setColorAccount
      * @description Set color to account
      * @param {object} account account to set color
      */
      setColorAccount(account) {
        const color = Ember.$('#color_account_' + account.id).val();
        account.set('color', color);
        account.save();
      },

      /**
       * @method showModalAdd
       * @description Display the modal add
       */
      async showModalAdd() {
        // Check if the user can add more accounts and that the plan is not limiting it
        const response = await this.get('ajax').request('/check-plan-permission-client/', {
          method: 'POST',
          data: {
            'action': 'account'
          }
        });

        if (response.data.success) {
          Ember.$('#modal_create_account').modal();
          this.set('isCreatingAccount', true);
          Ember.run.later(() => {
            Ember.$('#name_account_add').focus();
          }, 1000);
        } else {
          Ember.$("#modal-upgrade-plan").modal();
        }
      },

      /**
      * @method showModalAccount
      * @description Display modal right for edit o see data account
      * @param {*} account
      */
      showModalAccount(account) {
        this.set('account', account);
        this.set('title', this.get('account.name'));
        const self = this;
        Ember.$('#account-name').editable({
          validate: function (value) {
            if (Ember.$.trim(value) == '') return 'This field is required';
            self.set('account.name', value);
            self.account.save();
          },
          mode: 'inline',
          showbuttons: false,
          inputclass: 'form-control-sm'
        });
        Ember.$('#account-name').editable('setValue', this.get('account.name'));
        Ember.$('#wrapper').toggleClass('right-bar-enabled');
      }

    }
  });

  _exports.default = _default;
});