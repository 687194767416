define("mytaskpanel/components/task-groups/form-task-groups", ["exports", "mytaskpanel/data/colors"], function (_exports, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    toast: Ember.inject.service('toast'),
    swal: Ember.inject.service('swal'),
    action: '',
    projectId: '',
    colors: null,
    observerSearchFilterValue: Ember.observer('searchFilterValue', function () {
      let groups = [];
      const value = this.searchFilterValue.toLowerCase();

      if (value.trim() === '') {
        groups = this.get('store').peekAll('task-group');
      } else {
        groups = this.get('store').peekAll('task-group').filter(g => {
          const group = g.get('name').toLowerCase();
          return group.includes(value);
        });
      }

      this.set('groups', groups);
    }),

    async didInsertElement() {
      this._super();

      this.set('colors', _colors.default);
      const projectId = this.get('projects.firstObject').id;
      const groups = await this.get('store').query('task-group', {
        'project_id': projectId,
        'order': 'group_order'
      });
      this.set('projectId', projectId);
      this.set('groups', groups);
    },

    actions: {
      /**
      * @method showForm
      * @description form group
      * @param {*} action
      * @param {*} id Identification group
      */
      async showForm(action, id) {
        this.set('action', action); // Action edit or create

        if (action === 'create') {
          const project = await this.get('store').find('project', this.get('projectId'));
          const group = this.get('store').createRecord('task-group', {
            name: '',
            color: '',
            groupOrder: '',
            project: project
          });
          this.set('group', group);
        } else {
          // Get user
          const group = await this.get('store').find('task-group', id); // Set user

          this.set('group', group);
        } // Show modal


        Ember.$('#modal_edit_group').modal('show');
      },

      /**
      * @method deleteGroup
      * @description Delete group
      * @param {*} id Identification group
      */
      async deleteGroup(id) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('delete.question');
        const groupId = id;
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          // Delete record
          const group = await this.get('store').findRecord('task-group', groupId);
          group.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error',
              allowOutsideClick: false
            }).then(() => {
              window.location.reload();
            });
          });
        }
      },

      /**
      * @method createGroup
      * @description create group action
      */
      async createGroup() {
        if (Ember.isPresent(this.group.name) && Ember.isPresent(this.group.color) && Ember.isPresent(this.group.groupOrder)) {
          this.group.save().then(() => {
            this.get('toast').success(this.get('intl').t('task_group.created'));
            this.get('store').query('task-group', {
              'project_id': this.get('projectId'),
              'order': 'group_order'
            }).then(groups => {
              this.set('groups', groups);
            });
          }).catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          });
          Ember.$('#modal_edit_group').modal('hide');
        }
      },

      /**
      * @method editGroup
      * @description Edit group action
      */
      async editGroup() {
        if (Ember.isPresent(this.group.name) && Ember.isPresent(this.group.color) && Ember.isPresent(this.group.groupOrder)) {
          this.group.save().then(() => {
            this.get('toast').success(this.get('intl').t('task_group.updated'));
          }).catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          });
          Ember.$('#modal_edit_group').modal('hide');
        }
      },

      /**
      * @method selectColor
      * @description Select color
      * @param {*} v Value select
      */
      selectColor(v) {
        this.group.set('color', v);
      },

      /**
      * @method selectProject
      * @description Select project
      * @param {*} projectId Value select
      */
      async selectProject(v) {
        this.set('projectId', v);
        const groups = await this.get('store').query('task-group', {
          'project_id': v,
          'order': 'group_order'
        });
        this.set('groups', groups);
      }

    }
  });

  _exports.default = _default;
});