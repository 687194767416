define("mytaskpanel/routes/password-reset-form", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),

    async beforeModel(transition) {
      const params = transition.params['password-reset-form'];
      const response = await this.get('ajax').request('/password-reset-form/', {
        method: 'GET',
        data: {
          uidb64: params.uidb64,
          token: params.token,
          token_email: params.token_email
        }
      }).catch(() => {
        window.location = '/';
      });

      if (!response.data.success) {
        window.location = '/';
      }
    },

    model(params) {
      return params;
    }

  });

  _exports.default = _default;
});