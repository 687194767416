define("mytaskpanel/templates/price/thanks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6iRUpAjf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"jumbotron text-center h-100\"],[11,\"style\",\"background-color: #edf4f9;\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"display-3\"],[9],[0,\"¡\"],[1,[27,\"t\",[\"thanks\"],null],false],[0,\"!\"],[10],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"mt-5\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"issue.question\"],null],false],[0,\" \"],[7,\"a\"],[11,\"href\",\"https://agile.mytaskpanel.com/#contacto\"],[11,\"target\",\"_blank\"],[9],[1,[27,\"t\",[\"contact.us\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"lead mt-5\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"accounts.index\"],[[\"class\"],[\"btn btn-primary btn-custom\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"go.home\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/price/thanks.hbs"
    }
  });

  _exports.default = _default;
});