define("mytaskpanel/routes/price/thanks", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),
    priceService: Ember.inject.service('price'),

    async beforeModel() {
      try {
        const params = window.location.search;
        const response = await this.get('ajax').request('/get-order-checkout/' + params, {
          method: 'GET'
        }); // Update storage

        this.get('priceService').updateClientStorage(response.data.client); // Hide info

        Ember.$('#info-expired-client').hide();
      } catch (e) {
        this.transitionTo('price');
      }
    }

  });

  _exports.default = _default;
});