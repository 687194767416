define("mytaskpanel/templates/components/utils/filter-month-week", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xypjzfG/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[27,\"input\",null,[[\"type\",\"data-toggle\",\"data-width\",\"checked\",\"id\"],[\"checkbox\",\"switchbutton\",\"150\",[23,[\"filterHoursByWeek\"]],\"switch-type-date-hours\"]]],false],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/utils/filter-month-week.hbs"
    }
  });

  _exports.default = _default;
});