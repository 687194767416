define("mytaskpanel/router", ["exports", "mytaskpanel/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('signup');
    this.route('onboarding');
    this.route('reset-password');
    this.route('password-reset-form', {
      'path': '/password-reset-form/:uidb64/:token/:token_email'
    });
    this.route('index', {
      path: '/'
    }, () => {
      this.route('accounts', function () {
        this.route('archived');
        this.route('dashboard');
      });
      this.route('clients');
      this.route('users');
      this.route('roles');
      this.route('labels');
      this.route('task-groups');
      this.route('projects', {
        'path': '/projects/:account_id/:slug'
      }, function () {
        this.route('archived');
      });
      this.route('tasks', {
        'path': '/tasks/:project_id/:project_slug/:account_id/:account_slug'
      }, function () {
        this.route('dashboard');
        this.route('calendar');
        this.route('timeline');
      });
      this.route('settings');
      this.route('inbox');
    });
    this.route('price', function () {
      this.route('thanks');
    });
    this.route('timesheet', function () {
      this.route('summary');
    });
    this.route('generic-tasks');
    this.route('favorite-projects');
  });
  var _default = Router;
  _exports.default = _default;
});