define("mytaskpanel/services/files", ["exports", "mytaskpanel/libs/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),

    /**
     * @method uploadFileTask
     * @description Upload file in a task or project
     * @param {*} imgs
     * @param {*} formData
     * @param {*} withSummernote
     * @param {*} loadFileURL
     */
    async uploadFile(imgs, formData, withSummernote, loadFileURL) {
      const toast = this.get('toast');
      const totalImages = imgs.length;

      for (let i = 0; i < totalImages; i++) {
        const filename = imgs[i].name;
        const extension = filename.split('.').pop(); // Check extension is valid

        if (!(0, _utils.validExtensionFile)(extension)) {
          toast.warning(this.get('intl').t('task.file.invalid_extension'));
          return false;
        }

        let maxSizeFile = 0;
        let maxSpaceInDisk = 0;
        let totalSizeDiskUsed = 0;
        await this.get('ajax').request('/check-plan-permission-client/', {
          method: 'POST',
          data: {
            'action': 'file'
          }
        }).then(response => {
          maxSizeFile = response.data.max_file_size;
          maxSpaceInDisk = response.data.disk_space;
          totalSizeDiskUsed = response.data.total_size_disk_used;
        }).catch(() => {
          self.set('maxSizeFile', 0);
          self.set('maxSpaceInDisk', 0);
          self.set('totalSizeDiskUsed', 0);
        }); // Convert to gb

        totalSizeDiskUsed = totalSizeDiskUsed / 1024 / 1024 / 1024; // Check if the client has space in disk

        if (totalSizeDiskUsed >= maxSpaceInDisk) {
          toast.warning(this.get('intl').t('task.file.disk_space'));
          return false;
        } // Check size file


        const size = imgs[i].size; // Convert mg to bytes

        maxSizeFile = maxSizeFile * 1000000;

        if (size > maxSizeFile) {
          toast.warning(this.get('intl').t('task.file.max_size_1', {
            'file_name': imgs[i].name,
            'size': maxSizeFile + "mg"
          }));
        } else {
          formData.append('upload_img_' + i, imgs[i]);
        }
      }

      try {
        // Add file to s3
        const response = await this.get('ajax').request(loadFileURL, {
          method: 'POST',
          processData: false,
          contentType: false,
          data: formData
        });
        const files = response.data.files;
        files.forEach(file => {
          if (withSummernote) {
            Ember.$('#summernote').summernote('insertText', '');
            Ember.run.later(() => {
              if (window.getSelection) {
                if (window.getSelection().empty) {
                  // Chrome
                  window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) {
                  // Firefox
                  window.getSelection().removeAllRanges();
                }
              } else if (document.selection) {
                // IE?
                document.selection.empty();
              }

              Ember.$('#summernote').summernote('createLink', {
                text: file.name,
                url: file.url,
                isNewWindow: true
              });
            }, 1000);
            Ember.$('#summernote').summernote('focus');
          }
        }); // self.set('parent.addFilesInComment', self.get('parent.addFilesInComment') + 1);
      } catch (e) {// Hide loading
        // self.set('parent.parent.showLoading', false);
      }
    },

    async getFiles(contentType, contentObject) {
      const filesList = [];
      let files = [];

      if (contentType == 'project') {
        files = await this.get('store').query('project-upload', {
          'project': contentObject.id
        });
      }

      if (contentType == 'task') {
        files = await this.get('store').query('task-upload', {
          'task': contentObject.id
        });
      }

      files.map(file => {
        filesList.pushObject(file);
      });
      return filesList;
    }

  });

  _exports.default = _default;
});