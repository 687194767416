define("mytaskpanel/models/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inflector = _emberInflector.default.inflector;
  inflector.irregular('rol', 'roles');
  inflector.irregular('task-label', 'taskLabels');
  inflector.irregular('task-group', 'taskGroups');
  inflector.irregular('task-comment', 'taskComments');
  inflector.irregular('task-upload', 'taskUploads');
  inflector.irregular('sub-task', 'subTasks');
  inflector.irregular('account-user', 'accountusers');
  inflector.irregular('generic-task', 'genericTasks');
  inflector.irregular('project-user', 'projectusers');
  inflector.irregular('project-comment', 'projectComments');
  inflector.irregular('project-upload', 'projectUploads');
  inflector.irregular('history', 'histories');
  var _default = {};
  _exports.default = _default;
});