define("mytaskpanel/services/task", ["exports", "moment", "mytaskpanel/libs/utils"], function (_exports, _moment, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service('intl'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    toast: Ember.inject.service('toast'),
    labelService: Ember.inject.service('labels'),

    /**
     * @method done
     * @description Done task
     * @param {object} task Task to update
     * @param {boolean} hideFlag If hide te task to done
     */
    async done(task, hideFlag = false) {
      task.set('endDate', window.moment().format('YYYY-MM-DD'));
      task.set('taskState', '2');
      task.set('hideFlag', hideFlag);
      task.save();
      const toast = this.get('toast');
      toast.success(this.get('intl').t('task.finish'));
    },

    /**
     * @method unDone
     * @description un Done task
     * @param {object} task Task to update
     */
    async unDone(task) {
      task.set('endDate', null);
      task.set('taskState', '0');
      task.save();
    },

    /**
     * @method addUserFollowerTask
     * @description Add user like default follower in task
     * @param {*} task
     * @param {*} userId
     */
    async addUserFollowerTask(task, userId) {
      // Add user task like default follower
      const user = await this.get('store').find('user', userId);
      const follower = this.get('store').createRecord('follower', {
        task: task,
        user: user
      }); // Save follower

      await follower.save();
    },

    /**
     * @method addUserLoggedFollowerTask
     * @description Add user logged like default follower in task
     * @param {*} task
     */
    async addUserLoggedFollowerTask(task) {
      // Add user task like default follower
      const userLoggedId = this.get('session.data.authenticated.user').id;
      this.addUserFollowerTask(task, userLoggedId);
    },

    /**
     * @method changeEye
     * @description Change the icon eye for hide or show completed tasks
     * @param {*} isShow
     * @param classEye object jquery
     */
    changeEye(isShow, classEye) {
      let icon = 'fa fa-eye';

      if (!isShow) {
        icon = 'fa fa-eye-slash';
      }

      classEye.attr('class', icon);
    },

    /**
     * @method hideCompletedTasks
     * @description Hide completed tasks
     * @param tasks lists tasks
     * @param classEye object jquery
     */
    hideCompletedTasks(tasks, classEye) {
      if (classEye.attr('class') === 'fa fa-eye') {
        classEye.attr('class', 'fa fa-eye-slash');
      } else {
        classEye.attr('class', 'fa fa-eye');
      } // Check if the column is filtered


      const columnFiltered = tasks.filter(t => {
        return t.hideFlag === true;
      }); // If not filtered

      if (columnFiltered.length === 0) {
        this.setCompletedTasks(tasks, true);
      } else {
        // Already filter, then reset
        this.setCompletedTasks(tasks, false);
      }
    },

    /**
     * @method setCompletedTasks
     * @description Check if hide o show completed tasks
     * @param {*} tasks
     * @param {*} hide
     */
    setCompletedTasks(tasks, hide) {
      if (hide) {
        tasks.map(t => {
          if (t.endDate !== null && t.taskState === '2') {
            t.set('hideFlag', true);
          }
        });
      } else {
        tasks.map(t => {
          if (t.endDate !== null && t.taskState === '2') {
            t.set('hideFlag', false);
          }
        });
      }
    },

    /**
     * @method resetTasks
     * @description Reset tasks
     * @param tasks lists tasks
     */
    resetTasks(tasks) {
      tasks.map(t => {
        t.set('hideFlag', false);
      });
    },

    /**
     * @method getUnassignedTasks
     * @description Get  unassigned tasks
     * @param tasks lists tasks
     */
    getUnassignedTasks(tasks) {
      tasks.map(t => {
        if (t.userId !== null && t.userId !== '') {
          t.set('hideFlag', true);
        }
      });
    },

    /**
     * @method getAssignedFilterUser
     * @description Get  assigned to filter user
     * @param tasks lists tasks
     * @param userId User to filter
     */
    getAssignedFilterUser(tasks, userId) {
      tasks.map(t => {
        if (parseInt(t.userId) !== userId) {
          t.set('hideFlag', true);
        }
      });
    },

    /**
     * @method getFollowedByMe
     * @description Get followed tasks by user logged
     * @param tasks lists tasks
     * @param userId User to filter
     */
    getFollowedByMe(tasks, userId) {
      tasks.map(t => {
        if (!t.followersTask.includes(userId.toString())) {
          t.set('hideFlag', true);
        }
      });
    },

    /**
     * @method getThatHaveOrDontHaveLabels
     * @description Get have labels selected
     * @param tasks lists tasks
     * @param labelColor labels to filter
     */
    getThatHaveOrDontHaveLabels(tasks, labelsColor, haveLabel) {
      // If have label select
      if (haveLabel) {
        tasks.map(t => {
          if (!labelsColor.some(l => t.labels.includes(l))) {
            t.set('hideFlag', true);
          }
        });
      } else {
        // If not have label select
        tasks.map(t => {
          if (labelsColor.some(l => t.labels.includes(l))) {
            t.set('hideFlag', true);
          }
        });
      }
    },

    /**
     * @method getTasksByDueDate
     * @description Get tasks by due date
     * @param tasks lists tasks
     * @param byDueDateFilter Filter by due date
     */
    getTasksByDueDate(tasks, byDueDateFilter) {
      switch (byDueDateFilter) {
        case 'today':
          var today = (0, _utils.formatDateIsoString)();
          tasks.map(t => {
            if (t.endDate === null && t.dueDate !== null) {
              if (t.dueDate !== today) {
                t.set('hideFlag', true);
              }
            } else {
              t.set('hideFlag', true);
            }
          });
          break;

        case 'expired':
          var todayDateTime = (0, _utils.getDateWithTimeMidnight)();
          tasks.map(t => {
            if (t.endDate === null && t.dueDate !== null) {
              if ((0, _utils.getDateWithTimeMidnight)(t.dueDate) >= todayDateTime) {
                t.set('hideFlag', true);
              }
            } else {
              t.set('hideFlag', true);
            }
          });
          break;

        case 'tomorrow':
          var tomorrowDateTime = (0, _utils.getTomorrowDateTime)();
          var tomorrow = (0, _utils.getDateWithTimeMidnight)(tomorrowDateTime.toISOString().slice(0, 10));
          tasks.map(t => {
            if (t.endDate === null && t.dueDate !== null) {
              if ((0, _utils.getDateWithTimeMidnight)(t.dueDate).getTime() !== tomorrow.getTime()) {
                t.set('hideFlag', true);
              }
            } else {
              t.set('hideFlag', true);
            }
          });
          break;
      }
    },

    /**
     * @method getTaskWithFather
     * @description Get tasks with parent task
     * @param {*} tasks
     */
    getTaskWithFather(tasks) {
      tasks.map(t => {
        if (t.parentTaskId === null) {
          t.set('hideFlag', true);
        }
      });
    },

    /**
     * @method getTaskWithoutFather
     * @description Get tasks with without parent task
     * @param {*} tasks
     */
    getTaskWithoutFather(tasks) {
      tasks.map(t => {
        if (t.parentTaskId !== null) {
          t.set('hideFlag', true);
        }
      });
    },

    /**
     * @method copyUrlTask
     * @description copy the url task to clipboard
     * @param task Task to copy id
     */
    copyUrlTask(task) {
      const url = `${window.location.href}?task_id=${task.id}`;
      const dummy = document.createElement('input');
      document.body.appendChild(dummy);
      dummy.setAttribute('id', 'dummy_id');
      dummy.setAttribute('value', url);
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      const toast = this.get('toast');
      toast.success(this.get('intl').t('task.copy.clipboard'));
    },

    /**
     * @method createTask
     * @description Create task in task-list
     */
    async createTask(viewId, projectId, viewTasksSelected, userSelected, labelsIds, currentSprintId, contentTask, priority, remainTime, valueDatePickerCreate, taskGroups) {
      const project = await this.get('store').find('project', projectId);
      let taskGroup;

      if (viewTasksSelected === 'group') {
        taskGroup = await this.get('store').find('task-group', viewId);
      } else {
        taskGroup = await taskGroups.get('firstObject');
      }

      let taskState = null;

      if (viewTasksSelected === 'state') {
        taskState = viewId;
      } else {
        // Default TO-DO
        taskState = 0;
      } // Get user responsable


      let user = null;
      let userId = null;
      let userPhoto = '';
      let username = '';

      if (userSelected !== null) {
        user = await this.get('store').find('user', userSelected);
        userPhoto = user.userPhoto;
        userId = user.id;
        username = user.username;
      }

      if (viewTasksSelected === 'user') {
        user = await this.get('store').find('user', viewId);
        userPhoto = user.userPhoto;
        userId = user.id;
        username = user.username;
      } // Get colors labels


      const labelsColors = await this.get('labelService').getLabelsColor(labelsIds);
      let sprint;

      if (currentSprintId) {
        sprint = await this.get('store').find('sprint', currentSprintId);
      } // Create task


      const task = this.get('store').createRecord('task', {
        name: contentTask,
        project: project,
        projectId: projectId,
        taskState: taskState,
        priority: priority,
        remainTime: remainTime,
        estimatedTime: remainTime,
        taskGroup: taskGroup,
        taskGroupId: viewId,
        dueDate: valueDatePickerCreate,
        user: user,
        userId: userId,
        userPhoto: userPhoto,
        username: username,
        labels: labelsColors,
        sprint: sprint ? sprint : null,
        estimateEffort: 1,
        sprintId: currentSprintId ? parseInt(currentSprintId) : null
      }); // Save record

      await task.save(); // Add user logged like default follower to task

      this.addUserLoggedFollowerTask(task); // Save labels

      if (labelsIds.length > 0) {
        await this.get('labelService').saveLabelsIds(labelsIds, task);
      }

      return task;
    },

    /**
     * @method createTaskFromGroupOrTask
     * @description Create child task from group or other task
     */
    async createTaskFromGroupOrTask(viewTasksSelected, taskGroupId, currentSprintId, parentTaskId = null, priority = 0) {
      let sprint = null;
      let taskData = {}; // If is sprint project

      if (currentSprintId !== null) {
        sprint = await this.get('store').find('sprint', currentSprintId);
      }

      let parentTask = null;

      if (parentTaskId !== null) {
        parentTask = await this.get('store').find('task', parentTaskId);
      } // Task to add


      if (viewTasksSelected === 'group') {
        taskData = {
          taskGroupId: taskGroupId,
          sprintId: currentSprintId,
          sprint: sprint,
          parentTaskId: parentTaskId,
          parentTask: parentTask,
          name: '',
          priority: priority,
          estimatedTime: 0,
          remainTime: 0
        };
      } else if (viewTasksSelected === 'state') {
        taskData = {
          taskGroupId: taskGroupId,
          sprintId: currentSprintId,
          sprint: sprint,
          name: '',
          parentTaskId: parentTaskId,
          parentTask: parentTask,
          priority: priority,
          estimatedTime: 0,
          remainTime: 0,
          startDate: taskGroupId === 1 ? (0, _moment.default)().format('YYYY-MM-DD') : null // If task is in Doing group, it will have current date

        };
      } else if (viewTasksSelected === 'user') {
        if (taskGroupId !== 0) {
          // If task is assigned to an user
          const user = await this.get('store').find('user', taskGroupId);
          taskData = {
            user: user,
            userId: taskGroupId,
            username: user.username,
            userPhoto: user.userPhoto,
            taskGroupId: taskGroupId,
            sprintId: currentSprintId,
            sprint: sprint,
            parentTaskId: parentTaskId,
            parentTask: parentTask,
            priority: priority,
            estimatedTime: 0,
            remainTime: 0,
            name: ''
          };
        } else {
          // If task is not assigned to an user
          taskData = {
            taskGroupId: taskGroupId,
            sprintId: currentSprintId,
            sprint: sprint,
            name: '',
            userId: 0,
            priority: priority,
            parentTaskId: parentTaskId,
            parentTask: parentTask,
            estimatedTime: 0,
            remainTime: 0
          };
        }
      }

      const task = await this.store.createRecord('task', taskData);
      return task;
    },

    /**
     * @method getParentTasksCombo
     * @description Get tasks without parentTask and self task
     */
    getParentTasksCombo(tasks, taskId) {
      const task = parseInt(taskId);
      const excludeTaskSelected = tasks.filter(t => parseInt(t.id) !== task);
      const filteredTask = excludeTaskSelected.filter(t => parseInt(t.parentTaskId) !== task);
      return filteredTask.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0);
    },

    /**
     * Duplicate task
     * @param {*} taskId
     */
    async duplicateTask(taskId) {
      const response = await this.get('ajax').request(`/duplicate-task/`, {
        method: 'POST',
        data: {
          task_id: taskId
        }
      });
      return await this.store.find('task', response.data.id);
    }

  });

  _exports.default = _default;
});