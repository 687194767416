define("mytaskpanel/models/history", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    date: _emberData.default.attr(),
    task: _emberData.default.belongsTo('task'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});