define("mytaskpanel/services/labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    /**
    * @method addLabelToObject
    * @description Add label to object via BroadcastChannel
    */
    addLabelToObject(objectLabels, ref) {
      const self = this; // Check id add new label then add to list

      const bc = new BroadcastChannel('add_label');

      bc.onmessage = function (ev) {
        self.get('store').find('label', ev.data).then(newLabel => {
          ref.get(objectLabels).addObject(newLabel);
        }).catch(() => {// error
        });
      };
    },

    /**
     * @method saveLabelsIdsInTask
     * @description Save labels id in tasks
     * @param {*} labelsIds
     * @param {*} task
     */
    async saveLabelsIds(labelsIds, task) {
      const self = this;
      labelsIds.forEach(async l => {
        const label = self.get('store').peekRecord('label', l);
        const taskLabel = self.get('store').createRecord('task-label', {
          label: label,
          task: task
        }); // Save record

        await taskLabel.save();
      });
    },

    /**
     * @method getLabelsColor
     * @description Get labels color from array ids
     * @param {*} labelsIds Get array id's
     */
    async getLabelsColor(labelsIds) {
      const self = this; // Get colors labels

      const labelsColors = [];

      if (labelsIds.length > 0) {
        labelsIds.forEach(async l => {
          let label; // Check if the record exists in the store

          if (self.get('store').hasRecordForId('label', l)) {
            // Take the record from store
            label = self.get('store').peekRecord('label', l);
          } else {
            // Go to the backend to get the record
            label = await self.get('store').find('label', l);
          }

          labelsColors.push(label.get('color'));
        });
      }

      return labelsColors;
    }

  });

  _exports.default = _default;
});