define("mytaskpanel/templates/components/auth/page-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Th0Y5kA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"base\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"classWrapper\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"link\"],[11,\"href\",\"/assets/css/MTP_signin.css\"],[11,\"rel\",\"stylesheet\"],[11,\"type\",\"text/css\"],[9],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/auth/page-base.hbs"
    }
  });

  _exports.default = _default;
});