define("mytaskpanel/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "mytaskpanel/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service('session'),
    auth: Ember.inject.service('auth'),
    router: Ember.inject.service(),
    languageService: Ember.inject.service('language-intl'),
    namespace: _environment.default.APP.API_NAMESPACE,
    host: _environment.default.APP.API_HOST,
    headers: Ember.computed('session.data.authenticated.user', {
      get() {
        let headers = {};

        if (this.get('session.isAuthenticated')) {
          const clientId = this.get('session.data.authenticated.user').client;

          if (clientId != null) {
            headers['client-id'] = clientId;
          } else {
            this.get('auth').logout();
          } // Set timezone


          headers['timezone'] = this.get('session.data.authenticated.user').timezone;
          headers['language'] = this.get('languageService').getLanguage();
        }

        return headers;
      }

    }),
    buildURL: function (type, id) {
      var url = this._super(type, id);

      if (url.charAt(url.length - 1) !== '/') {
        url += '/';
      }

      return url;
    },

    authorize(xhr) {
      if (Ember.testing) {
        xhr.setRequestHeader('Authorization', 'jwt beyonce');
      } else {
        const {
          token
        } = this.get('session.data.authenticated');

        if (this.get('session.isAuthenticated') && !Ember.isEmpty(token)) {
          xhr.setRequestHeader('Authorization', `jwt ${token}`);
        }
      }
    },

    handleResponse(status, headers, payload, requestData) {
      if (status === 403 && payload === "Signature has expired.") {
        window.location.reload();
      }

      if (status === 403 && payload === 'Client expired') {
        const urlPrice = this.get('router').urlFor('price');
        window.location.href = urlPrice;
      }

      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});