define("mytaskpanel/libs/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.routeIsFromAuthentication = routeIsFromAuthentication;

  /**
   * Check if the transition come from auth login
   * @param {*} transition
   * @returns
   */
  function routeIsFromAuthentication(transition) {
    return 'clients' === transition.from.name || 'loading' === transition.from.name;
  }
});