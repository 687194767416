define("mytaskpanel/components/tasks/task-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    taskService: Ember.inject.service('task'),
    // Add other class to ember-view class
    classNameBindings: ['taskitem'],
    taskitem: true,
    // For drag and drop
    attributeBindings: ['draggable'],
    draggable: 'true',
    checkedTask: false,
    endDateFormTask: null,
    observerCompletedTasks: Ember.observer('task.endDate', 'task.taskGroupId', 'task.userId', function () {
      // This is only for active the reactivity for the helper get-total-tasks-completed
      try {
        let groupId = null;

        switch (this.get('parent.viewTasksSelected')) {
          case 'group':
            {
              groupId = parseInt(this.task.taskGroupId);
              break;
            }

          case 'user':
            {
              groupId = parseInt(this.task.userId);
              break;
            }

          case 'state':
            {
              groupId = parseInt(this.task.taskState);
              break;
            }
        }

        const group = this.parent.taskGroupsTasks.filter(x => x.taskGroupId === groupId).objectAt(0);
        const total = group.totalClickedCompletedTask + 1;
        group.set('totalClickedCompletedTask', total);
      } catch (e) {// Nothing
      }
    }),

    init() {
      this._super(...arguments);

      const id = Math.random().toString(36).substring(7) + '_' + this.get('task.id');
      this.set('elementId', 'task-item-element-' + id);
    },

    didReceiveAttrs() {
      this._super(); // TODO: Falta el campo endDate
      // TODO: Revisar como funciona el tilde cuando una tarea finalizo. Si vuelve a tildar que pasa?


      if (this.get('task.taskState') === '2' && this.get('task.endDate') !== '') {
        this.set('checkedTask', true);
      }

      if (this.get('userType') === 'I' && this.get('parent.viewTasksSelected') === 'user') {
        this.set('draggable', null);
      } else {
        this.set('draggable', 'true');
      }
    },

    actions: {
      /**
      * @method showModalTask
      * @description Show modal for edit task
      */
      showModalTask() {
        // If is the same task, open again without loading
        if (this.get('task') === this.get('parent.task')) {
          if (!Ember.$('#wrapper').hasClass('right-bar-enabled')) {
            Ember.$('#wrapper').toggleClass('right-bar-enabled');
          }
        } else {
          this.set('parent.task', this.get('task'));
        }
      },

      /**
      * @method showModalTaskParent
      * @description Open parent task
      * @param {*} taskId
      */
      async showModalTaskParent(taskId) {
        if (taskId === parseInt(this.get('parent.task.id'))) {
          if (!Ember.$('#wrapper').hasClass('right-bar-enabled')) {
            Ember.$('#wrapper').toggleClass('right-bar-enabled');
          }
        } else {
          const task = await this.get('store').find('task', taskId);
          this.set('parent.task', task);
        }
      },

      /**
      * @method updateTask
      * @description Update task
      */
      updateTask() {
        this.get('task').save();
      },

      /**
      * @method doneTask
      * @description Done task
      */
      async doneTask() {
        await this.get('taskService').done(this.get('task'), this.get('parent.parent.hiddenCompleteTask'));
        this.set('checkedTask', true);
        const date = new Date();
        this.set('task.endDate', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
        this.set('endDateFormTask', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
      },

      /**
      * @method unDoneTask
      * @description Done task
      */
      async unDoneTask() {
        await this.get('taskService').unDone(this.get('task'));
        this.set('checkedTask', false);
      },

      /**
      * @method createChildTask
      * @description New task from another task
      */
      async createChildTask() {
        const task = this.get('task');
        let taskGroupId = null;
        const viewTasksSelected = this.get('parent.viewTasksSelected');

        switch (viewTasksSelected) {
          case 'group':
            {
              taskGroupId = task.get('taskGroupId');
              break;
            }

          case 'state':
            {
              taskGroupId = task.get('taskState');
              break;
            }

          case 'user':
            {
              taskGroupId = task.get('userId');
              break;
            }
        } // Task to add


        const priority = task.get('priority') + 1; // For appear after task

        const newTask = await this.get('taskService').createTaskFromGroupOrTask(viewTasksSelected, taskGroupId, this.get('task.sprintId'), task.get('id'), priority); // Add task to list

        this.send('addTaskToBoard', newTask);
        Ember.run.later(() => {
          Ember.$('.new-task-empty').first().focus();
        }, 500);
      },

      /**
      * @method saveNameInNewTask
      * @description Save task created in task group
      */
      async saveNameInNewTask() {
        if (Ember.isPresent(this.get('task.name'))) {
          const project = await this.get('store').find('project', this.get('parent.project.id')); // Get Id group

          const idView = this.get('task.taskGroupId');
          let taskGroup;

          if (this.get('parent.viewTasksSelected') === 'group') {
            taskGroup = await this.get('store').find('task-group', this.get('task.taskGroupId'));
          } else {
            taskGroup = this.get('parent.taskGroups').get('firstObject');
          }

          const task = this.get('task'); // This is for not failed

          this.get('task').set('taskState', 0);
          this.get('task').set('remainTIme', 0);
          this.get('task').set('estimatedTime', 0);
          this.get('task').set('project', project);
          this.get('task').set('projectId', project.id);
          this.get('task').set('taskGroup', taskGroup);
          this.get('task').set('remainTime', task.remainTime);
          this.get('task').set('priority', task.priority);
          this.get('task').set('taskState', task.taskState); // If the view is by user, set the user

          if (this.get('parent.viewTasksSelected') === 'user' && idView !== 0) {
            const user = await this.get('store').find('user', idView);
            this.get('task').set('user', user);
          } // If the view is by state, override and set the state the group


          if (this.get('parent.viewTasksSelected') === 'state') {
            this.get('task').set('taskState', idView);
          } // Save task


          await task.save();
          this.get('task').set('id', task.id);
          this.set('task', task); // Add user logged like default follower to task

          this.get('taskService').addUserLoggedFollowerTask(task); // Update info data

          this.get('parent.parent').send('loadDataProject'); // Update elementId to set id

          const newElement = this.elementId.split("_");
          const id = newElement[0] + "_" + task.id;
          Ember.$(this.element).attr('id', id);
        }
      },

      /**
      * @method removeNewTask
      * @description Remove new task from tasks view
      */
      removeNewTask() {
        this.get('parent.taskGroupsTasks').objectAt(this.get('taskGroupIndex')).tasks.removeObject(this.get('task'));
      },

      /**
      * Add task to the board
      * @param {*} task
      */
      addTaskToBoard(task) {
        // Add task to list
        const tasks = this.get('parent.taskGroupsTasks').objectAt(this.get('taskGroupIndex')).tasks;
        tasks.insertAt(this.get('indexTask') + 1, task);
      },

      /**
       * @method copyUrlTask
       * @description copy the url task to clipboard
       */
      copyUrlTask() {
        this.get('taskService').copyUrlTask(this.get('task'));
      },

      /**
       * @method duplicateTask
       * @description Duplicate task
       */
      async duplicateTask() {
        const newTask = await this.get('taskService').duplicateTask(this.get('task').id);
        this.send('addTaskToBoard', newTask);
      }

    }
  });

  _exports.default = _default;
});