define("mytaskpanel/components/projects/project-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    projectService: Ember.inject.service('projects'),
    fileService: Ember.inject.service('files'),
    loadProject: Ember.observer('project', async function () {
      let userType = this.get('parent.userType');

      if (this.get('project.privacy') === 'P') {
        const records = await this.get('store').query('project-user', {
          project: this.get('project.id'),
          user: this.get('parent.user.id')
        });
        const projectUser = records.get('firstObject');
        userType = projectUser.userType;
      }

      this.set('userType', userType);
      const listFiles = await this.get('fileService').getFiles('project', this.get('project'));
      this.set('projectFiles', listFiles);
      this.loadComments();
      this.setProjectMembers();
    }),

    setProjectMembers() {
      const project = this.get('project');

      const _this = this;

      this.projectService.getMembersProject(project).then(response => {
        _this.set('projectMembers', response.data.members);
      });
    },

    /**
    * @method loadComments
    * @description Load comments project
    */
    async loadComments() {
      const comments = await this.get('store').query('project-comment', {
        'project': this.get('project.id')
      });
      const commentsList = [];
      comments.map(comment => {
        commentsList.pushObject(comment);
      });
      this.set('projectComments', commentsList);
    }

  });

  _exports.default = _default;
});