define("mytaskpanel/templates/components/utils/right-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uuEbTo5J",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\" Right Sidebar \"],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"side-bar right-bar\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mtp-boton-cerrar pointer\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[11,\"aria-hidden\",\"true\"],[9],[10],[3,\"action\",[[22,0,[]],\"closeModal\"]],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mtp-modal\"],[9],[0,\"\\n        \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[2,\" /Right-bar \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/utils/right-modal.hbs"
    }
  });

  _exports.default = _default;
});