define("mytaskpanel/helpers/date-diff", ["exports", "moment", "mytaskpanel/libs/utils"], function (_exports, _moment, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  * @method dateDiff
  * @description Get diff between not and param date
  */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service('intl'),

    compute(params) {
      let diffText = '';
      const dueDate = (0, _moment.default)(params[0]);
      const now = (0, _moment.default)(); // Exclude days

      const diffDays = Math.ceil(now.startOf('day').diff(dueDate.startOf('day'), 'days', true));

      if (diffDays >= 1) {
        diffText += '<i class="fa fa-calendar"></i> <span class="red">';
        let dayText = diffDays > 1 ? this.get('intl').t('days') : this.get('intl').t('day');
        diffText += +diffDays + ' ' + dayText + ' ' + this.get('intl').t('days.delay');
        diffText += "</span>";
        diffText = Ember.String.htmlSafe(diffText);
      } else if (diffDays === 0) {
        diffText += '<i class="fa fa-calendar"></i> <span class="yellow">';
        diffText += this.get('intl').t('today');
        diffText += "</span>";
        diffText = Ember.String.htmlSafe(diffText);
      } else if (diffDays === -1) {
        diffText += '<i class="fa fa-calendar"></i> <span class="green">';
        diffText += this.get('intl').t('tomorrow');
        diffText += "</span>";
        diffText = Ember.String.htmlSafe(diffText);
      } else {
        diffText += '<i class="fa fa-calendar"></i> <span class="white-green">';
        diffText += (0, _utils.getLocaleDateString)(dueDate);
        diffText += "</span>";
        diffText = Ember.String.htmlSafe(diffText);
      }

      return diffText;
    }

  });

  _exports.default = _default;
});