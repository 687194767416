define("mytaskpanel/components/generic-tasks/form-generic-tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    toast: Ember.inject.service('toast'),
    swal: Ember.inject.service('swal'),
    action: '',
    observerSearchFilterValue: Ember.observer('searchFilterValue', function () {
      let genericTasks = [];
      const value = this.searchFilterValue.toLowerCase();

      if (value.trim() === '') {
        genericTasks = this.get('store').peekAll('generic-task');
      } else {
        genericTasks = this.get('store').peekAll('generic-task').filter(l => {
          const genericTaks = l.get('name').toLowerCase();
          return genericTaks.includes(value);
        });
      }

      this.set('genericTasks', genericTasks);
    }),

    didInsertElement() {
      this._super();
    },

    actions: {
      /**
      * @method showForm
      * @description form generic task
      * @param {*} action
      * @param {*} id Identification generic task
      */
      async showForm(action, id) {
        this.set('action', action); // Action edit or create

        if (action === 'create') {
          const genericTask = this.get('store').createRecord('generic-task', {
            name: ''
          });
          this.set('genericTask', genericTask);
        } else {
          // Get user
          const genericTask = await this.get('store').find('generic-task', id); // Set user

          this.set('genericTask', genericTask);
        } // Show modal


        Ember.$('#modal_edit_genericTask').modal('show');
      },

      /**
      * @method deleteGenericTask
      * @description Delete generic task
      * @param {*} id Identification generic task
      */
      async deleteGenericTask(id) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('delete.question');
        const genericTaskId = id;
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          // Delete record
          const genericTask = await this.get('store').findRecord('generic-task', genericTaskId);
          genericTask.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error',
              allowOutsideClick: false
            }).then(() => {
              window.location.reload();
            });
          });
        }
      },

      /**
      * @method createGenericTask
      * @description create generci task action
      */
      async createGenericTask() {
        if (Ember.isPresent(this.genericTask.name)) {
          this.genericTask.save().then(() => {
            this.get('toast').success(this.get('intl').t('generic_task.created'));
          }).catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err,
              type: 'error'
            });
          });
          Ember.$('#modal_edit_genericTask').modal('hide');
        }
      },

      /**
      * @method editGenericTask
      * @description Edit genericTask action
      */
      async editGenericTask() {
        if (Ember.isPresent(this.genericTask.name)) {
          this.genericTask.save().then(() => {
            this.get('toast').success(this.get('intl').t('generic_task.updated'));
          }).catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          });
          Ember.$('#modal_edit_genericTask').modal('hide');
        }
      }

    }
  });

  _exports.default = _default;
});