define("mytaskpanel/components/projects/form-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    router: Ember.inject.service('router'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    toast: Ember.inject.service('toast'),
    swal: Ember.inject.service('swal'),
    projectService: Ember.inject.service('projects'),
    showLoading: false,
    randomId: Ember.computed('index', {
      get() {
        return Math.floor(Math.random() * 10000000).toString();
      }

    }),
    setForm: Ember.observer('project', function () {
      this.loadForm();
    }),
    type_project_id: Ember.computed('index', {
      get() {
        return Math.floor(Math.random() * 10000000).toString();
      }

    }),
    type_folder_id: Ember.computed('index', {
      get() {
        return Math.floor(Math.random() * 10000000).toString();
      }

    }),

    didInsertElement() {
      this._super(); // Config type privacy


      this.setTypePrivacy(); // Load components of the form

      this.loadForm();

      if (this.get('mode') === 'create') {
        this.set('project', this.get('store').createRecord('project'));
      }
    },

    /**
     * Set type privacy configuration
     */
    setTypePrivacy() {
      Ember.$('#switch-type-privacy').bootstrapToggle({
        on: this.get('intl').t('privacy.private'),
        off: this.get('intl').t('privacy.public')
      });
      this.set('messageTypePrivacy', this.get('intl').t('privacy.public_description'));
      const self = this;
      Ember.$('#switch-type-privacy').change(function () {
        if (Ember.$(this).is(":checked")) {
          self.set('messageTypePrivacy', self.get('intl').t('privacy.private_description'));
        } else {
          self.set('messageTypePrivacy', self.get('intl').t('privacy.public_description'));
        }
      });
    },

    /**
     * @method loadForm
     * @description Initialize components of the form
     */
    loadForm() {
      // Getting project and mode data
      const project = this.get('project');
      const mode = this.get('mode'); // Setting description rich textbox

      Ember.$('.form-project-description').summernote({
        minHeight: 120,
        focus: true,
        disableDragAndDrop: true,
        width: mode === 'edit' ? 520 : 620,
        toolbar: [['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['insert', []]]
      }); // Default type_folder

      Ember.$('#' + this.get('type_folder_id')).prop('checked', 'checked'); // Default type privacy

      Ember.$('#access_public_' + this.get('randomId')).prop('checked', 'checked'); // If project exists and mode is not create, set the project description

      if (project !== undefined && project.id !== null && mode !== 'create') {
        Ember.$('#form-project-description-edit').summernote('code', project.description);
        const privacy = this.get('project.privacy');

        if (privacy == 'A') {
          Ember.$('#access_public_' + this.get('randomId')).prop('checked', 'checked');
        } else {
          Ember.$('#access_private_' + this.get('randomId')).prop('checked', 'checked');
        }
      }
    },

    actions: {
      /**
      * @method deleteProject
      * @description Delete project
      * @param {*} project Project record
      */
      async deleteProject(project) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('project.delete.question');
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          this.set('parent.showLoading', true); // Delete record

          project.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          });
          this.get('projects').removeObject(project);
          this.set('parent.showLoading', false);
          Ember.$('#wrapper').toggleClass('right-bar-enabled');
        }
      },

      /**
      * @method setFieldActiveProject
      * @description Archive or not project
      */
      async setFieldActiveProject(active) {
        let text, question;

        if (active) {
          text = this.get('intl').t('activate');
          question = this.get('intl').t('project.activate.question');
        } else {
          text = this.get('intl').t('archive');
          question = this.get('intl').t('project.archive.question');
        }

        const result = await this.get('swal').open({
          title: text,
          text: question,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          this.set('parent.showLoading', true);
          const startDate = Ember.$('#start_date').val();
          const endDate = Ember.$('#end_date').val();
          const dueDate = Ember.$('#due_date').val();
          this.set('project.startDate', startDate ? startDate : null);
          this.set('project.endDate', endDate ? endDate : null);
          this.set('project.dueDate', dueDate ? dueDate : null);
          this.set('project.active', active);
          await this.project.save();
          const favorites = this.get('parent.favorites');

          if (!favorites) {
            this.get('projects').removeObject(this.project);
          }

          const toast = this.get('toast');

          if (active) {
            toast.success(this.get('intl').t('project.activate'));
          } else {
            toast.success(this.get('intl').t('project.archived'));
          }

          this.set('parent.showLoading', false);
          Ember.$('#wrapper').toggleClass('right-bar-enabled');
        }
      },

      /**
      * @method createEditProject
      * @description Create project or edit project
      */
      async createEditProject() {
        const name = this.get('project.name'); // Create account

        if (this.get('mode') === 'create') {
          const isCheckedPrivacy = Ember.$("#switch-type-privacy").is(":checked");
          const privacy = isCheckedPrivacy ? 'P' : 'A';
          const projectType = Ember.$('[name="access"]:checked').val();
          const description = Ember.$('#form-project-description-create').summernote('code');
          const productOwner = Ember.$('[name="product_owner"]').val();

          if (Ember.isPresent(name) && Ember.isPresent(projectType) && Ember.isPresent(productOwner)) {
            // This loading is local
            this.set('showLoading', true);
            const account = await this.get('store').find('account', this.get('params.account_id'));
            const user = await this.get('store').find('user', productOwner);
            const project = this.get('store').createRecord('project', {
              account: account,
              privacy: privacy,
              name: name,
              description: description,
              projectType: projectType,
              productOwner: user,
              projectState: 'P',
              active: true
            }); // Save record

            const p = await project.save();
            Ember.$('#modal_create_project').modal('hide');
            const toast = this.get('toast');
            toast.success(this.get('intl').t('project.created')); // Add object to model project

            this.get('projects').addObject(p);
            this.set('showLoading', false); // Redirect to tasks

            this.get('router').transitionTo('tasks', p.get('id'), p.get('slug'), p.get('account.id'), p.get('account.slug'));
          }
        } else {
          const privacyEdit = Ember.$('[name="type_privacy_' + this.get('randomId') + '"]:checked').val();
          const startDate = Ember.$('#start_date').val();
          const endDate = Ember.$('#end_date').val();
          const dueDate = Ember.$('#due_date').val();
          const description = Ember.$('#form-project-description-edit').summernote('code');
          this.set('project.startDate', startDate ? startDate : null);
          this.set('project.endDate', endDate ? endDate : null);
          this.set('project.dueDate', dueDate ? dueDate : null);
          this.set('project.description', description);

          if (privacyEdit) {
            this.set('project.privacy', privacyEdit);
          } // This loading is from project-list


          this.set('parent.showLoading', true);
          this.project.save().then(() => {
            // If add startDate and is project state planned change to ACTIVE
            if (startDate && this.get('project.projectState') === 'P') {
              this.set('project.projectState', 'A');
            } // If has endDate change project state to FINISHED


            if (endDate) {
              this.set('project.projectState', 'F');
            } // Update project members


            const project = this.get('project');
            this.projectService.getMembersProject(project).then(response => {
              this.set('projectMembers', response.data.members);
            });
            const toast = this.get('toast');
            toast.success(this.get('intl').t('project.update'));
            this.set('parent.showLoading', false);
          });
          Ember.$('#wrapper').toggleClass('right-bar-enabled');
        }
      }

    }
  });

  _exports.default = _default;
});