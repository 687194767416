define("mytaskpanel/templates/task-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ep8uii6O",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-sm-12\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-title-box\"],[9],[0,\"\\n      \"],[7,\"nav\"],[11,\"class\",\"navbar navbar-dark navbar-expand-md bg-faded justify-content-center\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\" navbar-brand d-flex w-50 mr-auto\"],[9],[1,[27,\"t\",[\"task_groups\"],null],false],[10],[0,\"\\n       \\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row h-87\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-sm-12\"],[9],[0,\"\\n\"],[1,[27,\"task-groups/form-task-groups\",null,[[\"groups\",\"projects\",\"searchFilterValue\"],[[23,[\"model\",\"groups\"]],[23,[\"model\",\"projects\"]],[23,[\"searchFilterValue\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/task-groups.hbs"
    }
  });

  _exports.default = _default;
});