define("mytaskpanel/templates/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zb87dRbS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"auth/page-base\",null,null,{\"statements\":[[0,\"\\n\"],[1,[27,\"auth/list-clients\",null,[[\"clients\",\"classTitle\",\"reload\"],[[23,[\"model\",\"clients\"]],\"white\",false]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/clients.hbs"
    }
  });

  _exports.default = _default;
});