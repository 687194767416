define("mytaskpanel/components/accounts/edit-member-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    store: Ember.inject.service('store'),
    toast: Ember.inject.service('toast'),
    swal: Ember.inject.service('swal'),
    actions: {
      /**
      * @method editAccountUser
      * @description Edit account user action
      */
      async editAccountUser() {
        const userType = Ember.$('input[name=user_type_' + this.get('randomId') + ']:checked').val();

        if (Ember.isPresent(userType)) {
          this.accountUser.set('userType', userType);
          this.accountUser.save().then(() => {
            this.get('toast').success(this.get('intl').t('user.updated'));
          }).catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          });
          Ember.$('#modal_edit_account_user').modal('hide');
        }
      }

    }
  });

  _exports.default = _default;
});