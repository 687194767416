define("mytaskpanel/services/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),

    /**
    * @method logout
    * @description logout session
    */
    logout() {
      this.set('session.data.blockInvalidateUrl', null);
      this.get('session').invalidate();
      window.location = '/';
    }

  });

  _exports.default = _default;
});