define("mytaskpanel/helpers/get-color-task", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  * @method getColorTask
  * @description Get color tasl item
  */
  var _default = Ember.Helper.extend({
    compute(params) {
      let value = '';
      const dueDate = params[0];
      const endDate = params[1];
      const now = (0, _moment.default)();
      const diffDays = Math.ceil(now.startOf('day').diff((0, _moment.default)(dueDate).startOf('day'), 'days', true));

      if (endDate === null || endDate === undefined) {
        if (dueDate === null || dueDate === undefined) {
          value = 'csuspended';
        } else if (dueDate !== null && diffDays < 0) {
          value = 'cactive';
        } else if (diffDays === 0) {
          value = 'cplanned';
        } else if (diffDays > 0) {
          value = 'cdeleted';
        }
      } else {
        value = 'ccompleted';
      }

      return value;
    }

  });

  _exports.default = _default;
});