define("mytaskpanel/components/modal-files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    intl: Ember.inject.service('intl'),
    ajax: Ember.inject.service('ajax'),
    toast: Ember.inject.service('toast'),
    swal: Ember.inject.service('swal'),
    fileService: Ember.inject.service('files'),
    showLoading: false,
    loadFileURL: '',
    actions: {
      /**
       * @method uploadFile
       * @description Detect if files has been selected
       */
      async uploadFile() {
        const imgs = Ember.$('#files-upload-list')[0].files;

        if (imgs.length > 0) {
          this.set('showLoading', true);
          let formData = new window.FormData();
          let typeObject;
          let contentObject;

          if (this.get('project')) {
            typeObject = 'project';
            contentObject = this.get('project');
            formData.append('project', this.get('project.id'));
            this.set('loadFileURL', '/project-uploads/');
          }

          if (this.get('task')) {
            typeObject = 'task';
            contentObject = this.get('task');
            formData.append('task', this.get('task.id'));
            this.set('loadFileURL', '/task-uploads/');
          }

          await this.get('fileService').uploadFile(imgs, formData, false, this.loadFileURL); // Reload files

          const listFiles = await this.get('fileService').getFiles(typeObject, contentObject);
          this.set('files', listFiles);
          this.set('showLoading', false);
        }
      },

      /**
      * @method downloadFile
      * @description Download file
      * @param {*} upload object
      */
      showFile(upload) {
        let urlGetFile = '/task-uploads/';

        if (this.get('project')) {
          urlGetFile = '/project-uploads/';
        }

        this.get('ajax').request(`${urlGetFile}?file=${upload.id}&show_file=true`, {
          method: 'GET'
        }).then(response => {
          const url = response.data.url;
          window.open(url, '_blank');
        }).catch(() => {
          const toast = this.get('toast');
          toast.error(this.get('intl').t('task.file.show_error'));
        });
      },

      /**
      * @method downloadFile
      * @description Download file
      * @param {*} upload object
      */
      downloadFile(upload) {
        this.get('ajax').request(`${this.loadFileURL}?file=${upload.id}`, {
          method: 'GET'
        }).then(response => {
          const url = response.data.url;
          const a = document.createElement('a');
          a.href = url;
          a.download = upload.name;
          a.click();
          window.URL.revokeObjectURL(url);
        }).catch(() => {
          const toast = this.get('toast');
          toast.error(this.get('intl').t('task.file.download_error'));
        });
      },

      /**
      * @method deleteUpload
      * @description Delete upload file
      * @param {*} upload
      */
      async deleteUpload(upload) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('task.file.delete.question');
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          this.set('showLoading', true); // Delete record

          await upload.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          }); // Hide loading

          this.set('showLoading', false); // Remove object from the list

          this.get('files').removeObject(upload);
        }
      }

    }
  });

  _exports.default = _default;
});