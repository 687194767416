define("mytaskpanel/components/projects/projects-list", ["exports", "mytaskpanel/libs/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    toast: Ember.inject.service('toast'),
    intl: Ember.inject.service('intl'),
    guideTour: Ember.inject.service('guide-tour'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    userType: null,
    badgeClass: '',
    badge: '',
    accountProjects: null,
    accountProjectsListOriginal: null,
    showLoading: false,
    favorites: false,
    observerSearchFilterValue: Ember.observer('searchFilterValue', function () {
      const value = this.searchFilterValue.toLowerCase();

      if (value.trim() === '') {
        this.set('accountProjects', this.accountProjectsListOriginal);
      } else {
        const projects = this.accountProjects.filter(pr => {
          const project = pr.name.toLowerCase();
          return project.includes(value);
        });
        this.set('projects', projects);
      }
    }),

    didReceiveAttrs() {
      this._super();

      const self = this; // Data account for session menu

      this.set('favorites', this.favorites);

      if (!this.favorites) {
        this.get('session').set('session.content.authenticated.account_id', this.params.account_id);
        this.get('session').set('session.content.authenticated.account_slug', this.params.slug);
      } // Set array


      this.set('accountProjects', []);
      const projects = this.get('projects');
      projects.map(project => {
        self.accountProjects.pushObject(project);
      }); // For filter

      this.set('accountProjectsListOriginal', this.accountProjects);
    },

    didInsertElement() {
      this._super();

      const accountUser = this.get('accountUser');
      let userType = null;

      if (accountUser) {
        userType = accountUser.userType;
      } else {
        userType = this.get('user.userType');
      }

      this.set('userType', userType); // Guide tour for project

      this.get('guideTour').showGuideTourProject();
      this.loadProjectFromQueryParam();
    },

    /**
    * @method loadProjectFromQueryParam
    * @description Load project modal from query param
    */
    async loadProjectFromQueryParam() {
      if (this.project_id_query_param) {
        const project_id = this.project_id_query_param;
        const project = await this.get('store').find('project', project_id);
        this.send('showModalProject', project);
      }
    },

    /**
    * @method setProjectStateFormat
    * @description Set badge a badgeClass for projectState
    */
    setProjectStateFormat() {
      // Remove attr style for set color
      Ember.$("#state-project").removeAttr("style"); // Set badge

      const projectState = this.get('project.projectState') || '';

      switch (projectState.trim()) {
        // Planned
        case 'P':
          this.set('badge', 'Planned');
          this.set('badgeClass', 'warning');
          break;
        // Active

        case 'A':
          this.set('badge', 'Active');
          this.set('badgeClass', 'success');
          break;
        // Finished

        case 'F':
          this.set('badge', 'Finished');
          this.set('badgeClass', 'primary');
          break;
        // Suspend

        case 'S':
          this.set('badge', 'Suspend');
          this.set('badgeClass', 'secondary');
          break;

        default:
          this.set('badge', '');
          this.set('badgeClass', '');
          break;
      }
    },

    /**
    * @method setEditables
    * @description Set fields editables in header
    */
    setEditables() {
      const self = this;
      Ember.$('#project-name').editable({
        validate: function (value) {
          if (Ember.$.trim(value) == '') return 'This field is required';
          self.set('project.name', value);
          self.project.save();
        },
        mode: 'inline',
        showbuttons: false,
        inputclass: 'form-control-sm'
      }); // Reset value

      Ember.$('#project-name').editable('setValue', this.get('project.name'));
      const listProjectState = [];
      this.get('project.listProjectStates').map(obj => {
        listProjectState.push({
          value: obj[0],
          text: obj[1]
        });
      }); // State header editable

      Ember.$('#state-project').editable({
        showbuttons: false,
        validate: function (value) {
          if (Ember.$.trim(value) == '') return 'This field is required';
          self.set('project.projectState', value);
          self.setProjectStateFormat();
          self.project.save();
        },
        mode: 'inline',
        inputclass: 'form-control-sm',
        source: listProjectState
      }); // Reset value

      Ember.$('#state-project').editable('setValue', this.get('project.projectState'));
    },

    actions: {
      /**
       * @method showModalAdd
       * @description Display the modal add
       */
      async showModalAdd() {
        // Check if the user can create more projects and that the plan is not limiting it
        const response = await this.get('ajax').request('/check-plan-permission-client/', {
          method: 'POST',
          data: {
            'action': 'project'
          }
        });

        if (response.data.success) {
          Ember.$('#modal_create_project').modal();
          Ember.run.later(() => {
            Ember.$('#name_project_add').focus();
          }, 1000);
        } else {
          Ember.$("#modal-upgrade-plan").modal();
        }
      },

      /**
      * @method showModalProject
      * @description: Display modal right for edit o see data project
      * @param {*} project
      */
      async showModalProject(project) {
        // Set header
        this.set('project', project);
        this.set('title', this.get('project.name'));
        const productOwner = await this.get('store').findRecord('user', this.get('project.productOwnerId'));
        this.set('owner', productOwner.name);
        this.set('dateProject', this.get('project.dueDate') || '');

        if (this.favorites) {
          const users = await this.get('store').query('user', {
            'account_id': project.account.get('id')
          }, {
            reload: true
          });
          const accountUser = await this.get('store').query('account-user', {
            account: project.account.get('id'),
            user: this.get('user.id')
          }).then(records => {
            return records.get('firstObject');
          });
          let userType = this.get('user.userType');

          if (accountUser) {
            userType = accountUser.userType;
          }

          this.get('session').set('session.content.authenticated.account_id', project.account.get('id'));
          this.get('session').set('session.content.authenticated.account_slug', project.account.get('slug'));
          this.set('userType', userType);
          this.set('users', users);
        } // Get users fot editable product owner


        const self = this;
        const usersData = this.get('users').toArray();
        let users = [];
        usersData.forEach(u => {
          users.push({
            value: u.get('id'),
            text: u.get('name')
          });
        }); // Project header editable

        Ember.run.later(() => {
          Ember.$('#project-ownerdrop').editable('destroy');
          Ember.$('#project-ownerdrop').editable({
            showbuttons: false,
            validate: function (value) {
              if (Ember.$.trim(value) == '') return 'This field is required';
              self.get('store').find('user', value).then(user => {
                self.set('project.productOwner', user);
                self.project.save();
              });
            },
            mode: 'inline',
            inputclass: 'form-control-sm',
            source: users,
            emptytext: this.get('project.productOwner.name')
          });
          Ember.$('#project-ownerdrop').editable('setValue', this.get('project.productOwnerId'));
        }, 100); // Set icon

        const projectType = this.get('project.projectType' || '');

        if (projectType.trim() == 'P') {
          this.set('icon', 'fa fa-sitemap fa-rotate-270');
        } else {
          this.set('icon', 'fa fa-window-maximize');
        } // Set badge


        this.setProjectStateFormat(); // Set dates

        const startDate = this.get('project.startDate');
        const endDate = this.get('project.endDate');
        const dueDate = this.get('project.dueDate'); // Cargo la fecha de vencimiento

        if (dueDate === null || dueDate === '') {
          this.set('date', null);
        }

        setTimeout(() => {
          if (Ember.isPresent(startDate)) {
            Ember.$('#start_date').val(startDate);
          } else {
            Ember.$('#start_date').val(null);
          }

          if (Ember.isPresent(endDate)) {
            Ember.$('#end_date').val(endDate);
          } else {
            Ember.$('#end_date').val(null);
          }

          if (Ember.isPresent(dueDate)) {
            Ember.$('#due_date').val(dueDate);
            this.set('date', (0, _utils.getLocaleDateString)(dueDate));
          } else {
            Ember.$('#due_date').val(null);
          }
        }, 100); // Set editables

        this.setEditables();

        if (!Ember.$('#wrapper').hasClass('right-bar-enabled')) {
          Ember.$('#wrapper').toggleClass('right-bar-enabled');
        }
      },

      /**
      * @method addToFavorites
      * @description: Add or remove user favorite project
      * @param {*} project
      */
      async addToFavorites(project) {
        const user = this.get('user');

        try {
          await this.get('ajax').request('/add-remove-favorite-project/', {
            method: 'POST',
            data: {
              'project_id': project.id,
              'user_id': user.id,
              'is_added': !project.isFavorite
            }
          });
          const projects = this.get('accountProjects');
          const posProject = projects.findIndex(p => p.id === project.id);
          this.set(`accountProjects.${posProject}.isFavorite`, !project.isFavorite);

          if (project.isFavorite) {
            this.get('toast').success(this.get('intl').t('user.favorite_project.add'));
          } else {
            this.get('toast').success(this.get('intl').t('user.favorite_project.remove'));
          }
        } catch (error) {
          if (project.isFavorite) {
            this.get('toast').error(this.get('intl').t('user.favorite_project.add.error'));
          } else {
            this.get('toast').error(this.get('intl').t('user.favorite_project.remove.error'));
          }
        }
      }

    }
  });

  _exports.default = _default;
});