define("mytaskpanel/models/sprint", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    startDate: _emberData.default.attr(),
    endDate: _emberData.default.attr(),
    nameWithParent: _emberData.default.attr('string'),
    project: _emberData.default.belongsTo('project'),
    parentSprint: _emberData.default.belongsTo('sprint'),
    projectId: _emberData.default.attr('number'),
    parentSprintId: _emberData.default.attr('number'),
    tree: _emberData.default.attr('string'),
    recursiveLevel: _emberData.default.attr('number')
  });

  _exports.default = _default;
});