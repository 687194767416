define("mytaskpanel/helpers/get-data-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDataArray = getDataArray;
  _exports.default = void 0;

  /**
   * @method getDataArray
   * @description Get data from array
   * @param {*} params
   */
  function getDataArray(params) {
    let xarray = params[0];
    let xindex = params[1];
    let element = params[2];

    if (xarray) {
      if (element) {
        return xarray[xindex][element];
      } else {
        return xarray[xindex];
      }
    } else {
      return null;
    }
  }

  var _default = Ember.Helper.helper(getDataArray);

  _exports.default = _default;
});