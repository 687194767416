define("mytaskpanel/services/sprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    store: Ember.inject.service('store'),

    /**
     * @method getSprintsOfProject
     * @description Get iterations of the project
     * @param {*} projectId
     * @param {*} projectSlug
     * @param {*} currentSprintId
     */
    async getSprintsOfProject(projectId, projectSlug, currentSprintId) {
      const netSprints = [];
      const sprintsArray = [{
        id: projectSlug,
        // required
        parent: '#',
        // required
        text: projectSlug,
        // node text
        state: {
          opened: true,
          // is the node open
          disabled: false,
          // is the node disabled
          selected: false // is the node selected

        }
      }];

      try {
        // Get the sprints related to the project
        const response = await this.get('ajax').request(`/sprints/?project_id=${projectId}`, {
          method: 'GET'
        });
        const data = response.data; // Load the sprints in array for tree

        data.map(item => {
          const parentData = item.relationships["parent-sprint"].data;
          let selected = false; // Get selected sprint

          if (parseInt(currentSprintId) === parseInt(item.id)) {
            selected = true;
          }

          const node = {
            id: item.id,
            // required
            parent: parentData !== null ? parentData.id : projectSlug,
            // required
            text: item.attributes.name,
            // node text
            nameWithParent: item.attributes['name-with-parent'],
            // icon        : "mdi mdi-format-float-right", // string for custom
            state: {
              opened: true,
              // is the node open
              disabled: false,
              // is the node disabled
              selected: selected // is the node selected

            },
            //li_attr     : {},  // attributes for the generated LI node
            a_attr: {
              click: () => {} // attributes for the generated A node

            }
          };
          sprintsArray.push(node);
          netSprints.push(node);
        });
      } catch (e) {// Nothing
      }

      return {
        sprintsArray,
        netSprints
      };
    },

    async getLastIteration(projectId, projectSlug) {
      // Get the last iteration of sprint
      let sprintName = null;
      let currentSprintId = null;

      try {
        // Cheque if previously selected a sprint
        const sprintSelected = window.sessionStorage.getItem(`sprint_${projectId}`);

        if (sprintSelected === undefined || sprintSelected === null) {
          const responseCurrentSprint = await this.get('ajax').request('/get-current-iteration-sprint/?project_id=' + projectId, {
            method: 'GET'
          });
          sprintName = responseCurrentSprint.data.name;
          currentSprintId = responseCurrentSprint.data.id; // Save in storage

          this.saveSprintStorage(projectId, responseCurrentSprint.data);
        } else {
          const dataSprint = JSON.parse(sprintSelected);
          sprintName = dataSprint.name;
          currentSprintId = dataSprint.id;
        }
      } catch (e) {
        sprintName = this.get('intl').t('sprint.all.sprints'); // First value for currentSprint is the slug

        currentSprintId = projectSlug;
      }

      return {
        sprintName,
        currentSprintId
      };
    },

    /**
     * @method saveSprintStorage
     * @description Save in localStorage the last sprint selected
     * @param {*} projectId
     * @param {*} sprint
     */
    saveSprintStorage(projectId, sprint) {
      // Save in storage
      window.sessionStorage.setItem(`sprint_${projectId}`, JSON.stringify({
        'id': sprint.id,
        'name': sprint.name
      }));
    },

    /**
     * @method loadDataProject
     * @description Load data related to the project
     * @param {*} projectId
     * @param {*} sprintId
     */
    async loadDataProject(projectId, sprintId) {
      let data = null;

      try {
        let params = `?project_id=${projectId}`;

        if (sprintId !== null && sprintId !== undefined && sprintId !== '') {
          params += `&sprint_id=${sprintId}`;
        }

        const response = await this.get('ajax').request(`/get-data-task-project/${params}`, {
          method: 'GET'
        });
        data = response.data;
      } catch (e) {
        data = {
          total_tasks: 0,
          total_tasks_open: 0,
          total_tasks_close: 0,
          total_tasks_estimated_time: 0,
          total_tasks_remain_time: 0,
          days_to_close: 0,
          total_hours_by_project: 0,
          dates_sprint: ''
        };
      }

      return data;
    },

    /**
     *
     * @method showTasksBySprint
     * @description Show tasks of the sprint selected
     * @param projectId {int} Project id
     * @param sprintId {int} Sprint id
     * @param viewTasksSelected {string} type of view selected
     */
    async showTasksBySprint(projectId, sprintId, viewTasksSelected) {
      let tasksData; // Get the type filter

      let typeFilter = '';

      switch (viewTasksSelected) {
        case 'group':
          {
            typeFilter = 'alltasks_bygroup';
            break;
          }

        case 'user':
          {
            typeFilter = 'alltasks_byuser';
            break;
          }

        case 'state':
          {
            typeFilter = 'alltasks_bystate';
            break;
          }
      }

      if (parseInt(sprintId) >= 0) {
        tasksData = await this.get('store').query('task', {
          'type_filter': typeFilter,
          'sprint_id': sprintId,
          'project_id': parseInt(projectId)
        });
      } else {
        tasksData = await this.get('store').query('task', {
          'type_filter': typeFilter,
          'project_id': parseInt(projectId)
        });
      } // Fix inmutable data


      const tasks = [];
      tasksData.map(task => {
        tasks.push(task);
      });
      return tasks;
    },

    /**
     * @method checkIfSelectSprint
     * @description Check if the user select one sprint, if not get the first
     * @param {*} self Context of the component
     */
    checkIfSelectSprint(self) {
      let currentSprintId = self.get('parent.currentSprintId'); // If current sprint id is not a number, is the root.
      // For kanban project this condition not working

      if (isNaN(currentSprintId)) {
        const toast = self.get('toast');
        toast.warning(self.get('intl').t('task.must.belong.sprint'));
        const firstSprint = self.get('parent.netSprints')[0];
        self.set('parent.currentSprintId', firstSprint.id);
        currentSprintId = self.get('parent.currentSprintId');
      }

      return currentSprintId;
    }

  });

  _exports.default = _default;
});