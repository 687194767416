define("mytaskpanel/components/projects/edit-member-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),
    swal: Ember.inject.service('swal'),
    actions: {
      /**
      * @method editProjectUser
      * @description Edit project user action
      */
      async editProjectUser() {
        const userType = Ember.$('input[name=user_type_' + this.get('randomId') + ']:checked').val();
        this.projectUser.set('userType', userType);
        this.projectUser.save().then(() => {
          this.get('toast').success(this.get('intl').t('user.updated'));
          this.set('memberInList.userType', userType);
        }).catch(err => {
          this.get('swal').open({
            title: 'Error',
            text: err.errors.error,
            type: 'error'
          });
        });
        Ember.$('#modal_edit_project_user').modal('hide');
      }

    }
  });

  _exports.default = _default;
});