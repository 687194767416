define("mytaskpanel/components/utils/date-expired-client", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    days: 0,
    showMessageExpired: false,

    didInsertElement() {
      this._super();

      const user = this.get('session.data.authenticated.user'); // If is free not is necessary

      if (user.licence_type === 'F' && user.package.toLocaleLowerCase() === 'free') {
        this.set('showMessageExpired', false);
        return;
      }

      const today = (0, _moment.default)();
      const dateExpiredClient = (0, _moment.default)(user.date_expired_client);
      const daysDiff = dateExpiredClient.diff(today, 'days');
      let checkDays = 0; // Free

      if (user.licence_type === 'F') {
        checkDays = 30;
      } else {
        checkDays = 20;
      } // Check if it shows the message of if it will expire


      if (daysDiff >= 0) {
        if (daysDiff > checkDays) {
          this.set('showMessageExpired', false);
        } else {
          this.set('showMessageExpired', true);
        }
      } else {
        this.set('showMessageExpired', false);
      }

      this.set('days', daysDiff);
    }

  });

  _exports.default = _default;
});