define("mytaskpanel/components/inbox/inbox-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    checkChart: Ember.observer('parent.loadChart', function () {
      this.setChartPercentage();
    }),

    /**
    * @method setChartPercentage
    * @description Set the inicial data to the inbox Chart
    */
    setChartPercentage() {
      const expiredTasksLength = this.get('parent.expiredTasksLength');
      const plannedTasksLength = this.get('parent.plannedTasksLength');
      const withoutPlannedTasksLength = this.get('parent.withoutPlannedTasksLength');
      this.set('totalTasks', this.get('parent.totalTasks'));
      const percExpired = parseInt(expiredTasksLength);
      const percPlanned = parseInt(plannedTasksLength);
      const percWithoutPlanned = parseInt(withoutPlannedTasksLength);
      const expiredLabel = this.get('intl').t('task.expired');
      const plannedLabel = this.get('intl').t('task.planned');
      const withoutPlanningLabel = this.get('intl').t('task.without_planning');
      const config = {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [percExpired, percPlanned, percWithoutPlanned],
            backgroundColor: ['#F44336', '#FFCA28', '#BDBDBD']
          }],
          labels: [expiredLabel, plannedLabel, withoutPlanningLabel]
        },
        options: {
          responsive: true,
          legend: {
            display: false
          },
          animation: {
            animateScale: true,
            animateRotate: true
          }
        }
      };
      var ctx = document.getElementById('chart-area').getContext('2d');
      new window.Chart(ctx, config);
    }

  });

  _exports.default = _default;
});