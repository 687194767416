define("mytaskpanel/components/labels/form-labels", ["exports", "mytaskpanel/data/colors"], function (_exports, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    toast: Ember.inject.service('toast'),
    swal: Ember.inject.service('swal'),
    action: '',
    colors: null,
    observerSearchFilterValue: Ember.observer('searchFilterValue', function () {
      let labels = [];
      const value = this.searchFilterValue.toLowerCase();

      if (value.trim() === '') {
        labels = this.get('store').peekAll('label');
      } else {
        labels = this.get('store').peekAll('label').filter(l => {
          const label = l.get('name').toLowerCase();
          return label.includes(value);
        });
      }

      this.set('labels', labels);
    }),

    didInsertElement() {
      this._super();

      this.set('colors', _colors.default);
    },

    actions: {
      /**
      * @method showForm
      * @description form label
      * @param {*} action
      * @param {*} id Identification label
      */
      async showForm(action, id) {
        this.set('action', action); // Action edit or create

        if (action === 'create') {
          const label = this.get('store').createRecord('label', {
            name: '',
            color: ''
          });
          this.set('label', label);
        } else {
          // Get user
          const label = await this.get('store').find('label', id); // Set user

          this.set('label', label);
        } // Show modal


        Ember.$('#modal_edit_label').modal('show');
      },

      /**
      * @method deleteLabel
      * @description Delete label
      * @param {*} id Identification label
      */
      async deleteLabel(id) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('delete.question');
        const labelId = id;
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          // Delete record
          const label = await this.get('store').findRecord('label', labelId);
          label.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error',
              allowOutsideClick: false
            }).then(() => {
              window.location.reload();
            });
          });
        }
      },

      /**
      * @method createLabel
      * @description create label action
      */
      async createLabel() {
        if (Ember.isPresent(this.label.name) && Ember.isPresent(this.label.color)) {
          this.label.save().then(() => {
            this.get('toast').success(this.get('intl').t('label.created')); // Send message that has one new label

            const bc = new BroadcastChannel('add_label');
            bc.postMessage(this.label.id);
          }).catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err,
              type: 'error'
            });
          });
          Ember.$('#modal_edit_label').modal('hide');
        }
      },

      /**
      * @method editLabel
      * @description Edit label action
      */
      async editLabel() {
        if (Ember.isPresent(this.label.name) && Ember.isPresent(this.label.color)) {
          this.label.save().then(() => {
            this.get('toast').success(this.get('intl').t('label.updated'));
          }).catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          });
          Ember.$('#modal_edit_label').modal('hide');
        }
      },

      /**
      * @method selectColor
      * @description Select color
      * @param {*} v Value select
      */
      selectColor(v) {
        this.label.set('color', v);
      }

    }
  });

  _exports.default = _default;
});