define("mytaskpanel/components/users/list-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    swal: Ember.inject.service('swal'),
    randomId: 'list-users',
    observerSearchFilterValue: Ember.observer('searchFilterValue', function () {
      let users = [];
      const value = this.searchFilterValue.toLowerCase();

      if (value.trim() === '') {
        users = this.get('store').peekAll('user');
      } else {
        users = this.get('store').peekAll('user').filter(us => {
          const user = us.get('name').toLowerCase();
          return user.includes(value);
        });
      }

      this.set('users', users);
    }),
    actions: {
      /**
      * @method showEditForm
      * @description Redirect to to edit form user
      * @param {*} id Identification user
      */
      async showEditForm(id) {
        // Get user
        const user = await this.get('store').find('user', id); // Set user

        this.set('user', user); // Set user type

        const userType = this.get('user.userType');
        Ember.$('[name=user_type_' + this.get('randomId') + ']').filter('[value="' + userType + '"]').prop('checked', true); // Show modal

        Ember.$("#modal_edit_user").modal('show');
      },

      /**
      * @method deleteUser
      * @description Delete user
      */
      async deleteUser(id) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('delete.question');
        const userId = id;
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          // Delete record
          const user = await this.get('store').findRecord('user', userId);
          user.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error',
              allowOutsideClick: false
            }).then(() => {
              window.location.reload();
            });
          });
        }
      },

      /**
      * @method showModalInvite
      * @description Show modal
      */
      showModalInvite() {
        Ember.$("#invitation_user").modal('show');
      }

    }
  });

  _exports.default = _default;
});