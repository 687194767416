define("mytaskpanel/components/timesheet/list-summary", ["exports", "mytaskpanel/components/timesheet/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    toast: Ember.inject.service('toast'),
    timesheetService: Ember.inject.service('timesheet'),
    showLoading: false,
    totalHours: 0,
    userSelected: null,
    typeDateSelected: 'month',
    date: null,
    isAdminOrOwner: false,
    filterUser: false,
    disabledButtons: Ember.computed('date', 'userSelected', {
      get() {
        if (Ember.isPresent(this.userSelected) && Ember.isPresent(this.date)) {
          return false;
        } else {
          return true;
        }
      }

    }),
    dateObserver: Ember.observer('date', function () {
      this.send('filter');
    }),
    userSelectedObserver: Ember.observer('userSelected', function () {
      this.send('filter');
    }),
    typeDateSelectedObserver: Ember.observer('typeDateSelected', async function () {
      if (Ember.isPresent(this.get('date')) && this.get('isAdminOrOwner')) {
        const filterUser = this.get('filterUser');
        const users = await this.timesheetService.getUsersWithHours(filterUser, this.typeDateSelected, this.date);
        this.set('users', users);
      }
    }),

    didInsertElement() {
      this._super();

      const userType = this.get('session.data.authenticated.user').user_type;
      this.set('isAdminOrOwner', userType === 'O' || userType === 'A' ? true : false);
      this.set('summary', []);
    },

    actions: {
      /**
       * @method filter
       * @description Filter data grid
       */
      async filter() {
        if (!Ember.isPresent(this.get('date'))) {
          return false;
        }

        const user = this.get('userSelected');
        const {
          dateFrom,
          dateTo
        } = (0, _utils.getDateParams)(this.get('typeDateSelected'), this.get('date'));

        if (Ember.isPresent(user) && Ember.isPresent(dateFrom)) {
          this.set('showLoading', true); // Get data

          const response = await this.get('ajax').request('/summary-timesheet', {
            method: 'GET',
            data: {
              user: user,
              date_from: dateFrom,
              date_to: dateTo
            }
          });
          this.set('summary', response.data);
          let totalHours = 0;
          response.data.map(record => {
            totalHours += record.total;
          });
          this.set('totalHours', totalHours);
          this.set('showLoading', false);
        } else {
          this.set('summary', []);
          this.set('totalHours', 0);
        }
      },

      /**
       * @method exportAllUsers
       * @description Export all users
       */
      async exportAllUsers() {
        try {
          this.set('showLoading', true);
          const {
            dateFrom,
            dateTo
          } = (0, _utils.getDateParams)(this.get('typeDateSelected'), this.get('date'));
          const days = this.get('timesheetService').initTimesheet(this.get('typeDateSelected'));
          await this.get('ajax').request('/summary-timesheet/export-all-users/', {
            method: 'POST',
            data: {
              date_from: dateFrom,
              date_to: dateTo,
              days: JSON.stringify(days)
            }
          });
          this.set('showLoading', false);
          const toast = this.get('toast');
          toast.success(this.get('intl').t('timesheet.export.async.message'));
        } catch (e) {
          this.set('showLoading', false);
          const toast = this.get('toast');
          toast.error(this.get('intl').t('timesheet.export.error'));
        }
      },

      /**
       * @method exportAllProjects
       * @description Export all projects
       */
      async exportAllProjects() {
        try {
          this.set('showLoading', true);
          const {
            dateFrom,
            dateTo
          } = (0, _utils.getDateParams)(this.get('typeDateSelected'), this.get('date'));
          const days = this.get('timesheetService').initTimesheet(this.get('typeDateSelected'));
          await this.get('ajax').request('/summary-timesheet/export-all-projects/', {
            method: 'POST',
            data: {
              date_from: dateFrom,
              date_to: dateTo,
              days: JSON.stringify(days)
            }
          });
          this.set('showLoading', false);
          const toast = this.get('toast');
          toast.success(this.get('intl').t('timesheet.export.async.message'));
        } catch (e) {
          this.set('showLoading', false);
          const toast = this.get('toast');
          toast.error(this.get('intl').t('timesheet.export.error'));
        }
      },

      /**
       * @method exportData
       * @description Export data user
       */
      async exportData() {
        try {
          this.set('showLoading', true);
          const {
            dateFrom,
            dateTo
          } = (0, _utils.getDateParams)(this.get('typeDateSelected'), this.get('date'));
          const days = this.get('timesheetService').initTimesheet(this.get('typeDateSelected'));
          const response = await this.get('ajax').request('/summary-export-timesheet/', {
            method: 'POST',
            dataType: 'binary',
            xhr: () => {
              const myXhr = Ember.$.ajaxSettings.xhr();
              myXhr.responseType = 'arraybuffer';
              return myXhr;
            },
            data: {
              user: this.get('userSelected'),
              date_from: dateFrom,
              date_to: dateTo,
              days: JSON.stringify(days)
            }
          });
          const blob = new Blob([response], {
            type: 'application/xlsx'
          });
          const downloadUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          const userId = this.get('userSelected');
          const user = this.get('users').filter(u => parseInt(u.id) === parseInt(userId))[0];
          const filename = user['name'].toLocaleLowerCase().replaceAll(' ', '_');
          a.download = filename + '.xlsx';
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          this.set('showLoading', false);
        } catch (e) {
          this.set('showLoading', false);
          const toast = this.get('toast');
          toast.error(this.get('intl').t('timesheet.export.error'));
        }
      }

    }
  });

  _exports.default = _default;
});