define("mytaskpanel/components/tasks/task-dashboard", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*global Morris */

  /*global Chartist */
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),
    // API Response
    response: null,
    showLoadingSpinner: false,
    // Project duration data
    elapsed: 0,
    remaining: 0,
    elapsedPercentage: 0,
    remainingPercentage: 0,
    // Tasks assignment data
    assignedTasks: 0,
    unassignedTasks: 0,
    assignedTasksPercentage: 0,
    unassignedTasksPercentage: 0,
    // All tasks state data - Project Summary Chart
    completedTasks: 0,
    delayedTasks: 0,
    plannedTasks: 0,
    noDateTasks: 0,
    completedTasksPercentage: 0,
    delayedTasksPercentage: 0,
    plannedTasksPercentage: 0,
    noDateTasksPercentage: 0,
    // Data tasks by group
    dataTasksByGroup: null,
    // All tasks state data - All tasks chart
    completedAllTasks: 0,
    delayedAllTasks: 0,
    plannedAllTasks: 0,
    noDateAllTasks: 0,
    completedAllTasksPercentage: 0,
    delayedAllTasksPercentage: 0,
    plannedAllTasksPercentage: 0,
    noDateAllTasksPercentage: 0,
    // Incomplete tasks data
    notCompletedTasks: 0,
    notCompletedTasksPercentage: 0,
    // Filter options data
    tagsOptions: null,
    groupsOptions: null,

    /**
     * @var duration - Project duration in days
     * @constant elapsed - Project elapsed time in days
     * @constant remaining - Project remaining time in days
     * @constant elapsedPercentage - Percentage of the elapsed time
     * @constant remainingPercentage - Percentage of the remaining time
     */
    didInsertElement() {
      this._super();

      const users = [];
      const user = this.get('session.data.authenticated.user');
      let userType = user.user_type;
      const accountUser = this.get('accountUser');
      const projectUser = this.get('projectUser');

      if (projectUser) {
        userType = projectUser.userType;
      } else if (accountUser) {
        userType = accountUser.userType;
      }

      this.set('userType', userType);

      if (userType === 'O' || userType === 'A') {
        this.get('users').forEach(u => {
          users.push({
            id: u.id,
            name: u.name
          });
        });
      } else {
        users.push({
          id: user.id,
          name: this.get('intl').t('users.me')
        });
      }

      this.set('userType', userType);
      this.set('users', users);
      const project = this.get('project');

      if (project.startDate && project.dueDate) {
        const isFinishedProject = project.endDate !== undefined && project.endDate !== null;
        let elapsed;

        if (isFinishedProject) {
          elapsed = (0, _moment.default)(project.endDate).diff((0, _moment.default)(project.startDate), 'days');
        } else {
          elapsed = (0, _moment.default)().diff((0, _moment.default)(project.startDate), 'days');
        } // Check if use the endDate or today


        let remaining;

        if (project.endDate !== undefined && project.endDate !== null) {
          remaining = (0, _moment.default)(project.dueDate).diff(project.endDate, 'days');
        } else {
          remaining = (0, _moment.default)(project.dueDate).diff((0, _moment.default)(), 'days');
        }

        const duration = (0, _moment.default)(project.dueDate).diff(project.startDate, 'days');
        const elapsedPercentage = (elapsed / duration * 100).toFixed();
        const remainingPercentage = Math.abs(100 - elapsedPercentage);
        this.set('elapsed', elapsed);
        let colorStat = '';

        if (isFinishedProject) {
          this.set('isRemaining', false);
        } else {
          this.set('isRemaining', remaining > 0);
          colorStat = this.getColorDashboard(this.get('isRemaining'), remaining, false);
        }

        let dueDateColorStat = this.getColorDashboard(this.get('isRemaining'), remaining, isFinishedProject);
        this.set('dueDateColorStat', dueDateColorStat);
        this.set('colorStat', colorStat);
        this.set('remainingOriginal', remaining);
        this.set('remaining', Math.abs(remaining));
        this.set('elapsedPercentage', elapsedPercentage);
        this.set('remainingPercentage', remainingPercentage);
      } else {
        this.set('elapsed', 0);
        this.set('isRemaining', false);
        this.set('remaining', 0);
        this.set('remainingOriginal', 0);
        this.set('dueDateColorStat', '');
        this.set('colorStat', '');
        this.set('elapsedPercentage', 0);
        this.set('remainingPercentage', 0);
      } // Project Summary Chart


      this.get('dataGraphic').task_status.forEach(status => {
        switch (status.state) {
          case 'C':
            this.set('completedTasks', status.total_state);
            this.set('completedAllTasks', status.total_state);
            break;

          case 'E':
            this.set('delayedTasks', status.total_state);
            this.set('delayedAllTasks', status.total_state);
            break;

          case 'P':
            this.set('plannedTasks', status.total_state);
            this.set('plannedAllTasks', status.total_state);
            break;

          case 'R':
            this.set('noDateTasks', status.total_state);
            this.set('noDateAllTasks', status.total_state);
            break;
        }
      });
      const totalStatusTasks = this.get('completedTasks') + this.get('delayedTasks') + this.get('plannedTasks') + this.get('noDateTasks');

      if (totalStatusTasks != 0) {
        this.set('completedTasksPercentage', Math.ceil(this.get('completedTasks') / totalStatusTasks * 100));
        this.set('delayedTasksPercentage', Math.ceil(this.get('delayedTasks') / totalStatusTasks * 100));
        this.set('plannedTasksPercentage', Math.ceil(this.get('plannedTasks') / totalStatusTasks * 100));
        this.set('noDateTasksPercentage', Math.ceil(this.get('noDateTasks') / totalStatusTasks * 100));
        this.set('notCompletedTasks', totalStatusTasks - this.get('completedTasks'));
        this.set('notCompletedTasksPercentage', 100 - this.get('completedTasksPercentage'));
      } else {
        this.set('completedTasksPercentage', 0);
        this.set('delayedTasksPercentage', 0);
        this.set('plannedTasksPercentage', 0);
        this.set('noDateTasksPercentage', 0);
        this.set('notCompletedTasks', 0);
        this.set('notCompletedTasksPercentage', 0);
      }

      Ember.$('#dashboard-progress-bar-completed').attr('style', `width:${this.get('completedTasksPercentage')}%`);
      Ember.$('#dashboard-progress-bar-delayed').attr('style', `width:${this.get('delayedTasksPercentage')}%`);
      Ember.$('#dashboard-progress-bar-planned').attr('style', `width:${this.get('plannedTasksPercentage')}%`);
      Ember.$('#dashboard-progress-bar-no-date').attr('style', `width:${this.get('noDateTasksPercentage')}%`);
      this.set('response', this.get('dataGraphic'));
      this.loadOptions();
      this.loadCharts();
    },

    /**
     * @method getColorDashboard
     * @description Get color of dashboard
     */
    getColorDashboard(conditional, remaining, isFinishedProject) {
      let colorStat = '';

      if (conditional) {
        if (!isFinishedProject) {
          if (remaining > 10) {
            colorStat = 'background-green';
          } else if (remaining >= 0 && remaining < 10) {
            colorStat = 'background-orange';
          }
        }
      } else {
        if (remaining < 0) {
          colorStat = 'background-red';
        }
      }

      return colorStat;
    },

    /**
     * @method loadData
     * @description Load data graphic from backend
     */
    async loadData() {
      const data = {
        project_id: this.get('project.id')
      };

      if (Ember.$('#datepicker-vencimiento').val() !== '') {
        data.due_date = Ember.$('#datepicker-vencimiento').val();
      }

      if (Ember.$('#inputUser').val() !== '' && Ember.$('#inputUser').val() !== null) {
        data.user_id = Ember.$('#inputUser').val();
      }

      if (Ember.$('#estado').val() !== '') {
        data.task_state = Ember.$('#estado').val().toString();
      }

      if (Ember.$('#sprintId').val() !== '' && Ember.$('#sprintId').val() !== undefined) {
        data.sprint_id = Ember.$('#sprintId').val().toString();
      }

      if (Ember.$('#inputTaskGroup').val() !== '') {
        data.task_group_id = Ember.$('#inputTaskGroup').val().toString();
      }

      if (Ember.$('#inputTag').val() !== '') {
        data.label_id = Ember.$('#inputTag').val().toString();
      }

      const toast = this.get('toast');

      try {
        const response = await this.get('ajax').request('/task-dashboard/', {
          method: 'POST',
          data: data
        });
        this.set('response', response.data);
        this.set('showLoadingSpinner', false);
        this.loadCharts();
      } catch (e) {
        toast.error(this.get('intl').t('dashboard.error'));
      }
    },

    /**
     * @method loadOptions
     * @description Load the <select> filter options.
     */
    loadOptions() {
      const tagOptions = [];
      const groupOptions = [];

      this.get('labels')._arrangedContent.forEach(tag => {
        tagOptions.push({
          id: tag.__recordData.id,
          name: tag.__recordData.__data.name
        });
      });

      this.set('tagsOptions', tagOptions);
      this.get('groups').map(group => {
        groupOptions.push({
          id: group.get('id'),
          name: group.get('name')
        });
      });
      this.set('groupsOptions', groupOptions);
    },

    /**
     * @method loadCharts
     * @description Load the charts data
     */
    loadCharts() {
      this.loadTasksByGroupChart();
      this.loadAllTasksChart();

      if (this.get('userType') !== 'I') {
        this.loadTaskAssignmentChart();
        this.loadTasksByTagChart();
        this.loadProductivityChart();
      }
    },

    /**
    * @method loadTasksByGroupChart
    * @description Load tasks by group
    */
    loadTasksByGroupChart() {
      Ember.$('#donut_mio').children().remove();
      const data = [];
      const colors = [];
      const dataTasksByGroup = [];
      let totalTasks = 0;
      this.get('response').task_group.forEach(group => {
        data.push({
          value: group.total_group,
          label: group.name
        });
        colors.push(group.color);
        totalTasks += group.total_group;
        dataTasksByGroup.push({
          total: group.total_group,
          name: group.name,
          color: group.color,
          percentage: 0
        });
      });
      dataTasksByGroup.forEach(group => {
        group.percentage = Math.ceil(group.total * 100 / totalTasks);
      });
      this.set('dataTasksByGroup', dataTasksByGroup);
      Morris.Donut({
        element: 'donut_mio',
        data: data,
        labelColor: '#333',
        colors: colors,
        formatter: function (x) {
          return Math.ceil(x * 100 / totalTasks) + '%';
        }
      });
    },

    /**
    * @method loadAllTasksChart
    * @description Load all tasks
    */
    loadAllTasksChart() {
      Ember.$('#morris-donut-example2').children().remove();
      this.get('response').task_status.forEach(status => {
        switch (status.state) {
          case 'C':
            this.set('completedAllTasks', status.total_state);
            break;

          case 'E':
            this.set('delayedAllTasks', status.total_state);
            break;

          case 'P':
            this.set('plannedAllTasks', status.total_state);
            break;

          case 'R':
            this.set('noDateAllTasks', status.total_state);
            break;
        }
      });
      const tasksStatus = [];
      this.get('response').task_status.forEach(status => {
        tasksStatus.push(status.state);
      });

      if (!tasksStatus.includes('C')) {
        this.set('completedAllTasks', 0);
      }

      if (!tasksStatus.includes('E')) {
        this.set('delayedAllTasks', 0);
      }

      if (!tasksStatus.includes('P')) {
        this.set('plannedAllTasks', 0);
      }

      if (!tasksStatus.includes('R')) {
        this.set('noDateAllTasks', 0);
      }

      const totalStatusTasks = this.get('completedAllTasks') + this.get('delayedAllTasks') + this.get('plannedAllTasks') + this.get('noDateAllTasks');

      if (totalStatusTasks != 0) {
        this.set('completedAllTasksPercentage', Math.ceil(this.get('completedAllTasks') / totalStatusTasks * 100));
        this.set('delayedAllTasksPercentage', Math.ceil(this.get('delayedAllTasks') / totalStatusTasks * 100));
        this.set('plannedAllTasksPercentage', Math.ceil(this.get('plannedAllTasks') / totalStatusTasks * 100));
        this.set('noDateAllTasksPercentage', Math.ceil(this.get('noDateAllTasks') / totalStatusTasks * 100));
      } else {
        this.set('completedAllTasksPercentage', 0);
        this.set('delayedAllTasksPercentage', 0);
        this.set('plannedAllTasksPercentage', 0);
        this.set('noDateAllTasksPercentage', 0);
      }

      Morris.Donut({
        element: 'morris-donut-example2',
        data: [{
          value: this.get('completedAllTasksPercentage'),
          label: Ember.$('#dashboard-all-tasks-graphic-completed').text()
        }, {
          value: this.get('delayedAllTasksPercentage'),
          label: Ember.$('#dashboard-all-tasks-graphic-delayed').text()
        }, {
          value: this.get('plannedAllTasksPercentage'),
          label: Ember.$('#dashboard-all-tasks-graphic-planned').text()
        }, {
          value: this.get('noDateAllTasksPercentage'),
          label: Ember.$('#dashboard-all-tasks-graphic-no-date').text()
        }],
        labelColor: '#333',
        colors: ['#6c757d', '#ef5350', '#f1b53d', '#67D1F7'],
        formatter: function (x) {
          return x + "%";
        }
      });
    },

    /**
    * @method loadTaskAssignmentChart
    * @description Load tasks by assignment
    */
    loadTaskAssignmentChart() {
      if (this.get('response').task_assigned.length > 0) {
        if (this.get('response').task_assigned.length == 2) {
          this.set('assignedTasks', this.get('response').task_assigned[0].total_task);
          this.set('unassignedTasks', this.get('response').task_assigned[1].total_task);
        } else if (this.get('response').task_assigned.length == 1) {
          if (this.get('response').task_assigned[0].assignation == 'unassigned') {
            this.set('unassignedTasks', this.get('response').task_assigned[0].total_task);
            this.set('assignedTasks', 0);
          } else {
            this.set('assignedTasks', this.get('response').task_assigned[0].total_task);
            this.set('unassignedTasks', 0);
          }
        }

        this.set('assignedTasksPercentage', (this.get('assignedTasks') / (this.get('assignedTasks') + this.get('unassignedTasks')) * 100).toFixed());
        this.set('unassignedTasksPercentage', (this.get('unassignedTasks') / (this.get('assignedTasks') + this.get('unassignedTasks')) * 100).toFixed());
        const data = {
          series: [this.get('assignedTasks'), this.get('unassignedTasks')]
        };

        const sum = (a, b) => {
          return a + b;
        };

        Chartist.Pie('#simple-pie', data, {
          labelInterpolationFnc: function (value) {
            return Math.round(value / data.series.reduce(sum) * 100) + '%';
          }
        });
      } else {
        this.set('assignedTasks', 0);
        this.set('unassignedTasks', 0);
        this.set('assignedTasksPercentage', 0);
        this.set('unassignedTasksPercentage', 0);
        Ember.$('#simple-pie').children().remove();
      }
    },

    /**
    * @method loadTasksByTagChart
    * @description Load tasks by tag
    */
    loadTasksByTagChart() {
      const tags = [];
      const tasksByTags = [];
      this.get('response').task_label.forEach(task => {
        tags.push(task.name);
        tasksByTags.push(task.total);
      });
      Chartist.Bar('#etiquetashorizontal', {
        labels: tags,
        series: [tasksByTags]
      }, {
        seriesBarDistance: 10,
        reverseData: true,
        horizontalBars: true,
        axisY: {
          offset: 70
        }
      });
    },

    /**
    * @method loadProductivityChart
    * @description Load tasks by productivity
    */
    loadProductivityChart() {
      const usersProductivity = [];
      const spProductivity = [];
      this.get('response').task_user.forEach(user => {
        usersProductivity.push(user.username.split('@')[0]);
        spProductivity.push(user.total_sp);
      });
      Chartist.Bar('#productivChar', {
        labels: usersProductivity,
        series: [spProductivity]
      }, {
        seriesBarDistance: 10,
        axisX: {
          offset: 60
        },
        axisY: {
          offset: 80,
          labelInterpolationFnc: function (value) {
            return value + ' SP';
          },
          scaleMinSpace: 15
        }
      });
    },

    actions: {
      /**
       * @method applyFilter
       * @description Change the charts data according to the selected filters
       *
       * @var hasChanged - Indicates if filters that omit some graphics have not been used
       */
      async applyFilter() {
        this.set('showLoadingSpinner', true);
        await this.loadData();
        this.set('showLoadingSpinner', false);
      },

      /**
      * @method resetFilters
      * @description Reset filters
      */
      resetFilters() {
        const htmlObjects = ['#sprintId', '#estado', '#inputTag', '#datepicker-vencimiento', '#inputTaskGroup'];
        htmlObjects.map(id => {
          Ember.$(id).val('');
        });
        Ember.$('#inputUser').val(null);
        this.send('applyFilter');
      }

    }
  });

  _exports.default = _default;
});