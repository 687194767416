define("mytaskpanel/routes/clients", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    clients: Ember.inject.service('clients'),
    session: Ember.inject.service('session'),

    beforeModel() {
      // If hast client_id redirect to accounts if not
      // is login the route accounts redirect to login
      const clientId = this.get('session.data.authenticated.user').client;

      if (clientId !== null) {
        this.transitionTo('accounts');
      }
    },

    async model() {
      const response = await this.get('clients').getClients();
      return response.data;
    }

  });

  _exports.default = _default;
});