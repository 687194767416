define("mytaskpanel/components/tasks/form-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    labelsService: Ember.inject.service('labels'),
    swal: Ember.inject.service('swal'),
    subTasks: null,
    showItemNewSubTask: false,
    summernoteDescription: '#TaskSummernoteDescription',
    subTaskPercentage: 0,
    formReadonly: false,
    // Load labels in input form
    taskLabelsLoad: Ember.observer('parent.taskLabels', function () {
      this.didReceiveAttrs();
      Ember.run.later(() => {
        Ember.$('.tokenize-labels').tokenize2();
        this.get('parent.taskLabels').map(item => {
          if (Ember.$('.tokenize-labels').tokenize2()) {
            Ember.$('.tokenize-labels').tokenize2().trigger('tokenize:tokens:add', [item.id, item.name, true]);
          }
        });
        const self = this;
        Ember.$('.tokenize-labels').on('tokenize:tokens:remove', (event, labelId) => {
          Ember.run(() => {
            try {
              const l = self.get('parent.taskLabels').find(x => x.id === labelId);
              l['delete'] = true;
            } catch (e) {// Nothing
            }
          });
        });
      }, 100);
    }),
    formTask: Ember.observer('task', function () {
      const userId = this.get('session.data.authenticated.user').id;

      if (this.userType === 'I' || this.userType === 'M' && parseInt(userId) !== parseInt(this.get('task.userId'))) {
        this.set('formReadonly', true);
      } else {
        this.set('formReadonly', false);
      }
    }),
    // Load followers in input form
    followersLoad: Ember.observer('parent.followers', function () {
      Ember.run.later(() => {
        Ember.$('.tokenize-followers').tokenize2();
        this.get('parent.followers').map(item => {
          if (Ember.$('.tokenize-followers').tokenize2() !== undefined) {
            Ember.$('.tokenize-followers').tokenize2().trigger('tokenize:tokens:add', [item.id, item.name, true]);
          }
        });
        const self = this;
        Ember.$('.tokenize-followers').on('tokenize:tokens:remove', (event, followerId) => {
          Ember.run(() => {
            try {
              const f = self.get('parent.followers').find(x => x.id === followerId);
              f['delete'] = true;
            } catch (e) {// Nothing
            }
          });
        });
      }, 100);
    }),
    // Load subtasks in grid form
    loadSubTasks: Ember.observer('parent.subTasks', function () {
      this.set('subTasks', []);
      const subTasks = this.get('parent.subTasks'); // Add subTask

      subTasks.map(subTask => {
        this.subTasks.pushObject(subTask);
      });
      const subTaskCompleted = this.subTasks.filter(t => {
        return t.completed === true;
      });
      this.set('subTaskCompleted', subTaskCompleted);
      const percentage = parseInt(subTaskCompleted.length * 100 / subTasks.length);
      this.set('subTaskPercentage', isNaN(percentage) ? 0 : percentage);
    }),

    didReceiveAttrs() {
      this._super();

      if (this.get('task') !== null) {
        const usersResult = this.get('parent').users.filter(user => parseInt(user.id) === this.get('task').userId);

        if (usersResult.length > 0) {
          this.set('taskUserName', usersResult[0].name);
        } else {
          this.set('taskUserName', '');
        }

        const labels = this.get('task').taskLabels;

        if (labels.length > 0) {
          this.set('taskLabels', labels);
        } else {
          this.set('taskLabels', []);
        }
      }
    },

    didInsertElement() {
      this._super(); // Check id add new label then add to list


      this.get('labelsService').addLabelToObject('parent.labels', this); // Init components

      this.initComponents();
    },

    /**
    * @method initComponents
    * @description Init components
    */
    initComponents() {
      // Set summernote to textarea to description
      Ember.$(this.summernoteDescription).summernote({
        minHeight: 120,
        focus: true,
        disableDragAndDrop: true,
        toolbar: [['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['insert', ['link']]]
      });
    },

    /**
    * @method saveTags
    * @description Save followers and labels
    * @param {*} task
    */
    saveTags(task) {
      const tags = [{
        'name': 'labels',
        'model': 'task-label',
        'variable': 'taskLabels',
        'model-main': 'label'
      }, {
        'name': 'followers',
        'model': 'follower',
        'variable': 'followers',
        'model-main': 'user'
      }];
      tags.map(tag => {
        const items = Ember.$('.tokenize-' + tag['name']).val() || [];

        if (items.length > 0) {
          items.map(async item => {
            const checkExists = this.get('parent.' + tag['variable']).find(x => x.id === item);

            if (checkExists === undefined || checkExists === null) {
              const record = await this.get('store').find(tag['model-main'], item);
              let data;

              if (tag['name'] === 'labels') {
                data = {
                  task: task,
                  label: record
                };
              } else {
                data = {
                  task: task,
                  user: record
                };
              }

              const modelTag = this.get('store').createRecord(tag['model'], data);
              await modelTag.save();

              if (tag['variable'] === 'taskLabels') {
                const ts = modelTag;
                this.get('parent.' + tag['variable']).push({
                  id: ts.get('label.id'),
                  name: ts.get('nameLabel'),
                  delete: false,
                  pk: ts.get('id')
                });
              } else if (tag['variable'] === 'followers') {
                const fw = modelTag;
                this.get('parent.' + tag['variable']).push({
                  id: fw.get('user.id'),
                  name: fw.get('name'),
                  delete: false,
                  pk: fw.get('id')
                });
              }
            }
          });
        }
      });
    },

    /**
    * @method deleteTags
    * @description Delete followers and labels
    * @param {*} variable
    * @param {*} model
    */
    deleteTags(variable, model) {
      const records = this.get('parent.' + variable).filter(x => x.delete === true);
      records.map(async record => {
        const recordDelete = await this.get('store').findRecord(model, record.pk, {
          reload: true
        });
        this.get('parent.' + variable).removeObject(record);
        recordDelete.destroyRecord().catch(() => {// Error to delete
        });
      });
    },

    /**
    * @method deleteTask
    * @description Delete task in list
    * @param task Task to delete
    */
    deleteTask(task) {
      try {
        // Delete task in table kanban
        const taskGroupsTasks = this.get('parent.parent.taskGroupsTasks');
        let group;

        switch (this.get('parent.parent.viewTasksSelected')) {
          case 'group':
            {
              group = taskGroupsTasks.filter(x => parseInt(x.taskGroupId) === parseInt(task.taskGroupId));
              break;
            }

          case 'state':
            {
              group = taskGroupsTasks.filter(x => parseInt(x.taskGroupId) === parseInt(task.taskState));
              break;
            }

          case 'user':
            {
              group = taskGroupsTasks.filter(x => {
                let userId = 0;

                if (task.userId !== null) {
                  userId = parseInt(task.userId);
                }

                return parseInt(x.taskGroupId) === userId;
              });
              break;
            }
        }

        group[0].tasks.removeObject(task);
      } catch (e) {
        this.get('parent.updateTasks')();
      }
    },

    actions: {
      /**
      * @method assignedToMe
      * @description Assigned to me
      */
      assignedToMe() {
        const userId = this.get('session.data.authenticated.user').id;
        this.get('task').set('userId', userId);
      },

      /**
      * @method saveTask
      * @description Save task from the form
      */
      async saveTask() {
        const toast = this.get('toast');
        const t = this.get('task');
        const startDate = Ember.$('#start_date_form_task').val();
        const endDate = Ember.$('#end_date_form_task').val();
        const dueDate = Ember.$('#due_date_form_task').val();
        t.set('startDate', startDate ? startDate : null);
        t.set('endDate', endDate ? endDate : null);
        t.set('dueDate', dueDate ? dueDate : null);

        if (startDate) {
          t.set('taskState', '1');
        }

        if (endDate) {
          t.set('taskState', '2');
        } // Get taskGroupId


        const originalTaskGroupId = t.get('taskGroupId');
        const taskGroupId = parseInt(Ember.$("#task-group-form-task").val());
        const taskGroup = await this.get('store').find('task-group', taskGroupId);
        t.set('taskGroup', taskGroup);
        t.set('taskGroupId', taskGroupId); // Check if move the task (only for view group)

        if (this.get('parent.parent.viewTasksSelected') === 'group') {
          if (originalTaskGroupId !== taskGroupId) {
            const originalTg = this.get('parent.parent.taskGroupsTasks').filter(t => t.taskGroupId === originalTaskGroupId)[0];
            const tg = this.get('parent.parent.taskGroupsTasks').filter(t => t.taskGroupId === taskGroupId)[0];
            originalTg.tasks.removeObject(t);
            tg.tasks.pushObject(t);
          }
        } // Get assigned


        const userId = Ember.$("#assigned-user-form-task").val();

        if (userId) {
          const user = await this.get('store').find('user', userId);
          t.set('user', user);
        } else {
          t.set('user', '');
        } // Set project


        const originalProjectId = t.projectId || (await this.get('t.project.id'));
        const projectId = parseInt(Ember.$("#project_form_task").val());
        const project = await this.get('store').find('project', projectId);
        t.set('project', project); // Get parent task

        const parentTaskId = Ember.$("#parent-task-form-task").val();

        if (parentTaskId !== '') {
          const tsk = await this.get('store').find('task', parentTaskId);
          t.set('parentTask', tsk);
        } else {
          t.set('parentTask', '');
        } // Get sprint


        let changeSprint = false;
        const sprintId = Ember.$('#sprint_form_task').val();
        const originalSprintId = t.sprintId || (await this.get('t.sprint.id'));

        if (sprintId !== '' && sprintId !== undefined && sprintId !== null) {
          const sprint = await this.get('store').find('sprint', parseInt(sprintId));
          t.set('sprint', sprint);
          t.set('sprintId', parseInt(sprint.id));

          if (parseInt(originalSprintId) !== parseInt(sprintId)) {
            changeSprint = true;
          }
        } else {
          t.set('sprint', null);
          t.set('sprintId', null);
        } // Get description


        t.set('description', Ember.$(this.summernoteDescription).summernote("code"));
        this.deleteTags('taskLabels', 'task-label');
        this.deleteTags('followers', 'follower'); // If change the project, set the task-group

        let changeProject = false;

        if (parseInt(originalProjectId) !== parseInt(projectId)) {
          const taskGroupsProject = await this.get('store').query('task-group', {
            'project_id': projectId
          }); // By default get the first task group

          const taskGroup = await this.get('store').find('task-group', taskGroupsProject.get('firstObject').id);
          t.set('taskGroup', taskGroup);
          changeProject = true;
        }

        t.save().then(async task => {
          // Clear summernote description
          Ember.$(this.summernoteDescription).summernote('code', ''); // Clear all links

          Ember.$(this.summernoteDescription).summernote('unlink'); // Save tasks

          this.saveTags(t); // Save labels and followers

          await this.parent.setTaskLabels(task);
          await this.parent.setTaskFollowers(task); // Update info data

          try {
            this.get('parent.parent.parent').send('loadDataProject');
          } catch (e) {// Nothing
          }

          Ember.run.later(() => {
            this.get('parent.updateTasks')();
          }, 1000); // If change the project or sprint, delete from list

          if (changeProject || changeSprint) {
            this.deleteTask(task);
          } // close modal task, clean object task


          this.set('task', null);
          toast.success(this.get('intl').t('task.update'));
        }).catch(() => {
          toast.error(this.get('intl').t('task.error'));
        });
        Ember.$('#wrapper').toggleClass('right-bar-enabled');
      },

      /**
      * @method deleteTask
      * @description Delete task
      * @param {task} object task (model task)
      */
      async deleteTask(task) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('task.delete.question');
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          // Delete record
          await task.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          }); // Delete task in table kanban

          this.deleteTask(task); // Update info data

          try {
            this.get('parent.parent.parent').send('loadDataProject');
          } catch (e) {} // Nothing
          // Clear all links


          Ember.$(this.summernoteDescription).summernote('unlink'); // Close modal

          Ember.$('#wrapper').toggleClass('right-bar-enabled');
        }
      },

      /**
      * @method showNewSubTask
      * @description Show new record to add subtask to task
      */
      showNewSubTask() {
        // Remove previous subtask new
        const subTaskEmpty = this.subTasks.filter(t => {
          return t.newItem === true;
        });

        if (subTaskEmpty.length > 0) {
          this.subTasks.removeObject(subTaskEmpty[0]);
        }

        this.set('showItemNewSubTask', true);
        this.subTasks.pushObject({
          name: '',
          completed: false,
          userPhoto: '',
          newItem: true
        });
      },

      /**
      * @method addSubTask
      * @description Add subtask to task
      */
      async addSubTask() {
        // Get new subtask
        const newSubTasks = this.subTasks.filter(obj => {
          return obj.newItem === true;
        });
        const item = newSubTasks[0];

        if (item.name !== '' && item.name !== null && item.name !== undefined) {
          // Params for subtask
          const task = this.get('task');
          item['user'] = parseInt(Ember.$("#assigned-user-form-subtask").val());
          let user = null;

          if (item.user !== undefined && item.user !== null && item.user !== '' && !isNaN(item.user)) {
            user = await this.get('store').find('user', item.user);
          } // Add new subtask


          const subTask = this.get('store').createRecord('sub-task', {
            task: task,
            user: user,
            name: item.name,
            completed: item.completed
          });
          await subTask.save(); // Reset subtasks

          const subTasks = await this.get('store').query('sub-task', {
            'task': task.id
          });
          this.set('subTasks', []); // Add subTask

          subTasks.map(subTask => {
            this.subTasks.pushObject(subTask);
          });
          this.set('showItemNewSubTask', false);
        }
      },

      /**
      * @method cancelNewSubTask
      * @description Cancel form for new subtask in task
      */
      cancelNewSubTask() {
        this.set('showItemNewSubTask', false);
        const newSubTasks = this.subTasks.filter(obj => {
          return obj.newItem !== true;
        });
        this.set('subTasks', newSubTasks);
      },

      /**
      * @method saveAndNew
      * @description Save and new
      */
      async saveAndNew() {
        this.send('addSubTask');
        Ember.run.later(() => {
          this.send('showNewSubTask');
        }, 1000);
      },

      /**
      * @method updateSubTask
      * @description Update subtask
      * @param {*} subTask
      */
      updateSubTask(subTask) {
        subTask.set('completed', !subTask.completed);
        subTask.save();
      },

      /**
      * @method deleteSubTask
      * @description Delete subtask
      * @param {*} subTask
      */
      deleteSubTask(subTask) {
        subTask.destroyRecord();
        this.subTasks.removeObject(subTask);
      }

    }
  });

  _exports.default = _default;
});