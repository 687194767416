define("mytaskpanel/services/account-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    /**
    * @method getSummaryTimesheetData
    * @description Get total hours by project and by user in a project
    */
    async getSummaryTimesheetData(typeDate, selectedMonth, selectedWeek) {
      let params = '';

      if (typeDate === 'month') {
        params = `?month=${selectedMonth}`;
      } else {
        params = `?week=${selectedWeek}`;
      }

      const response = await this.get('ajax').request(`/summary-timesheet-dashboard/${params}`, {
        method: 'GET'
      });
      const totalHoursUsers = response.data.total_hours_users;
      const projectsActivities = Ember.A();
      const ProjectActivityObject = Ember.Object.extend({
        selected: null,
        project_activity_name: null,
        total_hours_project: null,
        hours_by_user: null
      });
      response.data.projects_activities.forEach(project => {
        projectsActivities.pushObject(ProjectActivityObject.create({
          selected: false,
          project_activity_name: project.project_activity_name,
          total_hours_project: project.total_hours_project,
          hours_by_user: project.hours_by_user
        }));
      });
      const timesheetUsers = response.data.timesheet_users.map(user => {
        return {
          'user': user.email.split('@')[0],
          'totalHours': user.total_hours
        };
      });
      return {
        projectsActivities,
        timesheetUsers,
        totalHoursUsers
      };
    }

  });

  _exports.default = _default;
});