define("mytaskpanel/components/utils/modal-upgrade-plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service('-routing'),
    actions: {
      /**
      * @method redirectPrice
      * @description Redirect to price route to upgrade the plan
      */
      redirectPrice() {
        const router = this.get('router');
        Ember.$("#modal-upgrade-plan").modal('hide');
        router.router.transitionTo('price');
      }

    }
  });

  _exports.default = _default;
});