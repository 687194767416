define("mytaskpanel/helpers/account-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountColor = accountColor;
  _exports.default = void 0;

  /**
  * @method accountColor
  * @description Get color to show in item account
  * @param {*} params
  */
  function accountColor(params
  /*, hash*/
  ) {
    const color = params[0];
    let background = '';

    if (color === '' || color === undefined || color === null) {
      background = '#464f63';
    } else {
      background = color;
    }

    return background;
  }

  var _default = Ember.Helper.helper(accountColor);

  _exports.default = _default;
});