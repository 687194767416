define("mytaskpanel/components/utils/filter-month-week", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    filterHoursByWeek: false,

    didInsertElement() {
      this._super();

      Ember.$('#switch-type-date-hours').bootstrapToggle({
        on: this.get('intl').t('by_week'),
        off: this.get('intl').t('by_month')
      });
    }

  });

  _exports.default = _default;
});