define("mytaskpanel/components/utils/text-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super();

      this.set('originalTaskGroupName', this.get('text'));
    },

    actions: {
      toggleEditing(param, scape) {
        this.set('isEditing', param);

        if (scape) {
          this.set('text', this.get('originalTaskGroupName'));
        }
      }

    }
  });

  _exports.default = _default;
});