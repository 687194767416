define("mytaskpanel/components/gantt-line-inline-childs", ["exports", "@kockpit/ember-gantt/components/gantt-line-inline-childs"], function (_exports, _ganttLineInlineChilds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ganttLineInlineChilds.default;
    }
  });
});