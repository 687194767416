define("mytaskpanel/components/tasks/tasks-timeline", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    store: Ember.inject.service('store'),
    toast: Ember.inject.service('toast'),
    data: null,
    dayWidth: 25,

    // Zoom
    didReceiveAttrs() {
      this._super(); // Check if the project has startDate and dueDate


      if (Ember.isPresent(this.get('project.startDate') && Ember.isPresent(this.get('project.dueDate')))) {
        // Date to timeline, took the project dates
        this.set('viewStartDate', (0, _moment.default)(this.get('project.startDate')).toDate());
        this.set('viewEndDate', (0, _moment.default)(this.get('project.dueDate')).toDate()); // Loas the gantt

        this.loadData();
      } else {
        const toast = this.get('toast');
        toast.warning(this.get('intl').t('task.timeline.errorDates'));
      }
    },

    /**
     * @method loadData
     * @description Load data for timeline
     */
    loadData() {
      const data = Ember.A();
      let existsErrorsDate = false; // Object model for sprint object

      const SprintObject = Ember.Object.extend({
        id: null,
        name: null,
        startDate: null,
        endDate: null,
        tasks: null,
        color: null,
        collapsed: false
      }); // Object model for sprint object

      const TaskObject = Ember.Object.extend({
        title: null,
        dateStart: null,
        dateEnd: null,
        color: null
      }); // For project agile

      if (this.get('project.projectType') === 'P') {
        const errorsDates = [];
        this.get('sprints').map(s => {
          let startDateSprint = null; // Check if exists the start-date

          if (Ember.isPresent(s.attributes['start-date'])) {
            startDateSprint = s.attributes['start-date'];
          } else {
            // otherwise reported error (no sprint date)
            errorsDates.push(parseInt(s.id));
          }

          let endDateSprint = null; // Check if exists the end-date

          if (Ember.isPresent(s.attributes['end-date'])) {
            endDateSprint = s.attributes['end-date'];
          } else {
            // otherwise reported error (no sprint date)
            errorsDates.push(parseInt(s.id));
          } // Add the sprint


          data.pushObject(SprintObject.create({
            id: s.id,
            name: s.attributes.name,
            startDate: (0, _moment.default)(startDateSprint).toDate(),
            endDate: (0, _moment.default)(endDateSprint).toDate(),
            tasks: Ember.A([]),
            color: 'red',
            collapsed: false
          }));
        });
        this.get('tasks').map(t => {
          try {
            let sprintId = t.relationships.sprint.data.id; // If the iteration has error, ignore the task

            if (errorsDates.indexOf(parseInt(sprintId)) >= 0 || errorsDates.length === 0) {
              const record = data.filter(r => parseInt(r.id) === parseInt(sprintId));

              if (record.length > 0) {
                const sprint = record[0]; // Get the dates

                const {
                  startDateTask,
                  dueDateTask
                } = this.getDatesTask(t, sprint.startDate, sprint.endDate); // Get if color if the task dates are invalid

                const colorText = this.isInvalidDate(startDateTask, dueDateTask); // Add the task

                sprint.tasks.pushObject(TaskObject.create({
                  title: t.attributes.name,
                  dateStart: startDateTask,
                  dateEnd: dueDateTask,
                  color: t.attributes.color,
                  colorText: colorText
                }));
              }
            }
          } catch (e) {// Nothing
          }
        });
      } else {
        // First value the project
        const projectName = this.get('project.name');
        const startDateProject = (0, _moment.default)(this.get('project.startDate')).toDate();
        const endDateProject = (0, _moment.default)(this.get('project.dueDate')).toDate(); // Add the project like "sprint"

        data.pushObject(SprintObject.create({
          name: projectName,
          startDate: startDateProject,
          endDate: endDateProject,
          tasks: Ember.A([]),
          collapsed: false
        }));
        this.get('tasks').map(t => {
          // Get the dates
          const {
            startDateTask,
            dueDateTask
          } = this.getDatesTask(t, startDateProject, endDateProject); // Get if color if the task dates are invalid

          const colorText = this.isInvalidDate(startDateTask, dueDateTask); // Add the task

          data[0].tasks.pushObject(TaskObject.create({
            title: t.attributes.name,
            dateStart: startDateTask,
            dateEnd: dueDateTask,
            color: t.attributes.color,
            colorText: colorText
          }));
        });
      } // If exists error inform to the user


      if (existsErrorsDate) {
        const toast = this.get('toast');
        toast.warning(this.get('intl').t('task.timeline.errorDates'));
      } // Sorting tasks by start date


      data.forEach(sprint => {
        sprint.tasks.sort((taskA, taskB) => {
          return taskA.dateStart > taskB.dateStart ? 1 : taskA.dateStart < taskB.dateStart ? -1 : 0;
        });
      }); // Set values to the template

      this.set('data', data);
    },

    /**
     * @method getDatesTask
     * @description Get Start date and get date of one task
     */
    getDatesTask(t, defaultStartDate, defaultEndDate) {
      let startDate = defaultStartDate;
      const taskStartDate = t.attributes['start-date'];
      const taskDueDate = t.attributes['due-date'];
      const taskEndDate = t.attributes['end-date'];
      let dueDate = taskEndDate || taskDueDate; // Check if exists the start-date of the tasks

      if (Ember.isPresent(taskStartDate)) {
        startDate = (0, _moment.default)(taskStartDate).toDate();

        if ((0, _moment.default)(taskStartDate).isBefore(defaultStartDate) || (0, _moment.default)(taskStartDate).isAfter(defaultEndDate)) {
          startDate = defaultStartDate;
        }
      } // Check if exists the due-date of the tasks


      if (Ember.isPresent(dueDate)) {
        if ((0, _moment.default)(dueDate).isAfter(defaultEndDate) || (0, _moment.default)(dueDate).isBefore(defaultStartDate)) {
          dueDate = defaultEndDate;
        } else {
          dueDate = (0, _moment.default)(dueDate).toDate();
        }
      } else {
        dueDate = defaultEndDate;
      }

      return {
        startDateTask: startDate,
        dueDateTask: dueDate
      };
    },

    /**
     * @method isInvalidDate
     * @description Check if the range of dates is correct
     * @param {*} startDate
     * @param {*} endDate
     */
    isInvalidDate(startDate, endDate) {
      let color = '';

      if (startDate > endDate) {
        color = 'Red';
      }

      return color;
    },

    actions: {
      /**
       * @method collapse
       * @description Collapse the sprint
       * @param {*} sprint
       */
      collapse(sprint) {
        sprint.toggleProperty('collapsed');
      },

      /**
       * @method zoom
       * @description Zoom gantt
       * @param {*} value
       */
      zoom(value) {
        const newDayWidth = Math.max(1, parseInt(this.get('dayWidth')) + parseInt(value));
        this.set('dayWidth', newDayWidth);
      }

    }
  });

  _exports.default = _default;
});