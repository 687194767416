define("mytaskpanel/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    projectType: _emberData.default.attr('string'),
    account: _emberData.default.belongsTo('account'),
    accountActive: _emberData.default.attr(),
    accountProject: _emberData.default.attr('string'),
    productOwner: _emberData.default.belongsTo('user'),
    productOwnerId: _emberData.default.attr('number'),
    privacy: _emberData.default.attr('string'),
    projectState: _emberData.default.attr('string'),
    listProjectStates: _emberData.default.attr(),
    totalTasks: _emberData.default.attr('number'),
    totalTasksCompleted: _emberData.default.attr('number'),
    startDate: _emberData.default.attr(),
    dueDate: _emberData.default.attr(),
    endDate: _emberData.default.attr(),
    active: _emberData.default.attr('boolean'),
    userPm: _emberData.default.attr('string'),
    tasks: _emberData.default.hasMany('task'),
    isFavorite: _emberData.default.attr('boolean'),
    percentageTask: Ember.computed('totalTasks', 'totalTasksCompleted', function () {
      if (this.get('totalTasksCompleted') > 0) {
        return `${Math.ceil(this.get('totalTasksCompleted') * (100 / this.get('totalTasks'))).toString()}%`;
      } else {
        return 0;
      }
    }),
    projectExpired: Ember.computed('dueDate', 'endDate', function () {
      const endDate = this.get('endDate');

      if (endDate === null || endDate === undefined || endDate === '') {
        const dueDate = this.get('dueDate');

        if (new Date(dueDate) < new Date()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});