define("mytaskpanel/mixins/auth-route-mixin", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),

    async beforeModel(transition) {
      if (!this.get('session.isAuthenticated')) {
        this.set("session.prevTransition", transition);
      }
    }

  });

  _exports.default = _default;
});