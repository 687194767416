define("mytaskpanel/models/task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    sprint: _emberData.default.belongsTo('sprint'),
    parentTask: _emberData.default.belongsTo('task'),
    taskState: _emberData.default.attr('string'),
    priority: _emberData.default.attr('number'),
    estimateEffort: _emberData.default.attr('number'),
    estimatedTime: _emberData.default.attr('number'),
    remainTime: _emberData.default.attr('number'),
    realTime: _emberData.default.attr('number'),
    startDate: _emberData.default.attr(),
    endDate: _emberData.default.attr(),
    dueDate: _emberData.default.attr(),
    project: _emberData.default.belongsTo('project'),
    taskGroup: _emberData.default.belongsTo('task-group'),
    user: _emberData.default.belongsTo('user'),
    taskLabels: _emberData.default.hasMany('task-label'),
    taskComments: _emberData.default.hasMany('task-comment'),
    subTasks: _emberData.default.hasMany('sub-task'),
    followers: _emberData.default.hasMany('follower'),
    histories: _emberData.default.hasMany('history'),
    taskUploads: _emberData.default.hasMany('task-upload'),
    // For lists
    sprintId: _emberData.default.attr('number'),
    totalSubtasks: _emberData.default.attr('number'),
    completedSubtasks: _emberData.default.attr('number'),
    amountChildtasks: _emberData.default.attr('number'),
    userPhoto: _emberData.default.attr('string'),
    taskGroupId: _emberData.default.attr('number'),
    amountTaskgroup: _emberData.default.attr('number'),
    groupName: _emberData.default.attr('string'),
    parentTaskId: _emberData.default.attr('number'),
    groupColor: _emberData.default.attr('string'),
    labels: _emberData.default.attr(),
    userId: _emberData.default.attr('number'),
    username: _emberData.default.attr('string'),
    projectId: _emberData.default.attr('number'),
    followersTask: _emberData.default.attr(),
    // For inbox
    state: _emberData.default.attr('string'),
    // For hide or show completed tasks
    hideFlag: _emberData.default.attr(),
    // Computed
    taskExpired: Ember.computed('dueDate', 'endDate', function () {
      const endDate = this.get('endDate');

      if (endDate === null || endDate === undefined || endDate === '') {
        const dueDate = this.get('dueDate');

        if (new Date(dueDate) < new Date()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});