define("mytaskpanel/helpers/bytes-to-size", ["exports", "mytaskpanel/libs/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bytesToSize = bytesToSize;
  _exports.default = void 0;

  /**
  * @method bytesToSize
  * @description Bytes to size
  * @param {*} params
  */
  function bytesToSize(params) {
    const size = parseInt(params[0]);
    return (0, _utils.bytesToSize)(size);
  }

  var _default = Ember.Helper.helper(bytesToSize);

  _exports.default = _default;
});