define("mytaskpanel/templates/components/inbox/inbox-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qxunBSRl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"id\",\"portlet5\"],[11,\"class\",\"panel-collapse collapse show\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"portlet-body\"],[9],[0,\"\\n        \"],[7,\"canvas\"],[11,\"id\",\"chart-area\"],[11,\"height\",\"40vh\"],[11,\"width\",\"80vw\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/inbox/inbox-chart.hbs"
    }
  });

  _exports.default = _default;
});