define("mytaskpanel/components/price/form-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    priceService: Ember.inject.service('price'),
    session: Ember.inject.service('session'),
    showLoading: false,
    extraDetails: null,
    packageSelected: null,
    packagePreSelected: null,
    billingCycle: null,
    client: null,
    pSelected: Ember.computed.or('packageSelected', 'packagePreSelected'),
    disableStepChange: false,

    didReceiveAttrs() {
      this.set('extraDetails', [{
        color: 'bgGreenl',
        dashboard: 'Básico',
        priceColor: ''
      }, {
        color: 'bgGreend',
        dashboard: 'Clásico',
        priceColor: ''
      }, {
        color: 'bgBlue',
        dashboard: 'Full',
        priceColor: 'cYellow'
      }, {
        color: 'bgBlack',
        dashboard: 'Full',
        priceColor: 'cPink'
      }]);
    },

    async didInsertElement() {
      this._super();

      const client = await this.priceService.setInitialData();
      this.set('client', client);
      this.set('packagePreSelected', client.package);
      this.initComponents();
      Ember.$("#companny_name").hide();
      Ember.run.later(this, function () {
        Ember.$.FormWizard.init();
      }, 200);
    },

    initWizard() {
      const self = this;

      const FormWizard = function () {}; //creates form with validation


      FormWizard.prototype.createValidatorForm = function ($form_container) {
        $form_container.validate({
          errorPlacement: function errorPlacement(error, element) {
            element.after(error);
          }
        });
        $form_container.children('div').steps({
          headerTag: 'h3',
          bodyTag: 'section',
          labels: {
            finish: "Pagar con Paypal"
          },
          transitionEffect: 'slideLeft',
          onStepChanging: function (event, currentIndex, newIndex) {
            $form_container.validate().settings.ignore = ':disabled,:hidden';

            if (currentIndex === 0 && newIndex == 1) {
              const packageSelected = self.get('packageSelected.name');
              return packageSelected === 'Free' || packageSelected === undefined ? false : true;
            }

            if (currentIndex === 1 && newIndex == 2) {
              return self.get('billingCycle') && !self.get('disableStepChange') ? true : false;
            }

            Ember.$("#full-name-label").text(Ember.$("#name2").val());
            Ember.$("#company-label").text(Ember.$("#company").val());
            Ember.$("#vat-number-label").text(Ember.$("#vat-number").val());
            Ember.$("#address-label").text(`${Ember.$("#pais").val()}, ${Ember.$("#provincia").val()}, ${Ember.$("#city").val()}, 
            ${Ember.$("#address").val()}`);
            return $form_container.valid();
          },
          onFinishing: function () {
            $form_container.validate().settings.ignore = ':disabled';
            return $form_container.valid();
          },
          onFinished: async function () {
            self.set('showLoading', true);

            try {
              const response = await self.priceService.createCheckout(self.get('packageSelected'), self.get('billingCycle')); // Open url for pay

              const win = window.open(response.data.url, '_blank');
              win.focus();
            } catch (e) {// Display error
            }

            self.set('showLoading', false);
          }
        });
        return $form_container;
      };

      FormWizard.prototype.init = function () {
        //form with validation
        this.createValidatorForm(Ember.$('#wizard-validation-form'));
      }; //init


      Ember.$.FormWizard = new FormWizard(), Ember.$.FormWizard.Constructor = FormWizard;
    },

    initComponents() {
      this.initWizard();
    },

    actions: {
      checkedCompanyOrPerson() {
        const $company = Ember.$("#companny_name");

        if ($company.is(":visible")) {
          $company.hide();
        } else {
          $company.show();
        }
      },

      /**
       * @method selectedPlan
       * @description select plan
       */
      selectedPlan(packageSelected) {
        this.set('packageSelected', packageSelected);
        this.priceService.onSelectedPlan(packageSelected);
      },

      /**
       * @method sendUpgradePlan
       * @description Send upgrade plan
       */
      sendUpgradePlan() {
        this.set('showLoading', false);
      },

      /**
       * @method changeBillingCycle
       * @description Change billing cycle
       * @param {*} billingCycle
       */
      async changeBillingCycle(billingCycle) {
        this.set('billingCycle', billingCycle);
        const status = await this.priceService.onChangeBillingCycle(billingCycle, this.get('packageSelected'));

        if (!status) {
          this.set('disableStepChange', true);
        } else {
          this.set('disableStepChange', false);
          const client = this.get('client');
          Ember.$('#name2').val(client.name);
          Ember.$('#pais').val(client.country_name);
          Ember.$('#city').val(client.city);
          Ember.$('#provincia').val(client.province);
          Ember.$('#vat-number').val(client.vat_number);
        }
      }

    }
  });

  _exports.default = _default;
});