define("mytaskpanel/routes/accounts/archived", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    model() {
      const userId = this.get('session.data.authenticated.user').id;
      const clientId = this.get('session.data.authenticated.user').client;
      return Ember.RSVP.hash({
        user: this.get('store').find('user', userId),
        users: this.get('store').findAll('user'),
        accountsArchived: this.get('store').query('account', {
          'user': userId,
          'archived': 1
        }),
        publicPendingMembers: this.get('ajax').request('/pending-members/?client_id=' + clientId, {
          method: 'GET'
        }).then(response => {
          return response.data;
        })
      });
    }

  });

  _exports.default = _default;
});