define("mytaskpanel/helpers/get-color-project-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getColorProjectState = getColorProjectState;
  _exports.default = void 0;

  function getColorProjectState(projectState) {
    let value = '';
    let state = '';

    if (Array.isArray(projectState)) {
      state = projectState[0];
    } else {
      state = projectState;
    }

    switch (state) {
      // Planned
      case 'P':
        value = 'cplanned';
        break;
      // Active

      case 'A':
        value = 'cactive';
        break;
      // Finished

      case 'F':
        value = 'ccompleted';
        break;
      // Low

      case 'L':
        value = 'cdeleted';
        break;
      // Suspend

      case 'S':
        value = 'csuspended';
        break;

      default:
        value = '';
        break;
    }

    return value;
  }

  var _default = Ember.Helper.helper(getColorProjectState);

  _exports.default = _default;
});