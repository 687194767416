define("mytaskpanel/routes/projects/archived", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),

    model() {
      const params = this.modelFor('projects');
      const userId = this.get('session.data.authenticated.user').id;
      return Ember.RSVP.hash({
        user: this.get('store').find('user', userId),
        account: this.get('store').find('account', params.params.account_id),
        users: this.get('store').findAll('user'),
        accountUser: this.get('store').query('account-user', {
          account: params.params.account_id,
          user: userId
        }).then(records => {
          return records.get('firstObject');
        }),
        projectsArchived: this.get('store').query('project', {
          'user': userId,
          'account': params.params.account_id,
          'archived': 1
        }),
        params: params.params
      });
    }

  });

  _exports.default = _default;
});