define("mytaskpanel/components/auth/form-signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),
    chkTerms: true,
    showLoading: false,
    enabledRegister: true,
    disabledRegister: Ember.computed('chkTerms', 'email', 'enabledRegister', {
      get() {
        const terms = this.chkTerms ? true : false;
        const email = Ember.isPresent(this.email) ? true : false;
        return !terms || !email || !this.enabledRegister ? true : false;
      }

    }),
    actions: {
      /**
      * @method signup
      * @description Add email and send email for to register
      */
      async signup() {
        this.set('errorMessage', '');
        this.set('enabledRegister', false); // Lowercasing email

        this.set('email', this.get('email').toLowerCase()); // Get querystring

        const urlParams = new URLSearchParams(window.location.search); // Data campaigng source

        let utmSource = '';

        if (urlParams.get('utm_source') !== null || urlParams.get('utm_source') !== undefined) {
          utmSource = urlParams.get('utm_source');
        } // Data campaigng content


        let utmContent = '';

        if (urlParams.get('utm_content') !== null || urlParams.get('utm_content') !== undefined) {
          utmContent = urlParams.get('utm_content');
        } // Data campaigng content


        let packageData = '';

        if (urlParams.get('package') !== null || urlParams.get('package') !== undefined) {
          packageData = urlParams.get('package');
        }

        const {
          email
        } = this.getProperties('email');
        this.set('showLoading', true);
        const response = await this.get('ajax').request('/signup/', {
          method: 'POST',
          data: {
            email: email,
            utm_source: utmSource,
            utm_content: utmContent,
            package: packageData
          }
        });
        this.set('showLoading', false);
        const toast = this.get('toast');

        if (response.data.success) {
          if (response.data.duplicated) {
            this.set('errorMessage', this.get('intl').t('auth.signup.duplicated'));
          } else {
            this.set('errorMessage', '');
            this.set('email', '');
            toast.success(this.get('intl').t('email.check'));
          }
        } else {
          this.set('errorMessage', this.get('intl').t('auth.signup.error'));
        }

        this.set('enabledRegister', true);
      }

    }
  });

  _exports.default = _default;
});