define("mytaskpanel/components/utils/modal-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      /**
      * @method closeModal
      * @description Close modal
      */
      closeModal() {
        Ember.$('#help-wrapper').toggleClass('right-bar-enabled');
      }

    }
  });

  _exports.default = _default;
});