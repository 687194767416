define("mytaskpanel/mixins/only-paying-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service('ajax'),
    clientsService: Ember.inject.service('clients'),
    intl: Ember.inject.service('intl'),
    toast: Ember.inject.service('toast'),

    async beforeModel() {
      const authenticationRoute = this.get('authenticationRoute');
      const isPayingClient = await this.get('clientsService').isPayingClient();

      if (!isPayingClient) {
        const toast = this.get('toast');
        toast.warning(this.get('intl').t('client.feature.payment'));
        return this.transitionTo(authenticationRoute);
      }
    }

  });

  _exports.default = _default;
});