define("mytaskpanel/services/task-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    swal: Ember.inject.service('swal'),
    toast: Ember.inject.service('toast'),

    /**
     * @method createTaskGroup
     * @description create task group
     * @param {string} name Group name
     * @param {int} projectId Id of the project
     * @param {array} colors Data colors
     * @param {int} lastGroupOrder Last task group id
     */
    async createTaskGroup(name, projectId, colors, lastGroupOrder) {
      try {
        const project = await this.get('store').find('project', projectId);
        const color = colors[Math.floor(Math.random() * colors.length)];
        const group = this.get('store').createRecord('task-group', {
          name: name,
          color: color,
          groupOrder: lastGroupOrder,
          project: project
        });
        await group.save();
        this.get('toast').success(this.get('intl').t('task_group.created'));
      } catch (err) {
        this.get('swal').open({
          title: 'Error',
          text: 'Error',
          type: 'error'
        });
      }
    },

    /**
     * @method updateNameTaskGroup
     * @description update name task group
     * @param {int} groupId Group id
     * @param {string} name Group name
     * @param {object} self context
     */
    async updateNameTaskGroup(groupId, name, self) {
      try {
        const group = await this.get('store').find('task-group', groupId);
        self.set('group', group);
        self.group.set('name', name);
        await self.group.save();
        this.get('toast').success(this.get('intl').t('task_group.updated'));
        this.get('store').query('task-group', {
          'project_id': self.params.project_id,
          'order': 'group_order'
        }).then(groups => {
          self.set('taskGroups', groups);
        });
      } catch (e) {
        this.get('swal').open({
          title: 'Error',
          text: 'Error',
          type: 'error'
        });
      }
    },

    /**
     * @method deleteTaskGroup
     * @description Delete task group selected
     * @param {*} taskGroup taksGroup to delete
     * @param {*} taskGroupsTasks List of the tasks groups
     * @param {*} tasks List of the tasks
     */
    async deleteTaskGroup(taskGroup, taskGroupsTasks, tasks) {
      const text = this.get('intl').t('delete');
      const question = this.get('intl').t('task_group.delete.question');
      const result = await this.get('swal').open({
        title: text,
        text: question,
        type: 'warning',
        showCancelButton: true
      });

      if (result.value) {
        // Check if the task group has tasks
        const taskGroupId = taskGroup.taskGroupId;
        let withTasks = false;
        tasks.forEach(task => {
          if (task.taskGroupId === taskGroupId) {
            withTasks = true;
          }
        }); // Check if is true

        if (withTasks) {
          this.get('swal').open({
            title: this.get('intl').t('task_groups'),
            text: this.get('intl').t('task_group.delete.not_empty'),
            type: 'warning'
          });
        } else {
          // Find the record to delete
          const taskGroupDelete = await this.get('store').find('task-group', taskGroupId); // Delete record

          taskGroupDelete.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          }); // Remove the object in the view

          taskGroupsTasks.removeObject(taskGroup);
        }
      }
    }

  });

  _exports.default = _default;
});