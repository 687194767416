define("mytaskpanel/helpers/is-weekend-day", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isWeekendDay = isWeekendDay;
  _exports.default = void 0;

  function isWeekendDay(day) {
    const dayFormatted = (0, _moment.default)(day, 'YYYY-MM-DD');
    return (0, _moment.default)(dayFormatted).day() === 0 || (0, _moment.default)(dayFormatted).day() === 6;
  }

  var _default = Ember.Helper.helper(isWeekendDay);

  _exports.default = _default;
});