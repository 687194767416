define("mytaskpanel/components/labels/multiple-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // tagName is for ember. Not add a wrapper div
    tagName: '',
    // Label id new dynamic
    labelIdNew: '',

    didInsertElement() {
      this._super();

      this.set('labelIdNew', 'labels_new' + Math.floor(Math.random() * 10000000).toString());
    },

    didRender() {
      // Load popovers
      this.loadPopovers();
    },

    /**
     * @method loadPopovers
     * @description Load popovers
     */
    loadPopovers() {
      // Load popovers
      Ember.$('.po-markup > .po-link').popover({
        html: true,
        // must have if HTML is contained in popover
        // get the title and conent
        title: function () {
          return Ember.$(this).parent().find('.po-title').html();
        },
        content: function () {
          const clone = Ember.$(this).parent().find('.po-body').clone(true).removeClass('hide');
          return clone;
        }
      });
    },

    actions: {
      /**
       * @method cancelPopover
       * @description Cacel popover
       */
      cancelPopover() {
        Ember.$('.po-markup > .po-link').popover('hide');
      },

      /**
       * @method setLabels
       * @description Set value to priority
       * @param {string} typeForm If show or hide priority popover
       */
      setLabels(typeForm) {
        if (typeForm === 'show') {
          Ember.$('.popover-body').find(`#${this.get('labelIdNew')}`).val(this.get('parent.labels_selected_new'));
        } else {
          const self = this;
          const labels = Ember.$('.popover-body').find(`#${this.get('labelIdNew')}`).val();
          const labelsSelectedName = Ember.$('.popover-body').find(`#${this.get('labelIdNew')}`).find(':selected').text();

          if (this.get('formType') === 'form-task') {
            labels.map(l => {
              try {
                const label = self.get('labels').filter(x => x.id === l)[0];
                Ember.$('.tokenize-labels').trigger('tokenize:tokens:add', [label.id, label.name, true]);
              } catch (e) {// Nothing
              }
            });
          } else {
            this.set('parent.labels_selected_new', labels);
            this.set('parent.labelsSelectedName', labelsSelectedName);
          }

          this.actions.cancelPopover();
        }
      }

    }
  });

  _exports.default = _default;
});