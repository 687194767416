define("mytaskpanel/helpers/round-decimal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundDecimal = roundDecimal;
  _exports.default = void 0;

  function roundDecimal(params
  /*, hash*/
  ) {
    const value = params[0];
    const decimals = params[1];
    return Number(value).toFixed(decimals);
  }

  var _default = Ember.Helper.helper(roundDecimal);

  _exports.default = _default;
});