define("mytaskpanel/services/guide-tour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),

    /**
    * @method updateTour
    * @description Update to show or not guide tour
    * @param {*} fieldTour
    * @param {*} show
    */
    updateTour(fieldTour, show) {
      // Update tour_account
      switch (fieldTour) {
        case 1:
          this.get('session').set('session.content.authenticated.user.tour_account', false);
          break;

        case 2:
          this.get('session').set('session.content.authenticated.user.tour_project', false);
          break;

        case 3:
          this.get('session').set('session.content.authenticated.user.tour_task', false);
          break;
      }

      const newSession = this.get('session.data');
      const key = this.get('session.store._store.key'); // Save in localStorage

      localStorage.setItem(key, JSON.stringify(newSession));
      const formData = new FormData();
      const data = {
        field_tour: fieldTour,
        show: show
      };
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      }); // Make api call

      this.get('ajax').request('/update-tour/', {
        method: 'POST',
        processData: false,
        contentType: false,
        data: formData
      }).then(() => {// Nothing
      });
    },

    /**
    * @method showGuideTourAccount
    * @description Show guide tour for account and update
    */
    showGuideTourAccount(force) {
      // Check if show the tour
      const tour_account = this.get('session.data.authenticated.user').tour_account;

      if (tour_account || force) {
        Ember.run.later(() => {
          // Tour
          const driver = new window.Driver({
            opacity: 0.50
          });
          driver.defineSteps([{
            element: '#new-account-button',
            popover: {
              title: this.get('intl').t('account.tour.title'),
              description: this.get('intl').t('account.tour.description'),
              position: 'bottom'
            }
          }]);
          driver.start(); // Update tour

          if (!force) {
            this.updateTour(1, false);
          }
        }, 100);
      }
    },

    /**
    * @method showGuideTourProject
    * @description Show guide tour for project and update
    */
    showGuideTourProject(force) {
      // Check if show the tour
      const tour_project = this.get('session.data.authenticated.user').tour_project;

      if (tour_project || force) {
        Ember.run.later(() => {
          // Tour
          const driver = new window.Driver({
            opacity: 0.50
          });
          driver.defineSteps([{
            element: '#new-project-button',
            popover: {
              title: this.get('intl').t('project.tour.title'),
              description: this.get('intl').t('project.tour.description'),
              position: 'bottom'
            }
          }]);
          driver.start(); // Update tour

          if (!force) {
            this.updateTour(2, false);
          }
        }, 100);
      }
    },

    /**
    * @method showGuideTourTask
    * @description Show guide tour for task and update
    */
    showGuideTourTask(force) {
      // Check if show the tour
      const tour_task = this.get('session.data.authenticated.user').tour_task;

      if (tour_task || force) {
        let timeLater = 500;

        if (tour_task) {
          timeLater = 1000;
        }

        Ember.run.later(() => {
          // Tour
          const driver = new window.Driver({
            doneBtnText: this.get('intl').t('tour.doneBtnText'),
            closeBtnText: this.get('intl').t('tour.closeBtnText'),
            nextBtnText: this.get('intl').t('tour.nextBtnText'),
            prevBtnText: this.get('intl').t('tour.prevBtnText'),
            opacity: 0.50
          });
          driver.defineSteps([{
            element: '#create-task-form',
            popover: {
              title: this.get('intl').t('task.tour.step1.title'),
              description: this.get('intl').t('task.tour.step1.description')
            }
          }, {
            element: '#addtask',
            popover: {
              title: this.get('intl').t('task.tour.step19.title'),
              description: this.get('intl').t('task.tour.step19.description'),
              position: 'bottom'
            }
          }, {
            element: '#exampleTextarea',
            popover: {
              title: this.get('intl').t('task.tour.step2.title'),
              description: this.get('intl').t('task.tour.step2.description'),
              position: 'bottom'
            }
          }, {
            element: '#task-icons',
            popover: {
              title: this.get('intl').t('task.tour.step3.title'),
              description: this.get('intl').t('task.tour.step3.description'),
              position: 'bottom'
            }
          }, {
            element: '#user_responsable_popover',
            popover: {
              title: this.get('intl').t('task.tour.step4.title'),
              description: this.get('intl').t('task.tour.step4.description'),
              position: 'bottom'
            }
          }, {
            element: '#labels_popever',
            popover: {
              title: this.get('intl').t('task.tour.step5.title'),
              description: this.get('intl').t('task.tour.step5.description'),
              position: 'bottom'
            }
          }, {
            element: '#datepickercreate',
            popover: {
              title: this.get('intl').t('task.tour.step6.title'),
              description: this.get('intl').t('task.tour.step6.description'),
              position: 'bottom'
            }
          }, {
            element: '#priority_popover',
            popover: {
              title: this.get('intl').t('task.tour.step7.title'),
              description: this.get('intl').t('task.tour.step7.description'),
              position: 'bottom'
            }
          }, {
            element: '#remain_time',
            popover: {
              title: this.get('intl').t('task.tour.step8.title'),
              description: this.get('intl').t('task.tour.step8.description'),
              position: 'bottom'
            }
          }, {
            element: '#sprint',
            popover: {
              title: this.get('intl').t('task.tour.step9.title'),
              description: this.get('intl').t('task.tour.step9.description'),
              position: 'bottom'
            }
          }, {
            element: '#dragTree a',
            popover: {
              title: this.get('intl').t('task.tour.step9b.title'),
              description: this.get('intl').t('task.tour.step9b.description'),
              position: 'bottom'
            }
          }, {
            element: '#filter',
            popover: {
              title: this.get('intl').t('task.tour.step10.title'),
              description: this.get('intl').t('task.tour.step10.description'),
              position: 'bottom'
            }
          }, {
            element: '#info',
            popover: {
              title: this.get('intl').t('task.tour.step12.title'),
              description: this.get('intl').t('task.tour.step12.description'),
              position: 'bottom'
            }
          }, {
            element: '#refresh',
            popover: {
              title: this.get('intl').t('task.tour.step13.title'),
              description: this.get('intl').t('task.tour.step13.description'),
              position: 'bottom'
            }
          }, {
            element: '#eye_button_all',
            popover: {
              title: this.get('intl').t('task.tour.step14.title'),
              description: this.get('intl').t('task.tour.step14.description'),
              position: 'bottom'
            }
          }, {
            element: '#expand_button_icon',
            popover: {
              title: this.get('intl').t('task.tour.step15.title'),
              description: this.get('intl').t('task.tour.step15.description'),
              position: 'bottom'
            }
          }, {
            element: '#changeview',
            popover: {
              title: this.get('intl').t('task.tour.step11.title'),
              description: this.get('intl').t('task.tour.step11.description'),
              position: 'bottom'
            }
          }, {
            element: '.calendarlink',
            popover: {
              title: this.get('intl').t('task.tour.step16.title'),
              description: this.get('intl').t('task.tour.step16.description'),
              position: 'left'
            }
          }, {
            element: '.timelinelink',
            popover: {
              title: this.get('intl').t('task.tour.step17.title'),
              description: this.get('intl').t('task.tour.step17.description'),
              position: 'left'
            }
          }, {
            element: '.dashboardlink',
            popover: {
              title: this.get('intl').t('task.tour.step18.title'),
              description: this.get('intl').t('task.tour.step18.description'),
              position: 'left'
            }
          }]);
          driver.start(); // Update tour

          if (!force) {
            this.updateTour(3, false);
          }
        }, timeLater);
      }
    }

  });

  _exports.default = _default;
});