define("mytaskpanel/templates/components/utils/date-expired-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k0Cd5ICR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"showMessageExpired\"]]],null,{\"statements\":[[7,\"li\"],[11,\"class\",\"list-inline-item notification-list alerta mr-4\"],[11,\"id\",\"info-expired-client\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"text-warning align-sub mr-2\"],[9],[0,\"\\n    \"],[1,[21,\"days\"],false],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"days\"]],1],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"day\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"days\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"session\",\"data\",\"authenticated\",\"user\",\"licence_type\"]],\"F\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"client.expire.trial\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"client.expire\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"price\"],[[\"class\"],[\"btn btn-warning btn-sm text-black\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"upgrade\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/utils/date-expired-client.hbs"
    }
  });

  _exports.default = _default;
});