define("mytaskpanel/components/tasks/tasks-calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    calendarService: Ember.inject.service('calendar'),
    calendar: null,

    didInsertElement() {
      this._super(); // Creating ToastUI Calendar


      this.set('calendar', this.get('calendarService').createCalendar()); // Setting calendar colors

      this.get('calendar').setCalendarColor('1', {
        color: '#FFFFFF',
        bgColor: '#04A9FF',
        borderColor: '#04A9FF',
        dragBgColor: '#FFFFFF'
      }); // Setting tasks on the calendar

      this.get('calendarService').setTasksOnCalendar(this.get('calendar'), this.get('project').id); // Setting date text

      this.updateDate();
    },

    /**
     * @method updateDate
     * @description Update date when user scrolls through weeks or months
     */
    updateDate() {
      const dateRange = this.get('calendar').getDate().toDate();
      Ember.$('#calendar-current-month-text').text((0, _moment.default)(dateRange).format('MMM YYYY'));
    },

    actions: {
      /**
       * @method today
       * @description Move to current date
       */
      today() {
        this.get('calendar').today();
        this.updateDate();
      },

      /**
       * @method prev
       * @description Move to previous week or month
       */
      prev() {
        this.get('calendar').prev();
        this.updateDate();
      },

      /**
       * @method next
       * @description Move to next week or month
       */
      next() {
        this.get('calendar').next();
        this.updateDate();
      },

      /**
       * @method weekly
       * @description Change calendar view to weekly
       */
      weekly() {
        this.get('calendar').changeView('week', true);
      },

      /**
       * @method monthly
       * @description Change calendar view to monthly
       */
      monthly() {
        this.get('calendar').changeView('month', true);
      }

    }
  });

  _exports.default = _default;
});