define("mytaskpanel/helpers/description-user-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.descriptionUserType = descriptionUserType;
  _exports.default = void 0;

  /**
  * @method descriptionUserType
  * @description Get description user type
  * @param {*} params
  */
  function descriptionUserType(params) {
    let value = params[0];

    switch (value) {
      case 'O':
        value = 'Owner';
        break;

      case 'M':
        value = 'Member';
        break;

      case 'A':
        value = 'Admin';
        break;

      case 'I':
        value = 'Invited';
        break;
    }

    return value;
  }

  var _default = Ember.Helper.helper(descriptionUserType);

  _exports.default = _default;
});