define("mytaskpanel/components/users/pending-users-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    swal: Ember.inject.service('swal'),
    actions: {
      async removePendingUser(user, accountId) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('delete.question');
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          const clientId = this.get('session.data.authenticated.user').client;
          const self = this;
          const formData = new FormData();
          const data = {
            email: user.email,
            account_id: accountId,
            client_id: clientId
          };
          Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
          }); // Delete record

          this.get('ajax').request('/pending-members/', {
            method: 'POST',
            processData: false,
            contentType: false,
            data: formData
          }).then(response => {
            if (response.data.status === 'OK') {
              self.get('publicPendingMembers').removeObject(user);
            }
          }).catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.name,
              type: 'error',
              allowOutsideClick: false
            }).then(() => {// window.location.reload();
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});