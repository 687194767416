define("mytaskpanel/services/ajax", ["exports", "ember-ajax/services/ajax", "mytaskpanel/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service('session'),
    auth: Ember.inject.service('auth'),
    languageService: Ember.inject.service('language-intl'),
    host: _environment.default.APP.API_URL,
    headers: Ember.computed('session.data.authenticated.token', {
      get() {
        let headers = {};
        const authToken = this.get('session.data.authenticated.token');

        if (authToken) {
          headers['Authorization'] = `jwt ${authToken}`;
        }

        headers['language'] = this.get('languageService').getLanguage();
        return headers;
      }

    }),

    options(url, options = {}) {
      const uri = url;
      options = Ember.assign({}, options);
      options.url = this._buildURL(url, options);
      options.type = options.type || 'GET';
      options.dataType = options.dataType || 'json';
      options.contentType = Ember.isEmpty(options.contentType) ? Ember.get(this, 'contentType') : options.contentType;

      if (this._shouldSendHeaders(options)) {
        options.headers = this._getFullHeadersHash(options.headers);
      } else {
        options.headers = options.headers || {};
      }

      if (this.get('session.isAuthenticated')) {
        const clientId = this.get('session.data.authenticated.user').client;

        if (clientId !== null) {
          options.headers['client-id'] = clientId;
        } else {
          // Only allow client id null in get-clients and check-clients
          if (!uri.includes('get-clients') && !uri.includes('check-clients')) {
            this.get('auth').logout();
          }
        } // Set timezone


        options.headers['timezone'] = this.get('session.data.authenticated.user').timezone;
        options.headers['language'] = this.get('languageService').getLanguage();
      }

      return options;
    }

  });

  _exports.default = _default;
});