define("mytaskpanel/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlSafe = htmlSafe;
  _exports.default = void 0;

  /**
  * @method htmlSafe
  * @description html safe string
  * @param {*} params
  */
  function htmlSafe(params
  /*, hash*/
  ) {
    return Ember.String.htmlSafe(params.join(''));
  }

  var _default = Ember.Helper.helper(htmlSafe);

  _exports.default = _default;
});