define("mytaskpanel/components/accounts/form-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    toast: Ember.inject.service('toast'),
    swal: Ember.inject.service('swal'),
    productOwner: null,
    showLoading: false,
    randomId: Ember.computed('index', {
      get() {
        return Math.floor(Math.random() * 10000000).toString();
      }

    }),
    setForm: Ember.observer('account', function () {
      this.loadForm();
    }),
    product_owner_id: Ember.computed('index', {
      get() {
        return "pw_id_" + Math.floor(Math.random() * 10000000).toString();
      }

    }),

    didInsertElement() {
      this._super();

      this.loadForm();
    },

    /**
    * @method loadForm
    * @description Initialize components of the form
    */
    loadForm() {
      // Getting mode data
      const mode = this.get('mode'); // Setting description rich textbox

      Ember.$('.form-account-description').summernote({
        minHeight: 120,
        focus: true,
        disableDragAndDrop: true,
        width: mode === 'edit' ? 520 : 620,
        toolbar: [['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['insert', []]]
      }); // If is creating an account

      if (mode === 'create') {
        // Init checked public
        Ember.$('#access_public_' + this.get('randomId')).prop('checked', 'checked'); // User logged by default

        const productOwner = parseInt(this.get('session.data.authenticated.user').id);
        this.set('productOwner', productOwner);
        Ember.$('#form-account-description-create').summernote('code', '');
      } else {
        // Is edit, then init fields
        const nameAccount = this.get('account.name');
        const privacy = this.get('account.privacy');
        const productOwner = parseInt(this.get('account.productOwner.id'));
        this.set('name_account', nameAccount);
        this.set('productOwner', productOwner);

        if (privacy == 'A') {
          Ember.$('#access_public_' + this.get('randomId')).prop('checked', 'checked');
        } else {
          Ember.$('#access_private_' + this.get('randomId')).prop('checked', 'checked');
        } // If account exists and mode is not create, set the account description


        if (this.get('account') !== undefined && this.get('account') !== null && this.get('account.id') !== null) {
          Ember.$('#form-account-description-edit').summernote('code', this.get('account.description'));
        }
      }
    },

    /**
    * @method resetForm
    * @description Reset form
    */
    resetForm() {
      if (this.get('mode') === 'create') {
        this.set('name_account', '');
        this.set('description', '');
        this.set('productOwner', parseInt(this.get('session.data.authenticated.user').id));
        Ember.$('#access_public_' + this.get('randomId')).prop('checked', 'checked');
      }
    },

    actions: {
      /**
      * @method deleteAccount
      * @description Delete account
      * @param {*} account Account object
      */
      async deleteAccount(account) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('account.delete.question');
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          this.set('parent.showLoading', true); // Delete record

          account.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.errors.error,
              type: 'error'
            });
          });
          this.get('accounts').removeObject(account);
          this.set('parent.showLoading', false);
          Ember.$('#wrapper').toggleClass('right-bar-enabled');
        }
      },

      /**
      * @method setFieldActiveAccount
      * @description Archive or not account
      */
      async setFieldActiveAccount(active) {
        let text, question;

        if (active) {
          text = this.get('intl').t('activate');
          question = this.get('intl').t('account.activate.question');
        } else {
          text = this.get('intl').t('archive');
          question = this.get('intl').t('account.archive.question');
        }

        const result = await this.get('swal').open({
          title: text,
          text: question,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          this.set('parent.showLoading', true);
          this.set('account.active', active);
          await this.account.save();
          this.get('accounts').removeObject(this.account);
          const toast = this.get('toast');

          if (active) {
            toast.success(this.get('intl').t('account.activate'));
          } else {
            toast.success(this.get('intl').t('account.archived'));
          }

          this.set('parent.showLoading', false);
          Ember.$('#wrapper').toggleClass('right-bar-enabled');
        }
      },

      /**
      * @method actionAccount
      * @description Create account or edit account
      */
      async actionAccount() {
        const privacy = Ember.$('[name="type_privacy_' + this.get('randomId') + '"]:checked').val();
        const productOwner = Ember.$('#' + this.get('product_owner_id')).val();

        if (this.get('mode') === 'create') {
          const name = this.get('name_account');
          const description = Ember.$('#form-account-description-create').summernote('code'); //Create account

          if (Ember.isPresent(name) && Ember.isPresent(privacy) && Ember.isPresent(productOwner)) {
            // This loading is local
            this.set('showLoading', true);
            const user = await this.get('store').find('user', productOwner);
            const account = this.get('store').createRecord('account', {
              name: name,
              description: description,
              privacy: privacy,
              productOwner: user,
              active: true
            }); // Save record

            const ac = await account.save();
            const toast = this.get('toast');
            toast.success(this.get('intl').t('account.created')); // Add object to model accounts

            this.get('accounts').addObject(ac); // Reset form

            this.resetForm();
            this.set('showLoading', false); // Change to the form invitation

            this.set('parent.isCreatingAccount', false); // Set data of the account

            this.set('parent.typeAccount', privacy);
            this.set('parent.accountCreated', ac);
          }
        } else {
          // Edit account
          if (Ember.isPresent(privacy) && Ember.isPresent(productOwner)) {
            // This loading is from account-list
            this.set('parent.showLoading', true);
            const user = await this.get('store').find('user', productOwner);
            const description = Ember.$('#form-account-description-edit').summernote('code');
            this.account.set('description', description);
            this.account.set('privacy', privacy);
            this.account.set('productOwner', user);
            await this.account.save();
            const toast = this.get('toast');
            toast.success(this.get('intl').t('account.update')); // Reset form

            this.resetForm();
            this.set('parent.showLoading', false);
            Ember.$('#wrapper').toggleClass('right-bar-enabled');
          }
        }
      }

    }
  });

  _exports.default = _default;
});