define("mytaskpanel/templates/components/tasks/modal-form-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bJNtNptE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"id\",\"task_filter_modal\"],[11,\"class\",\"container demo\"],[9],[0,\"\\n\\n\\t\"],[2,\" Modal \"],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"modal right fade\"],[11,\"id\",\"myModal2\"],[11,\"tabindex\",\"-1\"],[11,\"role\",\"dialog\"],[11,\"aria-labelledby\",\"myModalLabel2\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"modal-dialog\"],[11,\"role\",\"document\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"button\"],[11,\"class\",\"close m-t-5\"],[11,\"data-dismiss\",\"modal\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"h5\"],[11,\"class\",\"modal-title\"],[11,\"id\",\"myModalLabel2\"],[9],[1,[27,\"t\",[\"filters\"],null],false],[10],[0,\"\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n          \"],[1,[27,\"tasks/form-filter\",null,[[\"parent\"],[[22,0,[]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\\n\\t\\t\\t\"],[10],[2,\" modal-content \"],[0,\"\\n\\t\\t\"],[10],[2,\" modal-dialog \"],[0,\"\\n\\t\"],[10],[2,\" modal \"],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/tasks/modal-form-filter.hbs"
    }
  });

  _exports.default = _default;
});