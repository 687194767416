define("mytaskpanel/helpers/convert-to-int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertToInt = convertToInt;
  _exports.default = void 0;

  /**
  * @method convertToInt
  * @description Convert value to int
  * @param {*} params
  */
  function convertToInt(params) {
    let value = params[0];

    if (typeof value === 'string') {
      value = parseInt(value);
    }

    return value;
  }

  var _default = Ember.Helper.helper(convertToInt);

  _exports.default = _default;
});