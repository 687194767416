define("mytaskpanel/routes/users", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "mytaskpanel/mixins/only-owner"], function (_exports, _authenticatedRouteMixin, _onlyOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _onlyOwner.default, {
    model() {
      const clientId = this.get('session.data.authenticated.user').client;
      return Ember.RSVP.hash({
        users: this.get('store').findAll('user', {
          reload: true
        }).catch(() => {
          this.transitionTo('/');
        }),
        user: null,
        publicPendingMembers: this.get('ajax').request('/pending-members/?client_id=' + clientId, {
          method: 'GET'
        }).then(response => {
          return response.data;
        })
      });
    }

  });

  _exports.default = _default;
});