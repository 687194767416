define("mytaskpanel/services/modal-form-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    open: function () {
      this.trigger('open-modal-form-task', []);
    }
  });

  _exports.default = _default;
});