define("mytaskpanel/routes/projects", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),

    async beforeModel(transition) {
      try {
        const accountId = transition.params.projects['account_id'];
        const account = await this.get('store').find('account', accountId);

        if (!account.get('active')) {
          this.transitionTo('accounts');
        }
      } catch (e) {
        this.transitionTo('accounts');
      }
    },

    model(params) {
      const userId = this.get('session.data.authenticated.user').id;
      return Ember.RSVP.hash({
        user: this.get('store').find('user', userId),
        account: this.get('store').find('account', params.account_id),
        users: this.get('store').query('user', {
          'account_id': params.account_id
        }, {
          reload: true
        }),
        accountUser: this.get('store').query('account-user', {
          account: params.account_id,
          user: userId
        }).then(records => {
          return records.get('firstObject');
        }),
        projects: this.get('store').query('project', {
          'user': userId,
          'account': params.account_id
        }),
        params: params
      });
    },

    afterModel(model) {
      const userId = this.get('session.data.authenticated.user').id;
      const user = model.users.filter(us => {
        return parseInt(us.id) === userId;
      }); // Check if has permission to enter to project

      if (user.length === 0) {
        this.transitionTo('accounts');
      }
    },

    actions: {
      willTransition() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});