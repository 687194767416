define("mytaskpanel/templates/components/utils/colors-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W5mW4YX1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"  \"],[7,\"datalist\"],[11,\"id\",\"presetColors\"],[9],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#009ddc\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#008080\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#000080\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#ef476f\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#800080\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#4d194d\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#fca311\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#bf0603\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#b75d69\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#57cc99\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#7cb518\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#008000\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#808000\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#666675\"],[10],[0,\"\\n    \"],[7,\"option\"],[9],[0,\"#3a3a44\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/utils/colors-default.hbs"
    }
  });

  _exports.default = _default;
});