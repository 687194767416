define("mytaskpanel/services/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),

    async getMembersProject(project) {
      let response;

      try {
        response = await this.get('ajax').request(`/project/${project.id}/get-members/`, {
          method: 'GET'
        });
      } catch (e) {
        response = {
          data: {
            members: []
          }
        };
      }

      return response;
    }

  });

  _exports.default = _default;
});