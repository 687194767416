define("mytaskpanel/services/timesheet", ["exports", "moment", "mytaskpanel/components/timesheet/utils", "mytaskpanel/libs/utils"], function (_exports, _moment, _utils, _utils2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    store: Ember.inject.service('store'),
    toast: Ember.inject.service('toast'),

    /**
     * @method loadActivitiesProject
     * @description Load the data for dropdown project/activity
     * @param {*} genericTasks
     * @param {*} projects
     */
    loadActivitiesProject(genericTasks, projects) {
      const projectsActivities = [];
      genericTasks.forEach(gt => {
        projectsActivities.push({
          id: gt.id,
          name: gt.name,
          type: 'genericTask'
        });
      });
      projects.forEach(pr => {
        projectsActivities.push({
          id: pr.id,
          name: pr.accountProject,
          type: 'project'
        });
      });
      return projectsActivities.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0);
    },

    /**
     * @method initTimesheet
     * @description Init data timesheet
     * @param {*} typeDateSelected
     */
    initTimesheet(typeDateSelected) {
      let date;

      if (typeDateSelected === 'month') {
        date = Ember.$('#datepicker-month').val();
      } else {
        date = (0, _moment.default)(Ember.$('#datepicker-week').val());
      }

      let daysAux;

      if (typeDateSelected === 'month') {
        let month, year;

        try {
          year = parseInt(date.split('-')[0]);
          month = parseInt(date.split('-')[1]) - 1;
        } catch (e) {
          year = (0, _moment.default)().year();
          month = (0, _moment.default)().month();
        }

        daysAux = (0, _utils2.daysInMonth)(month, year);
      } else {
        daysAux = (0, _utils2.daysInWeek)(date);
      }

      const days = Ember.A();
      const DayObject = Ember.Object.extend({
        day: null,
        totalHoursByDay: null,
        formatDay: null
      });
      daysAux.map(d => {
        days.pushObject(DayObject.create({
          day: d,
          totalHoursByDay: 0,
          formatDay: (0, _moment.default)(d).format('ddd DD-MM')
        }));
      });
      return days;
    },

    /**
     * @method getRecordObject
     * @description Get record object timesheet
     */
    getRecordObject() {
      return Ember.Object.extend({
        user: null,
        date: null,
        task: null,
        tasks: null,
        taskFree: null,
        taskDescription: null,
        project: null,
        genericTask: null,
        id: null,
        days: Ember.A(),
        totalHours: null
      });
    },

    /**
     * @method getDayObject
     * @description Get object day
     */
    getDayObject() {
      return Ember.Object.extend({
        day: null,
        totalHours: null,
        totalHoursTime: null,
        recordId: null,
        isNew: null
      });
    },

    /**
     * @method addRecord
     * @description Add record to the grid
     * @param {*} records
     * @param {*} days
     * @param {*} user
     */
    addRecord(records, days, user) {
      const DayObject = this.getDayObject();
      const allDays = Ember.A(); // Add days to record

      days.map(d => {
        allDays.pushObject(DayObject.create({
          day: d.day,
          isNew: true,
          totalHours: null,
          totalHoursTime: '00:00',
          recordId: null
        }));
      });
      const RecordObject = this.getRecordObject();
      records.pushObject(RecordObject.create({
        user: user,
        task: null,
        tasks: null,
        taskFree: null,
        taskDescription: null,
        project: null,
        genericTask: null,
        id: null,
        days: allDays
      }));
    },

    /**
     * @method getDataTimesheet
     * @description Get data grid timesheet
     * @param {*} days
     * @param {*} user
     * @param {*} dateFrom
     * @param {*} dateTo
     */
    async getDataTimesheet(days, user, dateFrom, dateTo, sortedBy = null, projectActivity = null) {
      // Get data
      const data = await this.get('store').query('timesheet', {
        user: user,
        date_from: dateFrom,
        date_to: dateTo,
        sorted_by: sortedBy,
        project_activity: projectActivity
      });
      return this.renderTimesheet(data, days);
    },

    /**
     * @method renderTimesheet
     * @description Render timesheet
     * @param {*} data
     * @param {*} days
     */
    renderTimesheet(data, days) {
      let totalHours = 0;
      const records = Ember.A();
      const RecordObject = this.getRecordObject();
      data.map(record => {
        // Reset
        const allDays = Ember.A();
        const Day = this.getDayObject(); // Add days to record

        days.map(d => {
          const r = record.days.filter(rd => rd.day === d.day);

          if (r.length > 0) {
            let totalHoursByDay = d.get('totalHoursByDay');
            let totalHours = r[0]['total-hours'];
            let totalHoursTime = r[0]['total-hours-time'];
            let value = totalHoursByDay += parseFloat(totalHours);
            d.set('totalHoursByDay', value);
            allDays.pushObject(Day.create({
              day: d.day,
              isNew: false,
              totalHours: totalHours,
              totalHoursTime: totalHoursTime,
              recordId: r[0]['record-id']
            }));
          } else {
            allDays.pushObject(Day.create({
              day: d.day,
              isNew: true,
              totalHours: null,
              totalHoursTime: '00:00',
              recordId: null
            }));
          }
        }); // Add record

        records.pushObject(RecordObject.create({
          user: record.user,
          task: record.task,
          taskDescription: record.taskDescription,
          tasks: record.tasks,
          taskFree: record.taskFree,
          project: record.project,
          genericTask: record.genericTask,
          id: record.id,
          days: allDays
        }));
        totalHours += record.totalHoursGroup;
      });
      totalHours = Number(totalHours).toFixed(2);
      return {
        records,
        totalHours,
        days
      };
    },

    /**
     * @method hm2dec
     * @description Hours/minutes to decimal
     * @param {*} hoursMinutes
     */
    hm2dec(hoursMinutes) {
      const hours = parseInt(hoursMinutes[0], 10);
      const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
      return (hours + minutes / 60).toFixed(2);
    },

    /**
     * @method time2dec
     * @description Time to decimal
     * @param {*} tIn
     */
    time2dec(tIn) {
      if (tIn == '') return 0;
      if (tIn.indexOf('h') >= 0 || tIn.indexOf(':') >= 0) return this.hm2dec(tIn.split(/[h:]/));
      if (tIn.indexOf('m') >= 0) return this.hm2dec([0, tIn.replace('m', '')]);
      if (tIn.indexOf(',') >= 0) return parseFloat(tIn.split(',').join('.')).toFixed(2);
      if (tIn.indexOf('.') >= 0) return parseFloat(tIn);
      return parseInt(tIn, 10);
    },

    /**
     * @method saveHours
     * @description Save the hours (Create or edit)
     * @param {object} record
     * @param {boolean} isNew
     * @param {object} day
     * @param {object} input
     */
    async saveHours(record, isNew, day, input) {
      const toast = this.get('toast');
      const valueTime = input.target.value;
      const value = parseFloat(this.time2dec(valueTime));

      if (value > 24 || value < 0) {
        toast.warning(this.get('intl').t('timesheet.value.incorrect'));
        return false;
      } // Chequeo que seleccione un proyecto


      if (record.project === null && record.genericTask === null) {
        toast.warning(this.get('intl').t('timesheet.error.project'));
        return false;
      }

      if (isNew && value > 0) {
        const timesheet = this.get('store').createRecord('timesheet', {
          user: record.user,
          date: day.day,
          task: record.task,
          tasks: record.tasks,
          taskFree: record.taskFree,
          project: record.project,
          genericTask: record.genericTask,
          totalHours: value
        });
        day.set('isNew', false);
        day.set('totalHours', value);
        const timesheetObject = await timesheet.save();
        day.set('recordId', timesheetObject.id);
        record.set('id', timesheetObject.id);
      } else {
        if (day.recordId !== null && day.recordId !== undefined) {
          const timesheet = await this.get('store').find('timesheet', day.recordId);
          timesheet.set('task', record.task);
          timesheet.set('taskFree', record.taskFree);
          timesheet.set('project', record.project);
          timesheet.set('genericTask', record.genericTask);
          timesheet.set('totalHours', value);
          await timesheet.save();
          day.set('totalHours', value);
          day.set('totalHoursTime', valueTime);
        }
      }
    },

    /**
     * @method bulkUpdateTimesheet
     * @description Update bulk timesheet
     * @param {*} record
     * @param {*} typeField
     * @param {*} newValue
     */
    async bulkUpdateTimesheet(record, typeField, newValue) {
      const isNewRecord = record.days.filter(d => d.isNew === false);

      if (isNewRecord.length > 0) {
        const days = record.days.filter(d => d.recordId !== null);
        let ids = '';
        days.map(d => {
          ids += d.recordId.toString() + ',';
        });
        ids = ids.substring(0, ids.length - 1);
        await this.get('ajax').request('/bulk-update-delete-timesheet/', {
          method: 'PUT',
          data: {
            type_field: typeField,
            ids: ids,
            new_value: newValue
          }
        });
      }
    },

    /**
     * @method bulkDeleteTimesheet
     * @description Delete bulk timesheet
     * @param {*} record
     * @param {*} data
     */
    async bulkDeleteTimesheet(record, data) {
      const isNewRecord = record.days.filter(d => d.isNew === false);

      if (isNewRecord.length === 0) {
        data.removeObject(record);
      } else {
        const days = record.days.filter(d => d.recordId !== null);
        let ids = '';
        days.map(d => {
          ids += d.recordId.toString() + ',';
        });
        ids = ids.substring(0, ids.length - 1);
        let taskId = null;

        if (record.task) {
          taskId = record.task.get('id');
        }

        await this.get('ajax').request('/bulk-update-delete-timesheet/', {
          method: 'DELETE',
          data: {
            ids: ids,
            taskId: taskId
          }
        });
        data.removeObject(record);
      }
    },

    /**
     * @method getDataForUpdateTotals
     * @description Get data for update column total and total hours general
     * @param {*} data
     * @param {*} day
     */
    getDataForUpdateTotals(data, day, onlyGeneral = false) {
      let totalColumn = 0;
      let totalHoursGeneral = 0;
      data.map(rc => {
        // Get too the totalhours by column
        if (!onlyGeneral) {
          let totalHours = rc.days.filter(d => d.day === day.day)[0].totalHours;

          if (totalHours > 0) {
            totalColumn += parseFloat(totalHours);
          }
        } // Get totalhoursGeneral


        rc.days.map(d => {
          if (d.totalHours > 0) {
            totalHoursGeneral += parseFloat(d.totalHours);
          }
        });
      });
      return {
        totalColumn,
        totalHoursGeneral
      };
    },

    /**
     * @method getUsers
     * @description Get timesheets' users based on user_type of the user logged in
     */
    async getUsers(withHours = false, dateFrom = null, dateTo = null) {
      let response;

      try {
        let url = '/timesheet-users/';

        if (withHours) {
          url = url + `?with_hours=${withHours}&date_from=${dateFrom}&date_to=${dateTo}`;
        }

        response = await this.get('ajax').request(url, {
          method: 'GET'
        });
      } catch (e) {
        response = {
          data: []
        };
      }

      return response;
    },

    /**
     * @method orderUsers
     * @description Order users
     * @param {*} usersData
     * @returns
     */
    orderUsers(usersData) {
      let users = [];

      if (usersData.length > 0) {
        usersData.map(u => users.push(u));
        users = users.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0);
      }

      return users;
    },

    /**
     * /**
     * @method getUsersWithHours
     * @description Filter users with hours, get the users that have hours in the date selected
     * @param {*} filterUser
     * @param {*} typeDateSelected
     * @param {*} date
     */
    async getUsersWithHours(filterUser, typeDateSelected, date) {
      const {
        dateFrom,
        dateTo
      } = (0, _utils.getDateParams)(typeDateSelected, date);
      const response = await this.getUsers(filterUser, dateFrom, dateTo);
      const usersData = response.data;
      const users = this.orderUsers(usersData);
      return users;
    }

  });

  _exports.default = _default;
});