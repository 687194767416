define("mytaskpanel/routes/task-groups", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "mytaskpanel/mixins/only-owner"], function (_exports, _authenticatedRouteMixin, _onlyOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _onlyOwner.default, {
    model() {
      return Ember.RSVP.hash({
        groups: [],
        projects: this.get('store').query('project', {
          'archived': 0,
          'all': 1
        })
      });
    }

  });

  _exports.default = _default;
});