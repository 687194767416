define("mytaskpanel/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    router: Ember.inject.service('-routing'),
    session: Ember.inject.service('session'),
    languageIntl: Ember.inject.service('language-intl'),

    beforeModel() {
      return this.get('languageIntl').setLang();
    },

    /**
    * @method renderTemplate
    * @description Render all templates and show loading before
    */
    renderTemplate() {
      this.render('loading');

      this._super();
    },

    /**
    * Override method from ember-simple-auth
    * for trigget after login success
    */
    sessionAuthenticated() {
      this.get('languageIntl').setLang();
      const prevTransition = this.get("session.prevTransition");

      if (prevTransition) {
        this.transitionTo("index");
      } else {
        this._super(...arguments);
      }
    },

    /**
    * Override method from ember-simple-auth
    * for trigger after logout
    */
    sessionInvalidated() {
      const blockInvalidateUrl = this.get('session.data.blockInvalidateUrl');
      this.set('session.data.blockInvalidateUrl', null); // For onboarding or create new client. Not redirect to login

      switch (blockInvalidateUrl) {
        case 'isCreateNewClient':
          {
            const router = this.get('router');
            router.router.transitionTo('signup');
            break;
          }

        case 'onboarding':
          {
            window.location.reload();
            break;
          }

        default:
          {
            return this._super(...arguments);
          }
      }
    }

  });

  _exports.default = _default;
});