define("mytaskpanel/components/accounts/account-members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    swal: Ember.inject.service('swal'),
    members: null,
    randomId: 'list-members',
    loadMembers: Ember.observer('accountUsers', function () {
      this.setMembers();
    }),
    loadPendingMembers: Ember.observer('privatePendingMembers', function () {
      this.setPendingMembers();
    }),

    didInsertElement() {
      this._super();

      this.setMembers();
      this.setPendingMembers();
    },

    /**
    * @method setMembers
    * @description Set members
    */
    setMembers() {
      // Get members
      if (this.get('account.privacy') === 'P') {
        this.set('members', this.get('accountUsers'));
      } else {
        this.set('members', this.get('allUsers'));
      }
    },

    /**
    * @method setPending
    * @description Set pending members
    */
    setPendingMembers() {
      // Get members for private or public account
      if (this.get('account.privacy') === 'P') {
        this.set('pendingMembers', this.get('privatePendingMembers'));
      } else {
        this.set('pendingMembers', this.get('publicPendingMembers'));
      }
    },

    actions: {
      /**
      * @method resendInvitation
      * @description resend invitation
      */
      async resendInvitation(_user) {
        const clientId = this.get('session.data.authenticated.user').client;
        const user = this.get('session.data.authenticated.user');
        const userInvited = user.first_name + " " + user.last_name;
        const data = {
          invitations: _user.email,
          user_invited: userInvited,
          client_id: clientId,
          resend: 1
        };

        if (this.get('account.privacy') === 'P') {
          data['account_id'] = this.get('account.id');
        }

        this.set('showLoading', true);

        try {
          await this.get('ajax').request('/send-invitations/', {
            method: 'POST',
            data: data
          });
        } catch (e) {// Nothing
        }

        this.set('showLoading', false);
      },

      /**
      * @method removePendingUser
      * @description Remove user pending
      */
      async removePendingUser(user, accountId) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('delete.question');
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          const clientId = this.get('session.data.authenticated.user').client;
          const self = this;
          const formData = new FormData();
          const data = {
            email: user.email,
            account_id: accountId,
            client_id: clientId
          };
          Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
          }); // Delete record

          this.get('ajax').request('/pending-members/', {
            method: 'POST',
            processData: false,
            contentType: false,
            data: formData
          }).then(response => {
            if (response.data.status === 'OK') {
              self.get('pendingMembers').removeObject(user);
            }
          }).catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.name,
              type: 'error',
              allowOutsideClick: false
            }).then(() => {
              window.location.reload();
            });
          });
        }
      },

      /**
      * @method removeUserMember
      * @description Remove user member
      */
      async removeUserMember(accountUser) {
        const deleteText = this.get('intl').t('delete');
        const deleteQuestion = this.get('intl').t('delete.question');
        const result = await this.get('swal').open({
          title: deleteText,
          text: deleteQuestion,
          type: 'warning',
          showCancelButton: true
        });

        if (result.value) {
          await accountUser.destroyRecord().catch(err => {
            this.get('swal').open({
              title: 'Error',
              text: err.name,
              type: 'error',
              allowOutsideClick: false
            });
          });
        }
      },

      /**
      * @method showEditForm
      * @description Redirect to to edit form accountUser
      */
      async showEditForm(accountUser) {
        // Set accountUser
        this.set('accountUser', accountUser); // Set user type

        const userType = accountUser.userType;
        Ember.$('[name=user_type_' + this.get('randomId') + ']').filter('[value="' + userType + '"]').prop('checked', true); // Show modal

        Ember.$("#modal_edit_account_user").modal('show');
      }

    }
  });

  _exports.default = _default;
});