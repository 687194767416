define("mytaskpanel/routes/tasks", ["exports", "mytaskpanel/mixins/auth-route-mixin"], function (_exports, _authRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    async beforeModel(transition) {
      this._super(...arguments);

      try {
        const projectId = transition.params.tasks['project_id'];
        const project = await this.get('store').find('project', projectId);

        if (!project.get('active') || !project.get('accountActive')) {
          this.transitionTo('accounts');
        }
      } catch (e) {
        this.transitionTo('accounts');
      }
    },

    model(params) {
      const userId = this.get('session.data.authenticated.user').id;
      return Ember.RSVP.hash({
        params: params,
        user: this.get('store').find('user', userId),
        users: this.get('store').query('user', {
          'project_id': params.project_id
        }, {
          reload: true
        }),
        accountUser: this.get('store').query('account-user', {
          account: params.account_id,
          user: userId
        }).then(records => {
          return records.get('firstObject');
        }),
        projectUser: this.get('store').query('project-user', {
          project: params.project_id,
          user: userId
        }).then(records => {
          return records.get('firstObject');
        }),
        labels: this.get('store').findAll('label'),
        taskGroupsByName: this.get('store').query('task-group', {
          'project_id': params.project_id,
          'order': 'name'
        }),
        taskGroups: this.get('store').query('task-group', {
          'project_id': params.project_id,
          'order': 'group_order'
        }),
        taskStates: this.get('ajax').request('/get-task-states/', {
          method: 'GET'
        }),
        projects: this.get('store').query('project', {
          'account': params.account_id
        }, {
          reload: true
        }),
        project: this.get('store').find('project', params.project_id),
        tasks: this.get('store').query('task', {
          'type_filter': 'alltasks_bystate',
          'project_id': params.project_id
        }).then(tasks => {
          return tasks.toArray();
        })
      });
    },

    afterModel(model) {
      const userId = this.get('session.data.authenticated.user').id;
      const user = model.users.filter(us => {
        return parseInt(us.id) === userId;
      }); // Check if has permission to enter to project

      if (user.length === 0) {
        this.transitionTo('accounts');
      }
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('model', model);
    },

    actions: {
      /**
      * @method updateTasks
      * @description Update tasks route model
      */
      updateTasksRoute() {
        const controller = this.controllerFor('tasks'); // Update model

        this.model(this.context.params).then(m => {
          controller.set('model', m);
        });
      },

      /**
      * @method refreshRoute
      * @description Reload route
      */
      refreshRoute() {
        this.refresh();
      },

      willTransition() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});