define("mytaskpanel/components/gantt-line-barcontent", ["exports", "@kockpit/ember-gantt/components/gantt-line-barcontent"], function (_exports, _ganttLineBarcontent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ganttLineBarcontent.default;
    }
  });
});