define("mytaskpanel/templates/components/utils/text-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xnkwosd2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isEditing\"]]],null,{\"statements\":[[0,\"    \"],[14,1,[[27,\"action\",[[22,0,[]],\"toggleEditing\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"span-text-editable\"],[9],[0,\"\\n        \"],[1,[21,\"text\"],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"toggleEditing\",true],[[\"on\"],[\"click\"]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/utils/text-editable.hbs"
    }
  });

  _exports.default = _default;
});