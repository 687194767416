define("mytaskpanel/components/tasks/form-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-dupe-keys */
  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    taskService: Ember.inject.service('task'),

    /**
    * @method all
    * @description Get all tasks
    */
    all() {
      this.get('taskService').resetTasks(this.parent.parent.tasks);
    },

    /**
    * @method unassigned
    * @description Get unassigned tasks
    */
    unassigned() {
      this.get('taskService').getUnassignedTasks(this.parent.parent.tasks);
    },

    /**
    * @method getAssignedToMe
    * @description Get assigned to user logged tasks
    */
    getAssignedToMe() {
      const userId = this.get('session.data.authenticated.user').id;
      this.get('taskService').getAssignedFilterUser(this.parent.parent.tasks, userId);
    },

    /**
    * @method getAssignedToUser
    * @description Filter by user select
    */
    getAssignedToUser() {
      const userId = parseInt(Ember.$('#form-filter-tasks-user').val());
      this.get('taskService').getAssignedFilterUser(this.parent.parent.tasks, userId);
    },

    /**
    * @method getFollowedByMe
    * @description Get followed tasks by user logged
    */
    getFollowedByMe() {
      const userId = this.get('session.data.authenticated.user').id;
      this.get('taskService').getFollowedByMe(this.parent.parent.tasks, userId);
    },

    /**
    * @method getThatHaveLabels
    * @description Get have labels selected
    */
    getThatHaveLabels() {
      const labelColor = Ember.$('#form-filter-tasks-label-have').val();
      this.get('taskService').getThatHaveOrDontHaveLabels(this.parent.parent.tasks, labelColor, true);
    },

    /**
    * @method getThatDontHaveLabels
    * @description Get don't have labels selected
    */
    getThatDontHaveLabels() {
      const labelColor = Ember.$('#form-filter-tasks-label-dont-have').val();
      this.get('taskService').getThatHaveOrDontHaveLabels(this.parent.parent.tasks, labelColor, false);
    },

    /**
    * @method getDueDateByToday
    * @description Get tasks due date today
    */
    getDueDateByToday() {
      this.get('taskService').getTasksByDueDate(this.parent.parent.tasks, 'today');
    },

    /**
    * @method getDueDateByExpired
    * @description Get Tasks due date expired
    */
    getDueDateByExpired() {
      this.get('taskService').getTasksByDueDate(this.parent.parent.tasks, 'expired');
    },

    /**
    * @method getDueDateByExpireTomorrow
    * @description Get tasks due date expire tomorrow
    */
    getDueDateByExpireTomorrow() {
      this.get('taskService').getTasksByDueDate(this.parent.parent.tasks, 'tomorrow');
    },

    actions: {
      /**
      * @method resetFilters
      * @description Reset filters
      */
      resetFilters() {
        Ember.$('#radio_user_1').prop('checked', 'checked');
        Ember.$('#check_with_task_father').prop('checked', '');
        Ember.$('#check_task_final').prop('checked', '');
        Ember.$('#radio_date_all').prop('checked', 'checked');
        Ember.$('#radio_label_all').prop('checked', 'checked');
        Ember.$('#form-filter-tasks-label-have').val('');
        Ember.$('#form-filter-tasks-label-dont-have').val('');
        Ember.$('#form-filter-tasks-user').val('');
        this.all();
      },

      /**
      * @method setRadioUserSelect
      * @description Set radio for user selected
      */
      setRadioUserSelect() {
        Ember.$('#radio_user_4').removeAttr('checked');
        Ember.$('#radio_user_4').attr('checked', 'checked');
        this.send('filter');
      },

      /**
      * @method setRadioLabel
      * @description Set radio selected
      * @param {*} radioLabel
      */
      setRadioLabel(radioLabel) {
        if (radioLabel === '1') {
          Ember.$('#radio_label_2').removeAttr('checked');
          Ember.$('#radio_label_1').attr('checked', 'checked');
        } else {
          Ember.$('#radio_label_1').removeAttr('checked');
          Ember.$('#radio_label_2').attr('checked', 'checked');
        }

        this.send('filter');
      },

      /**
      * @method filter
      * @description Orchestra all methods together
      */
      filter() {
        const radioUserValue = Ember.$('[name="radioUser"]:checked').val();
        const radioDateValue = Ember.$('[name="radioDate"]:checked').val();
        const radioLabelValue = Ember.$('[name="radioLabel"]:checked').val();
        const checkFather = Ember.$('#check_with_task_father').is(':checked');
        const checkFinal = Ember.$('#check_task_final').is(':checked');
        const methodsUser = {
          'optionUser1': 'all',
          'optionUser2': 'unassigned',
          'optionUser3': 'getAssignedToMe',
          'optionUser4': 'getAssignedToUser',
          'optionUser5': 'getFollowedByMe'
        };
        const methodsDate = {
          'optionDate1': 'all',
          'optionDate2': 'getDueDateByToday',
          'optionDate3': 'getDueDateByExpired',
          'optionDate4': 'getDueDateByExpireTomorrow'
        };
        const methodsLabel = {
          'optionLabel1': 'all',
          'optionLabel2': 'getThatHaveLabels',
          'optionLabel3': 'getThatDontHaveLabels'
        };

        try {
          this.all();

          if (methodsUser[radioUserValue] !== 'all') {
            eval('this.' + methodsUser[radioUserValue] + '()');
          }

          if (methodsDate[radioDateValue] !== 'all') {
            eval('this.' + methodsDate[radioDateValue] + '()');
          }

          if (methodsLabel[radioLabelValue] !== 'all') {
            eval('this.' + methodsLabel[radioLabelValue] + '()');
          }

          if (checkFather && !checkFinal) {
            this.get('taskService').getTaskWithFather(this.parent.parent.tasks);
          }

          if (checkFinal && !checkFather) {
            this.get('taskService').getTaskWithoutFather(this.parent.parent.tasks);
          }
        } catch (e) {// Nothing
        }
      }

    }
  });

  _exports.default = _default;
});