define("mytaskpanel/routes/onboarding", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    router: Ember.inject.service('-routing'),
    toast: Ember.inject.service('toast'),

    async beforeModel() {
      const token = window.location.search.split('token=')[1];
      const response = await this.get('ajax').request('/is-valid-token/', {
        method: 'POST',
        data: {
          token: token
        }
      }).catch(() => {
        this.transitionTo('login');
      });

      if (!response.data.valid) {
        this.transitionTo('login');
      }
    },

    async model() {
      try {
        const router = this.get('router');
        const token = window.location.search.split('token=')[1];
        const response = await this.get('ajax').request('/onboarding/?token=' + token, {
          method: 'GET'
        }); // If exists the record in the account

        if (response.data.exists_record_account) {
          const data = {
            user_type: response.data.user_type,
            type_invitation: response.data.type_invitation,
            email: response.data.email,
            client: response.data.client_id,
            account: response.data.account_id,
            username: response.data.username
          };
          const formData = new FormData();
          Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
          }); // Not show form onboarding and redirect to login

          const responseOnBoarding = await this.get('ajax').request('/onboarding/', {
            method: 'POST',
            processData: false,
            contentType: false,
            data: formData
          });

          if (responseOnBoarding.data.success) {
            router.router.transitionTo('login');
          }
        }

        return response.data;
      } catch (err) {
        const toast = this.get('toast');
        toast.error(this.get('intl').t('auth.onboarding.error'));
      }
    }

  });

  _exports.default = _default;
});