define("mytaskpanel/templates/components/users/edit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8dinuO0w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"form\"],[11,\"class\",\"card-box\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"email\"],null],false],[0,\": \"],[1,[23,[\"user\",\"email\"]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"utils/user-type\",null,[[\"randomId\"],[[23,[\"randomId\"]]]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"form-group text-right m-t-20\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-xs-12\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-custom w-md waves-effect waves-light\"],[11,\"type\",\"submit\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"edit\"],null],false],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"editUser\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/components/users/edit-form.hbs"
    }
  });

  _exports.default = _default;
});