define("mytaskpanel/services/calendar", ["exports", "mytaskpanel/data/calendarTheme", "moment"], function (_exports, _calendarTheme, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    intl: Ember.inject.service('intl'),

    /**
     * @method createCalendar
     * @description Create a ToastUI Calendar instance and return it
     */
    createCalendar() {
      // Setting calendar configuration
      const config = {
        defaultView: 'month',
        theme: _calendarTheme.default,
        taskView: false,
        scheduleView: true,
        useDetailPopup: true,
        isReadOnly: true,
        template: {
          allday: schedule => {
            return schedule.title;
          },
          alldayTitle: () => {
            return 'All Day';
          },
          time: schedule => {
            return schedule.title;
          },
          popupDetailDate: (isAllDay, start, end) => {
            return `${this.get('intl').t('date.date_due')}: ${(0, _moment.default)(end._date).format('ll')}`;
          }
        },
        month: {
          daynames: [this.get('intl').t('calendar.sun'), this.get('intl').t('calendar.mon'), this.get('intl').t('calendar.tue'), this.get('intl').t('calendar.wed'), this.get('intl').t('calendar.thu'), this.get('intl').t('calendar.fri'), this.get('intl').t('calendar.sat')],
          startDayOfWeek: 0,
          narrowWeekend: true
        },
        week: {
          daynames: [this.get('intl').t('calendar.sun'), this.get('intl').t('calendar.mon'), this.get('intl').t('calendar.tue'), this.get('intl').t('calendar.wed'), this.get('intl').t('calendar.thu'), this.get('intl').t('calendar.fri'), this.get('intl').t('calendar.sat')],
          startDayOfWeek: 0,
          narrowWeekend: true
        }
      };
      return new window.tui.Calendar('#calendar', config);
    },

    /**
     * @method setTasksOnCalendar
     * @description Get tasks and set them on the calendar component
     */
    setTasksOnCalendar(calendar, projectId) {
      // Getting current project tasks
      this.get('store').query('task', {
        type_filter: 'alltasks_bygroup',
        project_id: projectId
      }).then(tasks => {
        let schedules = [];
        tasks.forEach(task => {
          // Getting tasks with dueDate or endDate attributes
          if (task.dueDate !== null || task.endDate !== null) {
            // Saving final date
            const taskDate = task.dueDate !== null ? task.dueDate : task.endDate; // Getting task color

            const taskColor = this.getTaskColor(task); // Creating a new schedule

            const newSchedule = {
              id: task.id,
              calendarId: '1',
              title: task.name,
              category: 'time',
              isAllDay: true,
              color: '#FFFFFF',
              bgColor: taskColor,
              borderColor: taskColor,
              start: (0, _moment.default)(taskDate).toDate(),
              end: (0, _moment.default)(taskDate).toDate()
            }; // Adding schedule to schedules array

            schedules.push(newSchedule);
          }
        }); // Setting chosen tasks on calendar component

        calendar.createSchedules(schedules);
      });
    },

    /**
     * @method getTaskColor
     * @description Set a color for each task depending on its state
     * @param {Object} task Current task
     */
    getTaskColor(task) {
      if (task.endDate !== null) {
        return '#9E9E9E'; // Grey
      } else if (task.startDate === null) {
        return '#FF6D00'; // Orange
      } else if (task.startDate !== null && task.dueDate !== null && task.endDate === null && (0, _moment.default)(task.startDate).isBefore((0, _moment.default)()) && (0, _moment.default)(task.dueDate).isAfter((0, _moment.default)())) {
        return '#00C853'; // Green
      } else if (task.dueDate !== null && (0, _moment.default)(task.dueDate).isBefore((0, _moment.default)())) {
        return '#FF1744'; // Red
      } else {
        return '#00B0FF'; // Lightblue
      }
    }

  });

  _exports.default = _default;
});