define("mytaskpanel/routes/favorite-projects", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),

    model() {
      const userId = this.get('session.data.authenticated.user').id;
      return Ember.RSVP.hash({
        projects: this.get('store').query('project', {
          'favorites': '1'
        }),
        user: this.get('store').find('user', userId)
      });
    }

  });

  _exports.default = _default;
});