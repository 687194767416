define("mytaskpanel/components/utils/send-invitations", ["exports", "mytaskpanel/libs/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    router: Ember.inject.service('router'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    toast: Ember.inject.service('toast'),
    randomId: 'send-invitation',
    users: null,
    showLoading: false,

    didInsertElement() {
      this._super(); // Default member


      Ember.$('[name=user_type_' + this.get('randomId') + '][value="M"]').attr('checked', 'checked'); // Load guests users for all unless list-users (not is necessary because invite new people)

      if (this.get('from') !== 'list-users') {
        this.set('users', this.get('store').peekAll('user'));
      } // Configure invitations system


      Ember.run.later(() => {
        Ember.$('.tokenize-guests_' + this.get('randomId')).tokenize2({
          delimiter: [',', ' '],
          tokensAllowCustom: true
        });
        const self = this;
        Ember.$('.tokenize-guests_' + this.get('randomId')).on('tokenize:tokens:remove', (event, guestId) => {
          Ember.run(() => {
            try {
              const f = self.get('users').find(x => x.id === guestId);
              f['delete'] = true;
            } catch (e) {// Nothing
            }
          });
        }); // Enter auto

        Ember.$('.tokenize-guests_' + this.get('randomId')).on('tokenize:deselect', () => {
          const values = Ember.$('.tokenize-guests_' + self.get('randomId')).next().find('ul > li > input').val();

          if (values !== '' && values !== null && values !== undefined) {
            Ember.$('.tokenize-guests_' + self.get('randomId')).trigger('tokenize:tokens:add', [values]);
          }
        });
      }, 100);
    },

    /**
     * @method checkIfExistUserSchema
     * @description Check if the user exists by email
     * @param {*} email
     */
    async checkIfExistUserSchema(email) {
      const formData = new FormData();
      formData.append('email', email);
      const response = await this.get('ajax').request('/check-if-exists-user-schema/', {
        method: 'POST',
        processData: false,
        contentType: false,
        data: formData
      });
      return response.data.exists;
    },

    actions: {
      /**
      * @method sendInvitations
      * @description Send invitations
      */
      async sendInvitations() {
        const toast = this.get('toast'); // Check if the user can invite more people and that the plan is not limiting it

        const response = await this.get('ajax').request('/check-plan-permission-client/', {
          method: 'POST',
          data: {
            'action': 'user'
          }
        }); // If success is true, can invite more people

        if (response.data.success) {
          const invitations = Ember.$('#invitations_' + this.get('randomId')).val().toString();
          const userType = Ember.$('[name=user_type_' + this.get('randomId') + ']:checked').val();

          if (Ember.isPresent(invitations) && Ember.isPresent(userType)) {
            const user = this.get('session.data.authenticated.user');
            const userInvited = user.first_name + " " + user.last_name;
            const clientId = this.get('session.data.authenticated.user').client;
            const data = {
              invitations: invitations,
              user_invited: userInvited,
              client_id: clientId,
              user_type: userType
            }; // Check if the emails are valid

            let emailsInvalid = false;
            let existingEmails = [];
            const invitationsList = invitations.split(',');

            for (let invitation of invitationsList) {
              const existuser = await this.checkIfExistUserSchema(invitation);

              if (existuser) {
                existingEmails.push(invitation);
              }

              if (!(0, _utils.validateEmail)(invitation.trim())) {
                emailsInvalid = true;
              }
            }

            if (existingEmails.length > 0 && this.get('type_account') !== 'P') {
              toast.error(this.get('intl').t('users.invite.existing.error') + `: ${existingEmails.join(', ')}`);
              return;
            } // if has invalid emails informe with a message


            if (emailsInvalid) {
              toast.error(this.get('intl').t('account.invite_email_invalid'));
              return;
            } // If is private add the account_id


            if (this.get('type_account') === 'P') {
              data['account_id'] = this.get('account.id');
            }

            data['account_name'] = this.get('account.name'); // Send the invitations

            this.set('showLoading', true);
            const response = await this.get('ajax').request('/send-invitations/', {
              method: 'POST',
              data: data
            });
            this.set('showLoading', false);

            if (response.data.success) {
              // Reset to member default
              Ember.$('[name=user_type_' + this.get('randomId') + ']').filter('[value="M"]').prop('checked', true);
              Ember.$('#invitations_' + this.get('randomId')).val('');
              Ember.$('.tokenize-guests_' + this.get('randomId')).tokenize2().trigger('tokenize:clear');
              toast.success(this.get('intl').t('account.invitations.ok'));

              if (this.get('from') !== 'accounts-list') {
                invitations.split(',').map(invitation => {
                  this.get('pendingMembers').addObject({
                    email: invitation.trim()
                  });
                });
              }
            } else {
              toast.error(this.get('intl').t('account.invitations.error'));
            }
          } else {
            // For the another cases yes
            toast.error(this.get('intl').t('account.invite.empty'));
          }
        } else {
          toast.error(this.get('intl').t('account.upgrade_plan'));
        }
      },

      /**
       * @method closeInvitation
       * @description Close the modal invitations
       */
      closeInvitation() {
        Ember.$('#' + this.get('modalId')).modal('hide');

        if (this.get('from') === 'accounts-list') {
          // Redirect to projects
          this.get('router').transitionTo('projects', this.get('account.id'), this.get('account.slug'));
        }
      }

    }
  });

  _exports.default = _default;
});