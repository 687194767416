define("mytaskpanel/components/auth/form-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    intl: Ember.inject.service('intl'),
    actions: {
      /**
      * @method init
      * @description Initialize form
      */
      init() {
        this.actions.resetErrors(this);
        this.set('user', '');
        this.set('password', '');
      },

      /**
      * @method resetErrors
      * @description Reset errors messages
      */
      resetErrors(self) {
        self.set('errorMessage', '');
      },

      /**
      * @method authenticate
      * @description Login authentication
      */
      authenticate() {
        this.actions.resetErrors(this);
        const {
          email,
          password
        } = this.getProperties('email', 'password');

        if (!Ember.isPresent(email) || !Ember.isPresent(password)) {
          this.set('errorMessage', this.get('intl').t('auth.login.error'));
        } else {
          this.get('session').authenticate('authenticator:jwt', email, password).catch(() => {
            this.set('errorMessage', this.get('intl').t('auth.login.error'));
          });
        }
      }

    }
  });

  _exports.default = _default;
});