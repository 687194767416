define("mytaskpanel/components/accounts/account-dashboard", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*global Chartist */
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    accountDashboardService: Ember.inject.service('account-dashboard'),
    session: Ember.inject.service('session'),
    intl: Ember.inject.service('intl'),
    swal: Ember.inject.service('swal'),
    toast: Ember.inject.service('toast'),
    accountsCount: null,
    activeProjectsState: null,
    projectsNumbers: null,
    tasksCountByUser: null,
    tasksNumbers: null,
    showLoadingSpinner: true,
    showLoadingResultProject: false,
    filterHoursByWeek: false,
    selectedMonth: '',
    selectedWeek: '',
    filterByMonth: Ember.observer('filterHoursByWeek', 'selectedMonth', 'selectedWeek', function () {
      this.send('filterDashboardTimesheet');
    }),

    async didInsertElement() {
      this._super();

      const userType = this.get('session.data.authenticated.user').user_type;
      this.set('userType', userType); // Doing request

      const response = await this.get('ajax').request('/accounts-dashboard/', {
        method: 'GET'
      }); // Stopping loading spinner animation

      this.set('showLoadingSpinner', false); // Setting dashboard data

      this.set('accountsCount', response.data.accounts_count);
      this.set('activeProjectsState', response.data.active_projects_state);
      this.set('projectsNumbers', response.data.projects_numbers);
      this.set('tasksCountByUser', response.data.tasks_count_by_user);
      this.set('tasksNumbers', response.data.tasks_numbers);
      this.set('selectedMonth', (0, _moment.default)().format('YYYY-MM'));
      this.set('selectedWeek', (0, _moment.default)().weekYear() + '-W' + ((0, _moment.default)().week() - 1)); // Init Summary of results by project

      this.send('filterDashboardTimesheet');
    },

    didRender() {
      this._super();

      if (!this.get('showLoadingSpinner')) {
        // Loading charts
        this.loadTaskSummaryChart();
        this.loadOpenTasksByUserChart();
        Ember.$("span.ct-label.ct-horizontal.ct-end").attr("width", 50);
        Ember.$("span.ct-label.ct-horizontal.ct-end").attr("height", 30);
      }
    },

    /**
     * @method setSummaryTimesheetData
     * @description Set variables to render total hours by project and by user in a project
     */
    async setSummaryTimesheetData(typeDate) {
      this.set('showLoadingResultProject', true);
      const {
        projectsActivities,
        timesheetUsers,
        totalHoursUsers
      } = await this.get('accountDashboardService').getSummaryTimesheetData(typeDate, this.selectedMonth, this.selectedWeek);
      this.set('totalhoursProjectUser', projectsActivities);
      this.set('timesheetUsers', timesheetUsers);
      this.set('totalHoursUsers', totalHoursUsers);
      this.set('showLoadingResultProject', false);
    },

    /**
     * @method loadTaskSummaryChart
     * @description Loading data to tasks summary by state chart
     */
    loadTaskSummaryChart() {
      Ember.$('#accounts-dashboard-progress-bar-completed').attr('style', `width:${this.get('tasksNumbers').completed.percent}%`);
      Ember.$('#accounts-dashboard-progress-bar-delayed').attr('style', `width:${this.get('tasksNumbers').overdue.percent}%`);
      Ember.$('#accounts-dashboard-progress-bar-planned').attr('style', `width:${this.get('tasksNumbers').planned.percent}%`);
      Ember.$('#accounts-dashboard-progress-bar-no-date').attr('style', `width:${this.get('tasksNumbers').without_due_date.percent}%`);
    },

    /**
     * @method loadOpenTasksByUserChart
     * @description Loading data to open tasks by user chart
     */
    loadOpenTasksByUserChart() {
      const emails = this.get('tasksCountByUser').map(user => {
        return user.email.split('@')[0];
      });
      const count = this.get('tasksCountByUser').map(user => user.tasks_count);
      Chartist.Bar('#open-tasks-by-user-chart', {
        labels: emails,
        series: [count]
      }, {
        seriesBarDistance: 10,
        axisX: {
          offset: 80,
          showGrid: false
        },
        axisY: {
          offset: 80,
          labelInterpolationFnc: value => {
            if (value === 1) {
              return value + ` ${this.get('intl').t('task.singular')}`;
            } else {
              return value + ` ${this.get('intl').t('task.plural')}`;
            }
          },
          scaleMinSpace: 15,
          onlyInteger: true
        }
      });
    },

    /**
     * @method updateTableData
     * @description Update table modifiable fields
     * @param {Integer} projectId Selected project id
     * @param {String} color Selected project color
     * @param {String} nextControl Selected project next control
     * @param {String} observations Selected project observations
      * @param {String} userPm User pm selected
     */
    updateTableData(projectId, color, nextControl, observations, userPm = null) {
      // Getting selected project index
      const projects = this.get('activeProjectsState');
      const i = projects.findIndex(p => p.project_id === projectId); // Updating array data

      this.set(`activeProjectsState.${i}.color`, color);
      this.set(`activeProjectsState.${i}.next_control`, nextControl);
      this.set(`activeProjectsState.${i}.observations`, observations);
      this.set(`activeProjectsState.${i}.user_pm`, userPm); // Doing request

      this.get('ajax').request('/accounts-dashboard/', {
        method: 'PUT',
        data: {
          color,
          observations,
          project_id: projectId,
          next_control: nextControl,
          user_pm: userPm
        }
      }).then(response => {
        if (response.data.status_code === 200) {
          this.get('toast').success(this.get('intl').t('table.updated'));
        } else {
          this.get('toast').error(this.get('intl').t('table.updated.error'));
        }
      }).catch(() => {
        this.get('toast').error(this.get('intl').t('table.updated.error'));
      });
    },

    actions: {
      /**
       * @method updateColor
       * @description Show popup to change project color
       * @param {Object} project Project id and modifiable fields
       */
      updateColor(project) {
        // Getting user type
        const userType = this.get('userType'); // If user is Admin or Owner, can edit modifiable fields

        if (userType === 'O' || userType === 'A') {
          // Getting selected color
          const newColor = Ember.$(`#dashboard-color-input-${project.project_id}`).val(); // Updating data

          this.updateTableData(project.project_id, newColor, project.next_control, project.observations, project.user_pm);
        }
      },

      /**
       * @method updateObservations
       * @description Show popup to change selected project observations
       * @param {Object} project Project id and modifiable fields
       */
      updateObservations(project) {
        // Getting user type
        const userType = this.get('userType'); // If user is Admin or Owner, can edit modifiable fields

        if (userType === 'O' || userType === 'A') {
          // Getting current observations
          const observations = project.observations ? project.observations : ''; // SweetAlert custom popup

          this.get('swal').open({
            title: this.get('intl').t('observations'),
            showCancelButton: true,
            html: `
						<div class="form-group">
							<textarea
								class="form-control"
								id="dashboard-observations-input-${project.project_id}"
								rows="3"
							>${observations}</textarea>
						</div>
					`,
            cancelButtonText: this.get('intl').t('cancel'),
            confirmButtonText: this.get('intl').t('update')
          }).then(result => {
            // If user pressed on Confirm button
            if (result.value) {
              // Getting project id
              const projectId = project.project_id; // Getting new observations

              const newObs = Ember.$(`#dashboard-observations-input-${projectId}`).val(); // Updating data

              this.updateTableData(project.project_id, project.color, project.next_control, newObs, project.user_pm);
            }
          });
        }
      },

      /**
       * @method updatePM
       * @description Update pm field
       * @param {*} project
       */
      async updatePM(project) {
        // Getting user type
        const userType = this.get('userType'); // If user is Admin or Owner, can edit modifiable fields

        if (userType === 'O' || userType === 'A') {
          // SweetAlert custom popup
          this.get('swal').open({
            title: 'Project Manager',
            showCancelButton: true,
            html: `
            <div class="form-group">
              <div class="col-12">
                <input
                  id="dashboard-project-pm-${project.project_id}"
                  class="form-control"
                  type="text"
                  value="${project.user_pm ? project.user_pm : ''}"
                  maxlength="20"
                />
              </div>
            </div>
					`,
            cancelButtonText: this.get('intl').t('cancel'),
            confirmButtonText: this.get('intl').t('update')
          }).then(result => {
            // If user pressed on Confirm button
            if (result.value) {
              // Getting project id
              const projectId = project.project_id; // Getting new user_pm

              const userPm = Ember.$(`#dashboard-project-pm-${projectId}`).val(); // Updating data

              this.updateTableData(project.project_id, project.color, project.next_control, project.observations, userPm);
            }
          });
        }
      },

      /**
       * @method filterDashboardTimesheet
       * @description Load Summary of results by project by week or month
       */
      filterDashboardTimesheet() {
        if (this.filterHoursByWeek) {
          this.setSummaryTimesheetData('week');
        } else {
          this.setSummaryTimesheetData('month');
        }
      },

      /**
       * @method selectMonth
       * @description Select the month to filter the hours
       */
      selectMonth(month) {
        this.set('selectedMonth', month);
      },

      /**
       * @method selectWeek
       * @description Select the week to filter the hours
       */
      selectWeek(week) {
        this.set('selectedWeek', week);
      },

      /**
       * @method setDateNextControl
       * @description Set next control date of a project
       */
      setDateNextControl(project) {
        const dateSelected = Ember.$(`#datenextcontrol_${project.project_id}`).val();

        if (dateSelected) {
          this.updateTableData(project.project_id, project.color, window.moment(dateSelected).format('YYYY-MM-DD'), project.observations, project.user_pm);
        }
      },

      /**
       * @method selectRowSummary
       * @description Selected row and change color
       * @param {*} rows
       * @param {*} row
       */
      selectRowSummary(rows, row) {
        const newValue = !row.selected;
        rows.map(record => {
          record.set('selected', false);
        });
        row.set('selected', newValue);
      }

    }
  });

  _exports.default = _default;
});