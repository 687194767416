define("mytaskpanel/templates/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3NUveueX",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"settings/form-profile\",null,[[\"user\",\"countries\",\"languages\",\"company_size\",\"timezones\"],[[23,[\"model\",\"user\"]],[23,[\"model\",\"utils\",\"countries\"]],[23,[\"model\",\"utils\",\"languages\"]],[23,[\"model\",\"utils\",\"company_size\"]],[23,[\"model\",\"utils\",\"timezones\"]]]]],false],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mytaskpanel/templates/settings.hbs"
    }
  });

  _exports.default = _default;
});