define("mytaskpanel/components/accounts/account-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service('store'),
    // Load data for account
    loadAccount: Ember.observer('account', async function () {
      let userType = this.get('parent.userType');
      this.set('userType', userType); // If is private get accountUsers and pendingMembers

      if (this.get('account.privacy') === 'P') {
        // set user_type
        const records = await this.get('store').query('account-user', {
          account: this.get('account.id'),
          user: this.get('parent.user.id')
        });
        const accountUser = records.get('firstObject');
        userType = accountUser.userType;
        this.set('userType', userType); // Get users

        const responseAccountUser = await this.get('store').query('account-user', {
          account: this.get('account.id')
        });
        this.set('accountUsers', responseAccountUser); // Get pending members

        const clientId = this.get('session.data.authenticated.user').client;
        const accountId = this.get('account.id');
        const urlPendingMembers = '/pending-members/?client_id=' + clientId + '&account_id=' + accountId;
        const responsePendingMembers = await this.get('ajax').request(urlPendingMembers, {
          method: 'GET'
        });
        this.set('privatePendingMembers', responsePendingMembers.data);
      }
    })
  });

  _exports.default = _default;
});