define("mytaskpanel/services/language-intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    intl: Ember.inject.service('intl'),

    /**
     * @method getLanguage
     * @description Get language from storage or browser
     */
    getLanguage() {
      let clientLanguage;

      try {
        clientLanguage = this.get('session.data.authenticated.user').client_language;

        if (clientLanguage === null || clientLanguage === undefined || clientLanguage === '') {
          clientLanguage = 'en';
        }
      } catch (e) {
        // Get lang from broweser
        let lang = this.getLanguageBrowser();
        clientLanguage = lang;
      }

      return clientLanguage;
    },

    /**
    * @method setLang
    * @description Set language
    */
    setLang() {
      const clientLanguage = this.getLanguage();
      return this.get('intl').setLocale([clientLanguage]);
    },

    /**
     * @method getLanguageBrowser
     * @description Get language from browser
     */
    getLanguageBrowser() {
      let lang = navigator.language;
      lang = lang.split('-')[0].trim().toLocaleLowerCase();

      if (lang !== 'es' && lang !== 'en') {
        lang = 'es';
      }

      return lang;
    }

  });

  _exports.default = _default;
});