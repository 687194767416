define("mytaskpanel/components/auth/password-reset-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    // Check if password match
    checkPassword: Ember.observer('password', 'confirm_password', function () {
      if (Ember.isPresent(this.password) && Ember.isPresent(this.confirm_password)) {
        if (this.password != this.confirm_password) {
          this.set('error_password', this.get('intl').t('auth.error_password'));
        } else {
          this.set('error_password', '');
        }
      }
    }),
    enableReset: Ember.computed('password', 'confirm_password', {
      get() {
        if (Ember.isPresent(this.password) && Ember.isPresent(this.confirm_password)) {
          if (this.password != this.confirm_password) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }

    }),
    actions: {
      /**
      * @method resetPassword
      * @description Reset password
      */
      resetPassword() {
        this.set('errorMessage', '');
        const {
          password
        } = this.getProperties('password');
        const uidb64 = this.get('params.uidb64');
        const token = this.get('params.token');
        const tokenEmail = this.get('params.token_email');
        this.get('ajax').request('/password-reset-form/', {
          method: 'POST',
          data: {
            uidb64: uidb64,
            token: token,
            token_email: tokenEmail,
            password: password
          }
        }).then(response => {
          if (response.data.success) {
            this.get('session').authenticate('authenticator:jwt', response.data.email, password).catch(reason => {
              this.set('errorMessage', reason.responseJSON.non_field_errors);
            });
          } else {
            this.set('errorMessage', this.get('intl').t('auth.reset_password.error'));
          }
        }).catch(() => {
          this.set('errorMessage', this.get('intl').t('auth.reset_password.error'));
        });
      }

    }
  });

  _exports.default = _default;
});